import React, { useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import MyPaymentHistory from "./MyPaymentHistory";
import MyTransferHistory from "./MyTransferHistory";
import paymentIcon from "../../../images/payment_icon_darkblue.svg";
import withdrawIcon from "../../../images/withdraw_icon_darkblue.svg";

const MyTransactions = ({
  setIsPageLoading
}) => {
  const [isMyPaymentsVisible, setIsMyPaymentsVisible] = useState(false);
  const [isMyWithdrawalsVisible, setIsMyWithdrawals] = useState(false);

  return (
    <div>
      <div>
        <div className="padding-bottom-18">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsMyPaymentsVisible(true)}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={paymentIcon}
            />
            <span className="font-size-14 font-size-sm-17">My Payment History</span>
          </div>
        </div>

        <div className="padding-top-18 padding-bottom-10">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsMyWithdrawals(true)}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={withdrawIcon}
            />
            <span className="font-size-14 font-size-sm-17">My Transfer History</span>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isMyPaymentsVisible}
        onRequestClose={() => setIsMyPaymentsVisible(false)}
        className="modal max-width-980 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            My Payment History
          </h1>
          <i
            onClick={() => setIsMyPaymentsVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div className=" padding-10">
          <div
            style={{ maxHeight: "80vh" }}
            className="overflow-scroll-y padding-0 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
          >
            <MyPaymentHistory setIsPageLoading={setIsPageLoading} />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isMyWithdrawalsVisible}
        onRequestClose={() => setIsMyWithdrawals(false)}
        className="modal max-width-980 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            My Transfer History
          </h1>
          <i
            onClick={() => setIsMyWithdrawals(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className=" padding-10">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
          >
            <MyTransferHistory
              setIsPageLoading={setIsPageLoading}
            />
          </div>
        </div>
      </Modal>


    </div>
  );
};

export default connect()(MyTransactions);
