import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import CurrencyField from "../../../utilityComponents/CurrencyField";
import MoneyInputField from "../../../utilityComponents/MoneyInputField";
import { getSomeoneBasicDetails } from "../../../../store/actions/auth";
import { guaranteePoolMember } from "../../../../store/actions/moneyPool";

const AddNewGuarantee = ({
  user,
  setIsPageLoading,
  dispatch,
  handleClose
}) => {
  const [error, setError] = useState("");
  const [someoneGuaranteed, setSomeoneGuaranteed] = useState({});

  return (
    <div>
      <div className="row">
        <Formik
          initialValues={{
            guarantee_email: "",
            guarantee_limit: "",
            guarantee_limit_currency: "NGN"
          }}

          validationSchema={Yup.object({
            guarantee_email: Yup.string()
              .required("Guarantee email is required")
              .email("Must be an email").nullable(),
            guarantee_limit: Yup.string().required("Guarantee limit is required"),
            guarantee_limit_currency: Yup.string().required("Currency is required"),
          })}

          onSubmit={(values, { setSubmitting }) => {
            dispatch(guaranteePoolMember(values))
              .then(res => {
                if (res.error) {
                  NotificationManager.error(res.error.message, "Error!")
                } else {
                  if (res.success) {
                    NotificationManager.success(res.message, "Success!")
                    handleClose()
                  }
                }
              })
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
          }) => (
            <Form>
              <div className="padding-left-10 padding-right-10">
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Guarantee Email</label>
                  <Field
                    className="form-control" placeholder="Enter Guarantee Email"
                    type="email"
                    name="guarantee_email"
                    onBlur={() => {
                      if (values.guarantee_email) {
                        setIsPageLoading([true, "...loading"])
                        dispatch(getSomeoneBasicDetails(values.guarantee_email))
                          .then(res => {
                            setIsPageLoading([false])
                            if (res.error) {
                              setError(res.error.message)
                              setSomeoneGuaranteed({})
                            } else {
                              if (res.success) {
                                setError("")
                                setSomeoneGuaranteed(res.data)
                              }
                            }
                          })
                      }
                    }}
                  />
                  <ErrorMessage
                    className="red font-size-12"
                    name="guarantee_email"
                    component="div"
                  />

                  {error && <span className="red font-size-12">{error} </span>}
                </div>
                {someoneGuaranteed.first_name &&
                  <div className="row">
                    <div className="col-3 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                      {someoneGuaranteed.profile_picture ?
                        <img
                          className="d-block bg-lighter-blue fixed-width-50 fixed-height-50 rounded-circle"
                          src={`${someoneGuaranteed.profile_picture}?${Date.now()}`}
                        /> :
                        <i
                          className="fa fa-user-circle lighter-blue"
                          style={{ fontSize: "50px" }}
                        ></i>}
                    </div>
                    <div className="col-9 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                      <label className="form-label">Name</label>
                      <b>{someoneGuaranteed.first_name} {someoneGuaranteed.last_name} </b>
                    </div>
                  </div>
                }
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Guarantee Limit</label>
                  <div>
                    <MoneyInputField
                      className="form-control padding-6"
                      value={values.guarantee_limit}
                      currency={values.guarantee_limit_currency}
                      onChange={(value) => {
                        setFieldValue("guarantee_limit", value)
                      }}
                      placeholder="Enter guarantee limit"
                    />
                  </div>
                  <ErrorMessage
                    className="error-message"
                    name="guarantee_limit"
                    component="div"
                  />
                </div>
                <button
                  className="btn bg-dark-blue white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180"
                  onClick={handleSubmit}
                  type="submit"
                >
                  <b>Save</b>
                </button>
              </div>

            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(AddNewGuarantee);
