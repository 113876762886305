import axiosInstance from "../../helpers/axiosInstance";

// To get all help articles
export const getAllHelpArticles = () => {
    return async () => {
        return axiosInstance.get('/help-articles/get-help-articles')
            .then(response => {
                return response.data
            })
            .catch(error => {
                return { error: error ? error.response.data : null }
            })
    }
};
