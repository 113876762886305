import React, { useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useGoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import AddPhoneNumber from "./AddPhoneNumber"

const SocialLogin = (props) => {
  const [isAppleProcessing, setIsAppleProcessing] = useState(false);
  const [isFacebookProcessing, setIsFacebookProcessing] = useState(false);
  const [isGoogleProcessing, setIsGoogleProcessing] = useState(false);
  const [isAddPhoneNumberVisible, setIsAddPhoneNumberVisible] = useState(false);
  

  // // Facebook
  // const responseFacebook = async (response) => {
  //   props.setIsUsingSocialAuthentication(true)
  //   props.setSocialMediaType("facebook")

  //   if (response.status == "unknown") {
  //     setIsFacebookProcessing(false);
  //   } else {
  //     setIsFacebookProcessing(true);
  //   }
  //   try {
  //     props.handleSocialLogin({
  //       access_token: response.accessToken,
  //       socialMediaType: "facebook",
  //       referrer_code: props.referrer_code ? props.referrer_code : ""
  //     })

  //   } catch (error) {
  //     setIsFacebookProcessing(false);
  //     props.setSocialLoginError(error.response.data.detail);
  //   }
  // };

  // Google
  const onGoogleLogin = useGoogleLogin({
    onSuccess: (response) => {
      props.setIsUsingSocialAuthentication(true)
      props.setSocialMediaType("google")

      setIsGoogleProcessing(true)
      try {
        props.handleSocialLogin({
          access_token: response.access_token,
          socialMediaType: "google",
          referrer_code: props.referrer_code ? props.referrer_code : ""
        })
      } catch (error) {
        setIsGoogleProcessing(false);
        props.setSocialLoginError(error.response.data.detail);
      }
    },
    onError: (error) => {
      console.log(error)
    }
    ,
    flow: 'implicit',
  });

  // Apple
  const responseApple = async (res) => {
    const data = {
      code: res.authorization.code,
      id_token: res.authorization.id_token,
      client_id: process.env.REACT_APP_APPLE_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_APPLE_REDIRECT_URI
    }
    props.setIsUsingSocialAuthentication(true)
    props.setSocialMediaType("apple")
    setIsAppleProcessing(true)

    console.log("Line 766666666666666666666", res)

    if (res.user) {
      data.firstName = res.user.name.firstName
      data.lastName = res.user.name.lastName
    }

    try {
      props.handleSocialLogin({
        ...data,
        socialMediaType: "apple",
        referrer_code: props.referrer_code ? props.referrer_code : ""
      })
    } catch (error) {
      setIsAppleProcessing(false);
      props.setSocialLoginError(error.response.data.detail);
    }
  };

  return (
    <div>
      {/* <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        callback={(res) => responseFacebook(res)}
        render={(renderProps) => (
          <div onClick={renderProps.onClick} className="d-flex white">
            <i className="fab fa-facebook-f d-flex align-items-center justify-content-center fixed-width-50 fixed-height-40 font-size-22 bg-light-blue border-top-left-radius-10 border-bottom-left-radius-10"></i>
            <div className="d-flex cursor-pointer align-items-center justify-content-center stretch-full font-size-16 fixed-height-40 text-center bg-light-blue opacity-90 border-top-right-radius-10 border-bottom-right-radius-10">
              Sign in with Facebook
            </div>
          </div>
        )}
      />
      <span>
        {props.socialLoginError ? (
          <div className="margin-top-10">{props.socialLoginError}</div>
        ) : null}
      </span> */}

      <div
        className="g-signin2"
        data-width="300"
        data-height="200"
        data-longtitle="true"
      ></div>

      <br />

      <div>
        {props.socialLoginError ? <span>{props.socialLoginError}</span> : null}
        <div onClick={() => {
          try {
            onGoogleLogin()
          } catch (err) {
            console.log(err)
          }
        }} className="d-flex white">
          <i className="fab fa-google d-flex align-items-center justify-content-center fixed-width-50 
          fixed-height-40 font-size-22 bg-orange-red border-top-left-radius-10 border-bottom-left-radius-10"></i>
          <div className="d-flex cursor-pointer align-items-center justify-content-center stretch-full font-size-16
           fixed-height-40 text-center bg-orange-red opacity-90 border-top-right-radius-10 border-bottom-right-radius-10">
            Sign in with Google
          </div>
        </div>
      </div>

      <br />

      <div>
        <AppleSignin
          /** Auth options passed to AppleID.auth.init() */
          authOptions={{
            /** Client ID - eg: 'com.example.com' */
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
            /** Requested scopes, seperated by spaces - eg: 'email name' */
            scope: 'email name',
            /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
            redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
            /** State string that is returned with the apple response */
            state: 'state',
            /** Nonce */
            nonce: 'nonce',
            /** Uses popup auth instead of redirection */
            usePopup: true,
          }}

          onSuccess={(response) => {
            console.log("Line 164444444444444444444444", response)
            responseApple(response)
          }}

          /** Called upon sign in error */
          onError={(error) => console.error(error)} // default = undefined

          render={(props) => <div
            {...props}
            className="d-flex white margin">
            <i className="fab fa-apple d-flex align-items-center justify-content-center fixed-width-50 fixed-height-40 font-size-22 bg-black border-top-left-radius-10 border-bottom-left-radius-10"></i>
            <div
              className="d-flex cursor-pointer align-items-center justify-content-center stretch-full font-size-16 fixed-height-40 text-center bg-black opacity-90 border-top-right-radius-10 border-bottom-right-radius-10">
              Sign in with Apple
            </div>
          </div>}
        />

        <span>
          {props.socialLoginError ? (
            <div className="margin-top-10">{props.socialLoginError}</div>
          ) : null}
        </span>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isAddPhoneNumberVisible}
        onRequestClose={() => setIsAddPhoneNumberVisible(false)}
        className="modal max-width-560 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Add phone number
          </h1>
          <i
            onClick={() => setIsAddPhoneNumberVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-10 padding-left-md-30 padding-right-md-30"
          >
            <AddPhoneNumber handleClose={() => setIsAddPhoneNumberVisible(false)} />
          </div>
        </div>
      </Modal>
    </div >
  );
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(SocialLogin);
