import React, { useState, useContext } from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

const PhotoCameraModal = ({
  handleTakePhoto
}) => {
  return (
    <div style={{ marginBottom: "-6px" }}>
      <Camera
        onTakePhoto={(dataUri) => {
          handleTakePhoto(dataUri)
        }}
        isMaxResolution={true}
      />
    </div>
  );
};

export { PhotoCameraModal as default };
