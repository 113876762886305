import React from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FormattedMoney from "../utilityComponents/FormattedMoney";
import { NotificationManager } from "react-notifications";

const PouchMoneyPaymentDetails = ({
  amount,
  bankDetails = []
}) => {
  const handleCopyUrl = () => {
    NotificationManager.success("Copied to clipboard!")
  };

  return (
    <div>
      <div className="font-size-14 font-size-sm-16">
        <span className="red">
         ATTENTION: After payment, send the evidence and the transaction ID to info@pouchmoney.com or 08160636762 
        </span>
        <div className="d-flex justify-content-between padding-top-8 padding-bottom-8">
          <div className="padding-right-10">
            Amount to pay
          </div>
          <CopyToClipboard
            text={amount}
            onCopy={() => {
              handleCopyUrl();
            }}
          >
            <b className="d-flex align-items-center">
              <i className="fa fa-copy padding-4" ></i>
              <FormattedMoney value={amount} currency="NGN" />
            </b>
          </CopyToClipboard>
        </div>
        <div className="d-flex justify-content-between padding-top-8 padding-bottom-8">
          <div className="padding-right-10">
            Amount to wallet
          </div>
          <b>
            <FormattedMoney
              value={amount >= 10000 ? amount - 50 : amount} currency="NGN" />
          </b>
        </div>
      </div>
      <div className="margin-top-10">
        <b>PAY TO</b>
      </div>
      <div className="margin-top-10">
        {
          bankDetails && bankDetails.length &&
          bankDetails.map((bank, index) => {
            return (
              <div key={index} className="font-size-14 font-size-sm-16 padding-top-6 padding-bottom-6">
                <div className="d-flex justify-content-between padding-top-8 padding-bottom-8">
                  <div className="padding-right-10">Bank Name</div>
                  <b>{bank.bank_name}</b>
                </div>
                <div className="d-flex justify-content-between padding-top-8 padding-bottom-8">
                  <div className="padding-right-10">Account Number</div>

                  <CopyToClipboard
                    text={bank.account_number}
                    onCopy={() => {
                      handleCopyUrl();
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <i className="fa fa-copy padding-4"></i>
                      <b>{bank.account_number}</b>
                    </span>
                  </CopyToClipboard>
                </div>
                <div className="d-flex justify-content-between padding-top-8 padding-bottom-8">
                  <div className="padding-right-10">Bank Account</div>
                  <b>{bank.account_name}</b>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PouchMoneyPaymentDetails);
