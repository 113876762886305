import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";

export const AuthRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {

  const search = useLocation().search;
  const redirectTo = new URLSearchParams(search).get('redirectTo')

  let destinationPath = "/dashboard/home"

  if (redirectTo) {
    destinationPath = redirectTo
  }

  return (
    <Route
      {...rest}
      component={(props) => {

        return isAuthenticated ? (
          <Redirect to={destinationPath} />
        ) : (
            <Component {...props} />
          )
      }
      }
    />
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.userToken,
});

export default connect(mapStateToProps)(AuthRoute);
