import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import upkeepAvatar from "../images/transfer_money.svg";
import moneyPoolAvatar from "../images/money_pool_setup.svg";

const MyNotifications = ({
  user,
  recentNotifications
}) => {
  return (
    <div>
      {recentNotifications.length > 0 &&
        recentNotifications.map((notification, index) => {
          return (
            <div key={index} className="d-flex align-items-center justify-content-between padding-left-10 padding-right-10 margin-right-10 margin-top-10 margin-bottom-10 border-1 border-radius-8 border-color-grey">

              <div className="d-flex align-items-center">

                <div className="d-flex justify-content-center align-items-center white fixed-width-30 fixed-height-30 font-size-18 rounded-circle">

                  {notification.avatar ? (
                    <div>
                      {/*Display the saved file_url*/}
                      <img
                        className="d-block rounded-circle"
                        width="40px"
                        height="40px"
                        src={notification.avatar}
                      />
                    </div>
                  ) :
                    <div>
                      {/*Fetch the appropriate picture*/}
                      {notification.service_id ?
                        <div>
                          {
                            notification.service_type == "pool" &&
                            <img
                              className="d-block rounded-circle"
                              width="40px"
                              height="40px"
                              src={moneyPoolAvatar}
                            />
                          }

                          {
                            notification.service_type == "upkeep" &&
                            <img
                              className="d-block rounded-circle"
                              width="40px"
                              height="40px"
                              src={upkeepAvatar}
                            />
                          }

                          {
                            notification.service_type == "fundraiser" &&
                            <img
                              className="d-block rounded-circle"
                              width="40px"
                              height="40px"
                              src={upkeepAvatar}
                            />
                          }
                        </div>
                        :
                        <div>
                          {
                            user.profile_picture ?
                              <img
                                className="d-block rounded-circle"
                                width="40px"
                                height="40px"
                                src={user.profile_picture}
                              /> :
                              <span>
                                {user.first_name.substring(0, 1).toUpperCase() + user.last_name.substring(0, 1).toUpperCase()}
                              </span>
                          }
                        </div>
                      }
                    </div>
                  }
                </div>
                <div className="padding-left-14 padding-right-14">
                  <div>{notification.message}</div>
                  <span className="font-size-12 grey">
                    {moment(notification.created_at).fromNow()}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MyNotifications);


