import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import Modal from "react-modal";
import moment from "moment";
import Truncate from "react-truncate";
import UseOnClickOutside from "../UseOnClickOutside";
import { NotificationManager } from "react-notifications";
import 'emoji-mart/css/emoji-mart.css';
import { Picker, Emoji } from 'emoji-mart';
import { sendChatWithAttachment } from "../../store/actions/chat";
import ImageViewer from "./ImageViewer";
import PdfViewer from "./PdfViewer";
import PdfThumbnail from "./PdfThumbnail";
import sendIcon from "../images/send_icon.svg";
import CurrentWindowWidth from "../CurrentWindowWidth";
import { getConfirmAlert, getFileFormatFromMimeType } from "../../utilities";
import Spinner from "../utilityComponents/Spinner";
import popSound from "../audios/pop.wav"

const ChatBox = ({
  user,
  dispatch,
  handleClose,
  service_type,
  service_id
}) => {

  let windowWidth = CurrentWindowWidth();

  const [isLoading, setIsLoading] = useState(false);

  const [isEnterKeyPressed, setIsEnterKeyPressed] = useState(false);

  const [limit, setLimit] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [messages, setMessages] = useState([])
  const [chatLastSeen, setChatLastSeen] = useState(null);
  const [chatBeingReplied, setChatBeingReplied] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFilePreview, setSelectedFilePreview] = useState(null)
  const [chatRoomId, setChatRoomId] = useState(null)
  const [isFilePreviewVisible, setIsFilePreviewVisible] = useState(false)
  const [viewedDocument, setViewedDocument] = useState(null)
  const [openedFileFormat, setOpenedFileFormat] = useState(null)
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [isLastIndexMemo, setLastIndexMemo] = useState(null);
  const [isDropDownContent, setDropDownContent] = useState(false);
  const [isAngleDown, setAngleDown] = useState(false);
  const [isMessageIndex, setMessageIndex] = useState(null);
  const [previousScrollHeight, setPreviousScrollHeight] = useState(0);

  const [dropDownContentPositionTop, setDropDownContentPositionTop] = useState(0);
  const [dropDownContentPositionLeft, setDropDownContentPositionLeft] = useState(0);
  const [messagesBoxHeight, setMessagesBoxHeight] = useState(null);
  const [innerScreenHeight, setInnerScreenHeight] = useState(null);

  const [isReload, setReload] = useState(false);
  const [previewContainerHeight, setPreviewContainerHeight] = useState(null);

  const chatRef = useRef(null);
  const fileAttachmentRef = useRef(null);

  const closeDropDownContentRef = useRef(null);

  UseOnClickOutside(closeDropDownContentRef, () => setDropDownContent(false));

  const closeEmojiPickerRef = useRef(null);

  UseOnClickOutside(closeEmojiPickerRef, () => {
    setIsEmojiPickerOpen(false);
    setReload(!isReload);
  });

  const messagesBoxContainerRef = useRef(null);
  const getBottomContainer = useRef(null);

  const textAreaRef = useRef(null);

  const resetTextAreaHeight = () => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
    }
  }

  const initializeChats = (limit) => {

    chatRef.current = new WebSocket(
      `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/chat/${service_type}/${service_id}/connect`)

    chatRef.current.onopen = e => {

      chatRef.current.send(JSON.stringify({
        type: "initial_chats",
        limit: limit,
        user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
      }))

      chatRef.current.send(JSON.stringify({
        type: "register_chat_last_seen",
        user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
      }))
    }

    chatRef.current.onmessage = e => {
      const data = JSON.parse(e.data)

      if (data.type == "initial_chats") {
        if (data.chat_obj) {
          setMessages(data.chat_obj.chats)
          setChatRoomId(data.chat_obj.chat_room_id)
          setLimit(data.chat_obj.limit)
          setTotalCount(data.chat_obj.total_count)
          setChatLastSeen(data.chat_last_seen)
        }
      } else if (data.type == "see_more_chats") {
        if (data.chat_obj) {
          setMessages(prev => {
            return [...data.chat_obj.chats, ...prev]
          })

          setChatRoomId(data.chat_obj.chat_room_id)
          setLimit(data.chat_obj.limit)
          setTotalCount(data.chat_obj.total_count)
          setChatLastSeen(data.chat_last_seen)
          setIsLoading(false);
        }
      } else if (data.type == "chat_message") {
        if (data.chat) {

          if (data.chat.sender.id != user.id) {
            makeSound()
          }

          setMessages(prev => {
            return [...prev, data.chat]
          })
        }
      } else if (data.type == "message_deleted") {
        if (data.chat) {
          // Look for the message among the displayed messages and remove it
          const deletedChat = data.chat
          setMessages(prev => {
            const new_messages = prev.filter(m => m.id != deletedChat.id)
            return [...new_messages]
          })
        }
      } else if (data.type == "chat_last_seen") {
        if (data.chat_last_seen) {
          setChatLastSeen(data.chat_last_seen)
        }
      }
    }

    chatRef.current.onerror = e => {
      console.log('error', e)
    }
  }

  const isToday = (value) => {
    return moment(String(value)).isSame(new Date(), "day");
  }

  const isYesterday = (value) => {
    const yesterday = moment().subtract(1, "days").startOf("day");
    return moment(String(value)).isSame(yesterday, "d");
  }

  const isPastOneWeek = (value) => {
    const weeksAgo = moment().diff(String(value), "weeks");

    return weeksAgo == 0;
  }

  const areDatesInDifferentDays = (date1, date2) => {
    if (date1 && date2) {
      date1 = moment(String(date1)).format("dddd");
      date2 = moment(String(date2)).format("dddd");

      //Ensure the days are not just different day names but also different days
      const isSameDay = moment(String(date1)).isSame(date2, "d");

      return date1 != date2 && !isSameDay;
    }
  }

  const handleSelectFile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (fileLoadedEvent) => {
        const base64File = fileLoadedEvent.target.result;

        setSelectedFilePreview(base64File);
      };
      fileReader.readAsDataURL(file);
    }
  }

  //Use when there is file attachment
  const handleSendChatWithAttachmentByHttp = async (data) => {
    const formData = new FormData();

    formData.append("chat_room_id", data.chat_room_id);
    formData.append("text", data.text);
    formData.append("attachment", data.attachment);
    formData.append("replied_chat_id", data.replied_chat_id);

    const res = await dispatch(sendChatWithAttachment(formData))

    if (res.error) {
      NotificationManager.error(res.error.message, "Error!")
    } else {
      if (res.success == true) {
        const chat = res.data
        chatRef.current.send(JSON.stringify({
          type: "send_chat_with_attachment",
          chat_id: chat.id,
          user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
        }))
      }
    }
  }

  const handleDropDownContent = (index) => {
    if (isLastIndexMemo == index) {
      setDropDownContent(!isDropDownContent);
    } else {
      setDropDownContent(true);
    }
    setLastIndexMemo(index);
  }

  const handleMouseEnter = (index) => {
    for (let i = 0; i < messages.length; i++) {
      if (i == index) {
        setMessageIndex(i);
        setAngleDown(true);
      }
    }
  }

  const handleMouseLeave = () => {
    if (!isDropDownContent) {
      setAngleDown(false);
    }
  }

  const getMessagesBoxHeight = () => {
    if (getBottomContainer && getBottomContainer.current) {
      let innerScreenHeightVal = window.innerHeight - getBottomContainer.current.offsetHeight - 272;
      // (currentScreenWidth <= "768" ? 50 : 120);

      if (innerScreenHeightVal < 0) {
        innerScreenHeightVal = 0;
      }

      setInnerScreenHeight(innerScreenHeightVal);
      setMessagesBoxHeight(innerScreenHeightVal);
    }
  }

  const previewImageRef = useCallback(
    (node) => {
      if (node !== null) {
        const height = messagesBoxContainerRef.current.getBoundingClientRect().height - 49;
        setPreviewContainerHeight(height);

        node.style.maxHeight = height + "px";
        node.style.maxWidth = 100 + "%";

      }
    },
    [innerScreenHeight]
  );

  const emojiPickerRef = useCallback(
    (node) => {
      if (node !== null) {
        let boxHeight = messagesBoxContainerRef.current.getBoundingClientRect().height - getBottomContainer.current.getBoundingClientRect().height - 2;

        if (boxHeight < 0) {
          boxHeight = 0;
        }
        setMessagesBoxHeight(boxHeight);
      } else {
        setMessagesBoxHeight(innerScreenHeight);
      }
    },
    [innerScreenHeight]
  );

  const resizeTextarea = (event) => {
    if (event) {
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + "px";
    }
    getMessagesBoxHeight();
  }

  const reloadSeeMoreRef = (event) => {
    if (event && event.target) {
      setPreviousScrollHeight(event.target.scrollHeight);

      const lastMessage = messages[0]

      const referenceMessageId = lastMessage.id

      if (event.target.scrollTop == 0 && totalCount >= limit) {
        setIsLoading(true);
        chatRef.current.send(JSON.stringify({
          type: "see_more_chats",
          limit: limit + 10,
          reference_message_id: referenceMessageId,
          user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
        }));
      }
    }
  }

  const makeSound = () => {
    const aSound = document.createElement('audio');
    aSound.setAttribute('src', popSound);
    aSound.play();
  }


  //  If sending new message then scroll to bottom or if scrollTop == 0 get old messages and remain in that position.
  useEffect(() => {
    const scrollToNewPosition =
      messagesBoxContainerRef.current.scrollHeight -
      (messagesBoxContainerRef.current.scrollTop == 0 && totalCount >= limit ? previousScrollHeight : 0);

    messagesBoxContainerRef.current.scrollTop = scrollToNewPosition;
    setIsLoading(false);
  }, [messages, messagesBoxHeight])


  useEffect(() => {
    getMessagesBoxHeight();
  }, [isReload])


  useEffect(() => {
    getMessagesBoxHeight();

    window.addEventListener("resize", getMessagesBoxHeight);
    window.addEventListener("scroll", getMessagesBoxHeight);

    return () => {
      window.removeEventListener("resize", getMessagesBoxHeight);
      window.removeEventListener("scroll", getMessagesBoxHeight);
    };
  }, [windowWidth]);

  useEffect(() => {
    initializeChats(limit);
  }, [])

  return (
    <div>
      <div className="horizontal-line margin-bottom-20" />
      <Formik
        initialValues={{
          text: "",
          replied_chat_id: ""
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (values.text || selectedFile) {
            if (selectedFile) {
              // Use http
              handleSendChatWithAttachmentByHttp({
                chat_room_id: chatRoomId,
                text: values.text,
                attachment: selectedFile,
                replied_chat_id: values.replied_chat_id,
              }).then(() => {
                setSelectedFile(null);
                setSelectedFilePreview(null);
                setChatBeingReplied(null);
                resetForm();
                setReload(!isReload);
                resetTextAreaHeight();
                setIsLoading(false);
              })
            } else {
              // Use websocket
              chatRef.current.send(JSON.stringify({
                type: "chat_message",
                text: values.text,
                replied_chat_id: values.replied_chat_id,
                user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
              }))
              setChatBeingReplied(null);
              resetForm();
              setReload(!isReload);
              resetTextAreaHeight();
              setIsLoading(false);
            }
          }
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue
        }) => (
          <div className="bg-off-white border-radius-12 padding-top-10 relative">
            <div
              ref={messagesBoxContainerRef}
              style={{ height: messagesBoxHeight + 'px' }}
              onScroll={reloadSeeMoreRef}
              className="overflow-hidden overflow-scroll-y padding-10 padding-left-20 padding-left-lg-40 padding-right-20 padding-right-lg-40"
            >

              {isLoading &&
                <div className="padding-4">
                  <Spinner
                    fontSizeIcon="25px"
                  />
                </div>}

              {messages.length > 0 ? messages.map((message, index) => {
                return (
                  <div key={index}>
                    {/*Beginning of a period*/}
                    <div>
                      {(index > 0 &&
                        areDatesInDifferentDays(
                          message.created_at,
                          messages[index - 1].created_at
                        )) ||
                        (
                          index == 0
                          &&
                          <div>
                            {isToday(message.created_at) || isYesterday(message.created_at) || isPastOneWeek(message.created_at) ?
                              <div>
                                {isToday(message.created_at) || isYesterday(message.created_at) ?
                                  <div>
                                    {isToday(message.created_at) && <center>Today</center>}
                                    {isYesterday(message.created_at) && <center> Yesterday </center>}
                                  </div> :
                                  isPastOneWeek(message.created_at) && <center> {moment(message.created_at).format('dddd')} </center>
                                }
                              </div> :
                              <center>{moment(message.created_at).format('MMMM Do YYYY')}</center>
                            }
                          </div>
                        )}
                    </div>

                    {/*Chat message*/}
                    <div className="d-flex">
                      <div className={message.sender.id == user.id ? 'stretch-full' : null}>
                      </div>
                      <div className={`relative min-width-120 max-width-500 border-bottom-radius-10 margin-top-10 margin-bottom-10 cursor-pointer 
                        ${message.sender.id == user.id ?
                          'bg-xlighter-green width-auto margin-left-40 border-top-left-radius-10 text-break' :
                          'bg-lighter-blue width-auto margin-right-40 border-top-right-radius-10 text-break'}`}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                      >
                        <span
                          className={`absolute border-bottom-14 border-bottom-color-transparent
                              ${message.sender.id == user.id ?
                              'right-2 transformX100 border-left-14 border-left-color-xlighter-green' :
                              'left-2 transformX-100 border-right-14 border-right-color-lighter-blue'}`}>
                        </span>

                        <div
                          ref={el => {
                            if (isAngleDown && isMessageIndex == index && el) {
                              setDropDownContentPositionTop(el.getBoundingClientRect().top);
                              setDropDownContentPositionLeft(el.getBoundingClientRect().left);

                            }
                          }}
                          className="absolute top-4 right-4">

                          {isAngleDown && isMessageIndex == index ?
                            <i onClick={() => handleDropDownContent(index)}
                              className={`fa fa-angle-down opacity-75 d-flex align-items-center justify-content-center rounded-circle fixed-width-20 fixed-height-20 padding-12 black font-size-20 bg-lighter-grey
                                         ${message.replied_chat ? 'bg-light-back-drop-color white' : null}`}
                            ></i> : null}

                          {isDropDownContent && isLastIndexMemo == index ?
                            <div
                              ref={el => {
                                if (el && isMessageIndex == index) {
                                  el.style.left = dropDownContentPositionLeft -
                                    (message.sender.id == user.id ? el.getBoundingClientRect().width - 22 : 0) + "px";

                                  el.style.top = dropDownContentPositionTop + 24 + "px";
                                }
                              }}
                              className={`fixed fixed-width-180 padding-top-4 padding-bottom-4 font-size-13 border-radius-6 bg-white box-shadow
                                        ${message.sender == user.id ? 'transformX-100' : null}`}>
                              <div ref={closeDropDownContentRef}>
                                <p
                                  onClick={() => {
                                    setChatBeingReplied(message);
                                    setFieldValue("replied_chat_id", message.id);
                                    setDropDownContent(false);
                                    setReload(!isReload);
                                  }}
                                  className="hover-bg-light-grey padding-8 padding-left-16 padding-right-16"  >
                                  Reply
                                </p>

                                {message.sender.id == user.id &&
                                  <p className="hover-bg-light-grey padding-8 padding-left-16 padding-right-16"
                                    onClick={() => {
                                      if (message.sender.id == user.id) {
                                        getConfirmAlert({
                                          title: "Confirm!",
                                          message: "Are you sure you want to delete message? Note that it cannot be undone.",
                                          onClickYes: () => {
                                            chatRef.current.send(JSON.stringify({
                                              type: "delete_message",
                                              chat_id: message.id,
                                              user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
                                            }))
                                          },
                                          onClickNo: () => null
                                        })
                                      }
                                    }}>
                                    Delete
                                  </p>
                                }
                              </div>
                            </div> : null}
                        </div>
                        <div className="font-size-14 font-size-lg-17 line-height-18 line-height-lg-20">
                          {message.replied_chat || message.attachment_file_url ?
                            <div className="relative bg-light-back-drop-color border-radius-8 margin-4 padding-10 cursor-pointer">
                              {message.replied_chat &&
                                <div>
                                  <b className="font-size-14 blue">
                                    {message.replied_chat.sender.id == user.id ?
                                      <span>
                                        You
                                      </span>
                                      :
                                      <span>
                                        {message.replied_chat.sender.first_name} {message.replied_chat.sender.last_name}
                                      </span>
                                    }
                                  </b>
                                  <div className="font-size-13">
                                    {message.replied_chat.text}
                                  </div>
                                </div>}

                              {message.attachment_file_url &&
                                <div
                                  onClick={() => {
                                    const fileFormat = getFileFormatFromMimeType(message.attachment_mime_type)
                                    setOpenedFileFormat(fileFormat)
                                    setViewedDocument({
                                      file_url: message.attachment_file_url
                                    })
                                    setIsFilePreviewVisible(true)
                                  }}>

                                  {message.thumbnail_file_url ?
                                    <div className="padding-4">
                                      {/*It's an image*/}
                                      <img
                                        className="d-block fixed-width-150 border-radius-6 margin-left-8"
                                        src={message.thumbnail_file_url}
                                      />
                                    </div> :
                                    <div className="padding-4">
                                      {/*It's a pdf*/}
                                      <PdfThumbnail height={90} document={{
                                        file_url: message.attachment_file_url
                                      }} />
                                    </div>}
                                  <div className="float-end margin-right-6"></div>
                                </div>}
                            </div> : null}
                          {message.sender.id != user.id &&
                            <span>
                              <b className="font-size-14 blue">
                                {message.sender.first_name} {message.sender.last_name}
                              </b>
                            </span>
                          }
                          <p className="padding-top-6 padding-left-10 padding-right-10 font-size-14 line-height-18">
                            {message.text}
                          </p>
                          <span className="font-size-12 line-height-12 grey float-end padding-right-10 padding-bottom-6"  >
                            {moment(message.created_at).format('HH:mm')}
                          </span>
                        </div>
                      </div>
                      <div
                        className={message.sender.id != user.id ? 'stretch-full' : null}
                      >
                      </div>
                    </div>
                  </div>
                )
              })
                :
                <div className="d-flex flex-1 align-items-center justify-content-center">
                  <i class="fa fa-comments orange" style={{ fontSize: "120px" }}></i>
                </div>

                // <Spinner
                //   fontSizeIcon="30px"
                //   label="Loading messages..."
                // />
              }

              {/*Selected file preview*/}
              {selectedFile &&
                <div
                  className="absolute top-0 left-0 right-0"
                  style={{ height: previewContainerHeight + 'px' }}
                >
                  <div className="d-flex justify-content-between bg-dark-blue white font-size-18 padding-12 border-top-radius-8">
                    <b>Image Preview</b>
                    <i
                      onClick={() => {
                        setSelectedFile(null)
                        setSelectedFilePreview(null)
                      }}
                      className="fa fa-close font-size-22 white cursor-pointer"
                    ></i>
                  </div>
                  <div className="d-flex relative justify-content-center bg-light-grey"
                    style={{ minHeight: '100%' }}
                  >
                    <img
                      ref={previewImageRef}
                      className="absolute top-50-pct transformY-50 padding-10"

                      src={selectedFilePreview}
                    />
                    {isLoading &&
                      <div className="absolute top-0 bottom-0 left-0 right-0">
                        <Spinner
                          label="Uploading..."
                          fontSizeIcon="25px"
                          backgroundColor={true}
                        />
                      </div>}
                  </div>
                </div>}
            </div>

            <Form
              ref={getBottomContainer}
              className="bg-light-brown border-bottom-radius-12 padding-8 padding-left-8 padding-left-sm-20 padding-right-8 padding-right-sm-20"
            >
              {/*Replied message*/}
              <div>

                {chatBeingReplied &&
                  <div className="d-flex align-items-center margin-left-14 margin-right-14 margin-bottom-8">

                    <div className="d-flex justify-content-between stretch-full border-radius-6 padding-4 padding-left-14 padding-right-14 bg-light-back-drop-color">
                      <div className="d-flex flex-column justify-content-center">
                        {chatBeingReplied.sender.id == user.id ?
                          <b className="font-size-14 blue">
                            You
                          </b>
                          :
                          <b className="font-size-14 blue">
                            {chatBeingReplied.sender.first_name}
                            {chatBeingReplied.sender.last_name}
                          </b>
                        }

                        <p className="font-size-13 line-height-17" style={{ width: "100vh" }}>
                          <Truncate lines={2}>
                            {chatBeingReplied.text}
                          </Truncate>
                        </p>
                      </div>

                      <div>
                        {chatBeingReplied.thumbnail &&
                          <img
                            src={chatBeingReplied.thumbnail.fileUrl}
                            className="d-block border-radius-6 margin-left-8"
                            height="100"
                            width="100"
                          />
                        }
                      </div>
                    </div>
                    <i
                      className="fa fa-close padding-left-18 font-size-20 opacity-50 cursor-pointer"
                      onClick={() => {
                        setChatBeingReplied(null)
                        setFieldValue("replied_chat_id", "")
                        setReload(!isReload);
                      }}
                    ></i>
                  </div>}
              </div>

              {isEmojiPickerOpen &&
                <div className="padding-bottom-6" ref={closeEmojiPickerRef}>
                  <Picker
                    ref={emojiPickerRef}
                    title=""
                    onSelect={(event) => {
                      setFieldValue("text", values.text + event.native)
                    }}
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
              }

              <div className="d-flex align-items-end stretch-full">

                <div style={{ marginLeft: '-40px', marginRight: '-25px' }}>
                  <Emoji
                    onClick={() => {
                      setIsEmojiPickerOpen(!isEmojiPickerOpen);
                      setReload(!isReload);
                    }}

                    emoji={{
                      id: 'smiley',
                      name: 'Smiling Face with Open Mouth',
                      colons: ':smiley:',
                      text: ':)',
                      emoticons: [
                        '=)',
                        '=-)'
                      ],
                      skin: null,
                      native: '😃'
                    }} size={30} />
                </div>

                <i className="fa fa-paperclip font-size-26 opacity-50 margin-right-10 margin-right-sm-16 margin-bottom-8 cursor-pointer"
                  onClick={() => fileAttachmentRef.current.click()}
                ></i>
                <input
                  hidden
                  type="file"
                  ref={fileAttachmentRef}
                  onChange={(event) => handleSelectFile(event)}
                />

                <div className="bg-white stretch-full border-radius-20 padding-6">
                  <div className="overflow-scroll-y" style={{ maxHeight: '112px' }}>
                    <Field
                      innerRef={textAreaRef}
                      className="form-control padding-6 padding-sm-8 focus-none bg-transparent overflow-hidden font-size-12 font-size-sm-14"
                      component="textarea"
                      rows="1"
                      placeholder="Type your message"
                      name="text"
                      onKeyDown={(el) => {
                        if (el.key == "Enter") {
                          setIsEnterKeyPressed(true);
                        }
                        else {
                          setIsEnterKeyPressed(false);
                        }
                      }}

                      onKeyUp={(el) => {
                        if (el.key == "Enter" && !isLoading && (!!values.text.trim() || selectedFile)) {
                          setIsLoading(true);
                          handleSubmit();
                        }
                      }}

                      onChange={(el) => {
                        if (!isEnterKeyPressed) {
                          setFieldValue("text", el.target.value);
                          resizeTextarea(el);
                        }
                      }}
                    />
                  </div>
                </div>
                <img
                  src={sendIcon}
                  className={
                    `fixed-width-40 fixed-height-40 margin-left-4 margin-left-sm-10
                    ${!values.text.trim() && !selectedFile || isLoading ? 'pointer-events-none opacity-50' : "cursor-pointer"}`
                  }
                  onClick={() => {
                    setIsLoading(true);
                    handleSubmit();
                  }}
                />
              </div>
            </Form>
          </div>
        )}
      </Formik >

      <Modal
        ariaHideApp={false}
        isOpen={isFilePreviewVisible}
        onRequestClose={() => setIsFilePreviewVisible(false)}
        className="modal max-width-1000 padding-0 bg-black"
        overlayClassName="modal-backdrop"
      >
        <div>
          {openedFileFormat == "image" &&
            <ImageViewer
              maxHeightImage={600}
              document={viewedDocument}
              handleClose={() => setIsFilePreviewVisible(false)}
            />
          }

          {openedFileFormat == "pdf" &&
            <PdfViewer
              document={viewedDocument}
              handleClose={() => setIsFilePreviewVisible(false)}
            />
          }

          {openedFileFormat == "video" &&
            <video
              width="100%"
              height="260px"
              style={{
                outline: "none",
              }}
              src={`${viewedDocument.file_url}?${Date.now()}`}
              controls
              autoplay="autoplay"
            />
          }

          {openedFileFormat == "audio" &&
            <audio
              width="100%"
              height="260px"
              style={{
                outline: "none",
              }}
              src={`${viewedDocument.file_url}?${Date.now()}`}
              controls
              autoplay="autoplay"
            />
          }
        </div>
      </Modal>
    </div >
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ChatBox);
