import React, { useState, Fragment, useEffect } from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Select from "react-select";
import Dropzone from "react-dropzone";
import countryList from "react-select-country-list";
import { NotificationManager } from "react-notifications";
import {
  uploadIdProofDoc,
  deleteIdProofDoc,
  getUser
} from "../../../../store/actions/auth";
import { getDocumentFileformatFromUrl, getConfirmAlert, getFileNameFromFileObj, convertSnakeToStandardCasing } from "../../../../utilities";
import PdfViewer from "../../../utilityComponents/PdfViewer";
import PdfThumbnail from "../../../utilityComponents/PdfThumbnail";
import ImageViewer from "../../../utilityComponents/ImageViewer";
import PreviewFileUpload from "../../../utilityComponents/PreviewFileUpload";

const SubmitIdProofDocs = ({
  user,
  document,
  setIsPageLoading,
  dispatch,
}) => {
  const [viewedDocument, setViewedDocument] = useState("");
  const [latestFrontFile, setLatestFrontFile] = useState("");
  const [latestBackFile, setLatestBackFile] = useState("");
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false);
  const [isPdfViewerVisible, setIsPdfViewerVisible] = useState(false);
  const [isSelectTypeOpen, setIsSelectTypeOpen] = useState(false);
  const [selectedDocumentTypeValue, setSelectedDocumentTypeValue] = useState("");
  const [countries, setCountries] = useState([]);

  const handleUploadDocument = (values) => {
    setIsPageLoading([true, "Uploading..."]);

    const formData = new FormData();
    formData.append("front_file", latestFrontFile);

    if (latestBackFile) {
      formData.append("back_file", latestBackFile);
    }
    formData.append("document_type", values.document_type);
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("middle_name", values.middle_name);
    formData.append("country", values.country);
    formData.append("state", values.state);
    formData.append("city", values.city);
    formData.append("street_address", values.street_address);
    if(values.post_code){
      formData.append("post_code", values.post_code);
    }

    dispatch(uploadIdProofDoc(formData))
      .then((response) => {

        setIsPageLoading([false]);

        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            NotificationManager.success(response.message, "Success!")
            dispatch(getUser())
            // setIsSelectTypeOpen(false);
            setLatestFrontFile("");
            setLatestBackFile("")
            setSelectedDocumentTypeValue("")
          }
        }
      });
  };

  // Delete a document
  const handleDeleteDocument = (fileObj) => {
    setIsPageLoading([true, "Uploading..."]);

    dispatch(deleteIdProofDoc(fileObj))
      .then((response) => {

        setIsPageLoading([false]);

        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            NotificationManager.success(response.message, "Success!")
            dispatch(getUser())
            setLatestFrontFile("");
            setSelectedDocumentTypeValue("")
          }
        }
      });
  }

  useEffect(() => {
    // Get country list
    const options = countryList().getData();
    setCountries(options);

  }, [])

  return (
    <div>
      <div className="row">
        {document && document.front_file_url ?
          <div className="d-flex">
            {
              document.front_file_url &&
              <div className="min-width-130 max-width-130 margin-4 margin-sm-10 cursor-pointer bg-lighter-blue border-radius-6" >
                <div className="relative">
                  <div
                    className="fixed-height-100"
                    onClick={() => {
                      setViewedDocument({
                        ...document,
                        file_url: document.front_file_url
                      });
                      if (getDocumentFileformatFromUrl(document.front_file_url) == "pdf") {
                        setIsPdfViewerVisible(true);
                      } else if (getDocumentFileformatFromUrl(document.front_file_url) == "image") {
                        setIsImageViewerVisible(true);
                      }
                    }}
                  >
                    {getDocumentFileformatFromUrl(document.front_file_url) == "pdf" ? (
                      <PdfThumbnail height={90} document={document} />
                    ) : (
                      <img
                        width="100%"
                        height="100%"
                        className="d-block stretch-full border-top-radius-6"
                        src={`${document.front_file_url}?${document.front_file_url}`}
                      />
                    )}
                  </div>

                  <i
                    className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                    onClick={() => {
                      getConfirmAlert({
                        title: "Confirm delete!",
                        message: "Are you sure you want to delete this document?",
                        onClickYes: () => {
                          handleDeleteDocument(document)
                        },
                        onClickNo: () => null,
                      });
                    }}
                  ></i>
                </div>

                <div
                  className="font-size-12 padding-4 padding-left-8 padding-right-8 text-truncate border-bottom-radius-6"
                  onClick={() => {
                    setViewedDocument({
                      ...document,
                      file_url: document.front_file_url
                    });

                    if (getDocumentFileformatFromUrl(document.front_file_url) == "pdf") {
                      setIsPdfViewerVisible(true);
                    } else if (getDocumentFileformatFromUrl(document.front_file_url) == "image") {
                      setIsImageViewerVisible(true);
                    }
                  }}
                >
                  <b>{getFileNameFromFileObj(document.front_file_url)}</b>
                </div>
                <div className="padding-left-8 padding-right-8 padding-bottom-8 font-size-12">
                  {convertSnakeToStandardCasing(document.verification_status)}
                </div>
              </div>
            }

            {
              document.back_file_url &&
              <div className="min-width-130 max-width-130 margin-4 margin-sm-10 cursor-pointer bg-lighter-blue border-radius-6" >
                <div className="relative">
                  <div
                    className="fixed-height-100"
                    onClick={() => {
                      setViewedDocument({
                        ...document,
                        file_url: document.back_file_url
                      });
                      if (getDocumentFileformatFromUrl(document.back_file_url) == "pdf") {
                        setIsPdfViewerVisible(true);
                      } else if (getDocumentFileformatFromUrl(document.back_file_url) == "image") {
                        setIsImageViewerVisible(true);
                      }
                    }}
                  >
                    {getDocumentFileformatFromUrl(document.back_file_url) == "pdf" ? (
                      <PdfThumbnail height={90} document={document} />
                    ) : (
                      <img
                        width="100%"
                        height="100%"
                        className="d-block stretch-full border-top-radius-6"
                        src={`${document.back_file_url}?${document.back_file_url}`}
                      />
                    )}
                  </div>

                  <i
                    className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                    onClick={() => {
                      getConfirmAlert({
                        title: "Confirm delete!",
                        message: "Are you sure you want to delete this document?",
                        onClickYes: () => {
                          handleDeleteDocument(document)
                        },
                        onClickNo: () => null,
                      });
                    }}
                  ></i>
                </div>

                <div
                  className="font-size-12 padding-4 padding-left-8 padding-right-8 text-truncate border-bottom-radius-6"
                  onClick={() => {
                    setViewedDocument({
                      ...document,
                      file_url: document.back_file_url
                    });
                    if (getDocumentFileformatFromUrl(document.back_file_url) == "pdf") {
                      setIsPdfViewerVisible(true);
                    } else if (getDocumentFileformatFromUrl(document.back_file_url) == "image") {
                      setIsImageViewerVisible(true);
                    }
                  }}
                >
                  <b>{getFileNameFromFileObj(document.back_file_url)}</b>
                </div>

                <div className="padding-left-8 padding-right-8 padding-bottom-8 font-size-12">
                  {convertSnakeToStandardCasing(document.verification_status)}
                </div>
              </div>
            }
          </div>
          :
          <div>
            <Formik
              initialValues={{
                first_name: user.first_name,
                last_name: user.last_name,
                middle_name: user.middle_name,
                country: user.country,
                state: user.state,
                city: user.city,
                street_address: user.street_address,
                post_code: user.post_code,
                document_type: ""
              }}

              validationSchema={Yup.object({
                document_type: Yup.string().required("Select document type"),
                first_name: Yup.string().required("First name is required"),
                last_name: Yup.string().required("Last name is required"),
                country: Yup.string().required("Country is required"),
                state: Yup.string().required("State is required"),
                city: Yup.string().required("City is required"),
                street_address: Yup.string().required("Steet address is required"),
              })}

              onSubmit={(values, { setSubmitting }) => {
                if (!latestFrontFile) {
                  NotificationManager.error("Please upload an ID document")
                } else {
                  if (!["PASSPORT_ID", "NIN_SLIP"].includes(values.document_type) && !latestBackFile) {
                    NotificationManager.error("Also upload the back of the ID")
                  } else {
                    handleUploadDocument(values)
                  }
                }

              }}
            >
              {({
                handleSubmit,
                setFieldValue,
                isSubmitting,
                values,
              }) => (
                <Form>
                  <div className="padding-left-10 padding-right-10">
                    <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                      <label className="form-label">Document Type</label>
                      <Select
                        className="form-control padding-0 margin-top-10 margin-top-sm-0"
                        classNamePrefix="react-select"
                        placeholder="Select Documentation Type"
                        options={user.id_proof_options}
                        value={
                          user.id_proof_options
                            ? user.id_proof_options.find(
                              (option) => option.value === selectedDocumentTypeValue
                            )
                            : ""
                        }
                        onChange={(option) => {
                          // setSelectedDocumentTypeValue(option.value);
                          if (["PASSPORT_ID", "NIN_SLIP"].includes(values.document_type)) {
                            setLatestBackFile("")
                          }
                          setFieldValue("document_type", option.value);
                        }}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="document_type"
                        component="div"
                      />
                    </div>

                    <div>
                      <center className="red" >
                        This document won't be made public.
                      </center>

                      <Fragment >
                        <div className="d-flex" >
                          <div className="col-6 padding-right-5">

                            {latestFrontFile ?
                              <div className="min-width-130 max-width-130 margin-4 margin-sm-10 cursor-pointer bg-lighter-blue border-radius-6">
                                <div className="relative">
                                  <div
                                    className="fixed-height-100"
                                    onClick={() => {
                                      setViewedDocument(document);
                                      if (getDocumentFileformatFromUrl(latestFrontFile) == "pdf") {
                                        setIsPdfViewerVisible(true);
                                      } else if (getDocumentFileformatFromUrl(latestFrontFile) == "image") {
                                        setIsImageViewerVisible(true);
                                      }
                                    }}
                                  >
                                    <PreviewFileUpload file={latestFrontFile} />
                                  </div>

                                  <i
                                    className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                                    onClick={() => {
                                      getConfirmAlert({
                                        title: "Confirm delete!",
                                        message: "Are you sure you want to delete this document?",
                                        onClickYes: () => {
                                          setLatestFrontFile("")
                                        },
                                        onClickNo: () => null,
                                      });
                                    }}
                                  ></i>
                                </div>
                                <div
                                  className="font-size-12 padding-4 padding-left-8 padding-right-8 text-truncate border-bottom-radius-6"
                                  onClick={() => {
                                    setViewedDocument(document);
                                    if (getDocumentFileformatFromUrl(latestFrontFile) == "pdf") {
                                      setIsPdfViewerVisible(true);
                                    } else if (getDocumentFileformatFromUrl(latestFrontFile) == "image") {
                                      setIsImageViewerVisible(true);
                                    }
                                  }}
                                >
                                  <b>Front</b>
                                </div>
                              </div> :
                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  setLatestFrontFile(acceptedFiles[0]);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="d-flex align-content-center justify-content-center padding-top-20 padding-bottom-20">
                                    <div
                                      className="d-inline-block cursor-pointer"
                                      style={{ outline: "none" }}
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} accept="image/*,.pdf" />
                                      <center> FRONT</center>
                                      <p className="d-inline-block font-size-13 font-size-sm-14 line-height-16 line-height-sm-22 padding-6 padding-left-16 padding-right-16 white border-radius-10 bg-charcoal opacity-75">
                                        Drag 'n' drop or click to select file.
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            }
                          </div>

                          <div className="col-6 padding-left-5">
                            {
                              !["PASSPORT_ID", "NIN_SLIP"].includes(values.document_type) &&
                              <div>
                                {latestBackFile ?
                                  <div className="min-width-130 max-width-130 margin-4 margin-sm-10 cursor-pointer bg-lighter-blue border-radius-6">
                                    <div className="relative">
                                      <div
                                        className="fixed-height-100"
                                        onClick={() => {
                                          setViewedDocument(document);
                                          if (getDocumentFileformatFromUrl(latestBackFile) == "pdf") {
                                            setIsPdfViewerVisible(true);
                                          } else if (getDocumentFileformatFromUrl(latestBackFile) == "image") {
                                            setIsImageViewerVisible(true);
                                          }
                                        }}
                                      >

                                        <PreviewFileUpload file={latestBackFile} />
                                      </div>

                                      <i
                                        className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                                        onClick={() => {
                                          getConfirmAlert({
                                            title: "Confirm delete!",
                                            message: "Are you sure you want to delete this document?",
                                            onClickYes: () => {
                                              setLatestBackFile("")
                                            },
                                            onClickNo: () => null,
                                          });
                                        }}
                                      ></i>
                                    </div>
                                    <div
                                      className="font-size-12 padding-4 padding-left-8 padding-right-8 text-truncate border-bottom-radius-6"
                                      onClick={() => {
                                        setViewedDocument(document);
                                        if (getDocumentFileformatFromUrl(latestBackFile) == "pdf") {
                                          setIsPdfViewerVisible(true);
                                        } else if (getDocumentFileformatFromUrl(latestBackFile) == "image") {
                                          setIsImageViewerVisible(true);
                                        }
                                      }}
                                    >
                                      <b>Back</b>
                                    </div>
                                  </div> :
                                  <Dropzone
                                    onDrop={(acceptedFiles) => {
                                      setLatestBackFile(acceptedFiles[0]);
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="d-flex align-content-center justify-content-center padding-top-20 padding-bottom-20">
                                        <div
                                          className="d-inline-block cursor-pointer"
                                          style={{ outline: "none" }}
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} accept="image/*,.pdf" />
                                          <center> BACK</center>
                                          <p className="d-inline-block font-size-13 font-size-sm-14 line-height-16 line-height-sm-22 padding-6 padding-left-16 padding-right-16 white border-radius-10 bg-charcoal opacity-75">

                                            <span>Drag 'n' drop or click to select file.</span>
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                }
                              </div>
                            }
                          </div>

                        </div>
                      </Fragment>
                    </div>

                    <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                      <label className="form-label">First Name</label>
                      <Field
                        className="form-control"
                        placeholder="Enter First Name"
                        name="first_name"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="first_name"
                        component="div"
                      />
                    </div>
                    <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                      <label className="form-label">Middle Name</label>
                      <Field
                        className="form-control"
                        placeholder="Enter Middle Name"
                        name="middle_name"
                      />
                    </div>
                    <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                      <label className="form-label">Last Name</label>
                      <Field
                        className="form-control"
                        placeholder="Enter City"
                        name="last_name"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="last_name"
                        component="div"
                      />
                    </div>

                    <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                      <label className="form-label">Country</label>
                      <Field
                        component={() => (
                          <Select
                            className="form-control padding-0"
                            classNamePrefix="react-select"
                            placeholder="Select country"
                            options={countries}
                            value={
                              countries
                                ? countries.find(
                                  (option) => option.value === values.country
                                )
                                : ""
                            }
                            onChange={(option) => {
                              setFieldValue("country", option.value);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="country"
                        component="div"
                      />
                    </div>
                    <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                      <label className="form-label">State</label>
                      <Field
                        className="form-control"
                        placeholder="Enter State or Province"
                        name="state"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="state"
                        component="div"
                      />
                    </div>
                    <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                      <label className="form-label">City</label>
                      <Field
                        className="form-control"
                        placeholder="Enter City"
                        name="city"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="city"
                        component="div"
                      />
                    </div>
                    <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                      <label className="form-label">Address</label>
                      <Field
                        className="form-control"
                        placeholder="Enter Street Address"
                        name="street_address"
                      />
                      <ErrorMessage
                        className="error-message"
                        name="street_address"
                        component="div"
                      />
                    </div>

                    <button
                      className="btn bg-dark-blue white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180"
                      onClick={handleSubmit}
                      type="submit"
                    >
                      <b>Save Document</b>
                    </button>
                  </div>

                </Form>
              )}
            </Formik>
          </div>

        }
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isPdfViewerVisible}
        onRequestClose={() => setIsPdfViewerVisible(false)}
        className="modal max-width-800 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div>
          <PdfViewer
            document={viewedDocument}
            handleClose={() => setIsPdfViewerVisible(false)} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isImageViewerVisible}
        onRequestClose={() => setIsImageViewerVisible(false)}
        className="modal max-width-1000 padding-0 bg-black"
        overlayClassName="modal-backdrop"
      >
        <div>
          <ImageViewer
            maxHeightImage={600}
            document={viewedDocument}
            handleClose={() => setIsImageViewerVisible(false)}
          />
        </div>
      </Modal>

      {/* <Modal
        ariaHideApp={false}
        isOpen={isSelectTypeOpen}
        onRequestClose={() => {
          setLatestFrontFile("");
          setIsSelectTypeOpen(false);
        }}
        className="modal max-width-760 padding-0"
        overlayClassName="modal-backdrop"
      >
        <Formik
          initialValues={{
            first_name: user.first_name,
            last_name: user.last_name,
            middle_name: user.middle_name,
            country: user.country,
            state: user.state,
            city: user.city,
            street_address: user.street_address,
            post_code: user.post_code,
            document_type: selectedDocumentTypeValue
          }}

          validationSchema={Yup.object({
            document_type: Yup.string().required("Select document type"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            country: Yup.string().required("Country is required"),
            state: Yup.string().required("State is required"),
            city: Yup.string().required("City is required"),
            street_address: Yup.string().required("Steet address is required"),
          })}

          onSubmit={(values, { setSubmitting }) => {
            console.log(values)
            handleUploadDocument(values)
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
          }) => (
            <Form>
              <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-sm-40 padding-right-sm-40 justify-content-between align-items-center">
                <h1 className="font-size-20 font-size-sm-22 dark-blue">
                  User Identity Information
                </h1>
                <i
                  onClick={() => handleClose()}
                  className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
                ></i>
              </div>

              <div className="padding-left-10 padding-right-10 border-1 border-color-light-grey border-radius-8">
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Document Type</label>
                  <Select
                    className="form-control padding-0 margin-top-10 margin-top-sm-0"
                    classNamePrefix="react-select"
                    placeholder="Select Documentation Type"
                    options={user.id_proof_options}
                    value={
                      user.id_proof_options
                        ? user.id_proof_options.find(
                          (option) => option.value === selectedDocumentTypeValue
                        )
                        : ""
                    }
                    onChange={(option) => {
                      setSelectedDocumentTypeValue(option.value);
                      setFieldValue("document_type", option.value);
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="document_type"
                    component="div"
                  />
                </div>

                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">First Name</label>
                  <Field
                    className="form-control"
                    placeholder="Enter First Name"
                    name="first_name"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="first_name"
                    component="div"
                  />
                </div>
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Last Name</label>
                  <Field
                    className="form-control"
                    placeholder="Enter Middle Name"
                    name="middle_name"
                  />
                </div>
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Last Name</label>
                  <Field
                    className="form-control"
                    placeholder="Enter City"
                    name="last_name"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="last_name"
                    component="div"
                  />
                </div>

                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Country</label>
                  <Field
                    component={() => (
                      <Select
                        className="form-control padding-0"
                        classNamePrefix="react-select"
                        placeholder="Select country"
                        options={countries}
                        value={
                          countries
                            ? countries.find(
                              (option) => option.value === values.country
                            )
                            : ""
                        }
                        onChange={(option) => {
                          setFieldValue("country", option.value);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="country"
                    component="div"
                  />
                </div>
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">State</label>
                  <Field
                    className="form-control"
                    placeholder="Enter State or Province"
                    name="state"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="state"
                    component="div"
                  />
                </div>
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">City</label>
                  <Field
                    className="form-control"
                    placeholder="Enter City"
                    name="city"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="city"
                    component="div"
                  />
                </div>
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">Address</label>
                  <Field
                    className="form-control"
                    placeholder="Enter Street Address"
                    name="street_address"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="street_address"
                    component="div"
                  />
                </div>

                <button
                  className="btn bg-dark-blue white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180"
                  onClick={handleSubmit}
                  type="submit"
                >
                  <b>Save Document</b>
                </button>
              </div>

            </Form>
          )}
        </Formik>
      </Modal> */}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(SubmitIdProofDocs);
