import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const PhoneInputField = ({
  name,
  value,
  onChange,
  placeholderText,
  disabled,
  defaultCountry="NG"
}) => {
  return (
    <div>
      <PhoneInput
        className="form-control padding-0 d-flex focus-none"
        name={name}
        placeholder={placeholderText}
        value={value}
        onChange={(val) => {
          if (val == undefined) {
            val = "";
          }
          onChange(name, val);
        }}
        defaultCountry={defaultCountry}
        disabled={disabled}
      />
    </div>
  );
};

export default PhoneInputField;
