export default (prevState = {
    transaction_ref: null
}, action) => {
    switch (action.type) {
        case 'RESET_TRANSACTION_REF':
            return {
                ...prevState,
                transaction_ref: action.transaction_ref,
            };

        default:
            return prevState;
    }
};
