import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { useSpring, animated } from 'react-spring'
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import MyRecentActivities from "../tableTemplates/MyRecentActivities";
import MyNotifications from "../tableTemplates/MyNotifications";
import BalancesSlider from "../dashboard/pouchWallet/BalancesSlider"
import dashboard_home_img from "../images/dashboard_home_img.png";
import moneyPoolSetupImage from "../images/money_pool_setup.svg";
import testimonialSvg from "../images/testimonial_Icon.svg";
import appStore from "../images/app_store.svg";
import googlePlay from "../images/google_play.svg";
import emailIcon from "../images/email_icon_darkblue.svg";
import referImage from "../images/refer.png";
import historyIcon from "../images/history_icon_darkblue.svg";
import recentActivitiesIcon from "../images/recent_activitiesIcon_darkblue.svg";
import settingsIcon from "../images/settings_Icon_darkblue.svg";
import TestimonialForm from "../TestimonialForm";

const DashboardHome = ({ balances, user }) => {

  const [recentActivityObj, setRecentActivityObj] = useState(null);
  const [recentNotificationObj, setRecentNotificationObj] = useState(null);
  const [allActivityObj, setAllActivityObj] = useState(null);
  const [isAllActivityModalVisible, setIsAllActivityModalVisible] = useState(false);
  const [isAllNotificationModalVisible, setIsAllNotificationModalVisible] = useState(false);
  const [isTestimonialVisible, setIsTestimonialVisible] = useState(false);

  const [animateCyclicPoolBtn, handleAnimateCyclicPoolBtn] = useSpring(() => ({ scale: 1 }))
  const [animateReferBtn, handleAnimateReferBtn] = useSpring(() => ({ scale: 1 }))
  const [animateTopUpBtn, handleAnimateTopUpBtn] = useSpring(() => ({ scale: 1 }))
  // const [balances, setBalances] = useState([]);

  const recentNotificationSocketRef = useRef();
  const recentActivitySocketRef = useRef();
  const balanceRef = useRef(null)

  const fadeStyles = useSpring({
    from: { opacity: 0 },
    config: { duration: 2000 },
    loop: {
      opacity: 1,
    },
  })

  const slideStyles = useSpring({
    from: { x: 100, opacity: 0 },
    config: { duration: 1000 },
    loop: {
      x: 0, opacity: 1,
    },
  })

  const initializeNotificationSockets = (subType, limit) => {

    recentNotificationSocketRef.current = new WebSocket(
      `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/notification/dashboard/${subType}/connect`)

    recentNotificationSocketRef.current.onopen = e => {

      recentNotificationSocketRef.current.send(JSON.stringify({
        type: "notification_obj",
        limit,
        user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
      }))
    }

    recentNotificationSocketRef.current.onmessage = e => {
      const data = JSON.parse(e.data)

      if (data.type == "notification_obj") {
        if (data.notification_obj) {
          setRecentNotificationObj(data.notification_obj)
        }
      }
    }

    recentNotificationSocketRef.current.onerror = e => {
      console.log('error', e)
    }
  }

  const initializeActivitySockets = (subType, limit) => {
    recentActivitySocketRef.current = new WebSocket(`${process.env.REACT_APP_SOCKET_BASE_URL}/ws/activity/dashboard/${subType}/connect`)
    recentActivitySocketRef.current.onopen = e => {
      recentActivitySocketRef.current.send(JSON.stringify({
        type: "all_activities_obj",
        limit,
        user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
      }))
    }

    recentActivitySocketRef.current.onmessage = e => {
      const data = JSON.parse(e.data)

      if (data && data.all_activities_obj) {
        if (subType == "initial") {
          setRecentActivityObj(data.all_activities_obj)
        } else if (subType == "more") {
          setAllActivityObj(data.all_activities_obj)
        }
      }
    }

    recentActivitySocketRef.current.onerror = e => {
      console.log('error', e)
    }
  }

  // const handleGetBalancesBySocket = () => {
  //   balanceRef.current = new WebSocket(
  //     `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/pouch_wallet/${user.id}/connect`)

  //   balanceRef.current.onopen = e => {
  //     balanceRef.current.send(JSON.stringify({
  //       type: "wallet_balances",
  //       user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
  //     }))
  //   }

  //   balanceRef.current.onmessage = e => {
  //     const data = JSON.parse(e.data)

  //     if (data.type == "wallet_balances") {
  //       setBalances(data.balances)
  //     }
  //   }

  //   balanceRef.current.onerror = e => {
  //     console.log('error', e)
  //   }
  // }

  useEffect(() => {
    initializeNotificationSockets("initial", 5)

    initializeActivitySockets("initial", 5)

    // handleGetBalancesBySocket()

  }, []);

  return (
    <div className="overflow-hidden">
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-sm-6 d-flex justify-content-center">
              <div className="stretch-full">
                <animated.div style={fadeStyles} className="padding-20 margin-top-8 margin-bottom-8 margin-sm-10 box-shadow border-radius-14">
                  <h3 className="font-size-18 line-height-18 dark-blue">
                    <NavLink
                      to="/dashboard/money-pool"
                      className="text-decoration-none"
                    >
                      Cyclic Money Pool
                    </NavLink>
                  </h3>
                  <div className="d-flex flex-column align-items-center">
                    <NavLink
                      to="/dashboard/money-pool"
                      className="text-decoration-none"
                    >
                      <img
                        className="max-width-150 margin-top-8 margin-bottom-10 text-center"
                        src={moneyPoolSetupImage}
                      />
                    </NavLink>
                    <NavLink
                      to="/dashboard/money-pool/new"
                      className="stretch-full text-decoration-none"
                    >
                      <animated.button className="btn padding-8 bg-grad-blue-lightblue white stretch-full font-size-16"
                        style={animateCyclicPoolBtn}
                        onMouseEnter={() => {
                          handleAnimateCyclicPoolBtn({ scale: 1.05 });
                        }}
                        onMouseLeave={() => {
                          handleAnimateCyclicPoolBtn({ scale: 1 });
                        }}>
                        <b>Get Started</b>
                      </animated.button>
                    </NavLink>
                  </div>
                </animated.div>
              </div>
            </div>
            <div className="col-sm-6 d-flex justify-content-center">
              <div className="stretch-full margin-top-8 margin-bottom-8 margin-sm-10">
                <animated.div style={{ ...fadeStyles, height: "100%" }}
                  className="d-flex flex-column justify-content-between padding-20 box-shadow border-radius-14"
                >
                  <h3 className="font-size-18 line-height-18 dark-blue">
                    <NavLink
                      to="/dashboard/pouch-wallet"
                      className="text-decoration-none"
                    >
                      Pouch Wallet
                    </NavLink>
                  </h3>
                  <div>
                    <BalancesSlider balances={balances} />
                  </div>
                  <div>
                    <NavLink
                      to="/dashboard/pouch-wallet?q=topUp"
                      className="stretch-full text-decoration-none"
                    >
                      <animated.button className="btn padding-8 bg-grad-lightgreen-blue white stretch-full font-size-16"
                        style={animateTopUpBtn}
                        onMouseEnter={() => {
                          handleAnimateTopUpBtn({ scale: 1.05 });
                        }}
                        onMouseLeave={() => {
                          handleAnimateTopUpBtn({ scale: 1 });
                        }}>
                        <b>Top Up</b>
                      </animated.button>
                    </NavLink>
                  </div>
                </animated.div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-sm-5 d-flex flex-column align-items-center">
              <div className="stretch-full">
                <animated.div style={fadeStyles} className="padding-20 margin-top-8 margin-bottom-8 margin-sm-10 box-shadow border-radius-14">
                  <h3 className="font-size-18 line-height-18 dark-blue">
                    <NavLink to="/dashboard/account"
                      className="text-decoration-none"
                    >
                      Refer and Earn
                    </NavLink>
                  </h3>
                  <div className="d-flex flex-column align-items-center">
                    <NavLink to="/dashboard/account"
                      className="text-decoration-none"
                    >
                        <img
                        className="max-width-150 margin-bottom-10 text-center"
                        src={referImage}
                      />
                    </NavLink>
                    <NavLink to="/dashboard/account?q=referAndEarn"
                      className="stretch-full text-decoration-none"
                    >
                      <animated.button className="btn padding-8 bg-grad-lightgreen-lightblue white stretch-full font-size-16"
                        style={animateReferBtn}
                        onMouseEnter={() => {
                          handleAnimateReferBtn({ scale: 1.05 });
                        }}
                        onMouseLeave={() => {
                          handleAnimateReferBtn({ scale: 1 });
                        }}>
                        <b>Refer a Friend</b>
                      </animated.button>
                    </NavLink>
                  </div>
                </animated.div>
              </div>
              <div className="stretch-full text-decoration-none">
                <animated.div style={fadeStyles} className="padding-20 margin-top-8 margin-bottom-8 margin-sm-10 border-1 border-color-grey border-radius-14">
                  <NavLink
                    to="/dashboard/account?q=accountSettings"
                    className="d-flex align-items-center padding-top-20 padding-bottom-20 dark-blue text-decoration-none"
                  >
                    <img width="20" src={settingsIcon} />
                    <b className="padding-left-10">Account Settings</b>
                  </NavLink>
                  <NavLink
                    to="/dashboard/account?q=transactionHistory"
                    className="d-flex align-items-center padding-top-20 padding-bottom-20 dark-blue text-decoration-none"
                  >
                    <img width="20" src={historyIcon} />
                    <b className="padding-left-10">Transaction History</b>
                  </NavLink>
                  <NavLink
                    to="/contact-us"
                    className="d-flex align-items-center padding-top-20 padding-bottom-20 dark-blue text-decoration-none"
                  >
                    <img width="20" src={emailIcon} />
                    <b className="padding-left-10">Contact Us</b>
                  </NavLink>
                </animated.div>
              </div>
            </div>
            <div className="col-sm-7">
              <animated.div style={slideStyles} className="margin-top-8 margin-bottom-8 margin-sm-10">
                <img
                  className="d-block stretch-full border-top-radius-14"
                  src={dashboard_home_img}
                />
                <div className="padding-10 padding-sm-20 border-left-1 border-right-1 border-bottom-1 border-color-grey border-bottom-radius-14">
                  <div>
                    <h1 className="font-size-22 line-height-22 margin-top-10 margin-bottom-10">
                      Download the mobile app
                    </h1>
                    <div className="d-flex justify-content-between">
                      <div className="margin-top-10 margin-bottom-10">
                        <a target='_blank' href={"https://apps.apple.com/app/pouchmoney/id1602625150"}>
                          <img className="max-width-120" src={appStore} />
                        </a>
                      </div>
                      <div className="margin-top-10 margin-bottom-10">
                        <a target='_blank' href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.pouchmoney">
                          <img className="max-width-120" src={googlePlay} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </animated.div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="max-width-300">
          <div className="d-flex margin-top-30 margin-bottom-30 cursor-pointer align-items-center border-1 border-color-dark-blue dark-blue padding-10 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => {
              setIsAllNotificationModalVisible(true)
              initializeNotificationSockets("more", 10)
            }}>
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={recentActivitiesIcon}
            />
            <label className="cursor-pointer">See Recent Notifications</label>
          </div>

          <div className="d-flex margin-top-30 margin-bottom-30 cursor-pointer align-items-center border-1 border-color-dark-blue dark-blue padding-10 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => {
              setIsAllActivityModalVisible(true)
              initializeActivitySockets("more", 10)
            }}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={historyIcon}
            />
            <label className="cursor-pointer">See Recent Activities</label>
          </div>
        </div>

        <div className="col-md-5 padding-left-md-20 margin-top-10 margin-top-md-30 margin-bottom-80 margin-bottom-md-30">
          <div className="row border-1 border-color-dark-blue border-radius-16 padding-20">
            <div className="d-flex justify-content-center col-sm-4">
              <img
                className="max-width-120 margin-top-8 margin-bottom-14 text-center"
                src={testimonialSvg}
              />
            </div>
            <div className="d-flex justify-content-center col-sm-6">
              <div>
                <p className="dark-blue font-size-16 font-size-sm-18">
                  Would you like to share your story with the world?
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn bg-orange white max-width-260 padding-8 margin-top-10 font-size-16"
                    type="button"
                    onClick={() => setIsTestimonialVisible(true)}
                  >
                    <b>
                      Write a testimonial
                    </b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isAllNotificationModalVisible}
        onRequestClose={() => {
          initializeNotificationSockets("initial", 5)
          setIsAllNotificationModalVisible(false)
        }}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Recent Notifications
          </h1>
          <i
            onClick={() => setIsAllNotificationModalVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line" />
        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          {recentNotificationObj && recentNotificationObj.notifications && recentNotificationObj.notifications.length ?
            <MyNotifications
              recentNotifications={recentNotificationObj.notifications}
            /> :
            <div className="font-size-14 grey line-height-14">There are no recent notifications</div>}
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isAllActivityModalVisible}
        onRequestClose={() => {
          // initializeActivitySockets("initial", 5)
          setIsAllActivityModalVisible(false)
        }}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Recent Activities
          </h1>
          <i
            onClick={() => setIsAllActivityModalVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line" />
        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          {allActivityObj && allActivityObj.activities && allActivityObj.activities.length ?
            <MyRecentActivities
              recentActivities={allActivityObj.activities}
            /> :
            <div className="font-size-14 grey line-height-14">There are no recent activities</div>}
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isTestimonialVisible}
        onRequestClose={() => {
          setIsTestimonialVisible(false)
        }}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <TestimonialForm
          handleClose={() => setIsTestimonialVisible(false)}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    balances: state.auth.walletBalances,
  };
};

export default connect(mapStateToProps)(DashboardHome);