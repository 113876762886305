import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import shareIcon from "../../../images/share_icon_darkblue.svg";
import referIcon from "../../../images/refer_icon_darkblue.svg";
import SubmitIdProofDocs from "./SubmitIdProofDocs";
import SubmitAddressProofDocs from "./SubmitAddressProofDocs";
import SubmitCompanyInfo from "./SubmitCompanyInfo";

const VerificationDocs = ({ user, setIsPageLoading }) => {

  const [isSubmitIdProofDocVisible, setIsSubmitIdProofDocVisible] = useState(false);
  const [isSubmitIdAddressDocVisible, setIsSubmitIdAddressDocVisible] = useState(false);
  const [isSubmitCompanyVisible, setIsSubmitCompanyVisible] = useState(false);

  return (
    <div>
      <div>

        <div className="padding-bottom-18">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsSubmitIdProofDocVisible(true)}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={shareIcon}
            />
            <span className="font-size-14 font-size-sm-17">
              Submit Proof of ID
            </span>
          </div>
        </div>

        <div className="padding-top-18 padding-bottom-10">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsSubmitIdAddressDocVisible(true)}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={referIcon}
            />
            <span className="font-size-14 font-size-sm-17">Submit Proof of Address</span>
          </div>
        </div>

        <div className="padding-top-18 padding-bottom-10">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsSubmitCompanyVisible(true)}
          >
            <i
              className="fa fa-industry font-size-20 dark-blue fixed-width-20 margin-right-20 margin-right-sm-24"
            ></i>
            <span className="font-size-14 font-size-sm-17">Submit Proof of Company</span>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isSubmitIdProofDocVisible}
        onRequestClose={() => setIsSubmitIdProofDocVisible(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue text-center stretch-full">
            Submit Proof of ID Documents for Verification
          </h1>
          <i
            onClick={() => setIsSubmitIdProofDocVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <SubmitIdProofDocs
            document={user.id_proof_doc}
            setIsPageLoading={setIsPageLoading}
            handleClose={() => setIsSubmitIdProofDocVisible(false)} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isSubmitIdAddressDocVisible}
        onRequestClose={() => setIsSubmitIdAddressDocVisible(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue text-center stretch-full">
            Submit Proof of Address Documents for Verification
          </h1>
          <i
            onClick={() => setIsSubmitIdAddressDocVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <SubmitAddressProofDocs
            documents={user.address_proof_docs}
            setIsPageLoading={setIsPageLoading}
            handleClose={() => setIsSubmitIdAddressDocVisible(false)} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isSubmitCompanyVisible}
        onRequestClose={() => setIsSubmitCompanyVisible(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue text-center stretch-full">
            Submit Company Information
          </h1>
          <i
            onClick={() => setIsSubmitCompanyVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <SubmitCompanyInfo
            company={user.company_info}
            setIsPageLoading={setIsPageLoading}
            handleClose={() => setIsSubmitCompanyVisible(false)} />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(VerificationDocs);
