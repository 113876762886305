import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ContactUs = ({ user, setIsPageLoading, dispatch }) => {

    return (
        <div>
            <div className="inner-content">
                <h2>Contact Us</h2>
            </div>
            <div className="horizontal-line" />
            <div>


            </div>
        </div>
    );
};

export default ContactUs;
