import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfThumbnail = ({ document, width, height }) => {
  return (
    <div>
      <Document file={document.file_url}>
        <Page pageNumber={1} height={height} />
      </Document>
    </div>
  );
};

export default PdfThumbnail;
