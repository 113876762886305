import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { connect } from "react-redux";
import * as Yup from "yup";
import { convertSnakeToStandardCasing } from "../../utilities";
import CurrentWindowWidth from "../CurrentWindowWidth";
import filterIcon from "../images/filter_icon.png";

const FilterSorter = ({
  sorterOptions,
  initialSortBy,
  filterOptions,
  onSelectQuery,
  queryOrder,
  limit,
  totalCount,
  noOfItems,
  page
}) => {
  let windowWidth = CurrentWindowWidth();

  const selectInputWidth = () => {
    let getInnerContainerWidth = windowWidth / 5;

    if (getInnerContainerWidth <= 130) {
      getInnerContainerWidth = 130;
    }
    return getInnerContainerWidth;
  }

  const [initialFormikValues, setInitialFormikValues] = useState(null)
  const [isClearButtonVisible, setIsClearButtonVisible] = useState(false)
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useEffect(() => {
    const init = {}
    init["sort_by"] = initialSortBy
    init["query_order"] = queryOrder

    filterOptions.forEach((item) => {
      const filterType = Object.keys(item)[0]
      init[filterType] = ""
    })
    setInitialFormikValues(init)
  }, [])

  return (
    <div>
      {initialFormikValues &&
        <Formik
          initialValues={initialFormikValues}
          validationSchema={Yup.object({
            sort_by: Yup.string().required("Select a sort query"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            onSelectQuery(values)
          }}
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            resetForm,
          }) => (
            <Form>
              <div className="d-flex fixed-height-40 align-items-center margin-top-10 margin-bottom-10">
                <label className="form-label padding-right-8 dark-blue font-size-14">
                  Sort by
                </label>
                <Field
                  component={() => (
                    <Select
                      className="max-width-140 font-size-14 padding-0"
                      classNamePrefix="react-select"
                      options={sorterOptions}
                      value={
                        sorterOptions
                          ? sorterOptions.find(
                            (option) =>
                              option.value ===
                              values.sort_by
                          )
                          : ""
                      }
                      onChange={(option) => {
                        setFieldValue(
                          "sort_by",
                          option.value
                        );
                        handleSubmit()
                      }}
                    />
                  )}
                />
                <div
                  className={`fas fa-arrow-up font-size-14 grey border-1 border-color-light-grey border-radius-4 padding-6 margin-left-6 margin-right-6 cursor-pointer hover-bg-light-grey ${values.query_order == "asc" ? "bg-light-grey " : ""} `}
                  onClick={() => {
                    setFieldValue("query_order", "asc")
                    handleSubmit()
                  }}>
                </div>

                <div
                  className={`fas fa-arrow-down font-size-14 grey border-1 border-color-light-grey border-radius-4 padding-6 cursor-pointer ${values.query_order == "desc" ? "bg-light-grey" : ""}`}
                  onClick={() => {
                    setFieldValue("query_order", "desc")
                    handleSubmit()
                  }}>
                </div>

                {!isFilterVisible ?
                  <div className="d-flex justify-content-end stretch-full">
                    <img
                      className="d-block cursor-pointer"
                      onClick={() => setIsFilterVisible(true)}
                      src={filterIcon} width="20px" />
                  </div> :
                  <div className="d-flex justify-content-end stretch-full">
                    <i
                      className="fa fa-close red font-size-18 cursor-pointer"
                      onClick={() => {
                        resetForm()
                        setFieldValue("sort_by", initialSortBy)
                        setFieldValue("query_order", queryOrder)
                        onSelectQuery({
                          ...initialFormikValues,
                          sort_by: initialSortBy,
                          query_order: queryOrder
                        })
                        setIsClearButtonVisible(false)
                        setIsFilterVisible(false)
                      }}></i>
                  </div>}

              </div>

              {isFilterVisible &&
                <div className="padding-top-16 padding-bottom-16 padding-left-6 padding-left-sm-20 padding-right-6 padding-right-sm-20 padding-top-6 padding-bottom-6 border-1 border-color-light-grey border-radius-8">

                  {isClearButtonVisible &&
                    <button
                      className="btn margin-bottom-10 bg-orange-red max-width-80"
                      type="button"
                      onClick={() => {
                        resetForm()
                        setFieldValue("sort_by", initialSortBy)
                        setFieldValue("query_order", queryOrder)
                        onSelectQuery({
                          ...initialFormikValues,
                          sort_by: initialSortBy,
                          query_order: queryOrder
                        })
                        setIsClearButtonVisible(false)
                      }}
                    >
                      Clear
                    </button>}

                  <div>
                    <div className="d-flex flex-wrap justify-content-between">
                      {
                        filterOptions.map((item, index) => {
                          const filterType = Object.keys(item)[0]
                          const options = Object.values(item)[0]

                          return (
                            <div key={index} className="margin-2 margin-top-4 margin-bottom-6"
                              style={{ width: selectInputWidth() + "px", maxWidth: "220px" }}>
                              <div className="padding-top-4 padding-bottom-4 font-size-11">
                                <label>{convertSnakeToStandardCasing(filterType)}</label>
                              </div>
                              <Field
                                name={filterType}
                                component={() => {
                                  return (
                                    <Select
                                      classNamePrefix="react-select"
                                      options={options}
                                      value={
                                        options
                                          ? options.find(
                                            (option) =>
                                              option.value ===
                                              values[filterType]
                                          )
                                          : ""
                                      }
                                      onChange={(option) => {
                                        setFieldValue(filterType, option.value)
                                        setIsClearButtonVisible(true)
                                        handleSubmit()
                                      }}
                                    />
                                  )
                                }}
                              />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>}

              <div className="d-flex justify-content-between">
                <div>
                  {
                    !!page && !!totalCount && !!limit ?
                      <span>
                        Page {page}/{Math.ceil(totalCount / limit)}
                      </span>
                      : null
                  }

                </div>
                <div>{!!noOfItems && !!totalCount ? <span>
                  {noOfItems} items out of {totalCount}
                </span> : null}</div>
              </div>
            </Form>
          )}
        </Formik>
      }

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(FilterSorter);
