import React from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { connect } from "react-redux";
import { generateTransactionRef } from "../../store/actions/transaction";
import { refreshPage } from "../../utilities";

const PayNow = ({
  dispatch,
  config,
  onBankPaymentSuccessful,
  handlePayFromWallet
}) => {
  const handleFlutterPayment = useFlutterwave(config);

  return (
    <div>
      <div className="d-flex align-center padding-top-8 padding-bottom-8">
        <button
          className="btn bg-dark-blue max-width-140 padding-top-8 padding-bottom-8 margin-right-10 margin-right-sm-20"
          type="button"
          onClick={() => {
            handleFlutterPayment({
              callback: (res) => {
                closePaymentModal(); // this will close the modal programmatically

                dispatch(generateTransactionRef());

                if (res.status == "successful") {
                  onBankPaymentSuccessful(res.transaction_id)
                  // Refresh the page after 4000 milliseconds to avoid the flutterwave bug of recurring response
                  refreshPage(4000)
                } else {
                  console.log("Error", res);
                }
              },
              onClose: () => { },
            });
          }}
        >
          Pay from Bank
        </button>

        <button
          className="btn bg-orange max-width-140 padding-top-8 padding-bottom-8"
          type="button"
          onClick={() => {
            handlePayFromWallet()
          }}
        >
          Pay from Wallet
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(PayNow);
