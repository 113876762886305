import axiosInstance from "../../helpers/axiosInstance";

// For a user to write a testimonial
export const writeTestimonial = (data) => {
  return async () => {
    return axiosInstance.post(
      "/testimonials/write-testimonial", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

// To get the latest 100 testimonies
export const getLatestTestimonials = (data) => {
  return async () => {
    return axiosInstance.get(
      "/testimonials/get-testimonials")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};