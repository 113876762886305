import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment"
import Modal from "react-modal";
import { getTransferHistory } from "../../../../store/actions/transaction"
import FormattedMoney from "../../../utilityComponents/FormattedMoney"
import FilterSorter from "../../../utilityComponents/FilterSorter";
import { convertSnakeToStandardCasing } from "../../../../utilities";
import Pagination from "../../../utilityComponents/Pagination";

const MyTransferHistory = ({
  user,
  dispatch,
  setIsPageLoading,
}) => {
  const [initialParams, setInitialParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  });
  const [transferHistoryObj, setTransferHistoryObj] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [initialTotalCount, setInitialTotalCount] = useState(0);
  const [openedTransferDetails, setOpenedTransferDetails] = useState({});
  const [isOpenedTransferVisible, setIsOpenedTransferVisible] = useState(false);

  let currencies = user.supported_currencies.map(item => {
    return {
      label: item,
      value: item
    }
  })

  currencies = [{ label: "All", value: "" }, ...currencies]

  const handleGetTransferHistory = async (params) => {
    const res = await dispatch(getTransferHistory(params))
    if (res.success == true) {
      setTransferHistoryObj(res.data)
      setInitialParams(res.data.original_query_params)
      return res.data
    }
  }

  useEffect(() => {
    handleGetTransferHistory(initialParams)
      .then(data => {
        if (data) {
          setInitialTotalCount(data.total_count)
        }
      })

  }, [])

  return (
    <div>
      {initialTotalCount > 0 &&
        <div>
          <FilterSorter
            filterOptions={[
              {
                status: [
                  { label: "All", value: '' },
                  { label: 'Pending', value: 'PENDING' },
                  { label: 'Settled', value: 'SETTLED' }
                ]
              },
              {
                currency: currencies
              }
            ]}
            sorterOptions={[
              { label: 'Created Date', value: 'created_at' },
              { label: 'Amount', value: 'amount' }
            ]}
            initialSortBy={initialParams.sort_by}
            queryOrder={initialParams.query_order}
            limit={initialParams.no_of_items_per_page}
            page={initialParams.page}
            totalCount={initialParams.total_count}
            noOfItems={transferHistoryObj.transfers.length}
            onSelectQuery={(values) => {
              setIsSearching(true)
              const newQueryObj = {
                ...initialParams,
                ...values
              }
              handleGetTransferHistory(newQueryObj)
            }}
          />

        </div>}

      {transferHistoryObj && transferHistoryObj.transfers.length > 0 ?
        <div>

          <div className="row d-flex-hidden-sm align-items-center font-size-11 font-size-sm-13 font-size-md-15 padding-4 padding-md-16 padding-left-md-36 padding-right-md-36">
            <div className="col-sm-6 row align-items-center">
              <div className="col-3">ID</div>
              <div className="col-4">Status</div>
              <div className="col-5">Date</div>
            </div>
            <div className="col-sm-6 row align-items-center">
              <div className="col-3">Type</div>
              <div className="col-4">Amount</div>
              <div className="col-5">Total Fee</div>
            </div>
          </div>
          {
            transferHistoryObj.transfers.map((transfer, index) => {
              return (
                <div key={index}
                  className="row margin-bottom-14 margin-bottom-sm-18 align-items-center bg-lighter-grey font-size-11 font-size-sm-13 font-size-md-15 padding-4 padding-md-16 padding-left-md-36 padding-right-md-36 border-radius-6"
                  onClick={() => {
                    setOpenedTransferDetails(transfer)
                    setIsOpenedTransferVisible(true)
                  }}>
                  <div className="col-sm-6 row align-items-center margin-bottom-8">
                    <div className="col-3">
                      <div className="d-block-display-sm">ID</div>
                      <div>
                        <b>#{transfer.id}</b>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-block-display-sm">Status</div>
                      <div>
                        <span className="max-width-100 font-size-13 font-size-sm-13 normal-green">
                          <b>{convertSnakeToStandardCasing(transfer.status)}</b>
                        </span>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="d-block-display-sm">
                        Date
                      </div>
                      <div>
                        <b>{moment(String(transfer.created_at)).format("Do MMM YY, HH:mm a")}</b>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 row align-items-center margin-bottom-8">
                    <div className="col-3">
                      <div className="d-block-display-sm">
                        Type
                      </div>
                      <div>
                        <b>
                          {convertSnakeToStandardCasing(transfer.service_type)}
                        </b>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-block-display-sm">Amount</div>
                      <div>
                        <b><FormattedMoney value={transfer.amount} currency={transfer.amount_currency} /></b>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="d-block-display-sm">
                        Total Fee
                      </div>
                      <div>
                        <b><FormattedMoney value={parseFloat(transfer.transfer_fee) + parseFloat(transfer.platform_fee)} currency={transfer.transfer_fee_currency} /></b>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div> :
        <div>You do not have any transfer history {isSearching && "that matches this query"}</div>
      }
      {
        (transferHistoryObj && (transferHistoryObj.total_count > initialParams.no_of_items_per_page)) ?
          <Pagination
            {...initialParams}
            handleGetQuery={handleGetTransferHistory}
          /> :
          null
      }

      <Modal
        ariaHideApp={false}
        isOpen={isOpenedTransferVisible}
        onRequestClose={() => setIsOpenedTransferVisible(false)}
        className="modal"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Payout Transfer Details
          </h1>
          <i
            onClick={() => setIsOpenedTransferVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />
        <div className="dark-blue margin-10">
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110">Status</span>
            <b>{convertSnakeToStandardCasing(openedTransferDetails.status)}</b>
          </div>
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110">ID</span>  <b>#{openedTransferDetails.id}</b>
          </div>
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110">Reference</span>  <b>#{openedTransferDetails.reference}</b>
          </div>
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110">Date</span>  <b>{moment(openedTransferDetails.created_at).format("DD MMM YYYY, HH:mm a")}</b>
          </div>
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110">Amount</span> <b><FormattedMoney value={openedTransferDetails.amount} currency={openedTransferDetails.amount_currency} /></b>
          </div>
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110">Platform Fee</span> <b><FormattedMoney value={openedTransferDetails.platform_fee} currency={openedTransferDetails.platform_fee_currency} /></b>
          </div>
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110">Transfer Fee</span> <b><FormattedMoney value={openedTransferDetails.transfer_fee} currency={openedTransferDetails.transfer_fee_currency} /></b>
          </div>
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110">Account Name</span> <b>{openedTransferDetails.beneficiary_name}</b>
          </div>
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110"> Account No.</span> <b>{openedTransferDetails.beneficiary_account_number}</b>
          </div>
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110"> Bank Name</span> <b>{openedTransferDetails.beneficiary_bank_name}</b>
          </div>
          <div className="d-flex align-items padding-top-12 padding-bottom-12">
            <span className="min-width-110"> Country Code</span> <b>{openedTransferDetails.beneficiary_country_code}</b>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};


export default connect(mapStateToProps)(MyTransferHistory);
