import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import CreatePoolDescription from "./fragments/PoolDescription";
import EditPoolDescription from "./fragments/PoolDescription";
import PoolCollectionDates from "./PoolCollectionDates";
import MoneyPoolSummary from "./MoneyPoolSummary";
import PoolSetupFormHeader from "./fragments/PoolSetupFormHeader";
import { getSpecificMoneyPool } from "../../../../store/actions/moneyPool";
import { NotificationManager } from "react-notifications";

const MoneyPoolSetupSlides = ({
  dispatch,
  history,
  user,
  setIsPageLoading,
  isCreatingNew = true,
}) => {
  const search = useLocation().search;
  const { params } = useRouteMatch()

  const activeSlide = new URLSearchParams(search).get("activeSlide")
  const agentID = new URLSearchParams(search).get("agentID")
  const unit_payment = new URLSearchParams(search).get("unit_payment")
  const unit_payment_currency = new URLSearchParams(search).get("unit_payment_currency")
  const total_units = new URLSearchParams(search).get("total_units")
  const payment_frequency = new URLSearchParams(search).get("payment_frequency")
  const is_interest_paid = new URLSearchParams(search).get("is_interest_paid")
  const monthly_interest_rate = new URLSearchParams(search).get("monthly_interest_rate")
  const start_date = new URLSearchParams(search).get("start_date")

  const [slug] = useState(params.slug);
  const [pool, setPool] = useState(null);
  const [initialPoolDescription, setInitialPoolDescription] = useState({
    title: "",
    unit_payment: null,
    unit_payment_currency: 'NGN',
    total_units: null,
    payment_frequency: "",
    is_interest_paid: false,
    monthly_interest_rate: 0,
    start_date: null,
    end_date: null,
    open_to_public: false
  });
  const [visiblePoolSetupSlide, setVisiblePoolSetupSlide] = useState(null);
  const [slideNames] = useState([
    "createPoolDescription",
    "editPoolDescription",
    "poolCollectionDates",
    // "poolPaymentSetup",
    "poolSummary",
  ]); // Always maintain this order

  const handleGetSpecificMoneyPool = async (slug) => {
    setIsPageLoading([true, "Loading..."]);

    const res = await dispatch(getSpecificMoneyPool(slug))
    if (res) {
      setIsPageLoading([false]);
      if (res.error) {
        NotificationManager.error(res.error.message, "Error!")
      } else {
        if (res.success == true) {
          const pool = res.data
          setPool(pool)
        }
      }
    }
  };

  useEffect(() => {
    if (agentID) {
      initialPoolDescription["agent_id"] = agentID
      setInitialPoolDescription(initialPoolDescription)
    }

    if (unit_payment) {
      setInitialPoolDescription(prev => {
        return {
          ...prev,
          unit_payment
        }
      })
    }

    if (unit_payment_currency) {
      setInitialPoolDescription(prev => {
        return {
          ...prev,
          unit_payment_currency
        }
      })
    }

    if (total_units) {
      console.log(parseInt(total_units))
      setInitialPoolDescription(prev => {
        return {
          ...prev,
          total_units: parseInt(total_units)
        }
      })
    }

    if (payment_frequency) {
      setInitialPoolDescription(prev => {
        return {
          ...prev,
          payment_frequency
        }
      })
    }

    if (is_interest_paid) {
      setInitialPoolDescription(prev => {
        return {
          ...prev,
          is_interest_paid: JSON.parse(is_interest_paid)
        }
      })
    }

    if (monthly_interest_rate) {
      setInitialPoolDescription(prev => {
        return {
          ...prev,
          monthly_interest_rate: parseFloat(monthly_interest_rate)
        }
      })
    }

    if (start_date && new Date(start_date).getTime() > new Date().getTime()) {
      setInitialPoolDescription(prev => {
        return {
          ...prev,
          start_date: start_date
        }
      })
    }

  }, [])

  useEffect(() => {
    if (isCreatingNew) {
      setVisiblePoolSetupSlide("createPoolDescription");
    } else {
      if (slug) {
        handleGetSpecificMoneyPool(slug)
        if (activeSlide) {
          setVisiblePoolSetupSlide(activeSlide);
        } else {
          setVisiblePoolSetupSlide("editPoolDescription");
        }
      }
    }
  }, [isCreatingNew]);

  return (
    <div>

      <h2 className="font-size-22 font-size-sm-24 dark-blue">
        Set Up Cyclic Money Pool</h2>

      <div className="horizontal-line  margin-top-10 margin-bottom-20" />

      <PoolSetupFormHeader
        activeSlide={visiblePoolSetupSlide}
        slideNames={slideNames}
      />

      <div className="padding-top-10">

        {visiblePoolSetupSlide == "createPoolDescription" && (
          <CreatePoolDescription
            history={history}
            initialPoolDescription={initialPoolDescription}
            setPool={setPool}
            isCreatingNew={isCreatingNew}
            setIsPageLoading={setIsPageLoading}
          />
        )}

        {visiblePoolSetupSlide == "editPoolDescription" && pool && (
          <EditPoolDescription
            history={history}
            setPool={setPool}
            isCreatingNew={isCreatingNew}
            initialPoolDescription={pool.pool_description}
            setIsPageLoading={setIsPageLoading}
          />
        )}

        {visiblePoolSetupSlide == "poolCollectionDates" && pool && (
          <PoolCollectionDates
            history={history}
            user={user}
            pool={pool}
            setPool={setPool}
            areYouOptedIn={pool.pool_description.are_you_opted_in}
            isDateChangeable={pool.is_date_changeable}
            collectionDates={pool.collection_dates}
            setIsPageLoading={setIsPageLoading}
          />
        )}

        {visiblePoolSetupSlide == "poolSummary" && pool && (
          <MoneyPoolSummary
            history={history}
            setPool={setPool}
            pool={pool}
            setIsPageLoading={setIsPageLoading}
          />
        )}

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MoneyPoolSetupSlides);
