import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { convertSnakeToStandardCasing } from "../../../utilities";
import FormattedMoney from "../../utilityComponents/FormattedMoney";

const TransactionDetails = ({
  specificTransaction,
}) => {

  return (
    <div className="font-size-12 font-size-md-14">
      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Status</b>
        <div className="col-6">
          {convertSnakeToStandardCasing(specificTransaction.status)}
          {/* {specificTransaction.status == "PENDING" ?
            <div>
              <button
                type="button"
                onClick={async () => {
                  getConfirmAlert({
                    title: "Confirm Cancellation",
                    message:
                      "Are you sure you want to cancel this transfer?",
                    onClickYes: () => {
                      setIsPageLoading([true, "Cancelling..."]);
                      dispatch(cancelInitiatedTransfer(specificTransaction.id))
                        .then((res) => {
                          if (res.errorMessage) {
                            NotificationManager.error(res.errorMessage, "Error!")
                          } else {
                            NotificationManager.success(res.message, "Success!")
                            handleGetSpecificWalletTxn(specificTransaction.id)
                            handleGetWalletTransactions(initialWalletData)
                            dispatch(getUser())
                          }
                        })
                    },
                    onClickNo: () => { },
                  });
                }}
              >Cancel Transfer</button>

            </div> : null} */}
        </div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Wallet Tx. ID</b>
        <div className="col-6">{specificTransaction.id ? "#" + specificTransaction.id : "-"}</div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Transfer ID</b>
        <div className="col-6">{specificTransaction.transfer_id ? "#" + specificTransaction.transfer_id : "-"}</div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Reference ID</b>
        <div className="col-6">
          {specificTransaction.transfer_reference ? "#" + specificTransaction.transfer_reference : "-"}
        </div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Payment Txn ID</b>
        <div className="col-6">{specificTransaction.transaction_id ? "#" + specificTransaction.transaction_id : "-"}</div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Date</b>
        <div className="col-6">
          {moment(specificTransaction.created_at).format("DD MMM YYYY")}
        </div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Amount</b>
        <div className="col-6">
          <FormattedMoney value={specificTransaction.amount} currency={specificTransaction.amount_currency} />
        </div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Transfer Fee</b>
        <div className="col-6">
          <FormattedMoney value={specificTransaction.fee} currency={specificTransaction.amount_currency} />
        </div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Destination/Source</b>
        <div className="col-6">{convertSnakeToStandardCasing(specificTransaction.source)}</div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Type</b>
        <div className="col-6">{convertSnakeToStandardCasing(specificTransaction.transaction_type)}</div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Recipient/Sender</b>
        <div className="col-6">
          {specificTransaction.recipient ? specificTransaction.recipient : "-"}</div>
      </div>

      <div className="row padding-top-12 padding-bottom-12">
        <b className="col-6">Note</b>
        <div className="col-6">{specificTransaction.note ? specificTransaction.note : "-"}</div>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(TransactionDetails);
