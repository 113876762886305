import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const selfHelp = ({ user, setIsPageLoading, dispatch }) => {
  return (
    <div>
      <h2>Help</h2>
    </div>
  );
};

export default selfHelp;
