import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {  useRouteMatch } from "react-router-dom";
import { getSpecificOpenPublicMoneyPool } from "../../../../../store/actions/moneyPool";
import Truncate from "react-truncate";
import { NotificationManager } from "react-notifications";
import MoneyPoolInformation from "./MoneyPoolInformation";

const MoneyPoolPublicView = ({
    dispatch,
    windowWidth,
    setIsPageLoading
}) => {
    const { params } = useRouteMatch()
    const [slug] = useState(params.slug)
    const [pool, setPool] = useState(null)

    const handleGetSpecificOpenPublicMoneyPool = async (slug) => {
        setIsPageLoading([true, "Loading..."]);

        const res = await dispatch(getSpecificOpenPublicMoneyPool(slug))
        if (res) {
            setIsPageLoading([false]);
            if (res.error) {
                NotificationManager.error(res.error.message, "Error!")
            } else {
                if (res.success == true) {
                    const pool = res.data
                    setPool(pool)
                }
            }
        }
    };

    useEffect((
    ) => {
        if (slug) {
            handleGetSpecificOpenPublicMoneyPool(slug)
        }
    }, []);

    return (
        <div>
            <div className="setup-modal max-width-1200">
                <div className="padding-6 padding-left-md-40 padding-right-md-40 padding-bottom-md-20">
                    <h1 className="padding-top-8 padding-bottom-8 padding-left-0 padding-right-0 padding-right-md-20 dark-blue font-size-21 font-size-sm-24">
                        {pool && pool.pool_description ?
                            <Truncate lines={2}>
                                {pool.pool_description.title}
                            </Truncate> : ""}
                    </h1>
                    <div className="horizontal-line" /> <br />
                    {
                        pool &&
                        <MoneyPoolInformation
                            setIsPageLoading={setIsPageLoading}
                            windowWidth={windowWidth}
                            pool={pool}
                            setPool={setPool}
                            isForPublic={true}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    return {
        user: state.auth.user,
    };
};

export default connect(mapStateToProps)(MoneyPoolPublicView);
