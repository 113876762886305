import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { useLocation, NavLink, useRouteMatch } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Truncate from "react-truncate";
import DisplayCollectionDates from "../../setup/fragments/DisplayCollectionDates";
import {
  getSpecificMoneyPool
} from "../../../../../store/actions/moneyPool";
import RequestDateSwap from "../../setup/fragments/RequestDateSwap";
import MoneyPoolOverview from "./MoneyPoolOverview"
import Settings from "./Settings"
import PoolTransactions from "./PoolTransactions"
import NoOfUnseenMessages from "../../../../utilityComponents/NoOfUnseenMessages";
import ChatBox from "../../../../utilityComponents/ChatBox";

const MoneyPoolView = ({
  history,
  user,
  dispatch,
  setIsPageLoading,
  handleGetPendingPoolInvitations,
  windowWidth,
  mobileBreakpoint,
  tabletBreakpoint,
  desktopBreakpoint,
}) => {
  const { search } = useLocation()
  const { params } = useRouteMatch()
  const [slug] = useState(params.slug);
  const [pool, setPool] = useState({});
  const [isPoolPaymentVisible, setIsPoolPaymentVisible] = useState(false);
  const [
    isCollectionDatesModalVisible,
    setIsCollectionDatesModalVisible,
  ] = useState(false);
  const [isSwapDateModalVisible, setIsSwapDateModalVisible] = useState(false);
  const [isLinkBanKAccountVisible, setIsLinkBanKAccountVisible] = useState(
    false
  );
  // const [visibleComponent, setVisibleComponent] = useState("overview");
  const [visibleComponent, setVisibleComponent] = useState(new URLSearchParams(search).get('visibleComponent'));
  // const [canGoBack] = useState(JSON.parse(new URLSearchParams(search).get('canGoBack')));

  if (!visibleComponent) {
    history.push(`/dashboard/money-pool/view/${slug}?visibleComponent=overview`)
  }

  const handleSwitchComponents = (component) => {
    history.push(`/dashboard/money-pool/view/${pool.pool_description.slug}?visibleComponent=${component}`)
  }

  const handleGetSpecificMoneyPool = (slug) => {
    setIsPageLoading([true, "Loading..."]);

    dispatch(getSpecificMoneyPool(slug))
      .then((response) => {
        setIsPageLoading([false]);

        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            const pool = response.data
            setPool(pool);
            // if (action == "public-view") {
            //   // Check if the user is alread a member of this pool
            //   if (pool.is_pool_member == true) {
            //     history.push(`/dashboard/money-pool/view/${pool.pool_description.id}`)
            //   }
            // }
          }
        }
      })
  };

  useEffect(() => {
    if (slug) {
      handleGetSpecificMoneyPool(slug)
    }
  }, [])

  return (
    <div className="relative padding-bottom-20 border-color-light-grey border-radius-8">
      <div className="d-inline-block">
        <NavLink to="/dashboard/money-pool"
          className="d-flex align-center text-decoration-none dark-blue hover-dodgerblue align-items-center cursor-pointer"
        >
          <i className="fas fa-long-arrow-alt-left font-size-24 font-size-sm-30 padding-right-10"></i>
          <span className="d-block-hidden-sm font-size-16 font-size-sm-18">
            Dashboard
          </span>
        </NavLink>
      </div>

      <h1 className="padding-top-8 padding-bottom-8 padding-left-0 padding-right-0 padding-left-md-20 padding-right-md-20 dark-blue font-size-21 font-size-sm-24">
        {pool.pool_description ?
          <Truncate lines={2}>
            {pool.pool_description.title}
          </Truncate> : ""}
      </h1>

      <div className="horizontal-line" />

      {pool.pool_description ? (
        <div>
          {(pool.pool_description.are_you_opted_in || pool.is_pool_manager) &&
            <div className="d-flex align-items-center padding-top-10 padding-top-md-16 padding-bottom-10 padding-bottom-md-16 padding-left-0 padding-left-md-16 padding-right-0 padding-right-md-16 font-size-14 font-size-sm-18">
              <div className={`btn font-size-12 font-size-sm-14 line-height-14 margin-right-2 margin-right-sm-4 margin-top-4 margin-bottom-4 padding-6 padding-left-sm-12 padding-right-sm-12 border-color-dark-grey
              ${visibleComponent == "overview" ? "bg-orange border-color-orange white" : "bg-transparent dark-grey"}`}
                onClick={() => {
                  if (visibleComponent != "overview") {
                    handleSwitchComponents("overview")
                  }
                }} >
                Overview
              </div>&nbsp;

              <div className={`btn font-size-12 font-size-sm-14 line-height-14 margin-2 margin-sm-4 margin-top-4 margin-bottom-4 padding-6 padding-left-sm-12 padding-right-sm-12 border-color-dark-grey
              ${visibleComponent == "transactions" ? "bg-orange border-color-orange white" : "bg-transparent dark-grey"}`}
                onClick={() => {
                  if (visibleComponent != "transactions") {
                    handleSwitchComponents("transactions")
                  }
                }}>
                Transactions
              </div>&nbsp;

              <div className={`btn font-size-12 font-size-sm-14 line-height-14 margin-2 margin-sm-4 margin-top-4 margin-bottom-4 padding-6 padding-left-sm-12 padding-right-sm-12 border-color-dark-grey
              ${visibleComponent == "settings" ? "bg-orange border-color-orange white" : "bg-transparent dark-grey"}`}
                onClick={() => {
                  if (visibleComponent != "settings") {
                    handleSwitchComponents("settings")
                  }
                }}>
                Settings
              </div>&nbsp;

              <div className={`d-flex align-items-center btn font-size-12 font-size-sm-14 line-height-14 margin-left-2 margin-left-sm-4 margin-top-4 margin-bottom-4 padding-6 padding-left-sm-12 padding-right-sm-12 border-color-dark-grey
              ${visibleComponent == "chat" ? "bg-orange border-color-orange white" : "bg-transparent dark-grey"}`}
                onClick={() => {
                  if (visibleComponent != "chat") {
                    handleSwitchComponents("chat")
                  }
                }}>
                <span>
                  Chat
                </span>
                <div>
                  <NoOfUnseenMessages
                    service_id={pool.pool_description.id}
                    service_type={"pool"}
                  />
                </div>
              </div>
            </div>
          }
          <div className="horizontal-line" />

          <div className="relative padding-0 padding-left-md-20 padding-right-md-20">
            {pool.is_pool_manager && pool.is_pool_editable ?
              <i className="fa fa-edit absolute top-10 right-10 red font-size-18 cursor-pointer" onClick={() => {
                history.push(`/dashboard/money-pool/edit/${pool.pool_description.slug}`)
              }} /> : null}
            {
              visibleComponent == "overview" ?
                <MoneyPoolOverview
                  history={history}
                  pool={pool}
                  setPool={setPool}
                  setIsPageLoading={setIsPageLoading}
                  handleGetPendingPoolInvitations={handleGetPendingPoolInvitations}
                  windowWidth={windowWidth}
                  mobileBreakpoint={mobileBreakpoint}
                  tabletBreakpoint={tabletBreakpoint}
                  desktopBreakpoint={desktopBreakpoint}
                  setIsPoolPaymentVisible={setIsPoolPaymentVisible}
                  setIsSwapDateModalVisible={setIsSwapDateModalVisible}
                  setIsLinkBanKAccountVisible={setIsLinkBanKAccountVisible}
                  setIsCollectionDatesModalVisible={setIsCollectionDatesModalVisible}
                /> :
                null
            }

            {
              visibleComponent == "settings" ?
                <Settings
                  history={history}
                  pool={pool}
                  setPool={setPool}
                  setIsPageLoading={setIsPageLoading}
                  windowWidth={windowWidth}
                  mobileBreakpoint={mobileBreakpoint}
                  desktopBreakpoint={desktopBreakpoint}
                /> :
                null
            }

            {
              visibleComponent == "transactions" ?
                <PoolTransactions pool={pool} /> :
                null
            }

            {visibleComponent == "chat" ?
              <ChatBox
                service_type="pool"
                service_id={pool.pool_description.id}
              />
              : null}
          </div>

          <Modal
            ariaHideApp={false}
            isOpen={isCollectionDatesModalVisible}
            onRequestClose={() => setIsCollectionDatesModalVisible(false)}
            className="modal max-width-800 padding-0"
            overlayClassName="modal-backdrop"
          >
            <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
              <h1 className="font-size-20 font-size-md-22 dark-blue">
                Payout Dates
              </h1>
              <i
                onClick={() => setIsCollectionDatesModalVisible(false)}
                className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
              ></i>
            </div>
            <div className="horizontal-line margin-0" />
            <div className=" padding-10">
              <div
                style={{ maxHeight: "550px" }}
                className="overflow-scroll-y padding-0 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
              >
                <DisplayCollectionDates
                  poolId={pool.pool_description.id}
                  isDateChangeable={pool.is_date_changeable}
                  collectionDates={pool.collection_dates}
                  setPool={setPool}
                  setIsPageLoading={setIsPageLoading}
                />
              </div>
            </div>
          </Modal>

          <Modal
            ariaHideApp={false}
            isOpen={isSwapDateModalVisible}
            onRequestClose={() => setIsSwapDateModalVisible(false)}
            className="modal max-width-800 padding-0"
            overlayClassName="modal-backdrop"
          >
            <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
              <h1 className="font-size-20 font-size-md-22 dark-blue">
                {/* Payout Dates */}
              </h1>
              <i
                onClick={() => setIsSwapDateModalVisible(false)}
                className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
              ></i>
            </div>
            <div className="horizontal-line margin-0" />
            <div className=" padding-10">
              <div
                style={{ maxHeight: "550px" }}
                className="overflow-scroll-y padding-0 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
              >
                <RequestDateSwap
                  poolId={pool.pool_description.id}
                  collectionDates={pool.collection_dates}
                  setPool={setPool}
                  setIsPageLoading={setIsPageLoading}
                  handleClose={() => setIsSwapDateModalVisible(false)}
                />
              </div>
            </div>
          </Modal>
          <Modal
            ariaHideApp={false}
            isOpen={isCollectionDatesModalVisible}
            onRequestClose={() => setIsCollectionDatesModalVisible(false)}
            className="modal max-width-800 padding-0"
            overlayClassName="modal-backdrop"
          >
            <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
              <h1 className="font-size-20 font-size-md-22 dark-blue">
                Payout Dates
              </h1>
              <i
                onClick={() => setIsCollectionDatesModalVisible(false)}
                className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
              ></i>
            </div>
            <div className="horizontal-line margin-0" />
            <div className=" padding-10">
              <div
                style={{ maxHeight: "550px" }}
                className="overflow-scroll-y padding-0 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
              >
                <DisplayCollectionDates
                  poolId={pool.pool_description.id}
                  areYouOptedIn={pool.pool_description.are_you_opted_in}
                  isDateChangeable={pool.is_date_changeable}
                  collectionDates={pool.collection_dates}
                  setPool={setPool}
                  setIsPageLoading={setIsPageLoading}
                />
              </div>
            </div>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MoneyPoolView);
