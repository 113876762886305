import React, { useState, useEffect, useCallback, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Truncate from "react-truncate";
import CurrentWindowWidth from "../CurrentWindowWidth";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ImageViewer = ({ document, maxHeightImage, handleClose }) => {
  //   let windowWidth = CurrentWindowWidth();

  const [scale, setScale] = useState(1);
  const [rotateValue, setRotateValue] = useState(0);
  const [rotateState, setRotateState] = useState(false);

  const imageRef = useCallback(
    (node) => {
      if (node !== null) {
        node.style.transform = `rotate(${rotateValue}deg)`;

        if (rotateState) {
          node.style.maxWidth = maxHeightImage + "px";
          node.style.maxHeight = "100" * scale + "%";
        } else {
          node.style.maxWidth = "100" * scale + "%";
          node.style.maxHeight = maxHeightImage + "px";
        }
      }
    },
    [rotateValue, scale]
  );

  return (
    <div className="">
      <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
        <h1 className="stretch-full font-size-18 font-size-md-22 font-size-md-22 white">
          <center>
            <Truncate lines={1}>{document.description}</Truncate>
          </center>
        </h1>
        <i
          onClick={() => handleClose()}
          className="fa fa-close font-size-20 font-size-sm-22 white cursor-pointer hover-red"
        ></i>
      </div>

      <div className="horizontal-line" />

      <div
        className="d-flex relative align-items-center justify-content-center"
        style={{ height: "100%" }}
      >
        <div className="d-flex top-10 left-25-pct right-25-pct absolute justify-content-between white">
          <i
            className="fa fa-rotate-left font-size-24 cursor-pointer"
            onClick={() => {
              setRotateValue((prevState) => prevState - 90);
              setRotateState((state) => !state);
            }}
          ></i>
          <i
            className="fa fa-rotate-right font-size-24 cursor-pointer"
            onClick={() => {
              setRotateValue((prevState) => prevState + 90);
              setRotateState((state) => !state);
            }}
          ></i>

          <span
            className="fa fa-search-minus font-size-24 cursor-pointer"
            onClick={() => {
              if (scale > 0.5) {
                setScale(scale - 0.1);
              }
            }}
          ></span>
          <span
            className="fa fa-search-plus font-size-24 cursor-pointer"
            onClick={() => {
              if (scale < 1) {
                setScale(scale + 0.1);
              }
            }}
          ></span>
        </div>
        <img ref={imageRef} src={document.file_url} />
      </div>
    </div>
  );
};

export default ImageViewer;
