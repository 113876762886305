import React from "react";
import { connect } from "react-redux";

const PoolSetupFormHeader = ({ activeSlide, slideNames }) => {
  
  const handGetClassName = (slideName1, slideName2) => {
    if (activeSlide == slideName1 || activeSlide == slideName2) {
      return "active";
    } else if (
      slideNames.indexOf(slideName1) < slideNames.indexOf(activeSlide)
    ) {
      return "done";
    } else {
      return "not-active";
    }
  };

  return (
    <div className="setup-modal-progress">
      <div
        className={`first-page ${handGetClassName(
          "editPoolDescription"
        )} ${handGetClassName("createPoolDescription")}`}
      >
        <span>1</span>
        <label className="d-block-hidden-sm">Details</label>
      </div>
      <div className={handGetClassName("poolCollectionDates")}>
        <span>2</span>
        <label className="d-block-hidden-sm">Collection dates</label>
      </div>
      <div className={handGetClassName("poolSummary", "thanks")}>
        <span>3</span>
        <label className="d-block-hidden-sm">Finish</label>
      </div>
    </div>
  );
};

export default connect()(PoolSetupFormHeader);
