import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

const GetReferralLink = ({ user }) => {
  const [link, setLink] = useState("");
  const [isLinkCopiedVisible, setIsLinkCopiedVisible] = useState(false);

  const handleCopyLink = () => {
    setIsLinkCopiedVisible(true);

    setTimeout(() => {
      setIsLinkCopiedVisible(false);
    }, 2000);
  };

  useEffect(() => {
    setLink(`${process.env.REACT_APP_FRONTEND_BASE_URL}/register?referrer=${user.referral_code.referrer_code}`);
  }, []);

  return (
    <div>
      {isLinkCopiedVisible ? <p className="normal-green">Copied!</p> : null}

      <CopyToClipboard
        text={link}
        onCopy={() => {
          handleCopyLink();
        }}
      >
        <input
          className="form-control font-size-13 font-size-sm-16 padding-8 padding-sm-12"
          type="text"
          value={link}
          // disabled={true}
        />
      </CopyToClipboard>
      <center>
        <div className="btn margin-top-10 margin-top-sm-30 bg-dark-blue white max-width-140 padding-8 font-size-13 font-size-sm-15 border-radius-8">
          <CopyToClipboard
            text={link}
            onCopy={() => {
              handleCopyLink();
            }}
          >
            <span>Copy Link</span>
          </CopyToClipboard>
        </div>
      </center>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(GetReferralLink);
