import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NotificationManager } from 'react-notifications';
import { sendPhoneOtp, verifyPhoneOtp } from "../../store/actions/auth"

const VerifyPhoneNumber = ({
  dispatch,
  setIsPageLoading,
  handleClose,
  handleCloseProfileSettings
}) => {

  return (
    <div>
      <Formik
        initialValues={{
          // phone_number: user.phone_number,
          otp: "",
        }}
        validationSchema={Yup.object({
          otp: Yup.string().required("Please enter the code sent to your email or phone"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(verifyPhoneOtp(values))
            .then(res => {
              setSubmitting(false)
              setIsPageLoading([false]);
              if (res.error) {
                NotificationManager.error(res.error.message, 'Error!')
              } else {
                NotificationManager.success(res.message, 'Success!')
                handleCloseProfileSettings()
                handleClose()
              }
            })
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
        }) => (
          <Form>
            <div>
              <div className="padding-top-12 padding-bottom-12">
                <label className="form-label font-size-15">
                  Verification Code
                </label>
                <Field
                  className="form-control padding-top-10 padding-bottom-10 border-color-dark-blue"
                  placeholder="Enter verification code"
                  type="text"
                  name="otp"
                />
                <ErrorMessage
                  className="error-message"
                  name="otp"
                  component="div"
                />
              </div>

              <div>
                <button 
                  className="btn margin-right-20 margin-top-12 margin-bottom-12 padding-10 bg-blue white max-width-130 font-size-15 border-radius-8"
                  type="button"
                  onClick={() => {
                    setIsPageLoading([true, "Loading..."]);

                    dispatch(sendPhoneOtp())
                      .then(res => {
                        setIsPageLoading([false]);
                        if (res.error) {
                          NotificationManager.error(res.error.message, 'Error!')
                        } else {
                          NotificationManager.success(res.message, 'Success!')

                        }
                      })
                  }}>
                  Resend Code
                </button>
                <button
                  className="btn margin-top-12 margin-bottom-12 padding-10 bg-dark-blue white max-width-130 font-size-15 border-radius-8"
                  type="button"
                  onClick={handleSubmit}
                >
                  Verify
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(VerifyPhoneNumber);
