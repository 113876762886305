import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Truncate from "react-truncate";
import heroPicture from "./images/hero_picture.png";
import appStore from "./images/app_store.svg";
import googlePlay from "./images/google_play.svg";
import ServicesBlock from "./ServicesBlock";
import CurrentWindowWidth from "./CurrentWindowWidth";
import commitmentIcon from "./images/commitment_icon_darkblue.svg";
import explainerVideoImg from "./images/explainer-video_img.png";
import integrityIcon from "./images/integrity_icon_darkblue.svg";
import passionIcon from "./images/passion_icon_darkblue.svg";
import { useSpring, animated } from 'react-spring';
import { getLatestTestimonials } from "../store/actions/testimonial";
import { NotificationManager } from "react-notifications";
import { subscribeNewsletter } from "../store/actions/auth";

let isTruncatedItem = [];
let readMoreOrLess = [];

const Home = ({ user, history, dispatch }) => {
  const [isExplainerVideoOpen, setIsExplainerVideoOpen] = useState(false)
  const [reloadSeeMoreBtn, setReloadSeeMoreBtn] = useState(false)
  const [isTruncated, setIsTruncated] = useState([])
  // const [truncatedItem, setCheckTruncated] = useState(true)
  const [testimonies, setTestimonies] = useState([])
  const [animationPlayState, setAnimationPlayState] = useState("running")
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);
  const [isNewsletterPopupBlocked, setIsNewsletterPopupBlocked] = useState(false);

  const handleBlockNewsletterPopup = (value) => {
    localStorage.setItem("blockNewsletterPopup", value)
  }


  let windowWidth = CurrentWindowWidth();

  const fadeStyles = useSpring({
    from: { opacity: 0 },
    config: { duration: 2000 },
    loop: {
      opacity: 1,
    },
  })

  const slideToLeftStyles = useSpring({
    from: { x: 200, opacity: 0 },
    config: { duration: 1500 },
    loop: {
      x: 0, opacity: 1,
    },
  })

  const slideToRightStyles = useSpring({
    from: { x: -200, opacity: 0 },
    config: { duration: 1500 },
    loop: {
      x: 0, opacity: 1,
    },
  })

  const handleReadMore = (index) => {
    const truncate = isTruncated;
    truncate[index] = !isTruncated[index];
    setIsTruncated([...truncate]);
  }

  const handleTruncate = (truncated, index, length) => {
    isTruncatedItem[index] = truncated;

    if (index == length - 1) {
      setReloadSeeMoreBtn(true);
    }
  }

  useEffect(() => {
    const emptyArray1 = [];
    setIsTruncated([]);
    if (testimonies.length) {
      for (let i = 0; i < testimonies.length; i++) {
        emptyArray1.push(true);
        setIsTruncated([...emptyArray1]);
      }
    }
    readMoreOrLess = []
    setReloadSeeMoreBtn(false);
  }, [testimonies, windowWidth])


  useEffect(() => {
    document.title = "Home Page"

    dispatch(getLatestTestimonials())
      .then(res => {
        if (res.success) {
          setTestimonies(res.data)
        }
      })


    let blockNewsletterPopup = localStorage.getItem("blockNewsletterPopup")

    if (user && user.newsletter_subscribed == true && (!blockNewsletterPopup || blockNewsletterPopup == "false")) {
      handleBlockNewsletterPopup(true)
      blockNewsletterPopup = true
    } else {

      if (blockNewsletterPopup) {
        if (blockNewsletterPopup == "true" || blockNewsletterPopup == "false") {
          blockNewsletterPopup = JSON.parse(blockNewsletterPopup)
          setIsNewsletterPopupBlocked(blockNewsletterPopup)
        } else {
          handleBlockNewsletterPopup(false)
          setIsNewsletterPopupBlocked(false)
          blockNewsletterPopup = false
        }
      }
    }

    if (!blockNewsletterPopup || blockNewsletterPopup == false) {
      setTimeout(() => {
        setIsNewsletterModalOpen(true)
      }, 5000)
    }
  }, [])

  return (
    <div className="overflow-auto padding-left-12 padding-left-md-30 padding-left-lg-80 padding-right-12 padding-right-md-30 padding-right-lg-80">
      <div className="home-background-image padding-top-80 padding-top-md-140 padding-bottom-50 padding-bottom-md-100">
        <div className="row padding-bottom-20 padding-bottom-md-30">
          <animated.div style={slideToRightStyles} className="col-6 stretch-full-sm">
            <h1 className="font-size-28 font-size-sm-30 font-size-md-36 line-height-36 line-height-sm-40 line-height-md-46 dark-blue">
              Making <span className="orange">Contributions</span> Has Never Been Easier, More Fun And Less
              Stress
            </h1>
            <p className="font-size-17 font-size-md-20 line-height-22 line-height-md-24 margin-top-12 margin-top-md-30 margin-bottom-14 margin-bottom-md-36">
              Harness our powerful tools for raising funds for a cause,
              cyclic money pooling and contributing towards the upkeep of
              close and loved ones.
            </p>
            <div className="d-flex justify-content-center d-flex-display-sm margin-bottom-18">
              <img className="max-width-300" src={heroPicture} />
            </div>
            <div className="margin-bottom-8 margin-bottom-md-0">
              <NavLink to="/register">
                <button className="btn max-width-150 stretch-full-sm font-size-16 padding-16 border-radius-12 bg-dark-blue white">
                  Get Started
                </button>
              </NavLink>
            </div>
            <div className="d-flex d-flex-hidden-sm">
              <a target='_blank' href={"https://apps.apple.com/app/pouchmoney/id1602625150"}>
                <img
                  className="cursor-pointer fixed-width-180 margin-top-10 margin-top-md-40 margin-right-4 margin-right-sm-20"
                  src={appStore}
                />
              </a>
              <a target='_blank' href={"https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.pouchmoney"}>
                <img
                  className="cursor-pointer fixed-width-180 margin-top-10 margin-top-md-40 margin-left-4 margin-left-sm-20"
                  src={googlePlay}
                />
              </a>
            </div>
          </animated.div>
          <animated.div style={slideToLeftStyles} className="col-6 d-block-hidden-sm">
            <img className="stretch-full" src={heroPicture} />
          </animated.div>
        </div>

        {/* <div className="bg-lighter-grey">
          <div className="d-flex stretch-full justify-content-center padding-top-30 padding-top-10 padding-top-md-30 padding-bottom-10 padding-bottom-md-30">
            <div className="d-flex align-items-center max-width-550">
              <div className="max-width-110 margin-right-6 margin-right-md-22">
                <img src={shieldIcon} />
              </div>
              <div>
                <h2 className="font-size-24 font-size-md-30 line-height-29 dark-blue">
                  Your Security is our Priority
                </h2>
                <p className="font-size-14 font-size-md-16 line-height-22 padding-top-10">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="padding-top-20 padding-bottom-20 padding-top-md-40 padding-bottom-md-40">
          <div className="d-inline-block padding-top-10 padding-bottom-30">
            <h1 className="font-size-26 font-size-md-36 border-bottom-color-dark-blue dark-blue border-bottom-8">
              OUR&nbsp;SERVICES
            </h1>
          </div>
          <ServicesBlock history={history} />
        </div>

        <div className="padding-top-20 padding-bottom-20 padding-top-md-40 padding-bottom-md-40">
          <div className="d-inline-block padding-bottom-20 padding-bottom-sm-40">
            <h1 className="font-size-26 font-size-md-36 border-bottom-color-dark-blue dark-blue border-bottom-8">
              ABOUT US
            </h1>
          </div>

          <div className="d-flex justify-content-center padding-top-sm-10">
            <div className="margin-top-6 margin-bottom-20">
              <img onClick={() => setIsExplainerVideoOpen(true)}
                className="d-block border-radius-14 max-width-850"
                src={explainerVideoImg}
              />
            </div>
          </div>
        </div>

        {/* <div className="padding-top-20 padding-bottom-20 padding-top-md-40 padding-bottom-md-40">
          <div className="horizontal-line margin-bottom-30">
            <span>
              <h1 className="font-size-23 font-size-md-30 margin-left-20 margin-right-20">
                About&nbsp;Us
              </h1>
            </span>
          </div>
          <div className="row" >
            <div className="col-sm-6 row relative padding-top-14 padding-bottom-14 padding-bottom-md-60 padding-left-14 padding-left-md-40 padding-right-14 padding-right-md-40">
              <div className="d-flex absolute top-35-pct">
                <div className="col-4"></div>
                <div className="col-2" onClick={() => setIsExplainerVideoOpen(true)}>
                  <img
                    className="padding-left-6 padding-left-sm-0 padding-right-6 padding-right-sm-0"
                    src={playButtonIcon}
                  />
                </div>
              </div>
              <div className="col-6"></div>
              <img src={homeVidImg} className="border-radius-6" />
            </div>
            <div className="col-sm-6">
              <div style={{ minHeight: "300px" }} className="align-items-center bg-orange border-radius-6 margin-top-16 margin-right-10 margin-right-sm-30 padding-top-14 padding-top-md-50 padding-bottom-14 padding-bottom-md-60 padding-left-14 padding-left-md-40 padding-right-14 padding-right-md-40">
                <p className="white font-size-18">
                  Pouch Money is an online contributing platform that helps individuals, families, friends, colleagues, neighbours, etc,
                  to organize contributions for a common or diverse cause. We span across several countries and currencies across the globe.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="padding-top-30 padding-bottom-20 padding-top-md-40 padding-bottom-md-40">
          <div className="d-inline-block padding-bottom-20 padding-bottom-sm-40">
            <h1 className="font-size-26 font-size-md-36 border-bottom-color-dark-blue dark-blue border-bottom-8">
              OUR CORE VALUES
            </h1>
          </div>

          <div className="d-flex flex-wrap justify-content-evenly align-items-start">
            <div className="max-width-350 margin-top-10 margin-bottom-10 margin-md-20 padding-bottom-20 padding-left-md-30 padding-right-md-30 border-radius-14 box-shadow bg-white">
              <div className="margin-top-20 margin-top-md-50 text-center">
                <img className="max-width-100 fixed-height-100" src={commitmentIcon} />
              </div>
              <h3 className="font-size-23 font-size-sm-24 margin-top-10 margin-top-sm-20 margin-bottom-10 margin-bottom-sm-20 text-center orange">
                Commitment
              </h3>
              <p className="font-size-17 font-size-sm-18 padding-left-10 padding-right-10 margin-bottom-10 margin-bottom-md-40 text-center">
                We pride ourselves in the dedication to our customers by ensuring we are with them in every step of the way towards achieving their desired goals.
              </p>
            </div>

            <div className="max-width-350 margin-top-lg-120 margin-top-10 margin-bottom-10 margin-md-20 padding-bottom-20 padding-left-md-30 padding-right-md-30 border-radius-14 box-shadow bg-white">
              <div className="margin-top-20 margin-top-md-50 text-center">
                <img className="max-width-130 fixed-height-100" src={integrityIcon} />
              </div>
              <h3 className="font-size-23 font-size-sm-24 margin-top-10 margin-top-sm-20 margin-bottom-10 margin-bottom-sm-20 text-center orange">
                Integrity
              </h3>
              <p className="font-size-17 font-s55ize-sm-18 padding-left-10 padding-right-10 margin-bottom-10 margin-bottom-md-40 text-center">
                We offer transparency through out our services and among our customers within their contributing circles, by creating a trustworthy platform that enhances security, openness and fairness.
              </p>
            </div>

            <div className="max-width-350 margin-top-10 margin-bottom-10 margin-md-20 padding-bottom-20 padding-left-md-30 padding-right-md-30 border-radius-14 box-shadow bg-white">
              <div className="margin-top-20 margin-top-md-50 text-center">
                <img className="max-width-120 fixed-height-100" src={passionIcon} />
              </div>
              <h3 className="font-size-23 font-size-sm-24 margin-top-10 margin-top-sm-20 margin-bottom-10 margin-bottom-sm-20 text-center orange">
                Passion
              </h3>
              <p className="font-size-17 font-size-sm-18 padding-left-10 padding-right-10 margin-bottom-10 margin-bottom-md-40 text-center">
                We do our business with all our hearts knowing fully well that our true happiness only comes from the total satisfaction of our customers.
              </p>
            </div>
          </div>
        </div>

        {testimonies.length > 0 &&
          <div className="padding-top-30 padding-bottom-20 padding-top-md-40 padding-bottom-md-40">
            <div className="d-inline-block padding-bottom-20 padding-bottom-sm-40">
              <h1 className="font-size-26 font-size-md-36 border-bottom-color-dark-blue  dark-blue  border-bottom-8">
                TESTIMONIES
              </h1>
            </div>

            <div
              onMouseEnter={() => {
                setAnimationPlayState("paused")
              }}
              onMouseLeave={() => {
                setAnimationPlayState("running")
              }}
              className="bg-grad-lighterblue-lighterorange border-radius-24 height-100 padding-left-10 padding-right-10 padding-top-40 padding-bottom-40">
              <div
                className="slider-up"
              >
                <div
                  className="mover-up"
                  style={{ animationPlayState }}
                >
                  {testimonies.map((t, index) => {
                    return (
                      <div className="d-flex justify-content-center padding-top-30 padding-bottom-30">
                        <div key={index} className="d-flex align-items-center max-width-1300">
                          {index % 2 == 0 &&
                            <div className="padding-right-14 padding-right-md-30">
                              <img
                                width={windowWidth * 0.2 > 120 ? 120 : windowWidth * 0.2 + "px"}
                                height={windowWidth * 0.2 > 120 ? 120 : windowWidth * 0.2 + "px"}
                                className="d-block rounded-circle"
                                src={t.user.profile_picture ? t.user.profile_picture : ""}
                              />
                            </div>}

                          <div className={`row stretch-full ${index % 2 == 0 ? "padding-right-0 padding-right-md-200 justify-content-start" : "padding-left-0 padding-left-md-200 justify-content-end"}`}>
                            <div className="max-width-800 bg-white border-radius-14 padding-14 padding-md-20" >
                              <b className="font-size-22 font-size-md-24 dark-blue">
                                {t.user.first_name} {t.user.last_name}
                              </b>
                              <div className="font-size-15 font-size-sm-16 line-height-22">
                                <Truncate lines={isTruncated[index] ? 3 : null}
                                  onTruncate={(truncated) => handleTruncate(truncated, index, testimonies.length)}
                                >
                                  {t.comment}
                                </Truncate>

                                {isTruncatedItem[index] || readMoreOrLess[index] ?
                                  <div className="d-flex justify-content-end orange font-size-14 padding-top-10">
                                    <div
                                      onClick={() => handleReadMore(index)} className="cursor-pointer ">
                                      {isTruncated[index] ?
                                        <span
                                          onClick={() => readMoreOrLess[index] = true}
                                          className="hover-dodgerblue"
                                        >
                                          Read more
                                        </span>
                                        :
                                        <span
                                          onClick={() => setReloadSeeMoreBtn(false)}
                                          className="hover-dodgerblue"
                                        >
                                          See less
                                        </span>}
                                    </div>
                                  </div> : null}
                              </div>
                              <div className="padding-top-8 grey font-size-13">
                                {t.user.city}, {t.user.state}, {t.user.country}
                              </div>
                            </div>
                          </div>

                          {index % 2 !== 0 &&
                            <div className="padding-left-14 padding-left-md-30">
                              <img
                                width={windowWidth * 0.2 > 120 ? 120 : windowWidth * 0.2 + "px"}
                                height={windowWidth * 0.2 > 120 ? 120 : windowWidth * 0.2 + "px"}
                                className="d-block rounded-circle"
                                src={t.user.profile_picture ? t.user.profile_picture : ""}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>}

        <div className="d-flex justify-content-center padding-top-20 padding-bottom-10 margin-top-10 margin-top-md-34">
          <div className="max-width-450">
            <div className="margin-bottom-20 margin-bottom-md-0">
              <NavLink to="/register">
                <button className="btn stretch-full font-size-18 font-size-md-28 padding-14 padding-md-24 border-radius-8 bg-dark-blue white">
                  Create an Account
                </button>
              </NavLink>
            </div>
            <div className="d-flex justify-content-between">
              <div className="margin-top-10 margin-top-md-40 margin-right-10">
                <a target='_blank' href={"https://apps.apple.com/app/pouchmoney/id1602625150"}>
                  <img className="max-width-190" src={appStore} />
                </a>
              </div>

              <div className="margin-top-10 margin-top-md-40 margin-left-10">
                <a target='_blank' href={"https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.pouchmoney"}>
                  <img className="max-width-190" src={googlePlay} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isExplainerVideoOpen}
        onRequestClose={() => setIsExplainerVideoOpen(false)}
        className="modal"
        overlayClassName="modal-backdrop"
      >
        <iframe
          width={0.9 * windowWidth}
          height={0.45 * windowWidth}
          src="https://www.youtube.com/embed/arzhLenlIXg?autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="allowFullScreen"
        ></iframe>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isNewsletterModalOpen}
        onRequestClose={() => setIsNewsletterModalOpen(false)}
        className="modal modal--large bg-grad-darkblue-orange padding-top-10"
        overlayClassName="modal-backdrop"
      >
        <div style={{ position: "relative" }}>
          <div
            className="border-radius-20 padding-top-14 padding-top-lg-30 padding-bottom-14 padding-bottom-lg-30 padding-left-10 padding-left-lg-60 padding-right-10 padding-right-lg-60"
          >
            <Formik
              initialValues={{
                email: user.email ? user.email : ""
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Email is required")
                  .email("Must be an email")
              })}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(subscribeNewsletter(values))
                  .then(res => {
                    if (res.error) {
                      NotificationManager.error(res.error.message, "Error!")
                    } else {
                      if (res.success) {
                        NotificationManager.success(res.message, "Success!")
                        handleBlockNewsletterPopup(true)
                        setIsNewsletterModalOpen(false)
                      }
                    }
                  })
              }}
            >
              {() => (
                <Form>
                  <div className="row">
                    <div>
                      <h3 className="font-size-20 font-size-sm-26 font-size-lg-30 line-height-35 padding-bottom-10 white">
                        Be the first to know.
                      </h3>
                      <div className='white padding-bottom-10' >
                        Subscribe to our newsletter to receive articles on our services.
                      </div>
                      <div
                        className="close-modal-icon"
                        onClick={() => setIsNewsletterModalOpen(false)}
                      >
                        <i className="fa fa-close"></i>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-sm-9 padding-right-0 padding-right-sm-20">
                        <Field
                          className="form-control padding-top-14 padding-bottom-14 font-size-16"
                          placeholder="Email address"
                          name="email"
                        />
                      </div>
                      <div className="col-sm-3 padding-top-20 padding-bottom-20 padding-top-md-0 padding-bottom-md-0">
                        <button
                          className="btn bg-dark-blue font-size-16 max-width-140 border-radius-10 padding-14"
                          type="submit"
                        >Subscribe</button>
                      </div>
                      <ErrorMessage
                        className="red"
                        name="email"
                        component="div"
                      />
                    </div>
                    <div className='d-flex white margin-top-10 margin-bottom-10'>
                      <input
                        type='checkbox'
                        value={!isNewsletterPopupBlocked}
                        onChange={(event) => {
                          setIsNewsletterPopupBlocked(event.target.value)
                          handleBlockNewsletterPopup(event.target.value)
                        }}
                      /> <label>Don't show this popup again</label>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </div >
  );
};


const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Home)

