import { getUser } from "./auth";
import { RESET_TRANSACTION_REF } from "../actionTypes/transaction";
import axiosInstance from "../../helpers/axiosInstance";

//Get all bank account details
export const getAllBankAccountDetails = () => {
  return async (dispatch, getState) => {
    return axiosInstance
      .get("/transactions/get-all-bank-account-details")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Save-bank account details
export const saveBankAccountDetails = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance
      .post("/transactions/save-bank-account-details", data)
      .then((response) => {
        dispatch(getUser());
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Delete-bank account details
export const deleteBankAccountDetails = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance
      .delete("/transactions/delete-bank-account-details", { data })
      .then((response) => {
        dispatch(getUser());
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Save card charge authorization
export const saveChargeAuthorization = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance
      .post("/transactions/save-charge-authorization", data)
      .then((response) => {
        dispatch(getUser());

        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Delete saved bank card
export const deleteSavedCard = (cardDetails) => {
  return async (dispatch, getState) => {
    return axiosInstance
      .delete("/transactions/delete-saved-bank-card", { data: cardDetails })
      .then((response) => {
        dispatch(getUser());

        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Verify bank account number
export const verifyBankAccount = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance
      .post("/transactions/verify-bank-account", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Get saved bank cards
export const getSavedBankCards = () => {
  return async (dispatch, getState) => {
    return axiosInstance
      .get("/transactions/get-saved-cards")
      .then((response) => {
        dispatch(getUser());

        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Create payment subscription
export const createPaymentSubscription = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance
      .post("/transactions/create-payment-subscription-plan", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Cancel payment subscription
export const cancelPaymentSubscription = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance
      .post("/transactions/cancel-payment-subscription-plan", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//To generate transaction reference id
export const generateTransactionRef = () => {
  return async (dispatch, getState) => {
    return axiosInstance.get("/transactions/generate-transaction-reference-id")
      .then((response) => {
        if (response.data.success == true) {
          const transaction_ref = response.data.data;

          dispatch({
            type: RESET_TRANSACTION_REF,
            transaction_ref,
          });
        }
        // return response.data
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Get the list of available banks in a country (Flutterwave)
export const getBankList = (country_code) => {
  return async () => {
    return axiosInstance
      .get(`/transactions/get-all-banks?country_code=${country_code}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//Get the list of branches in a bank (Flutterwave)
export const getBankBranchList = (bank_code) => {
  return async () => {
    return axiosInstance
      .get(`/transactions/get-bank-branch-list/${bank_code}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//To get a transfer fee
export const getTransferFee = (data) => {
  return async () => {
    return axiosInstance
      .get(
        `/transactions/get-transfer-fee?amount=${data.amount}&currency=${data.currency}&type=${data.type}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};

//To get payment history
export const getPaymentHistory = (queryParams) => {
  return async (dispatch, getState) => {
    return axiosInstance.get(`/transactions/get-payment-history`,
      { params: queryParams })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

//To get withdrawal history
export const getTransferHistory = (queryParams) => {
  return async (dispatch, getState) => {
    return axiosInstance.get(`/transactions/get-transfer-history`,
      { params: queryParams }
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

//To get transfer details
export const getTransferDetails = (transfer_id) => {
  return async (dispatch) => {
    return axiosInstance.get(`/transactions/get-transfer-details/${transfer_id}`
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

//To send transaction otp
export const sendTransactionOtp = () => {
  return async (dispatch) => {
    return axiosInstance.post(`/transactions/send-transaction-otp`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};