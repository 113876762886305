import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

const PreviewFileUpload = ({ file }) => {

  const [base64, setBase64] = useState("")

  useEffect(() => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (fileLoadedEvent) => {
        const base64File = fileLoadedEvent.target.result;

        setBase64(base64File);
      };
      fileReader.readAsDataURL(file);
    }
  }, [file])

  return (
    <div
      className="fixed-height-100">
      <img
        width="100%"
        height="100%"
        className="d-block stretch-full border-top-radius-6"
        src={base64}
      />
    </div>
  );
};

export default PreviewFileUpload;
