import React, { useState, useEffect } from "react";

const Pagination = ({
  page,
  no_of_items_per_page,
  total_count,
  handleGetQuery,
  ...props
}) => {
  const [noOfPages, setNoOfPages] = useState(null)
  const [pageNosArr, setPageNosArr] = useState([])

  useEffect(() => {
    const noOfPages = Math.ceil(total_count / no_of_items_per_page)
    setNoOfPages(noOfPages)

    //Generate an array of page numbers
    const numberedPages = [];
    for (let i = 0; i < noOfPages; i++) {
      numberedPages.push(i + 1)
    }

    setPageNosArr(numberedPages)

  }, [total_count]);

  return (
    <div className="pagination-container">
      {noOfPages > 1 ? (
        <div className="pagination-container__content">
          <div
            className="arrow-btn"
            onClick={() => {
              if (page > 1) {
                const newActivePage = page - 1;
                handleGetQuery({
                  ...props,
                  page: newActivePage,
                  no_of_items_per_page: no_of_items_per_page
                })
              }
            }}
          >
            <i className="far fa-arrow-alt-circle-left"></i>
            <span style={{ padding: "0px 10px" }}>PREV</span>
          </div>

          <div className="pagination-text">
            {pageNosArr.map((pg, index) => {
              return (
                <span key={index}>
                  {((pg < (page + 2) && pg > (page - 2)) || ([1, pageNosArr.length].includes(pg))) &&
                    <span
                      className={`${page == pg ? "active margin-left-4 margin-right-4" : "margin-left-4 margin-right-4"}`}
                      onClick={() => {
                        handleGetQuery({
                          ...props,
                          page: pg,
                          no_of_items_per_page: no_of_items_per_page
                        })
                      }}
                    >
                      {(pg == (pageNosArr.length)) && ((pg - page) > 3) && <span>&middot; &middot;</span>}
                      {pg}
                      {pg == 1 && page > 3 && <span>&middot; &middot;</span>}
                    </span>
                  }
                </span>
              );
            })}
          </div>
          <div
            className="arrow-btn"
            onClick={() => {
              if (page < noOfPages) {
                const newActivePage = page + 1;
                handleGetQuery({
                  ...props,
                  page: newActivePage,
                  no_of_items_per_page: no_of_items_per_page
                })
              }
            }}
          >
            <span style={{ padding: "0px 10px" }}>NEXT</span>
            <i className="far fa-arrow-alt-circle-right"></i>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Pagination;
