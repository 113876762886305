import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import FormattedMoney from "../../../utilityComponents/FormattedMoney"
import FilterSorter from "../../../utilityComponents/FilterSorter";
import { convertSnakeToStandardCasing } from "../../../../utilities";
import Pagination from "../../../utilityComponents/Pagination";
import { getMyGuaranteedMembers, getMyPoolGuarantors } from "../../../../store/actions/moneyPool";

const MyGuarantors = ({
  user,
  dispatch,
  setIsPageLoading,
}) => {
  const [initialParams, setInitialParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  });
  const [myGuaranteesObj, setMyGuaranteesObj] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [initialTotalCount, setInitialTotalCount] = useState(0);

  let currencies = user.supported_currencies.map(item => {
    return {
      label: item,
      value: item
    }
  })

  currencies = [{ label: "All", value: "" }, ...currencies]

  const handleGetMyGuarantees = async (params) => {
    const res = await dispatch(getMyPoolGuarantors(params))
    if (res.error) {
      NotificationManager(res.error.message, "Error!")
    }
    else {
      if (res.success == true) {
        setMyGuaranteesObj(res.data)
        setInitialParams(res.data.original_query_params)
        return res.data
      }
    }
  }

  useEffect(() => {
    handleGetMyGuarantees(initialParams)
      .then(data => {
        if (data) {
          setInitialTotalCount(data.total_count)
        }
      })

  }, [])

  return (
    <div>
      <div>
        {
          !!myGuaranteesObj && myGuaranteesObj.guarantee_summaries && myGuaranteesObj.guarantee_summaries.length > 0 ?
            <div>
              {
                myGuaranteesObj.guarantee_summaries.map((guarantee, index) => {
                  return (
                    <div key={index} className="row padding-bottom-10">
                      <div className="d-flex col-sm-4" >
                        <span className="col-4 padding-bottom-10">Total</span>
                        <b className="col-8 font-size-16 blue">
                          <FormattedMoney value={guarantee.total_amount} currency={guarantee.currency} /> </b>
                      </div>
                      <div className="d-flex col-sm-4" >
                        <span className="col-4 padding-bottom-10">Used</span>
                        <b className="col-8 font-size-16 red"> <FormattedMoney value={guarantee.used_amount} currency={guarantee.currency} /> </b>
                      </div>
                      <div className="d-flex col-sm-4" >
                        <span className="col-4 padding-bottom-10">Available</span>
                        <b className="col-8 font-size-16 green"> <FormattedMoney value={guarantee.allowance} currency={guarantee.currency} /> </b>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            : null
        }
      </div>
      {initialTotalCount > 0 &&
        <div>
          <FilterSorter
            sorterOptions={[
              { label: 'Created Date', value: 'created_at' },
            ]}
            initialSortBy={initialParams.sort_by}
            queryOrder={initialParams.query_order}
            limit={initialParams.no_of_items_per_page}
            page={initialParams.page}
            totalCount={initialParams.total_count}
            noOfItems={myGuaranteesObj.guarantees.length}
            filterOptions={[
              {
                status: [
                  { label: "All", value: '' },
                  { label: 'Pending', value: 'PENDING' },
                  { label: 'Approved', value: 'APPROVED' },
                  { label: 'Cancelled', value: 'CANCELLED' },
                ]
              },
            ]}
            onSelectQuery={(values) => {
              setIsSearching(true)
              const newQueryObj = {
                ...initialParams,
                ...values
              }
              handleGetMyGuarantees(newQueryObj)
            }}
          />
          <div className="horizontal-line margin-top-10 margin-bottom-10" />
        </div>}

      {myGuaranteesObj &&
        myGuaranteesObj.guarantees.length > 0 ?
        <div>
          <div className="row d-flex-hidden-sm align-items-center padding-4 padding-md-0
           padding-left-md-36 padding-right-md-36 bg-light-grey">
            <div className="col-sm-5 row align-items-center">
              <div className="col-4 padding-4">ID</div>
              <div className="col-4 padding-8">Name</div>
            </div>
            <div className="col-sm-7 row align-items-center">
              <div className="col-4 padding-4"> Date</div>
              <div className="col-4 padding-4">Limit</div>
              <div className="col-4 padding-4">Status</div>
            </div>
          </div>
          {
            myGuaranteesObj.guarantees.map((guarantee, index) => {
              return (
                <div
                  key={index}
                  className="row margin-bottom-14 margin-bottom-sm-18 align-items-center padding-4 padding-md-16 padding-left-md-36 
                  padding-right-md-36 border-radius-6 hover-bg-lighter-grey">
                  <div className="col-sm-5 row align-items-center padding-bottom-8">
                    <div className="col-4 padding-2">
                      <div className="d-block-display-sm">
                        ID
                      </div>
                      <div>
                        <b>#{guarantee.id}</b>
                      </div>
                    </div>
                    <div className="col-8 padding-2">
                      <div className="d-block-display-sm">
                        Guarantor
                      </div>
                      <div>
                        <b>{guarantee.guarantor_full_name}</b>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-7 row align-items-center padding-bottom-8">
                    <div className="col-4 padding-2">
                      <div className="d-block-display-sm">
                        Date
                      </div>
                      <div>
                        <b>{moment(String(guarantee.created_at)).format("Do MMM 'YY")}</b>
                      </div>
                    </div>
                    <div className="col-4 padding-2">
                      <div className="d-block-display-sm">
                        Limit
                      </div>
                      <div>
                        <b><FormattedMoney
                          value={guarantee.guarantee_limit}
                          currency={guarantee.guarantee_limit_currency}
                        /></b>
                      </div>
                    </div>
                    <div className="col-4 padding-2">
                      <div className="d-block-display-sm">
                        Status
                      </div>
                      <div>
                        <b>
                          <b>{convertSnakeToStandardCasing(guarantee.status)}</b>
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="horizontal-line margin-top-10 margin-bottom-10" />
                </div>
              )
            })
          }

          {
            (myGuaranteesObj && (myGuaranteesObj.total_count > initialParams.no_of_items_per_page)) ?
              <Pagination
                {...initialParams}
                handleGetQuery={handleGetMyGuarantees}
              /> :
              null
          }
        </div> :
        <div className="grey padding-top-10 padding-bottom-20 padding-bottom-sm-10 text-center">
          You do not have any pool guarantor {isSearching && "that matches this query"}
        </div>}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};


export default connect(mapStateToProps)(MyGuarantors);
