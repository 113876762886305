import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import axiosInstance from '../../helpers/axiosInstance';

const VerifedEmailRedirect = (props) => {

    const key = props.match.params.key

    axiosInstance.get(`/verify-email/${key}/`)
        .then(res => {
            if (res.status == 200) {
                props.history.push("/login")
            }
        })
        .catch(error => {
            console.log(error.response)
        })

    useEffect(() => {

    }, [])

    return (
        <div className="container">

        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps)(VerifedEmailRedirect)