import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Truncate from "react-truncate";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import FormattedMoney from "../../utilityComponents/FormattedMoney";
import FilterSorter from "../../utilityComponents/FilterSorter";
import { convertSnakeToStandardCasing } from "../../../utilities";
import poolImage from "../../images/money_pool_setup.svg";
import cyclicMoneyPoolIcon from "../../images/cyclic_money_pool_darkblue.svg";
import Pagination from "../../utilityComponents/Pagination";
import { getMyPools } from "../../../store/actions/moneyPool";

const MyPools = ({
  history,
  dispatch,
  windowWidth,
  setIsPageLoading
}) => {
  const [pools, setPools] = useState([]);
  const [initialPoolCount, setInitialPoolCount] = useState(0);
  const [totalPoolCount, setTotalPoolCount] = useState(0);
  const [query, setQuery] = useState({
    no_of_items_per_page: 5,
    page: 1,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  });
  const imageHeightRef = useRef(null);

  const [imageHeight, setimageHeight] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [coverImgHeight, setCoverImgHeight] = useState(0);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [hoveredPoolId, setHoveredPoolId] = useState();

  const handleGetMyPools = async (query) => {
    setIsPageLoading([true, "Loading..."]);
    const response = await dispatch(getMyPools(query))

    setIsPageLoading([false]);

    if (response.error) {
      NotificationManager.error(response.error.message, "Error!")
    } else {
      if (response.success == true) {
        const data = response.data
        setPools(data.pools);
        setTotalPoolCount(data.total_count);

        setQuery(data.original_query_params)

        return data
      }
    }
  };

  const coverImgRef = useCallback(
    (node) => {
      if (node !== null) {
        setCoverImgHeight(node.getBoundingClientRect().width * 0.65);
      }
    },
    [windowWidth]
  );

  useEffect(() => {
    if (imageHeightRef.current) {
      setimageHeight(imageHeightRef.current.offsetHeight);
    }
  }, [imageHeightRef.current, windowWidth]);

  useEffect(() => {
    handleGetMyPools(query)
      .then(data => {
        if (data) {
          setInitialPoolCount(data.total_count);
        }
      })
  }, []);

  return (
    <div>
      {initialPoolCount > 0 &&
        <div className="margin-bottom-24">
          <FilterSorter
            sorterOptions={[
              { label: 'Created Date', value: 'created_at' },
              { label: 'Start Date', value: 'start_date' },
              { label: 'End Date', value: 'end_date' },
            ]}
            initialSortBy={query.sort_by}
            queryOrder={query.query_order}
            limit={query.no_of_items_per_page}
            page={query.page}
            totalCount={query.total_count}
            noOfItems={pools.length}
            filterOptions={[
              {
                status: [
                  { label: "All", value: '' },
                  { label: 'Not Submitted', value: 'NOT_SUBMITTED' },
                  { label: 'Submitted', value: 'SUBMITTED' },
                  { label: 'Subscribing', value: 'SUBSCRIBING' },
                  { label: 'Running', value: 'RUNNING' },
                  { label: 'Aborted', value: 'ABORTED' },
                  { label: 'Disabled', value: 'DISABLED' },
                  { label: 'Ended', value: 'ENDED' },
                ]
              }
            ]}
            onSelectQuery={(values) => {
              setIsSearching(true)
              const newQueryObj = {
                ...query,
                ...values
              }
              handleGetMyPools(newQueryObj)
            }}
          />
        </div>}

      {pools.length > 0 ? (
        <div>
          {pools.map((pool, index) => {
            return (
              <div className="relative ">
                <div
                  className="box-shadow border-radius-4 bg-white margin-bottom-30 cursor-pointer"
                  key={index}
                  onClick={() => {
                    history.push(`/dashboard/money-pool/view/${pool.pool_description.slug}?visibleComponent=overview&canGoBack=true`)
                  }}
                >
                  <div className="d-flex align-items-center padding-10">
                    <div className="relative">
                      <span
                        className={`absolute top-2 opacity-90 font-size-12 padding-2 white 
                    ${pool.pool_description.open_to_public ?  'bg-red': 'bg-normal-green'}`}>
                        {pool.pool_description.open_to_public ? 'Public' : 'Private'} </span>
                      <div
                        ref={coverImgRef}
                        className="col-4 fixed-width-80 background-image border-radius-4"
                        style={{
                          backgroundImage: `url(${pool.pool_description.pool_picture ? pool.pool_description.pool_picture : poolImage})`,
                          height: coverImgHeight + "px",
                          backgroundSize: 'contain'
                        }}>
                      </div>
                    </div>
                    <div className="col-8 font-size-16 dark-blue font-weight-bold padding-bottom-8 padding-left-2 padding-right-2">
                      <Truncate lines={1}>
                        {pool.pool_description.title}
                      </Truncate>
                    </div>
                  </div>
                  <div className="padding-bottom-10">
                    <div className="row margin-left-10">
                      <div className="col-md-6 d-flex margin-top-8">
                        <div className="col-4 padding-4">
                          <div className="font-size-13 line-height-13">ID</div>
                          <div>
                            <div className="font-size-13 dark-blue stretch-full">
                              {pool.pool_description.id}
                            </div>
                          </div>
                        </div>
                        <div className="col-4 padding-4">
                          <div className="font-size-13 line-height-13 grey">
                            Frequency
                          </div>
                          <div>
                            <div className="font-size-14 dark-blue">
                              {convertSnakeToStandardCasing(pool.pool_description.payment_frequency)}
                            </div>
                          </div>
                        </div>
                        <div className="col-4 padding-4">
                          <div className="font-size-13 line-height-13 grey">
                            Total Hands
                          </div>
                          <div className="font-size-13 dark-blue">
                            {pool.pool_description.total_units}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 d-flex margin-top-8">
                        <div className="col-4 padding-4">
                          <div className="font-size-13 line-height-13 grey">
                            Per Hand
                          </div>
                          <b className="font-size-13 font-size-sm-17 normal-green">
                            <FormattedMoney
                              value={pool.pool_description.unit_payment}
                              currency={pool.pool_description.unit_payment_currency}
                            />
                          </b>
                        </div>
                        <div className="col-4 padding-4">
                          <div className="font-size-13 line-height-13 grey">
                            Type
                          </div>
                          <div className="font-size-13 dark-blue">
                            {pool.pool_description.open_to_public ? 'Public' : 'Private'}
                          </div>
                        </div>
                        <div className="col-4 padding-4">
                          <div className="font-size-13 line-height-13 grey">
                            Monthly Interest
                          </div>
                          <div className="font-size-13 dark-blue">
                            {pool.pool_description.monthly_interest_rate} %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-left-4 margin-sm-10">
                      <div className="col-md-6 d-flex margin-top-8">
                        <div className="col-4  padding-4">
                          <div className="font-size-13 line-height-13 grey">
                            Created Date
                          </div>
                          <div className="font-size-13 dark-blue">
                            {moment(pool.pool_description.created_at).format("DD MMM YYYY")}
                          </div>
                        </div>
                        <div className="col-4 padding-4">
                          <div className="font-size-13 line-height-13 grey">
                            Start Date
                          </div>
                          <div className="font-size-13 dark-blue">
                            {moment(pool.pool_description.start_date).format("DD MMM YYYY")}
                          </div>
                        </div>
                        <div className="col-4 padding-4">
                          <div className="font-size-13 line-height-13 grey">
                            End Date
                          </div>
                          <div className="font-size-13 dark-blue">
                            {moment(pool.pool_description.end_date).format("DD MMM YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 d-flex margin-top-8">
                        <div className="col-4 padding-4">
                          <div className="font-size-13 line-height-13 grey">
                            % Subscription
                          </div>
                          <div className="font-size-13 dark-blue">
                            {pool.pool_description.picked_dates_percent.toFixed(0)}%
                          </div>
                        </div>
                        <div className="col-4 padding-4">
                          <div className="font-size-13 line-height-13 grey">
                            Status
                          </div>
                          <div className="font-size-13 dark-blue">
                            {convertSnakeToStandardCasing(pool.pool_description.status)}
                          </div>
                        </div>
                        <div className="col-4 padding-4">
                          {pool.required_actions.length ? (
                            <div className="d-flex align-items-center cursor-pointer">
                              <i className="fa fa-circle font-size-8 red padding-right-4"></i>
                              <div className="font-size-12 red"  >
                                Action Required
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  pool.is_pool_manager &&
                  <div className="absolute top-10 right-10"
                    // onMouseEnter={() => {
                    //   setHoveredPoolId(pool.pool_description.id)
                    //   setIsDropDownOpen(true)
                    // }}
                    onMouseLeave={() => {
                      setHoveredPoolId("")
                      setIsDropDownOpen(false)
                    }}
                    onClick={() => {
                      setHoveredPoolId(pool.pool_description.id)
                      setIsDropDownOpen(prev => !prev)
                    }}
                  >
                    <i class="fa fa-ellipsis-v font-size-16 padding-10 cursor-pointer"
                    ></i>
                    {
                      isDropDownOpen && hoveredPoolId == pool.pool_description.id ?
                        <div className="absolute top-10 right-20 bg-light-grey fixed-width-120">
                          <div>
                            <NavLink to={`/dashboard/money-pool/view/${pool.pool_description.slug}`}
                              className="router-link" > <div className="padding-10 hover-bg-light-brown">
                                <i className="fa fa-eye green"></i> View
                              </div></NavLink>
                          </div>
                          <div>
                            <NavLink to={`/dashboard/money-pool/edit/${pool.pool_description.slug}`}
                              className="router-link">
                              <div className="padding-10 hover-bg-light-brown"><i className="fa fa-edit red"></i> Edit</div>
                            </NavLink>
                          </div>
                        </div>
                        : null
                    }
                  </div>
                }
              </div>
            );
          })}
        </div>
      ) : (
        <center className="padding-top-50 padding-bottom-40">
          <img className="max-width-70" src={cyclicMoneyPoolIcon} />
          <h1 className="font-size-16 grey padding-top-10">You do not have any money pool {isSearching ? "that matches this query" : null} </h1>
        </center>
      )}

      {
        totalPoolCount > query.no_of_items_per_page ?
          <Pagination
            {...query}
            handleGetQuery={handleGetMyPools}
          /> :
          null
      }
    </div>
  );
};

export default connect()(MyPools);
