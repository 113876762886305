import React, {useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal"
import { NotificationManager } from "react-notifications";
import { getAcceptOrReject } from "../../../../../utilities";
import {
  acceptDateSwapRequest,
  rejectDateSwapRequest,
  getPoolMemberMissedPayments,
} from "../../../../../store/actions/moneyPool";
import PoolMemberMissedPayments from "../../setup/fragments/PoolMemberMissedPayments";
import MoneyPoolInformation from "./MoneyPoolInformation";

const MoneyPoolOverview = ({
  history,
  dispatch,
  pool,
  setPool,
  setIsPageLoading,
  handleGetPendingPoolInvitations,
  setIsLinkBanKAccountVisible,
  setIsCollectionDatesModalVisible
}) => {
  const [isMemberMissedPaymentsVisible, setIsMemberMissedPaymentsVisible] = useState(false);
  const [openedMemberMissedPayments, setOpenedMemberMissedPayments] = useState([]);

  const handleGetMemberMissedPayments = (memberEmail) => {
    dispatch(getPoolMemberMissedPayments({
      pool_id: pool.pool_description.id,
      email: memberEmail
    })).then(res => {
      if (res.error) {
        NotificationManager.error(res.error.message, "Error!")
      } else {
        if (res.success) {
          setOpenedMemberMissedPayments(res.data)
          setIsMemberMissedPaymentsVisible(true)
        }
      }
    })
  }

  return (
    <div>
      {pool.required_actions.length > 0 && (
        <div>
          <h3 className="font-size-18 dark-blue padding-top-8 padding-right-0 padding-right-md-20 padding-right-lg-30">
            Required Actions
          </h3>
          {pool.required_actions.map((action, index) => {
            return (
              <div
                className="d-flex justify-content-between margin-top-6 margin-bottom-12"
                key={index}
              >
                {action.message && (
                  <div className="d-flex col-8 align-items-center font-size-12 font-size-sm-15 padding-right-4">
                    <i className="fa fa-exclamation-triangle red padding-right-8"></i>
                    <p>{action.message}</p>
                  </div>
                )}

                {action.action != "accept_or_reject" ? (
                  <div className="max-width-300">
                    <button
                      className="btn font-size-12 float-end font-size-md-14 max-width-140 border-radius-8 bg-dark-blue border-color-dark-blue white padding-4 padding-md-8"
                      type="button"
                      onClick={() => {
                        if (action.action == "missed_payment") {
                          // setIsPoolPaymentVisible(true);
                          history.push(`/dashboard/money-pool/view/${action.slug}?visibleComponent=settings`)
                        } else if (action.action == "link_bank_account") {
                          setIsLinkBanKAccountVisible(true);
                        } else if (action.action == "swap_dates") {
                          getAcceptOrReject({
                            title: "Confirm",
                            message: `${action.data.requester_name} has requested to swap a collection date (${action.data.their_date})
                     with your's (${action.data.your_date}).`,
                            onClickAccept: () => {
                              dispatch(
                                acceptDateSwapRequest({
                                  swap_id: action.data.swap_id,
                                })
                              ).then((res) => {
                                if (res.error) {
                                  alert(res.error.message);
                                } else {
                                  setPool(res.data);
                                  alert(res.message);
                                  handleGetPendingPoolInvitations();
                                }
                              });
                            },
                            onClickReject: () => {
                              dispatch(
                                rejectDateSwapRequest({
                                  swap_id: action.data.swap_id,
                                })
                              ).then((res) => {
                                if (res.error) {
                                  alert(res.error.message);
                                } else {
                                  setPool(res.data);
                                  alert(res.message);
                                  handleGetPendingPoolInvitations();
                                }
                              });
                            },
                          });

                          // alert(action.swap_id)
                        } else if (action.action == "link_payment_card") {
                          // setIsPoolPaymentVisible(true);
                          history.push(`/dashboard/money-pool/view/${action.slug}?visibleComponent=settings`)
                        } else if (action.action == "pick_date") {
                          setIsCollectionDatesModalVisible(true);
                        } else if (action.action == "replace_expired_card" || action.action == "card_payment_declined") {
                          history.push(`/dashboard/money-pool/view/${action.slug}?visibleComponent=settings`)
                        } else if (action.action == "submit_form") {
                          history.push(`/dashboard/money-pool/edit/${action.slug}?activeSlide=poolSummary`)
                        } else if (action.action == "assign_units") {
                          history.push(`/dashboard/money-pool/edit/${action.slug}?activeSlide=poolMembersInfo`)
                        }
                      }}
                    >
                      Take Action
                    </button>
                  </div>
                ) : null}
              </div>
            );
          })}
          <div className="horizontal-line margin-top-24 margin-bottom-20" />
        </div>
      )}

      <MoneyPoolInformation
        pool={pool}
        setPool={setPool}
        setIsPageLoading={setIsPageLoading}
        history={history}
      />

      <Modal
        ariaHideApp={false}
        isOpen={isMemberMissedPaymentsVisible}
        onRequestClose={() => {
          setIsMemberMissedPaymentsVisible(false);
        }}
        className="modal max-width-720 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue text-center">
            Missed Payments
          </h1>
          <i
            onClick={() => {
              setIsMemberMissedPaymentsVisible(false);
            }}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            {openedMemberMissedPayments && openedMemberMissedPayments.length > 0 &&
              <div className="font-size-16 padding-bottom-6">
                <span className="grey">Name</span>
                <span className="dark-blue"> {openedMemberMissedPayments[0].name}</span>
              </div>
            }
            <PoolMemberMissedPayments
              missedPayments={openedMemberMissedPayments}
              pool={pool}
              setPool={setPool}
              handleGetMemberMissedPayments={handleGetMemberMissedPayments}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MoneyPoolOverview);