import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { poolManagerAddsAssistantManager, poolManagerRemovesAssistantManager } from "../../../../../store/actions/moneyPool";
import { getConfirmAlert } from "../../../../../utilities";

const Settings = ({
  user,
  dispatch,
  pool,
  setPool,
  setIsPageLoading
}) => { 
  return (
    <div>
      {
        user.id == pool.pool_description.pool_manager_id &&
        <div>

          <div className="horizontal-line margin-bottom-20" />

          <div>
            <b className="font-size-17 font-size-sm-19 dark-blue">
              Admin Settings
            </b>

            <div className="margin-top-6">
              {pool.pool_description.pool_assistant_managers &&
                pool.pool_description.pool_assistant_managers.length > 0 &&
                pool.pool_description.pool_assistant_managers.map((manager, index) => {
                  return (
                    <div key={index}>
                      <div className="d-flex padding-top-4 padding-bottom-4">
                        <div className="fixed-width-100">Email:</div>
                        <div className="dark-blue">{manager.email}</div>
                      </div>
                      <div className="d-flex padding-top-4 padding-bottom-4">
                        <div className="fixed-width-100">First Name:</div>
                        <div className="dark-blue">{manager.first_name}</div>
                      </div>
                      <div className="d-flex padding-top-4 padding-bottom-4">
                        <div className="fixed-width-100">Last Name:</div>
                        <div className="dark-blue">{manager.last_name}</div>
                      </div>

                      <button
                        className="btn bg-orange-red border-radius-8 padding-8 max-width-180 font-size-15 margin-top-10"
                        type="button"
                        onClick={() => {
                          getConfirmAlert({
                            title: "Confirm!",
                            message: "Are you sure you want to remove this co-organizer?",
                            onClickYes: () => {
                              setIsPageLoading([true, "...loading"])
                              dispatch(poolManagerRemovesAssistantManager({
                                pool_id: pool.pool_description.id,
                                assistant_user_email: manager.email
                              }))
                                .then(res => {
                                  setIsPageLoading([false])
                                  if (res.error) {
                                    NotificationManager.error(res.error.message, "Error!")
                                  } else {
                                    if (res.success == true) {
                                      NotificationManager.success(res.message, "Success!")
                                      setPool(res.data)
                                    }
                                  }
                                })
                            },
                            onClickNo: () => null
                          })
                        }}
                      >Remove Organizer</button>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <Formik
            initialValues={{
              pool_id: pool.pool_description.id,
              assistant_user_email: "",
            }}
            validationSchema={Yup.object({
              assistant_user_email: Yup.string().email("Must be an email").required("Email is required"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setIsPageLoading([true, "...loading"])
              dispatch(poolManagerAddsAssistantManager(values))
                .then(res => {
                  setSubmitting(false)
                  setIsPageLoading([false])
                  if (res.error) {
                    NotificationManager.error(res.error.message, "Error!")
                  } else {
                    if (res.success == true) {
                      NotificationManager.success(res.message, "Success!")
                      setPool(res.data)
                      resetForm()
                    }
                  }
                })
            }}
          >
            {({
              handleSubmit,

            }) => (
              <Form>
                <div className="padding-top-10 padding-bottom-10">
                  <h3>Invite other users to assist in managing this cyclic money pool</h3>
                  <div className="max-width-400 padding-top-10 stretch-full-sm padding-right-0 padding-right-sm-10">
                    <label className="form-label">Co-organizer's Email</label>
                    <Field
                      className="form-control"
                      placeholder="Add email of the co-organizer"
                      name="assistant_user_email"
                      type="email"
                    />
                    <ErrorMessage
                      className="error-message"
                      name="assistant_user_email"
                      component="div"
                    />
                  </div>
                  <button
                    className="btn bg-light-normal-blue border-radius-8 padding-8 max-width-140 font-size-15 margin-top-18"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

      }


    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Settings);
