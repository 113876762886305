import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const RetrieveAuthenticatorQRCode = ({
  questions,
  has_verified_phone,
  handleClose,
  onSubmitAnswer,
  onRequestPhoneOtp,
  onRequestAuthenticatorAppUri,
  onRetrieveUriSuccessful,
  onCorrectAnswers,
  isEmailAuthOptVisible,
}) => {
  const [phoneOtp, setPhoneOtp] = useState(null);
  const [visibleComponent, setVisibleComponent] = useState("phone_otp");
  const [myQuestions] = useState(questions);
  const [visibleQuestion, setVisibleQuestion] = useState({});
  const [undefined, setProvidedAnswerList] = useState([]);
  const [questionNo, setQuestionNo] = useState(0);
  const [emailOtp, setEmailOtp] = useState(null);
  const [areQuestionsAnswered, setAreQuestionsAnswered] = useState(false);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (myQuestions && myQuestions.length > 0) {
      setVisibleQuestion(myQuestions[0])
      setQuestionNo(1)
    }
  }, [])

  const AnswerSecurityQuestion = () => {
    return (
      <Formik
        key={visibleQuestion.id}
        initialValues={{
          question_id: visibleQuestion.id,
          answer: ""
        }}
        validationSchema={
          Yup.object().shape({
            question_id: Yup.string().required('Question is required'),
            answer: Yup.string().required('Answer is required')
          })
        }

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);

          let questionAnswersList

          // Submit one answer at a time
          onSubmitAnswer(values).then((res) => {
            if (res.success == true) {
              setProvidedAnswerList(prev => {
                let newArr = prev
                // Remove if already pushed in and push back in again
                newArr = newArr.filter(item => item.question_id != values.question_id)
                newArr.push(values)
                questionAnswersList = newArr
                return [...newArr]
              })

              if (questionNo == 1) {
                // Then answer the second question
                setQuestionNo(2)
                setVisibleQuestion(myQuestions[1])
              } else if (questionNo == 2) {

                // Send authentication otp to email
                onCorrectAnswers()
                  .then(() => {
                    setAreQuestionsAnswered(true)
                    setAnswers(questionAnswersList)
                  })

                // // Then request your authenticator uri
                // onRequestAuthenticatorAppUri({
                //   type: visibleComponent,
                //   data: {
                //     security_answers: questionAnswersList
                //   }
                // }).then(res => {
                //   if (res.success == true) {
                //     onRetrieveUriSuccessful(res.data)
                //     handleClose()
                //   }
                // })
              }
            }
          })
        }}
      >
        {({ handleSubmit, handleChange, isSubmitting, values }) => (
          <Form>
            <div>
              <p className="padding-bottom-6">Question <b>#{questionNo}</b> </p>
              <div>
                <label className="form-label">{visibleQuestion.question}</label>
                <Field
                  name="answer"
                  className="form-control  "
                  placeholder="Enter your answer"
                  onChange={handleChange("answer")}
                />
                <ErrorMessage
                  className="error-message"
                  name="answer"
                  component="div"
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn bg-dark-blue white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180">
              <b>Submit</b>
            </button>
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <div>

      {myQuestions && myQuestions.length > 0 && has_verified_phone &&
        <div className="d-flex margin-top-12 margin-bottom-12">
          <div
            className={`btn font-size-13 font-size-sm-15 margin-right-sm-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "phone_otp" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange dark-blue"}`}
            onClick={() => setVisibleComponent("phone_otp")}>
            Use Phone OTP
          </div>

          <div
            className={`btn font-size-13 font-size-sm-15 margin-right-sm-10 margin-left-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "security_answers" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange dark-blue"}`}
            onClick={() => setVisibleComponent("security_answers")}>
            Answer Security Questions
          </div>
        </div>}

      {has_verified_phone && visibleComponent == "phone_otp" &&
        <div>
          <div className="d-flex align-items-center padding-top-30 padding-bottom-30">
            <p className="margin-right-20">
              Mobile Phone OTP
            </p>
            <button
              className="btn bg-light-normal-blue max-width-160 padding-8"
              type="button"
              onClick={() => {
                onRequestPhoneOtp();
              }}
            >
              Generate Phone OTP
            </button>
          </div>

          <form className="row align-items-center padding-top-12 padding-bottom-12">
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                onChange={(e) => {
                  setPhoneOtp(e.target.value)
                }}
              />
            </div>
            <div className="col-sm-6 padding-left-sm-24">
              <button
                className="btn bg-fundraiser-color max-width-200 padding-8 margin-top-12 margin-top-sm-0"
                type="submit"
                onClick={() => {
                  // Then request your authenticator uri
                  onRequestAuthenticatorAppUri({
                    type: visibleComponent,
                    data: {
                      phone_otp: phoneOtp
                    }
                  }).then(res => {
                    if (res.success == true) {
                      onRetrieveUriSuccessful(res.data)
                      handleClose()
                    }
                  })
                }}
                disabled={!(phoneOtp && /^\d{6}$/gm.test(phoneOtp))}
              >
                Verify Phone OTP
              </button>
            </div>
          </form>
        </div>}

      {
        isEmailAuthOptVisible && visibleComponent == "security_answers" &&
        <div>
          <div className="d-flex align-items-center padding-top-30  ">
            <p>
              Email OTP
            </p>
          </div>

          <form className="row align-items-center">
            <div className="col-sm-5 padding-right-sm-12 padding-top-12 padding-bottom-12">
              <input
                className="form-control"
                type="text"
                onChange={(e) => {
                  setEmailOtp(e.target.value)
                }}
              />
            </div>
            <div className="col-sm-7 d-flex align-items-center padding-left-sm-12 padding-top-12 padding-bottom-12">
              <button
                className="btn bg-dark-green max-width-160 padding-8 margin-top-12 margin-top-sm-0 margin-right-20"
                type="submit"
                onClick={() => {
                  // Then request your authenticator uri
                  onRequestAuthenticatorAppUri({
                    emailOtp,
                    type: visibleComponent,
                    data: {
                      security_answers: answers
                    }
                  }).then(res => {
                    if (res.success == true) {
                      onRetrieveUriSuccessful(res.data)
                      handleClose()
                    }
                  })
                }}
                disabled={!(emailOtp && /^\d{6}$/gm.test(emailOtp))}
              >
                Verify Email OTP
              </button>

              <button
                className="btn bg-fundraiser-color max-width-160 padding-8 margin-top-12 margin-top-sm-0"
                type="button"
                onClick={() => {
                  onCorrectAnswers()
                }}
              >
                Resend Email OTP
              </button>
            </div>
          </form>
        </div>
      }

      {
        visibleComponent == "security_answers" && areQuestionsAnswered == false && myQuestions && myQuestions.length > 0 &&

        <div>
          <h2 className="font-size-19 font-size-sm-20 fundraiser-color padding-top-10 padding-bottom-10">Answer Security Questions</h2>

          <AnswerSecurityQuestion />
        </div>
      }
    </div>
  );
};

export default RetrieveAuthenticatorQRCode
