import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { NotificationManager } from 'react-notifications';
import { transferFromWalletToBank } from "../../../store/actions/pouchWallet";
import {
  getTransferFee,
} from "../../../store/actions/transaction";
import ConfirmWithdrawal from "./ConfirmWithdrawal";
import VerifyBankAccount from "../../payments/VerifyBankAccount";
import MoneyInputField from "../../utilityComponents/MoneyInputField"
import { getCurrencySymbol, isEnoughMoneyInWallet, numberWithCommas } from "../../../utilities";
import FormattedMoney from "../../utilityComponents/FormattedMoney";

const MakeWithdrawal = ({
  balances,
  user,
  dispatch,
  setIsPageLoading,
  handleClose,
}) => {
  const [currencies, setCurrencies] = useState([]);
  const [mySavedAccounts, setMySavedAccounts] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState({});
  const [showSaveNewBeneficiary, setShowSaveNewBeneficiary] = useState(false);
  const [isConfirmWithdrawalVisible, setIsConfirmWithdrawalVisible] = useState(false);
  const [isAccountPreviewVisible, setIsAccountPreviewVisible] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [withdrawalDetails, setWithdrawalDetails] = useState({});
  const [beneficiaryType, setBeneficiaryType] = useState("");

  const beneficiaryOptions = [
    {
      label: "Saved Beneficiary",
      value: "savedBeneficiary",
    },
    {
      label: "New Beneficiary",
      value: "newBeneficiary",
    },
  ];

  const handleSubmitTransfer = (values) => {
    setIsPageLoading([true, "Loading..."]);

    dispatch(
      transferFromWalletToBank({
        account_number: values.account_number,
        amount: values.amount,
        account_bank: values.bank_code,
        currency: values.currency,
        fee: values.fee,
        beneficiary_name: values.name,
        is_saving_beneficiary: values.is_saving_beneficiary,
        country_code: values.country_code,
        bank_name: values.bank_name,
        otp: values.otp
      })
    ).then((res) => {
      setIsPageLoading([false])

      if (res.error) {
        NotificationManager.error(res.error.message, 'Error message')
      } else {
        if (res.success == true) {
          handleClose();
          setIsConfirmWithdrawalVisible(false);

          NotificationManager.success(res.message, 'Success')
        }
      }
    });
  };

  useEffect(() => {
    // Get the available currency wallets
    const currencyObjects = balances.map((balance) => {
      return {
        label: `Balance: ${getCurrencySymbol(
          balance.currency
        )}${balance.available_balance ? numberWithCommas(balance.available_balance) : 0}`,
        value: balance.currency,
      };
    });
    setCurrencies(currencyObjects);

    // Get the user's saved bank accounts
    const bankAccounts = user.saved_bank_accounts.map((account) => {
      return {
        label: `${account.bank_account_name} ${account.bank_name} ${account.bank_account_number}`,
        value: account,
      };
    });
    setMySavedAccounts(bankAccounts);
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          amount: "",
          fee: "",
          name: "",
          account_number: "",
          bank_code: "",
          currency: "",
          is_saving_beneficiary: false,
        }}
        validationSchema={Yup.object({
          amount: Yup.number()
            .typeError("Amount must be a number")
            .positive("Amount must be a positive number")
            .integer("Amount must be a whole number")
            .required("Amount is required")
            .nullable(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            amount: values.amount,
            fee: values.fee,
            currency: values.currency,
            name: selectedBankAccount.bank_account_name,
            account_number: selectedBankAccount.bank_account_number,
            bank_code: selectedBankAccount.bank_code,
            is_saving_beneficiary: values.is_saving_beneficiary,
            country_code: selectedBankAccount.country_code,
            bank_name: selectedBankAccount.bank_name,
            bank_branch_code: selectedBankAccount.bank_branch_code,
            bank_branch_name: selectedBankAccount.bank_branch_name,
          };

          setWithdrawalDetails(data);

          setIsConfirmWithdrawalVisible(true);
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
        }) => (
          <Form>
            <div className="padding-bottom-12">
              <label className="form-label font-size-15">
                Select Currency Wallet
              </label>
              <Select
                className="form-control padding-0 border-color-dark-blue"
                classNamePrefix="react-select"
                placeholder="Select currency"
                options={currencies}
                value={
                  currencies
                    ? currencies.find(
                      (option) => option.value === values.currency
                    )
                    : ""
                }
                onChange={(option) => {
                  setFieldValue("currency", option.value);
                }}
              />
            </div>

            {values.currency ? (
              <div className="padding-top-12 padding-bottom-12">
                <label className="form-label font-size-15">Amount</label>
                <div>
                  <MoneyInputField
                    className="form-control padding-10 border-color-dark-blue fixed-height-40"
                    value={values.unit_payment}
                    currency={values.currency}
                    onChange={(amount) => {
                      setFieldValue("amount", amount)
                      dispatch(
                        getTransferFee({
                          amount,
                          currency: values.currency,
                          type: "account",
                        })
                      ).then((res) => {
                        if (res.error) {
                          if (amount > 0) {
                            NotificationManager.error(res.error.message, "Error")
                          }
                        } else {
                          if (res.success == true) {
                            setFieldValue("fee", res.data.total_fee);
                          }
                        }
                      });
                    }}
                    placeholder="Enter amount"
                  />
                </div>
                <ErrorMessage
                  className="error-message"
                  name="amount"
                  component="div"
                />

                <div
                  className="error-message">
                  {values.amount > 0 &&
                    isEnoughMoneyInWallet(values.amount + values.fee, values.currency) == false &&
                    "Insufficient fund"}
                </div>

                {
                  values.amount > 0 &&
                  <div>
                    <div className="d-flex padding-top-26">
                      <label className="margin-right-10" >Transfer Fee</label>
                      <div >
                        <b><FormattedMoney
                          value={values.fee}
                          currency={values.currency}
                          decimalScale={2} /></b>
                      </div>
                    </div>
                    <div className="d-flex padding-top-26">
                      <label className="margin-right-10" >Total Amount</label>
                      <div >
                        <b><FormattedMoney
                          value={values.amount + values.fee}
                          currency={values.currency}
                          decimalScale={2} /></b>
                      </div>
                    </div>
                  </div>
                }
              </div>
            ) : null}

            <div className="padding-top-12 padding-bottom-12">
              <label className="form-label font-size-15">
                Beneficiary Type
              </label>

              <Select
                className="form-control padding-0 border-color-dark-blue"
                classNamePrefix="react-select"
                placeholder="Select beneficiary type"
                options={beneficiaryOptions}
                value={
                  beneficiaryOptions
                    ? beneficiaryOptions.find(
                      (option) => option.value === beneficiaryType
                    )
                    : ""
                }
                onChange={(option) => {
                  setBeneficiaryType(option.value);

                  setIsAccountPreviewVisible(false);

                  setCanProceed(false);

                  setShowSaveNewBeneficiary(false);

                  if (option.value == "savedBeneficiary") {
                    setFieldValue("is_saving_beneficiary", false);
                  }
                }}
              />
            </div>

            {beneficiaryType == "savedBeneficiary" ? (
              <div className="padding-top-12 padding-bottom-12">
                <label className="form-label font-size-15">
                  Select a beneficiary
                </label>
                <Select
                  className="form-control padding-0 border-color-dark-blue"
                  classNamePrefix="react-select"
                  placeholder="Select a bank account"
                  options={mySavedAccounts}
                  value={
                    mySavedAccounts
                      ? mySavedAccounts.find(
                        (option) => option.value === selectedBankAccount
                      )
                      : ""
                  }
                  onChange={(bankAccount) => {
                    setSelectedBankAccount(bankAccount.value);
                    setCanProceed(true);
                  }}
                />
              </div>
            ) : null}

            {beneficiaryType == "newBeneficiary" ? (
              <div className="padding-top-12 padding-bottom-12">
                <VerifyBankAccount
                  setIsPageLoading={setIsPageLoading}
                  handleOnAccountVerification={(bankDetails) => {
                    setSelectedBankAccount({
                      bank_account_name: bankDetails.bank_account_name,
                      bank_account_number: bankDetails.bank_account_number,
                      bank_code: bankDetails.account_bank,
                      bank_name: bankDetails.bank_name,
                      country_code: bankDetails.country_code,
                      bank_branch_code: bankDetails.bank_branch_code,
                      bank_branch_name: bankDetails.bank_branch_name,
                    });

                    setBeneficiaryType("");
                    setIsAccountPreviewVisible(true);
                    setShowSaveNewBeneficiary(true);
                    setCanProceed(true);
                  }}
                />
              </div>
            ) : null}

            {isAccountPreviewVisible ? (
              <div className="padding-top-10">
                <div className="row align-items padding-top-10 padding-bottom-10">
                  <b className="col-6">Bank Name</b>
                  <span className="col-6">{selectedBankAccount.bank_name}</span>
                </div>

                <div className="d-flex align-items padding-top-10 padding-bottom-10">
                  <b className="col-6">Bank Account Name</b>
                  <span className="col-6">{selectedBankAccount.bank_account_name}</span>
                </div>

                <div className="d-flex align-items padding-top-10 padding-bottom-10">
                  <b className="col-6">Bank Account No</b>
                  <span className="col-6">{selectedBankAccount.bank_account_number}</span>
                </div>

                {
                  selectedBankAccount.bank_branch_name &&
                  <div className="d-flex align-items padding-top-10 padding-bottom-10">
                    <b className="col-6">Bank Branch Name</b>
                    <span className="col-6">{selectedBankAccount.bank_branch_name}</span>
                  </div>
                }
              </div>
            ) : null}

            {showSaveNewBeneficiary ? (
              <div className="padding-top-12 padding-bottom-12">
                <input
                  type="checkbox"
                  value={values.is_saving_beneficiary}
                  onChange={() => {
                    setFieldValue(
                      "is_saving_beneficiary",
                      !values.is_saving_beneficiary
                    );
                  }}
                />

                <label>Save Beneficiary</label>
              </div>
            ) : null}

            <div className="d-flex justify-content-end">
              <button
                className="btn margin-top-24 margin-bottom-20 padding-12 bg-dark-blue white max-width-250 font-size-17 border-radius-8"
                type="button"
                onClick={handleSubmit}
                disabled={!canProceed || !isEnoughMoneyInWallet(values.amount, values.currency)}
              >
                <b>Proceed</b>
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <Modal
        ariaHideApp={false}
        isOpen={isConfirmWithdrawalVisible}
        onRequestClose={() => setIsConfirmWithdrawalVisible(false)}
        className="modal max-width-760 padding-0 border-radius-14"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Preview and Confirm
          </h1>
          <i
            onClick={() => setIsConfirmWithdrawalVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="padding-8">
          <div
            style={{ maxHeight: "80vh" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            <ConfirmWithdrawal
              handleClose={() => setIsConfirmWithdrawalVisible(false)}
              withdrawalDetails={withdrawalDetails}
              handleSubmitTransfer={handleSubmitTransfer}
              setIsPageLoading={setIsPageLoading}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MakeWithdrawal);
