import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { generateSampleMoneyPool } from "../../store/actions/moneyPool";
import CurrencyField from "../utilityComponents/CurrencyField";
import DatePickerField from "../utilityComponents/DatePickerField";
import MoneyInputField from "../utilityComponents/MoneyInputField";
import FormattedMoney from "../utilityComponents/FormattedMoney";
import { convertSnakeToStandardCasing, getConfirmAlert, getCurrencySymbol } from "../../utilities";

const RequestToJoinMoneyPool = ({
  isAuthenticated,
  user,
  history,
  dispatch,
  setIsPageLoading,
}) => {
  const search = useLocation().search;
  const unit_payment = new URLSearchParams(search).get("unit_payment")
  const unit_payment_currency = new URLSearchParams(search).get("unit_payment_currency")
  const total_units = new URLSearchParams(search).get("total_units")
  const payment_frequency = new URLSearchParams(search).get("payment_frequency")
  const is_interest_paid = new URLSearchParams(search).get("is_interest_paid")
  const monthly_interest_rate = new URLSearchParams(search).get("monthly_interest_rate")
  const start_date = new URLSearchParams(search).get("start_date")

  const [visibleComponent, setVisibleComponent] = useState("form")
  const [sampleMoneyPool, setSampleMoneyPool] = useState(null)
  const [expectedInterests, setExpectedInterests] = useState([])
  const [isExpectedInterestsVisible, setIsExpectedInterestsVisible] = useState(false)
  const [initialValues, setInitialValues] = useState({
    unit_payment: "",
    unit_payment_currency: "",
    total_units: "",
    payment_frequency: "",
    monthly_interest_rate: 0,
    start_date: "",
    is_interest_paid: false
  })

  const formikRef = useRef(null)

  const options = [
    { value: "WEEKLY", label: "Weekly" },
    { value: "BI_WEEKLY", label: "Bi-weekly" },
    { value: "MONTHLY", label: "Monthly" },
  ];

  const generateQueryParams = () => {
    const params = new URLSearchParams(initialValues)
    return params
  }

  useEffect(() => {
    let currency

    if (isAuthenticated) {
      currency = user.preferred_currency
    } else {
      let locale = localStorage.getItem("locale")
      if (locale) {
        locale = JSON.parse(locale)
        currency = locale.currency

        if (!locale.supported_currencies || !locale.supported_currencies.includes(currency)) {
          currency = "USD"
        }
      }
    }

    formikRef.current?.setFieldValue("unit_payment_currency", currency);

    if (unit_payment) {
      formikRef.current?.setFieldValue("unit_payment", unit_payment);
    }

    if (unit_payment_currency) {
      formikRef.current?.setFieldValue("unit_payment_currency", unit_payment_currency);
    }

    if (total_units) {
      formikRef.current?.setFieldValue("total_units", total_units);
    }

    if (payment_frequency) {
      formikRef.current?.setFieldValue("payment_frequency", payment_frequency);
    }

    if (is_interest_paid) {
      formikRef.current?.setFieldValue("is_interest_paid", JSON.parse(is_interest_paid));
    }

    if (monthly_interest_rate) {
      formikRef.current?.setFieldValue("monthly_interest_rate", parseFloat(monthly_interest_rate));
    }

    if (start_date && new Date(start_date).getTime() > new Date().getTime()) {
      formikRef.current?.setFieldValue("start_date", start_date);
    }
  }, [])

  const ExpectedInterests = ({ list }) => {
    return (
      <div>
        {list.length > 0 ?
          <div>
            <div className="d-flex padding-top-10 padding-left-5" >
              <b className="col-5 font-size-14 font-size-sm-15 dark-blue" >Date</b>
              <b className="col-7 font-size-14 font-size-sm-15 dark-blue " >Interest</b>
            </div>
            {list.map((item, index) => {
              return <div key={index} className="d-flex padding-5 " >
                <span className="col-5 font-size-13 font-size-sm-14 dark-blue">{moment(item.interest_due_date).format("MMM Do, YYYY")}</span>
                <b className="d-flex col-7 blue padding-left-2" >
                  <FormattedMoney
                    value={item.interest}
                    currency={item.currency}
                    decimalScale={2} />
                </b>
              </div>
            })}
            <div className="horizontal-line" />
            <div className="d-flex padding-top-10 padding-left-5" >
              <b className="col-5 font-size-14 font-size-sm-15 dark-blue" >Total Interest Due</b>
              <b className="col-7 blue font-size-16 " >
                <FormattedMoney
                  value={list.map(i => i.interest).reduce((a, b) => a + b, 0)}
                  currency={list[0].currency}
                  decimalScale={2}
                /> </b>
            </div>
          </div>
          :
          <span>No interest to be paid back</span>}
      </div>
    )
  }

  const NavigationBlock = () => {
    return (
      <div className="d-flex align-items-center font-size-13 font-size-md-13 ">
        <div
          className="margin-bottom-10 margin-top-10 dark-blue margin-right-20"
          onClick={() => setVisibleComponent("form")}
        >
          <i
            class="fas fa-long-arrow-alt-left font-size-25"
            aria-hidden="true"
          ></i>
        </div>

        <CopyToClipboard
          text={`${process.env.REACT_APP_FRONTEND_BASE_URL}/money-pool?visibleModal=samplePool&${generateQueryParams()}`}
          onCopy={() => {
            NotificationManager.success("Link has been copied", "Success!")
          }}
        >
          <button
            className="btn margin-10 max-width-100 border-radius-6 bg-normal-green border-color-normal-green white"
            onClick={() => {

            }}
          >
            Copy Link
          </button>
        </CopyToClipboard>

        <button
          className="btn margin-10 max-width-110 bg-dark-blue white border-radius-6"
          onClick={() => {
            getConfirmAlert({
              title: "Confirm!",
              message: "You will be the organizer in charge of this money pool",
              onClickYes: () => {
                history.push(`/dashboard/money-pool/new?${generateQueryParams()}`)
              },
              onClickNo: () => null

            })
          }}
        >
          Get Started
        </button>
      </div>
    )
  }

  return (
    <div >
      {visibleComponent == "form" &&
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={Yup.object({
            unit_payment: Yup.number()
              .typeError("Must be a number")
              .positive("Must be a positive number")
              .integer("Must be a whole number")
              .required("Unit payment is required")
              .nullable(),
            total_units: Yup.number()
              .typeError("Must be a number")
              .min(3, 'Must not be less than 3 units')
              .max(52, 'Must not exceed 52 units')
              .integer("Must be a whole number")
              .required("Total no. of units is required")
              .nullable(),
            payment_frequency: Yup.string().required(
              "Payment frequency is required"
            ),
            monthly_interest_rate: Yup.number()
              .when("is_interest_paid", {
                is: true,
                then: Yup.number()
                  .typeError("Must be a number")
                  .positive("Must be a positive number")
                  .required("Monthly interest rate is required")
                  .max(5, "Monthly interest rate must not be more than 5%")
                  .moreThan(0, "Monthly interest rate must be greater than 0"),
                otherwise: Yup.number().nullable(),
              }),
            start_date: Yup.date()
              .typeError("Value must be a date")
              .required("Start date is required")
              .nullable(),
          })}

          onSubmit={(values, { setSubmitting }) => {
            setIsPageLoading([true, "Loading..."]);

            dispatch(generateSampleMoneyPool(values))
              .then((response) => {
                setIsPageLoading([false]);
                if (response.error) {
                  NotificationManager.error(response.error.message, 'Error Message!')
                } else {
                  if (response.success == true) {
                    setVisibleComponent("pool_details")
                    setSampleMoneyPool(response.data);
                  }
                }
              });

            setSubmitting(false);

            setInitialValues(values)
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
            values,
            setFieldValue,
          }) => (
            <Form>
              <div className="">
                <div className="row">
                  <div className="col-sm-6 padding-top-10 padding-bottom-10">
                    <div className="max-width-320 stretch-full-sm padding-right-0 padding-right-sm-10">
                      <label className="form-label">
                        Total Amount *
                      </label>
                      <div className="form-control d-flex padding-0 fixed-height-40">
                        <div className="font-size-16 border-top-left-radius-8 border-bottom-left-radius-8 padding-top-4 padding-left-15 padding-right-15 bg-lighter-blue">
                          <b>{getCurrencySymbol("NGN")}</b>
                        </div>
                        <MoneyInputField
                          onChange={(amount) => {
                            setFieldValue("amount", amount)
                            // handleUpdatePaymentConfig({
                            //   ...values,
                            //   amount
                            // });

                          }}
                        // placeholder="Enter donation amount"
                        />
                      </div>
                      <div>
                      </div>
                      <ErrorMessage
                        className="error-message"
                        name="unit_payment"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 padding-top-10 padding-bottom-10">
                    <div className="max-width-320 stretch-full-sm float-end padding-left-0 padding-left-sm-10">
                      <label className="form-label">
                        Unit Payment *
                      </label>
                      <div className="form-control d-flex padding-0 fixed-height-40">
                        <div className="font-size-16 border-top-left-radius-8 border-bottom-left-radius-8 padding-top-4 padding-left-15 padding-right-15 bg-lighter-blue">
                          <b>{getCurrencySymbol("NGN")}</b>
                        </div>
                        <MoneyInputField
                          onChange={(amount) => {
                            setFieldValue("amount", amount)
                            // handleUpdatePaymentConfig({
                            //   ...values,
                            //   amount
                            // });

                          }}
                        // placeholder="Enter donation amount"
                        />
                      </div>
                      <ErrorMessage
                        className="error-message"
                        name="total_units"
                        component="div"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 padding-top-10 padding-bottom-10">
                    <div className="max-width-320 stretch-full-sm padding-right-0 padding-right-sm-10">
                      <label className="form-label">
                        Payment Interval *
                      </label>
                      <Field
                        component={() => (
                          <Select
                            className="form-control padding-0"
                            classNamePrefix="react-select"
                            placeholder="Select contribution interval"
                            options={options}
                            value={
                              options
                                ? options.find(
                                  (option) =>
                                    option.value ===
                                    values.payment_frequency
                                )
                                : ""
                            }
                            onChange={(option) => {
                              setFieldValue(
                                "payment_frequency",
                                option.value
                              );
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="payment_frequency"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 padding-top-10 padding-bottom-10">
                    <div className="max-width-320 stretch-full-sm float-end padding-left-0 padding-left-sm-10">
                      <label className="form-label">Expected Receiving Date *</label>
                      <DatePickerField
                        name="start_date"
                        value={values.start_date}
                        minDate={new Date(new Date().getTime() + 4.32e+7)}
                        placeholderText="Start Date"
                        onChange={(event, selectedDate) => {
                          const currentDate = selectedDate;
                          setFieldValue("start_date", currentDate);
                        }}
                      />
                      <ErrorMessage
                        className="error-message"
                        name="start_date"
                        component="div"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 padding-top-10 padding-bottom-10">
                    <div className="max-width-320 stretch-full-sm padding-right-0 padding-right-sm-10">
                      <label className="form-label">
                        Preferred Monthly Interest Rate *
                      </label>
                      <div
                        className="d-flex">
                        <Field
                          className="form-control max-width-60"
                          type="number"
                          name="monthly_interest_rate"
                        />
                        <label
                          className="max-width-50 padding-left-10 padding-top-6">
                          %
                        </label>
                      </div>
                      <ErrorMessage
                        className="error-message"
                        name="monthly_interest_rate"
                        component="div"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="horizontal-line margin-top-20 margin-bottom-30" />

              <div className="d-flex justify-content-end margin-bottom-30">

                <button
                  className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-dark-blue white border-radius-12"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Generate
                </button>
              </div>
            </Form>
          )}
        </Formik>
      }

      {visibleComponent == "pool_details" && sampleMoneyPool &&
        <div>
          <NavigationBlock />

          <div className="horizontal-line margin-top-10 margin-bottom-10" />

          <div className="dark-blue font-size-16 ">
            <b>Summary</b>
          </div>

          <div className="padding-top-10 padding-bottom-10 font-size-13 font-size-sm-14">
            <div className="d-flex padding-bottom-10">
              <div className="col-4">
                <div className="font-size-12 grey">
                  Unit Amount
                </div>
                <div className="dark-blue" >
                  <FormattedMoney value={sampleMoneyPool.unit_payment} currency={sampleMoneyPool.currency} />
                </div>
              </div>
              <div className="col-4">
                <div className="font-size-12 grey">
                  No. of Hands
                </div>
                <div className="dark-blue">
                  {sampleMoneyPool.total_units}
                </div>
              </div>
              <div className="col-4">
                <div className="font-size-12 grey">
                  Interval
                </div>
                <div className="dark-blue">
                  {convertSnakeToStandardCasing(sampleMoneyPool.payment_frequency)}
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="col-4">
                <div className="font-size-12 grey">
                  Start Date
                </div>
                <div className="dark-blue">
                  {moment(sampleMoneyPool.start_date).format("DD MMM YYYY")}
                </div>
              </div>
              <div className="col-4">
                <div className="font-size-12 grey">
                  End Date
                </div>
                <div className="dark-blue">
                  {moment(sampleMoneyPool.end_date).format("DD MMM YYYY")}
                </div>
              </div>
              <div className="col-4">
                <div className="font-size-12 grey">
                  Monthly Interest
                </div>
                <div className="dark-blue">
                  {sampleMoneyPool.monthly_interest_rate}%
                </div>
              </div>
            </div>
          </div>

          <div className="horizontal-line margin-top-10 margin-bottom-10" />

          <div className="dark-blue font-size-16 ">
            <b>Collection Dates</b>
          </div>

          <div className="margin-top-10 border-1 border-color-light-grey border-radius-4">
            {sampleMoneyPool && sampleMoneyPool.collection_dates && sampleMoneyPool.collection_dates.length > 0 &&
              sampleMoneyPool.collection_dates.map((item, index) => {
                return <div
                  key={index}
                  className="padding-10 border-bottom-1 border-color-light-grey font-size-13 font-size-sm-14"
                >
                  <div className="d-flex padding-5 padding-top-0 ">
                    <span className="col-7">Position</span>
                    <b className="col-5"> #{index + 1} </b>
                  </div>
                  <div className="d-flex padding-5 ">
                    <span className="col-7">Collection Date</span>
                    <span className="col-5 blue"> {moment(item.collection_date).format("Do MMM YYYY")} </span>
                  </div>
                  <div className="d-flex padding-5 " >
                    <span className="col-7">Unit Amount</span>
                    <span className="col-5 green"> <FormattedMoney
                      value={item.unit_amount} currency={item.currency} /> </span>
                  </div>
                  <div className="d-flex padding-5 ">
                    <span className="col-7">Total Contribution</span>
                    <span className="col-5 dark-blue"> <FormattedMoney
                      value={item.total_contribution} currency={item.currency} /> </span>
                  </div>
                  <div className="d-flex padding-5 ">
                    <span className="col-7">Total Interest To Receive</span>
                    <span className="col-5 green"> <FormattedMoney
                      value={item.total_interest_amount_to_receive} currency={item.currency} /> </span>
                  </div>
                  <div className="d-flex padding-5 ">
                    <span className="col-7">Total Interest To Pay</span>
                    <span className="col-5 red"> <FormattedMoney
                      value={item.total_interest_amount_to_pay} currency={item.currency} /> </span>
                  </div>
                  <div className="d-flex padding-5  ">
                    <span className="col-7">Platform Commission</span>
                    <span className="col-5 blue"> <FormattedMoney
                      value={item.commission} currency={item.currency} /> </span>
                  </div>
                  <div className="d-flex padding-5 ">
                    <b className="col-7">Collection Payout</b>
                    <b className="col-5 green font-size-15"> <FormattedMoney
                      value={item.collection_date_payout} currency={item.currency} /> </b>
                  </div>

                  {(initialValues.monthly_interest_rate > 0 || initialValues.is_interest_paid == true) &&
                    <span className="col-7">
                      <button
                        type="button"
                        className="btn stretch-full-sm max-width-180 border-radius-6 font-size-13 font-size-sm-14 padding-top-sm-6 padding-bottom-sm-6 border-color-dark-blue dark-blue"
                        onClick={() => {
                          setExpectedInterests(item.list_of_payback_interests)
                          setIsExpectedInterestsVisible(true)
                        }}
                      >Interest Breakdown</button>
                    </span>
                  }
                </div>
              })
            }
          </div>

          <NavigationBlock />
        </div>
      }

      <Modal
        ariaHideApp={false}
        isOpen={isExpectedInterestsVisible}
        onRequestClose={() => setIsExpectedInterestsVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Expected Due Interests
          </h1>
          <i
            onClick={() => setIsExpectedInterestsVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="overflow-scroll-y padding-10 padding-top-10 padding-bottom-20 padding-left-md-40 padding-right-md-40"
          style={{ maxHeight: "80vh" }}
        >
          <ExpectedInterests
            list={expectedInterests}
          />
        </div>
      </Modal>
    </div >
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: !!state.auth.userToken
})

export default connect(mapStateToProps)(RequestToJoinMoneyPool);