import React, { useState, useEffect } from "react";

const ProgressBar = ({ status, height }) => {

    return (
        <div className="progress-bar relative" style={{height}}>
            
            {status ? <span className="progress-bar-moving" style={{ width: `${status}%` }}></span> : null}
            <div className="d-flex justify-content-center"><b className="absolute top-0 logo-blue" >{status.toFixed(0)}% subscribed</b></div>
        </div>
    );
};

export default ProgressBar;
