import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { writeTestimonial } from "../store/actions/testimonial";
import { NotificationManager } from "react-notifications";

const TestimonialForm = ({
  dispatch,
  handleClose
}) => {

  const [serviceOptions] = useState([
    { label: "Fundraiser", value: "FUNDRAISER" },
    { label: "Cyclic Money Pool", value: "MONEY_POOL" },
    { label: "Upkeep", value: "UPKEEP" }
  ])

  return (
    <div>
      {/* <div onClick={() => handleClose()} >Close</div>
      <h1 className="font-size-24">
        Let's hear your story!
      </h1> */}

      <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
        <h1 className="font-size-20 font-size-md-22 dark-blue">
          Let's hear your story!
        </h1>
        <i
          onClick={() => handleClose(false)}
          className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
        ></i>
      </div>

      <div className="horizontal-line margin-0" />

      <Formik
        initialValues={{
          service_type: "",
          comment: "",
        }}
        validationSchema={Yup.object({
          service_type: Yup.string().required("Service type is required"),
          comment: Yup.string().required("Comment is required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(writeTestimonial(values))
            .then((res) => {
              if (res.error) {
                NotificationManager.error(res.error.message, "Error!")
              } else {
                if (res.success == true) {
                  NotificationManager.success(res.message, "Success!")
                  handleClose()
                }
              }
            })
        }}
      >
        {({
          isSubmitting,
          values,
          setFieldValue
        }) => (
          <Form>
            <div
              style={{ maxHeight: "80vh" }}
              className="overflow-scroll-y padding-8 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
            >
              <div className="padding-top-10 padding-bottom-10">
                <Field
                  name="service_type"
                  component={() => {
                    return (
                      <Select
                        className="form-control padding-0"
                        classNamePrefix="react-select"
                        options={serviceOptions}
                        placeholder="Select service type"
                        value={
                          serviceOptions
                            ? serviceOptions.find(
                              (option) =>
                                option.value ===
                                values.service_type
                            )
                            : ""
                        }
                        onChange={(option) => {
                          setFieldValue("service_type", option.value)
                        }}
                      />
                    )
                  }}
                />
                <ErrorMessage
                  className="red font-size-12"
                  name="service_type"
                  component="div"
                />
              </div>

              <div className="padding-top-10 padding-bottom-10">
                <Field
                  className="form-control fixed-height-250"
                  component="textarea"
                  placeholder="Drop your comment here..."
                  type="text"
                  name="comment"
                />
                <ErrorMessage
                  className="red font-size-12"
                  name="comment"
                  component="div"
                />
              </div>

              <div className="row margin-bottom-0 margin-bottom-lg-14">
                <button
                  className="btn max-width-180 font-size-15 font-size-md-16 margin-top-20 margin-top-md-30 margin-bottom-20 padding-16 padding-md-20 border-radius-8 bg-dark-blue white"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect()(TestimonialForm);

