import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import Modal from "react-modal"
import moment from "moment";
import Truncate from "react-truncate";
import { NotificationManager } from "react-notifications";
import DisplayCollectionDates from "../../setup/fragments/DisplayCollectionDates";
import { convertSnakeToStandardCasing, getConfirmAlert } from "../../../../../utilities";
import {
  getPoolMemberMissedPayments,
  managerChangePoolAudience,
} from "../../../../../store/actions/moneyPool";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import PoolMemberMissedPayments from "../../setup/fragments/PoolMemberMissedPayments";
import CurrentWindowWidth from "../../../../CurrentWindowWidth";
import poolImage from "../../../../images/money_pool_setup.svg";

const MoneyPoolInformation = ({
  dispatch,
  pool,
  setPool,
  setIsPageLoading,
  isForPublic,
}) => {
  const [isMemberMissedPaymentsVisible, setIsMemberMissedPaymentsVisible] = useState(false);
  const [openedMemberMissedPayments, setOpenedMemberMissedPayments] = useState([]);
  const [coverImgHeight1, setCoverImgHeight1] = useState(0);

  let windowWidth = CurrentWindowWidth();

  const handleGetMemberMissedPayments = (memberEmail) => {
    dispatch(getPoolMemberMissedPayments({
      pool_id: pool.pool_description.id,
      email: memberEmail
    })).then(res => {
      if (res.error) {
        NotificationManager.error(res.error.message, "Error!")
      } else {
        if (res.success) {
          setOpenedMemberMissedPayments(res.data)
          setIsMemberMissedPaymentsVisible(true)
        }
      }
    })
  }

  const handleChangeAudience = (newAudience) => {
    setIsPageLoading([true, "...loading"])
    dispatch(managerChangePoolAudience({
      pool_id: pool.pool_description.id,
      new_audience: newAudience
    })).then(res => {
      if (res.error) {
        NotificationManager.error(res.error.message, "Error!")
      } else {
        if (res.success) {
          setPool(res.data)
        }
      }
    })
      .finally(() => {
        setIsPageLoading([false])
      })
  }

  const coverImgRef1 = useCallback(
    (node) => {
      if (node !== null) {
        setCoverImgHeight1(node.getBoundingClientRect().width * 0.65);
      }
    },
    [windowWidth]
  );

  return (
    <div>
      <div>
        <div className="d-flex justify-content-between margin-bottom-10 margin-top-10">
          <b className="font-size-17 font-size-sm-18 dark-blue">
            Pool Details
          </b>
          {pool.is_pool_manager && ["NOT_SUBMITTED", "SUBMITTED", "SUBSCRIBING"].includes(pool.pool_description.status) ?
            <button
              type="button"
              className={`btn fixed-width-100 margin-right-20 ${pool.pool_description.open_to_public ? 'bg-normal-green' : 'bg-red'}`}
              onClick={() => {
                const newAudience = pool.pool_description.open_to_public ? 'PRIVATE' : 'PUBLIC'
                getConfirmAlert({
                  title: "Confirm!",
                  message: `Are you sure you want to make this pool ${newAudience.toLowerCase()}?`,
                  onClickYes: () => {
                    handleChangeAudience(newAudience)
                  },
                  onClickNo: () => null,
                  variant: "danger"
                })
              }} >
              Make {pool.pool_description.open_to_public ? 'Private' : 'Public'}</button> : null}
        </div>

        <div className="d-flex box-shadow border-radius-4 bg-white padding-10 margin-bottom-30 margin-top-20">
          <div className="stretch-full padding-right-8 padding-top-20 padding-bottom-8 padding-bottom-sm-20">
            <div className="d-flex align-items-center">
              <div className="relative">
                <span
                  className={`absolute top-2 opacity-90 font-size-12 padding-2 white 
                    ${pool.pool_description.open_to_public ? 'bg-red' : 'bg-normal-green'}`}>
                  {pool.pool_description.open_to_public ? 'Public' : 'Private'} </span>
                <div
                  ref={coverImgRef1}
                  className="fixed-width-90 background-image border-radius-4"
                  style={{
                    backgroundImage: `url(${pool.pool_description.pool_picture ? pool.pool_description.pool_picture : poolImage})`,
                    height: coverImgHeight1 + "px",
                    backgroundSize: 'contain'
                  }}>
                </div>
              </div>
              <div>
                <div className="font-size-18 dark-blue font-weight-bold padding-left-2 padding-right-2 padding-bottom-8">
                  <Truncate lines={2}>
                    {pool.pool_description.title}
                  </Truncate>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="row padding-top-8 padding-bottom-8">
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    ID
                  </p>
                  <div>
                    <div className="font-size-15 dark-blue stretch-full">
                      #{pool.pool_description.id}
                    </div>
                  </div>
                </div>
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    Frequency
                  </p>
                  <div>
                    <p className="font-size-14 font-size-sm-16 dark-blue">
                      {convertSnakeToStandardCasing(pool.pool_description.payment_frequency)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row padding-top-8 padding-bottom-8">
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    Total Hands
                  </p>
                  <p className="font-size-14 font-size-sm-16 dark-blue">
                    {pool.pool_description.total_units}
                  </p>
                </div>
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    Per Hand
                  </p>
                  <p className="font-size-14 font-size-sm-16 normal-green">
                    <FormattedMoney
                      value={pool.pool_description.unit_payment}
                      currency={pool.pool_description.unit_payment_currency}
                    />
                  </p>
                </div>
              </div>
              <div className="row padding-top-8 padding-bottom-8">
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    Monthly Interest
                  </p>
                  <p className="font-size-14 font-size-sm-16 dark-blue">
                    {pool.pool_description.monthly_interest_rate}%
                  </p>
                </div>
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    Total Per Round
                  </p>
                  <p className="font-size-14 font-size-sm-16 normal-green">
                    <FormattedMoney
                      value={pool.pool_description.unit_payment * pool.pool_description.total_units}
                      currency={pool.pool_description.unit_payment_currency}
                    />
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="row padding-top-8 padding-bottom-8">
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    Start Date
                  </p>
                  <p className="font-size-14 font-size-sm-16 dark-blue">
                    {moment(pool.pool_description.start_date).format("DD MMM YYYY")}
                  </p>
                </div>
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    End Date
                  </p>
                  <p className="font-size-14 font-size-sm-16 dark-blue">
                    {moment(pool.pool_description.end_date).format("DD MMM YYYY")}
                  </p>
                </div>
              </div>
              <div className="row padding-top-8 padding-bottom-8">
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    Created Date
                  </p>
                  <p className="font-size-14 font-size-sm-16 dark-blue">
                    {moment(pool.pool_description.created_at).format("DD MMM YYYY")}
                  </p>
                </div>
                <div className="col-6 padding-left-2 padding-right-2">
                  <div className="font-size-13 line-height-13 grey">
                    Status
                  </div>
                  <div className="font-size-14 font-size-sm-16 dark-blue">
                    <div className="max-width-100" >
                      {convertSnakeToStandardCasing(pool.pool_description.status)}
                    </div>

                  </div>
                </div>
              </div>
              <div className="row padding-top-8 padding-bottom-8">
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    Next Due Date
                  </p>
                  <p className="font-size-14 font-size-sm-16 dark-blue">
                    {moment(pool.pool_description.next_due_date).isSame(new Date(), "day") ?
                      <span>Today</span>
                      :
                      <span>{moment(pool.pool_description.next_due_date).format("DD MMM YYYY")}</span>
                    }
                  </p>
                </div>
                <div className="col-6 padding-left-2 padding-right-2">
                  <p className="font-size-13 line-height-13 grey">
                    Subscribed
                  </p>
                  <p className="font-size-14 font-size-sm-16 dark-blue">
                    {pool.pool_description.picked_dates_percent}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="horizontal-line margin-top-24 margin-bottom-20" />
      </div>
      <div>
        <div className="margin-bottom-10">
          <b className="font-size-17 font-size-sm-18 dark-blue">
            Payout Dates
          </b>
        </div>

        <DisplayCollectionDates
          poolId={pool.pool_description.id}
          areYouOptedIn={pool.pool_description.are_you_opted_in}
          isDateChangeable={pool.is_date_changeable}
          collectionDates={pool.collection_dates}
          setPool={setPool}
          setIsPageLoading={setIsPageLoading}
          isForPublic={isForPublic}
        />
      </div>
      {/* 
      {isSwapPossible() && (
        <div>
          <h4>Collection Date Swap</h4>
          <div>
            <button
              type="button"
              onClick={() => setIsSwapDateModalVisible(true)}
            >
              Request
            </button>
          </div>
        </div>
      )} */}

      <Modal
        ariaHideApp={false}
        isOpen={isMemberMissedPaymentsVisible}
        onRequestClose={() => {
          setIsMemberMissedPaymentsVisible(false);
        }}
        className="modal max-width-720 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-8 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue text-center">
            Missed Payments
          </h1>
          <i
            onClick={() => {
              setIsMemberMissedPaymentsVisible(false);
            }}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-8">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-0 padding-top-sm-10 padding-bottom-sm-20 padding-left-md-30 padding-right-md-30"
          >
            {openedMemberMissedPayments && openedMemberMissedPayments.length > 0 &&
              <div className="font-size-16 padding-bottom-6">
                <span className="grey">Name</span>
                <span className="dark-blue"> {openedMemberMissedPayments[0].name}</span>
              </div>
            }
            <PoolMemberMissedPayments
              missedPayments={openedMemberMissedPayments}
              pool={pool}
              setPool={setPool}
              handleGetMemberMissedPayments={handleGetMemberMissedPayments}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MoneyPoolInformation);