import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import moment from "moment";
import { sendDateSwapRequest } from "../../../../../store/actions/moneyPool";

const RequestDateSwap = ({
  dispatch,
  user,
  poolId,
  collectionDates,
  setIsPageLoading,
  handleClose,
}) => {
  const [datesPickedByOthers, setDatesPickedByOthers] = useState([]);
  const [datesPickedByYou, setDatesPickedByYou] = useState([]);

  useEffect(() => {
    const filteredDatesPickedByOthers = collectionDates.filter(
      (dateObj) => dateObj.user_id != user.id && dateObj.is_picked
    );
    const datesPickedByOthers = filteredDatesPickedByOthers.map((dateObj) => {
      return {
        value: dateObj,
        label:
          moment(dateObj.date).format("DD-MMM-YYYY") +
          " picked by " +
          dateObj.full_name,
      };
    });
    setDatesPickedByOthers(datesPickedByOthers);

    const filteredDatesPickedByYou = collectionDates.filter(
      (dateObj) => dateObj.user_id == user.id && dateObj.is_picked
    );
    const datesPickedByYou = filteredDatesPickedByYou.map((dateObj) => {
      return {
        value: dateObj,
        label: moment(dateObj.date).format("DD-MMM-YYYY"),
      };
    });
    setDatesPickedByYou(datesPickedByYou);
  }, []);

  return (
    <div>
      <div>
        <Formik
          initialValues={{
            pool_id: poolId,
            date_requester_does_not_want: "",
            date_requester_wants: "",
            interested_date_owner_id: "",
          }}
          validationSchema={Yup.object({
            date_requester_does_not_want: Yup.string().required(
              "Date is required"
            ),
            date_requester_wants: Yup.string().required("Date is required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setIsPageLoading([true, "Loading..."]);
            dispatch(sendDateSwapRequest(values)).then((res) => {
              setIsPageLoading([false]);
              setSubmitting(false);

              if (res.error) {
                alert(res.error.message);
              } else {
                if (res.success == true) {
                  handleClose();
                  alert(res.message);
                }
              }
            });
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <h2 className="input-group">
                Request To Swap a Date with Another Member
              </h2>
              <div>
                <label className="input-label">Select your picked date:</label>
                <Field
                  component={() => (
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select date"
                      options={datesPickedByYou}
                      value={
                        datesPickedByYou
                          ? datesPickedByYou.find(
                              (option) =>
                                option.value.date ==
                                values.date_requester_does_not_want
                            )
                          : ""
                      }
                      onChange={(option) => {
                        setFieldValue(
                          "date_requester_does_not_want",
                          option.value.date
                        );
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  className="error-message"
                  name="date_requester_does_not_want"
                  component="div"
                />
              </div>
              <div>
                <label className="input-label">
                  Select the preferred date:
                </label>
                <Field
                  component={() => (
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select date"
                      options={datesPickedByOthers}
                      value={
                        datesPickedByOthers
                          ? datesPickedByOthers.find(
                              (option) =>
                                option.value.date == values.date_requester_wants
                            )
                          : ""
                      }
                      onChange={(option) => {
                        setFieldValue(
                          "date_requester_wants",
                          option.value.date
                        );
                        setFieldValue(
                          "interested_date_owner_id",
                          option.value.user_id
                        );
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  className="error-message"
                  name="date_requester_wants"
                  component="div"
                />
              </div>

              <button type="submit" disabled={isSubmitting}>
                Send Request
              </button>
              <div className="horizontal-line" />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(RequestDateSwap);
