import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import MoneyInputField from "../../../utilityComponents/MoneyInputField";
import { changePoolGuaranteeLimit } from "../../../../store/actions/moneyPool";

const ChangePoolGuaranteeLimit = ({
  setIsPageLoading,
  dispatch,
  handleClose,
  poolGuarantee,
  setSpecificPoolGuarantee,
  handleReloadGuarantees
}) => {
  const [error, setError] = useState("");

  return (
    <div>
      <div className="row">
        <Formik
          initialValues={{
            guarantee_id: poolGuarantee.id,
            new_guarantee_limit: poolGuarantee.guarantee_limit,
          }}

          validationSchema={Yup.object({
            new_guarantee_limit: Yup.number()
              .typeError("Must be a number")
              .positive("Must be a positive number")
              .integer("Must be a whole number")
              .required("New guarantee limit is required")
              .nullable(),
          })}

          onSubmit={(values, { setSubmitting }) => {
            setIsPageLoading([true, "Changing..."])
            dispatch(changePoolGuaranteeLimit(values))
              .then(res => {
                setSubmitting(false)
                setIsPageLoading([false])
                if (res.error) {
                  setError(res.error.message)
                } else {
                  if (res.success) {
                    NotificationManager.success(res.message, "Success!")
                    setSpecificPoolGuarantee(res.data)
                    handleReloadGuarantees()
                    handleClose()
                  }
                }
              })
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            isSubmitting,
            values,
          }) => (
            <Form>
              <div className="padding-left-10 padding-right-10">
                <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                  <label className="form-label">New Guarantee Limit</label>
                  <div>
                    <MoneyInputField
                      className="form-control d-flex padding-8"
                      value={values.new_guarantee_limit}
                      currency={poolGuarantee.guarantee_limit_currency}
                      onChange={(value) => {
                        setFieldValue("new_guarantee_limit", value)
                      }}
                      placeholder="Enter new guarantee limit"
                    />
                  </div>
                  <ErrorMessage
                    className="error-message"
                    name="new_guarantee_limit"
                    component="div"
                  />
                  <div>
                    {error && <span className="error-message">{error}</span>}
                  </div>
                </div>
                <button
                  className="btn bg-dark-blue white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <b>Save</b>
                </button>
              </div>

            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ChangePoolGuaranteeLimit);
