import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { NotificationManager } from 'react-notifications';
import Select from "react-select";
import {
  submitSecurityAnswers
} from "../../../../store/actions/auth";

const SecurityQuestions = ({
  user,
  dispatch,
  handleClose,
  onSaveSuccessful
}) => {
  const [securityQuestionsOptions, setSecurityQuestionsOptions] = useState([]);

  const questionsFilteredOutPickedQuestion = (pickedQuestions, questionId) => {
    const theOtherQUestion = pickedQuestions.find(e => e.question_id != questionId)

    let filteredQUestions
    if (theOtherQUestion) {
      filteredQUestions = securityQuestionsOptions.filter(item => item.value != theOtherQUestion.question_id)
    } else {
      filteredQUestions = securityQuestionsOptions
    }

    return filteredQUestions
  }

  useEffect(() => {
    const securityQuestions = user.general_security_questions.map(obj => {
      return {
        value: obj.id,
        label: obj.question
      }
    })
    setSecurityQuestionsOptions(securityQuestions)
  }, [])

  return (
    <div>
      <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
        <h1 className="font-size-20 font-size-md-22 dark-blue">
          Security Questions
        </h1>
        <i onClick={() => handleClose()}
          className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"></i>
      </div>

      <div className="horizontal-line margin-0" />

      <Formik
        initialValues={{
          question_answer_List: [
            {
              question_id: "",
              answer: ""
            },
            {
              question_id: "",
              answer: ""
            }
          ]
        }}
        validationSchema={
          Yup.object().shape({
            question_answer_List: Yup.array()
              .of(
                Yup.object().shape({
                  question_id: Yup.string().required('Question is required'), // these constraints take precedence
                  answer: Yup.string().required('Question is required'), // these constraints take precedence
                })
              )
          })
        }

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          const questionIdsArr = values.question_answer_List.map(item => item.question_id)
          if ((new Set(questionIdsArr)).size !== questionIdsArr.length) {
            // Ensure no duplicated questions
            alert("The questions must be different")
          } else {
            dispatch(submitSecurityAnswers(values))
              .then(res => {
                if (res.error) {
                  NotificationManager.error(res.error.message, "Error!")
                } else {
                  if (res.success == true) {
                    NotificationManager.success(res.message, "Success!")
                    onSaveSuccessful()
                  }
                }
              })
          }
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <div
              style={{ maxHeight: "80vh" }}
              className="overflow-scroll-y padding-10 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
            >
              <div>
                <h3>Choose your security questions</h3>
                <p className="padding-top-10">In the event that you lose access to your account or your authentication device, these questions will help you reset
                  your account credentials
                </p>
              </div>
              <FieldArray
                name="question_answer_List"
                render={() => (
                  <div>
                    {values.question_answer_List && values.question_answer_List.length > 0 && (
                      values.question_answer_List.map((obj, index) => (
                        <div key={index} className="padding-top-20 padding-bottom-20">
                          <div className="box-shadow align-items-center border-radius-6 padding-8">
                            <div className="padding-8">
                              <label>Question {index + 1} </label>
                              <Field
                                component={() => (
                                  <Select
                                    className="form-control padding-0"
                                    classNamePrefix="react-select"
                                    placeholder="Select your question"
                                    options={
                                      questionsFilteredOutPickedQuestion(
                                        values.question_answer_List,
                                        values.question_answer_List[index].question_id)
                                    }
                                    value={
                                      securityQuestionsOptions
                                        ? securityQuestionsOptions.find(
                                          (option) => {
                                            return option.value === values.question_answer_List[index].question_id
                                          })
                                        : ""
                                    }
                                    onChange={(option) => {
                                      setFieldValue(`question_answer_List[${index}].question_id`, option.value);
                                    }}
                                  />
                                )}
                              />
                              <ErrorMessage
                                className="error-message"
                                name={`question_answer_List[${index}].question_id`}
                                component="div"
                              />
                            </div>

                            <div className="padding-8">
                              <label>Answer {index + 1}</label>
                              <Field
                                className="form-control"
                                type="text"
                                name={`question_answer_List[${index}].answer`}
                                placeholder="Enter your answer"
                                onChange={(event) => {
                                  setFieldValue(`question_answer_List[${index}].answer`, event.target.value)
                                }}
                              />
                              <ErrorMessage
                                className="error-message"
                                name={`question_answer_List[${index}].answer`}
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                )}
              />

              <button className="btn bg-dark-blue white font-size-16 margin-top-20 margin-bottom-20 padding-10 max-width-180">
                <b>Submit</b>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(SecurityQuestions);
