import React, { useState } from "react";
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";

const LoadingPage = (props) => {
  const isPageLoading = props.isPageLoading[0];
  const loadingLabel = props.isPageLoading[1];

  return (
    <div>
      {isPageLoading &&
        <div
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(32, 28, 48, 0.5)",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            position: "fixed",
            zIndex: 100000,
          }}
        >
          <div>
            <div style={{ textAlign: "center" }}>
              <ScaleLoader
                // size={50}
                margin={4}
                height={50}
                color={"darkorange"}
              />
            </div>
            {loadingLabel &&
              <div style={{ textAlign: "center", marginTop: "-8px", color: "white" }}>
                {loadingLabel}
              </div>}
          </div>
        </div>}
    </div>
  );
}

export default LoadingPage;