import React from "react";
import NumberFormat from 'react-number-format';
import { getCurrencySymbol } from "../../utilities";

const MoneyInputField = ({
  value,
  onChange,
  placeholder,
  currency,
  ...props
}) => {
  return (
    <div
      {...props}
    >
      <div className="d-flex stretch-full">
        <span className="padding-left-2 padding-bottom-2">
          {getCurrencySymbol(currency ? currency : "NGN")}
        </span>
        <NumberFormat
          className="stretch-full padding-0 padding-bottom-4 padding-left-10 focus-none bg-transparent"
          placeholder={placeholder}
          displayType="input"
          value={value}
          onValueChange={
            (values) => {
              if (values.floatValue == undefined) {
                onChange("")
              } else {
                onChange(values.floatValue)
              }
            }
          }
          thousandSeparator={true}
          decimalScale={0}
        />
      </div>
    </div>
  );
};

export default MoneyInputField
