import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getCurrencySymbol, numberWithCommas } from "../../../utilities";
import MoneyInputField from "../../utilityComponents/MoneyInputField"
import PouchMoneyPaymentDetails from "../../payments/PouchMoneyPaymentDetails";

const TopUpWallet = ({
  user,
}) => {
  const [isPaymentDetailsVisible, setIsPaymentDetailsVisible] = useState(false);

  return (
    <div>
      <Formik
        initialValues={{
          amount: "",
          currency: "NGN",
          email: user.email,
        }}
        validationSchema={Yup.object({
          amount: Yup.number()
            .typeError("Amount must be a number")
            .positive("Amount must be a positive number")
            .integer("Amount must be a whole number")
            .required("Amount is required")
            .nullable(),
          currency: Yup.string().required("Currency is required"),
        })}
        onSubmit={() => {
          setIsPaymentDetailsVisible(true)
        }}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form>
            {
              isPaymentDetailsVisible ?
                <div>
                  <i className="fa fa-arrow-left font-size-24 cursor-pointer" onClick={() => { setIsPaymentDetailsVisible(false) }}></i>
                  <PouchMoneyPaymentDetails amount={values.amount} bankDetails={user.bank_payment_details} />
                </div>
                :
                <div>

                  <div className="padding-top-12 padding-bottom-12">
                    <label className="form-label font-size-15">Amount</label>
                    <div>
                      <MoneyInputField
                        className="form-control padding-10 border-color-dark-blue fixed-height-40"
                        currency="NGN"
                        onChange={(amount) => {
                          setFieldValue("amount", amount)
                        }}
                        value={values.amount}
                        placeholder="Enter amount"
                      />
                    </div>
                    <ErrorMessage
                      className="error-message"
                      name="amount"
                      component="div"
                    />
                    {values.currency == "NGN" &&
                      values.amount >= 10000 &&
                      <span className="red">Note: Your wallet will be credited with {getCurrencySymbol(values.currency)}{numberWithCommas(values.amount - 50)} due to a ₦50 stamp duty.</span>
                    }
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      className="btn margin-top-24 margin-bottom-20 padding-12 bg-dark-blue white max-width-250 font-size-17 border-radius-8"
                      type="button"
                      onClick={handleSubmit}
                    >
                      <b>Proceed</b>
                    </button>
                  </div>
                </div>
            }
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state,) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(TopUpWallet);
