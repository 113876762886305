import React, { useEffect, useState, useRef } from "react";
import FormattedMoney from "../../utilityComponents/FormattedMoney"

const AllBalances = ({ balances }) => {

    return (
        <div>
            {balances.map((bal, index) => {
                return (
                    <div key={index} className=" ">
                        <b className="font-size-17 dark-blue">
                            {bal.currency} Wallet
                        </b>

                        <div className="d-flex justify-content-between">
                            <div>
                                <label className="dark-blue font-size-14 font-size-sm-15">Book Balance</label>
                                <b className="font-size-14 fundraiser-color">
                                    <FormattedMoney
                                        value={bal.book_balance}
                                        currency={bal.currency}
                                        decimalScale={2}
                                    />
                                </b>
                            </div>
                            <div className="padding-right-20 padding-right-sm-100">
                                <label className="dark-blue font-size-14 font-size-sm-15">Available Balance</label>
                                <b className="font-size-14 normal-green">
                                    <FormattedMoney
                                        value={bal.available_balance}
                                        currency={bal.currency}
                                        decimalScale={2}
                                    />
                                </b>
                            </div>
                        </div>

                        {balances.length > 1 ?
                            <div className="horizontal-line margin-top-20 margin-bottom-20" />
                            :
                            <div className="margin-bottom-10" />}

                    </div>
                )
            })}
        </div>
    );
};

export default AllBalances;
