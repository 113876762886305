import React, { useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { NotificationManager } from 'react-notifications';
import { updateUser, getUser, signOut } from "../../store/actions/auth"
import { getConfirmAlert } from "../../utilities";
import PhoneInputField from "../utilityComponents/PhoneInputField";

const AddPhoneNumber = ({ dispatch, user, handleClose }) => {
  const [countryCode] = useState(user.country);

  return (
    <div>
      <Formik
        initialValues={{
          phoneNumber: "",
          firstName: user.first_name,
          lastName: user.last_name
        }}
        validationSchema={Yup.object({
          phoneNumber: Yup.string().required(
            "Phone number is required"
          ).nullable(),
          firstName: Yup.string().required(
            "First name is required"
          ).nullable(),
          lastName: Yup.string().required(
            "Last Name is required"
          ).nullable(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          dispatch(updateUser({
            ...user,
            phone_number: values.phoneNumber,
            first_name: values.firstName,
            last_name: values.lastName
          }))
            .then((res) => {
              dispatch(getUser())
                .then(() => {
                  handleClose()
                })

              if (res.error) {
                NotificationManager.error(res.error.message, 'Error!')
              } else {
                NotificationManager.success("Your profile has been successfully updated", 'Success!')
              }
            })
        }}
      >
        {({
          setFieldValue,
          isSubmitting,
          values,
        }) => (
          <Form>
            <div className="row">
              <div className="col-sm-6 padding-right-0 padding-right-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">First Name</label>
                <Field
                   className="form-control fixed-height-40 padding-4 padding-left-10 padding-right-10"
                  placeholder="Enter first name"
                  name="firstName"
                />
                <ErrorMessage
                  className="error-message"
                  name="firstName"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                <label className="form-label">Last Name</label>
                <Field
                   className="form-control fixed-height-40 padding-4 padding-left-10 padding-right-10"
                  placeholder="Enter last name"
                  name="lastName"
                />
                <ErrorMessage
                  className="error-message"
                  name="lastName"
                  component="div"
                />
              </div>
            </div>
            <div className="margin-bottom-8">
              <div className="form-control fixed-height-40 padding-4 padding-left-10 padding-right-10">
                {/* <PhoneInput
                  className="input padding-top-10 padding-top-md-12 padding-bottom-10 padding-bottom-md-12 padding-left-12 padding-left-md-24 padding-right-10 font-size-16 border-0 focus-none bg-transparent"
                  placeholder="Enter phone number"
                  value={values.phoneNumber}
                  name="phoneNumber"
                  onChange={(e) => {
                    setFieldValue("phoneNumber", e);
                  }}
                  defaultCountry={countryCode}
                /> */}

                <PhoneInputField
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={setFieldValue}
                  placeholderText="Enter phone number"
                  type="number"
                  defaultCountry={countryCode}
                />
              </div>
              <ErrorMessage
                className="red font-size-12"
                name="phoneNumber"
                component="div"
              />
            </div>
            <button
              className="btn max-width-140 font-size-15 font-size-md-17 margin-top-10 margin-top-md-20 margin-bottom-10 margin-bottom-md-20 margin-right-10 padding-16 border-radius-14 bg-dark-blue white"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </button>
            <button
              className="btn max-width-140 font-size-15 font-size-md-17 margin-top-10 margin-top-md-20 margin-bottom-10 margin-bottom-md-20 padding-16 border-radius-14 bg-dark-blue white"
              type="submit"
              onClick={() => {
                getConfirmAlert({
                  title: "Confirm!",
                  message: "Are you sure you want to logout?",
                  onClickYes: () => {
                    dispatch(signOut())
                      .then(() => {
                        handleClose()
                      })
                  },
                  onClickNo: () => null
                })
              }}
            >
              Log Out
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(AddPhoneNumber);
