import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useSpring, animated } from 'react-spring'
import { Link, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import countryList from "react-select-country-list";
import 'react-phone-number-input/style.css';
import PhoneInput from "react-phone-number-input";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import SocialLogin from "./SocialLogin";
import { getCurrencyList, register, socialMediaSignIn } from "../../store/actions/auth";
import userIcon from "../images/user_icon_darkblue.svg";
import emailIcon from "../images/email_icon_darkblue.svg";
import lockIcon from "../images/lock_icon_darkblue.svg";
import signUpImageBg from "../images/sign_up_image_bg.svg";
import MultiFactorAuth from "./MultiFactorAuth";

const Register = (props) => {

  const search = useLocation().search;

  const [error, setError] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("NG");
  // const [localeCountry, setLocaleCountry] = useState("Nigeria");
  const [localeCurrency, setLocaleCurrency] = useState("NGN");
  const [referrer, setReferrer] = useState(new URLSearchParams(search).get('referrer'));
  const [email] = useState(new URLSearchParams(search).get('email'));
  const [socialLoginError, setSocialLoginError] = useState("");
  const [isUsingSocialAuthentication, setIsUsingSocialAuthentication] = useState(false);
  const [socialMediaType, setSocialMediaType] = useState("");
  const [isMfaVisible, setIsMfaVisible] = useState(false);
  const [enabledMfas, setEnabledMfas] = useState([]);
  const [socialAccessToken, setSocialAccessToken] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
  const [appleAuthCredentials, setAppleAuthCredentials] = useState({});

  const handleSocialLogin = (values) => {
    setSocialLoginError("")

    setSocialAccessToken(values.access_token)

    if (values.socialMediaType == "apple") {
      setAppleAuthCredentials(values)
    }

    props.dispatch(socialMediaSignIn(values))
      .then((res) => {
        if (res.error) {
          if (res.error.loginError) {
            setSocialLoginError(res.error.loginError);
          }
          else if (res.error.mfaRequired) {
            setEnabledMfas(res.error.mfaRequired.data.enabled_mfas)

            setIsMfaVisible(true)
          } else if (res.error.invalidOtp) {
            NotificationManager.error(res.error.invalidOtp.message, "Error!")
          }
        } else {
          setIsMfaVisible(false)
        }
      })
      .catch(err => {
        setSocialLoginError(err.error)
      })
  }

  const fadeStyles = useSpring({
    from: { opacity: 0 },
    config: { duration: 2000 },
    loop: {
      opacity: 1,
    },
  })

  const slideStyles = useSpring({
    from: { x: -100, opacity: 0 },
    config: { duration: 1000 },
    loop: {
      x: 0, opacity: 1,
    },
  })

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      background: "transparent",
      border: "none",
      // You can also use state.isFocused to conditionally style based on the focus state
    }),
  };

  useEffect(() => {
    const options = countryList().getData();
    setCountries(options);

    props.dispatch(getCurrencyList()).then((res) => {
      if (res.error) {
        return;
      } else {
        if (res.success == true) {
          const currencies = res.data.map((cur) => {
            return {
              label: cur,
              value: cur,
            };
          });
          setCurrencies(currencies);
        }
      }
    });

    // Get default locale
    const locale = localStorage.getItem("locale");
    if (locale && locale != "undefined") {
      const parsedLocale = JSON.parse(locale);
      if (parsedLocale.country_code) {
        setCountryCode(parsedLocale.country_code);
      }
      if (parsedLocale.currency) {
        const localeCurrency = parsedLocale.currency;
        if (currencies.includes(localeCurrency)) {
          setLocaleCurrency(localeCurrency);
        }
      }
    }
  }, [currencies.length])

  useEffect(() => {
    document.title = "Register"

    if (referrer) {
      // Save the referrer code in the local storage
      localStorage.setItem("referrerCode", referrer)
    } else {
      // Check if there is a referrer code in the local storage
      const referrerCode = localStorage.getItem("referrerCode")
      setReferrer(referrerCode)
    }
  }, [])

  return (
    <div className="container">
      <div className="content-container">
        <div className="padding-top-30 padding-top-md-60 padding-bottom-30 padding-bottom-md-80 padding-right-0 padding-right-lg-80">
          <div className="row">
            <div className="col-6 d-block-hidden-sm">
              <animated.img className="stretch-full" style={slideStyles} src={signUpImageBg} />
            </div>
            <animated.div className="col-6 stretch-full-sm" style={fadeStyles}>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email,
                  phoneNumber: "",
                  country: "",
                  companyName: "",
                  preferredCurrency: "",
                  password: "",
                  repeatPassword: "",
                  referrer_code: referrer
                }}
                validationSchema={Yup.object({
                  firstName: Yup.string().required("First name is required"),
                  lastName: Yup.string().required("Last name is required"),
                  email: Yup.string()
                    .required("Email is required")
                    .email("Must be an email").nullable(),
                  phoneNumber: Yup.string().required(
                    "Phone number is required"
                  ),
                  country: Yup.string().required("Country is required"),
                  password: Yup.string()
                    .min(8, "Password must be at least 8 characters")
                    .required("Password is required"),
                  repeatPassword: Yup.string()
                    .required("Repeat password is required")
                    .oneOf(
                      [Yup.ref("password"), null],
                      "Passwords don't match!"
                    )
                    .matches(),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  props.dispatch(register({
                    ...values,
                    email: values.email.toLowerCase()
                  })).then((res) => {
                    setSubmitting(false);
                    if (res.error) {
                      setError(res.error);
                    } else {
                      if (res.message) {
                        props.history.push("/status/verification-email-sent");
                      }
                    }
                  });
                }}
              >
                {({
                  setFieldValue,
                  isSubmitting,
                  values,
                }) => (
                  <Form className="d-flex flex-column align-items-center">
                    <div>
                      <h1 className="font-size-21 font-size-md-32 margin-bottom-10 margin-bottom-sm-20">
                        Create an Account
                      </h1>
                    </div>
                    <div className="max-width-350">
                      <SocialLogin
                        referrer_code={referrer}
                        socialLoginError={socialLoginError}
                        setSocialLoginError={setSocialLoginError}
                        socialAccessToken={socialAccessToken}
                        setSocialAccessToken={setSocialAccessToken}
                        setSocialMediaType={setSocialMediaType}
                        setEnabledMfas={setEnabledMfas}
                        setIsMfaVisible={setIsMfaVisible}
                        handleSocialLogin={handleSocialLogin}
                        setIsUsingSocialAuthentication={setIsUsingSocialAuthentication}
                      />
                    </div>

                    <p className="font-size-16 padding-top-10 padding-top-md-20 padding-bottom-10 padding-bottom-md-20 grey">
                      or use your email
                    </p>

                    <div className="max-width-550 padding-top-10 padding-bottom-10">
                      <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-dark-blue bg-pale-navy">
                        <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                          <img width="24" src={userIcon} />
                        </div>
                        <Field
                          className="input padding-top-14 padding-top-md-16 padding-bottom-14 padding-bottom-md-16 padding-left-0 padding-right-10 font-size-15 font-size-lg-17 border-0 focus-none bg-transparent"
                          placeholder="First Name"
                          name="firstName"
                          onBlur={() => {
                            // Just to ensure the initial country and preferredCurrency values are
                            // entered to Formik
                            setFieldValue("country", countryCode);
                            setFieldValue("preferredCurrency", localeCurrency);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        className="red font-size-12"
                        name="firstName"
                        component="div"
                      />
                    </div>

                    <div className="max-width-550 padding-top-10 padding-bottom-10">
                      <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-dark-blue bg-pale-navy">
                        <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                          <img width="24" src={userIcon} />
                        </div>
                        <Field
                          className="input padding-top-14 padding-top-md-16 padding-bottom-14 padding-bottom-md-16 padding-left-0 padding-right-10 font-size-15 font-size-lg-17 border-0 focus-none bg-transparent"
                          placeholder="Last Name"
                          name="lastName"
                        />
                      </div>
                      <ErrorMessage
                        className="red font-size-12"
                        name="lastName"
                        component="div"
                      />
                    </div>

                    <div className="max-width-550 padding-top-10 padding-bottom-10">
                      <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-dark-blue bg-pale-navy">
                        <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                          <img width="24" src={emailIcon} />
                        </div>
                        <Field
                          className="input padding-top-14 padding-top-md-16 padding-bottom-14 padding-bottom-md-16 padding-left-0 padding-right-10 font-size-15 font-size-lg-17 border-0 focus-none bg-transparent"
                          placeholder="Email"
                          type="email"
                          name="email"
                        />
                      </div>
                      <ErrorMessage
                        className="red font-size-12"
                        name="email"
                        component="div"
                      />
                    </div>

                    <div className="max-width-550 padding-top-10 padding-bottom-10">
                      <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-dark-blue bg-pale-navy">
                        <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                          <i
                            className="fa fa-industry font-size-20 dark-blue"
                          ></i>
                        </div>
                        <Field
                          className="input padding-top-14 padding-top-md-16 padding-bottom-14 padding-bottom-md-16 padding-left-0 padding-right-10 font-size-15 font-size-lg-17 border-0 focus-none bg-transparent"
                          placeholder="Company Name"
                          name="companyName"
                        />
                      </div>
                    </div>

                    <div className="max-width-550 padding-top-10 padding-bottom-10">
                      <div className="d-flex align-items-center border-radius-10 border-1 border-color-dark-blue bg-pale-navy">
                        <PhoneInput
                          className="react-phone-number-input padding-top-10 padding-top-md-12 padding-bottom-10 padding-bottom-md-12 padding-left-24 padding-right-10 border-0 focus-none bg-transparent"                  
                          placeholder="Phone number"
                          value={values.phone_number}
                          name="phoneNumber"
                          onChange={(e) => {
                            setFieldValue("phoneNumber", e);
                          }}
                          defaultCountry={countryCode}
                        />
                      </div>
                      <ErrorMessage
                        className="red font-size-12"
                        name="phone_number"
                        component="div"
                      />
                    </div>

                    <div className="max-width-550 padding-top-10 padding-bottom-10">
                      <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-dark-blue bg-pale-navy">
                        <div className="fixed-width-70 dark-blue d-flex align-items-center justify-content-center padding-left-10">
                          <i className="material-icons font-size-32">
                            &#xe55f;
                          </i>
                        </div>
                        <Field
                          component={() => (
                            <Select
                              styles={customStyles}
                              className="input font-size-15 font-size-lg-17 padding-top-4 padding-top-md-6 padding-bottom-4 padding-bottom-md-6 padding-left-0 padding-right-10 bg-transparent"
                              classNamePrefix="react-select"
                              placeholder="Select country"
                              options={countries}
                              value={
                                countries
                                  ? countries.find(
                                    (option) => option.value === countryCode
                                  )
                                  : ""
                              }
                              onChange={(option) => {
                                // alert(option.value)
                                setCountryCode(option.value)
                                // setLocaleCountry(option.value);
                                setFieldValue("country", option.value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <ErrorMessage
                        className="red font-size-12"
                        name="country"
                        component="div"
                      />
                    </div>

                    <div className="max-width-550 padding-top-10 padding-bottom-10">
                      <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-dark-blue bg-pale-navy">
                        <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                          <img width="24" src={lockIcon} />
                        </div>
                        <Field
                          className="input padding-top-14 padding-top-md-16 padding-bottom-14 padding-bottom-md-16 padding-left-0 padding-right-10 font-size-15 font-size-lg-17 border-0 focus-none bg-transparent"
                          type={`${isPasswordVisible ? "text" : "password"}`}
                          name="password"
                          placeholder="Password"
                        />
                        <i
                          className={`fa fa-eye${isPasswordVisible ? "" : "-slash"} font-size-15 font-size-lg-17 padding-right-10`}
                          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        ></i>
                      </div>
                      <ErrorMessage
                        className="red font-size-12"
                        name="password"
                        component="div"
                      />
                    </div>

                    <div className="max-width-550 padding-top-10 padding-bottom-10">
                      <div className="d-flex margin-bottom-4 align-items-center border-radius-10 border-1 border-color-dark-blue bg-pale-navy">
                        <div className="fixed-width-80 d-flex align-items-center justify-content-center">
                          <img width="24" src={lockIcon} />
                        </div>
                        <Field
                          className="input padding-top-14 padding-top-md-16 padding-bottom-14 padding-bottom-md-16 padding-left-0 padding-right-10 font-size-15 font-size-lg-17 border-0 focus-none bg-transparent"
                          type={`${isRepeatPasswordVisible ? "text" : "password"}`}
                          name="repeatPassword"
                          placeholder="Repeat Password"
                        />
                        <i
                          className={`fa fa-eye${isRepeatPasswordVisible ? "" : "-slash"} font-size-15 font-size-lg-17 padding-right-10`}
                          onClick={() => setIsRepeatPasswordVisible(!isRepeatPasswordVisible)}
                        ></i>
                      </div>
                      <ErrorMessage
                        className="red font-size-12"
                        name="repeatPassword"
                        component="div"
                      />
                    </div>

                    {error &&
                      <p className="red font-size-12">
                        {error}
                      </p>}

                    <button
                      className="btn max-width-250 font-size-15 font-size-md-17 margin-top-10 margin-top-md-20 margin-bottom-10 margin-bottom-md-20  padding-16 padding-md-20 border-radius-14 bg-dark-blue white"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      SIGN UP
                    </button>
                    <div>
                      <span className="font-size-16 padding-top-10 padding-bottom-10 grey">
                        Already have an account?
                      </span>
                      <b className="margin-left-10 font-size-17">
                        <Link to="/login" className="text-decoration-none">
                          Sign in
                        </Link>
                      </b>
                    </div>

                    <Modal
                      ariaHideApp={false}
                      isOpen={isMfaVisible}
                      onRequestClose={() => setIsMfaVisible(false)}
                      className="modal max-width-650 padding-0"
                      overlayClassName="modal-backdrop"
                    >
                      <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                        <h1 className="font-size-20 font-size-md-22 dark-blue">
                          Multi-Factor Authentication
                        </h1>
                        <i
                          onClick={() => setIsMfaVisible(false)}
                          className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
                        ></i>
                      </div>

                      <div className="horizontal-line" />

                      <div
                        style={{ maxHeight: "80vh" }}
                        className="overflow-scroll-y padding-10 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
                      >
                        <MultiFactorAuth
                          is_authenticator_app_enabled={enabledMfas.includes("authenticator_app")}
                          is_phone_two_factor_enabled={enabledMfas.includes("phone_otp")}
                          handleClose={() => setIsMfaVisible(false)}
                          isUsingSocialAuthentication={isUsingSocialAuthentication}
                          handleSocialLogin={handleSocialLogin}
                          handleLogin={() => null}
                          values={values}
                          socialMediaType={socialMediaType}
                          socialAccessToken={socialAccessToken}
                          appleAuthCredentials={appleAuthCredentials}
                        />
                      </div>
                    </Modal>
                  </Form>
                )}
              </Formik>
            </animated.div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Register);
