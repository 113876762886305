import axiosInstance from "../../helpers/axiosInstance";

//Refer a friend
export const referAFriend = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance.post("/referrals/create-referral", data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Get my referrals
export const getMyReferrals = (queryParams) => {
  return async (dispatch, getState) => {
    return axiosInstance.get(
      `/referrals/get-my-referrals`,
      { params: queryParams }
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Get my accrued referral earnings
export const getMyAccruedReferralEarning = (data) => {
  return async (dispatch, getState) => {
    return axiosInstance.get(
      `/referrals/get-my-accrued-referral-earnings`
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Payout earnings to wallet
export const payoutEarningsToWallet = () => {
  return async (dispatch, getState) => {
    return axiosInstance.post(
      '/referrals/payout-earnings-to-wallet'
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Get my referral transactions
export const getReferralTransactions = (queryParams) => {
  return async (dispatch, getState) => {
    return axiosInstance.get(
      '/referrals/get-referral-transactions',
      { params: queryParams }
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};