import React, { useState, useEffect } from "react";
import { Field } from "formik";
import Select from "react-select";
import { connect } from "react-redux";

const CurrencyField = ({
  isAuthenticated,
  user,
  value,
  onChange,
  isCurrencyChangeable = true,
  styles
}) => {
  const [currencies, setCurrencies] = useState([]);

  // const customStyles = {
  //   control: (base, state) => (
  //     {
  //       ...base,
  //       justifyContent: "flex-end",
  //       // You can also use state.isFocused to conditionally style based on the focus state
  //     }
  //   ),
  // };

  const customStyles = {
    option: provided => ({
      ...provided,
      // color: 'white',
      // width: 200,
    }),
    control: provided => ({
      ...provided,
      color: 'white',
      // width: 200,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
      // width: 200,
    })
  }

  useEffect(() => {
    let supportedCurrencies =[]

    if (isAuthenticated) {
      supportedCurrencies = user.supported_currencies
    } else {
      let locale = localStorage.getItem("locale")

      if (locale) {
        locale = JSON.parse(locale)
        supportedCurrencies = locale.supported_currencies
      }
    }

    const currencies = supportedCurrencies.map((cur) => {
      return {
        label: cur,
        value: cur,
      };
    });
    setCurrencies(currencies);
  }, []);

  return (
    <div>
      <Field
        component={() => (
          <Select
            styles={{ ...customStyles, ...styles }}
            className="form-control padding-0 focus-none bg-transparent"
            classNamePrefix="react-select"
            // placeholder="Select preferred currency"
            options={currencies}
            isDisabled={!isCurrencyChangeable}
            value={
              currencies
                ? currencies.find((option) => option.value == value)
                : ""
            }
            onChange={onChange}
          />
        )}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    isAuthenticated: !!state.auth.userToken
  };
};

export default connect(mapStateToProps)(CurrencyField);
