import React from "react";
import DisplayCollectionDates from "./fragments/DisplayCollectionDates";

const PoolCollectionDates = ({
  history,
  pool,
  setPool,
  areYouOptedIn,
  isDateChangeable,
  collectionDates,
  poolMembers,
  setIsPageLoading,
}) => {
  return (
    <div className="padding-left-10 padding-right-10 border-1 border-color-light-grey border-radius-8">

      <div className="padding-left-md-50 padding-right-md-50 padding-top-8 padding-bottom-8">
        <h2 className="dark-blue font-size-19 font-size-sm-21">Payout Dates</h2>
      </div>

      <div className="horizontal-line margin-bottom-20" />

      <div className="padding-left-md-50 padding-right-md-50">
        <DisplayCollectionDates
          poolId={pool.pool_description.id}
          areYouOptedIn={areYouOptedIn}
          isDateChangeable={isDateChangeable}
          collectionDates={collectionDates}
          poolMembers={poolMembers}
          setPool={setPool}
          setIsPageLoading={setIsPageLoading}
        />
      </div>

      <div className="horizontal-line margin-top-20 margin-bottom-30" />

      <div className="d-flex justify-content-end margin-bottom-30">
        <button
          className="btn font-size-15 font-size-md-18 margin-right-30 margin-right-sm-50 max-width-160 padding-10 padding-md-16
          bg-white hover-bg-dark-blue dark-blue hover-white border-color-dark-blue border-radius-12"
          onClick={() => {
            history.push(`/dashboard/money-pool/edit/${pool.pool_description.slug}?activeSlide=editPoolDescription`)
          }}
        >
          <b>Previous</b>
        </button>
        <button
          className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16
          bg-dark-blue hover-bg-white white hover-dark-blue border-color-dark-blue border-radius-12"
          type="submit"
          onClick={() => {
            history.push(`/dashboard/money-pool/edit/${pool.pool_description.slug}?activeSlide=poolSummary`)
          }
          }
        >
          <b>Next</b>
        </button>
      </div>
    </div>
  );
};

export { PoolCollectionDates as default };
