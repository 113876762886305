import React from "react";
import parsePhoneNumber from 'libphonenumber-js'
import ReactCountryFlag from "react-country-flag"

const NationalPhoneNumber = ({
  internationalNumber
}) => {
  const phoneNumber = parsePhoneNumber(internationalNumber)

  return (
    <div>
      <ReactCountryFlag
        countryCode={phoneNumber.country}
        svg
        style={{
          width: '20px',
          height: '20px',
        }} />
        &nbsp;
      <span>{phoneNumber.format("NATIONAL")}</span>
    </div>
  );
};

export default NationalPhoneNumber
