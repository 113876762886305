import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Select from "react-select";
import Dropzone from "react-dropzone";
import countryList from "react-select-country-list";
import { NotificationManager } from "react-notifications";
import {
  uploadIdProofDoc,
  deleteIdProofDoc,
  getUser,
  submitCompany,
  deleteCompany
} from "../../../../store/actions/auth";
import { getDocumentFileformatFromUrl, getConfirmAlert, getFileNameFromFileObj, convertSnakeToStandardCasing } from "../../../../utilities";
import PdfViewer from "../../../utilityComponents/PdfViewer";
import PdfThumbnail from "../../../utilityComponents/PdfThumbnail";
import ImageViewer from "../../../utilityComponents/ImageViewer";
import PreviewFileUpload from "../../../utilityComponents/PreviewFileUpload";

const SubmitCompanyInfo = ({
  user,
  document,
  setIsPageLoading,
  dispatch,
  handleClose,
  company
}) => {
  const [error, setError] = useState("");
  const [viewedDocument, setViewedDocument] = useState("");
  const [logoFile, setLogoFile] = useState("");
  const [certificateFile, setCertificateFile] = useState("");
  const [latestBackFile, setLatestBackFile] = useState("");
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false);
  const [isPdfViewerVisible, setIsPdfViewerVisible] = useState(false);
  const [isSelectTypeOpen, setIsSelectTypeOpen] = useState(false);
  const [selectedDocumentTypeValue, setSelectedDocumentTypeValue] = useState("");
  const [countries, setCountries] = useState([]);

  const formikRef = useRef(null)

  const handleSubmitCompany = (values) => {
    setIsPageLoading([true, "Uploading..."]);

    const formData = new FormData();

    if (values.logo_file) {
      formData.append("logo_file", values.logo_file);
    }
    formData.append("certificate_file", values.certificate_file);
    formData.append("organization_name", values.organization_name);
    formData.append("registration_number", values.registration_number);
    formData.append("country", values.country);
    formData.append("state", values.state);
    formData.append("city", values.city);

    dispatch(submitCompany(formData))
      .then((response) => {

        setIsPageLoading([false]);

        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            NotificationManager.success(response.message, "Success!")
            dispatch(getUser())
            // setIsSelectTypeOpen(false);
            // setLatestFrontFile("");
            setSelectedDocumentTypeValue("")
          }
        }
      });
  };

  // Delete a document
  const handleDeleteDocument = (company) => {
    setIsPageLoading([true, "Uploading..."]);

    dispatch(deleteCompany(company))
      .then((response) => {

        setIsPageLoading([false]);

        if (response.error) {
          NotificationManager.error(response.error.message, "Error!")
        } else {
          if (response.success == true) {
            NotificationManager.success(response.message, "Success!")
            dispatch(getUser())
            formikRef.current?.resetForm()
          }
        }
      });
  }

  useEffect(() => {
    // Get country list
    const options = countryList().getData();
    setCountries(options);

  }, [])

  return (
    <div>
      <div className="row">
        {
          company && company.certificate_file_url ?
            <div className="min-width-130 max-width-130 margin-4 margin-sm-10 cursor-pointer bg-lighter-blue border-radius-6" >
              <div className="relative">
                <div
                  className="fixed-height-100"
                  onClick={() => {
                    setViewedDocument({
                      ...company,
                      file_url: company.certificate_file_url
                    });
                    if (getDocumentFileformatFromUrl(company.certificate_file_url) == "pdf") {
                      setIsPdfViewerVisible(true);
                    } else if (getDocumentFileformatFromUrl(company.certificate_file_url) == "image") {
                      setIsImageViewerVisible(true);
                    }
                  }}
                >
                  {getDocumentFileformatFromUrl(company.certificate_file_url) == "pdf" ? (
                    <PdfThumbnail height={90}
                      document={{
                        ...company,
                        file_url: company.certificate_file_url
                      }} />
                  ) : (
                    <img
                      width="100%"
                      height="100%"
                      className="d-block stretch-full border-top-radius-6"
                      src={`${company.certificate_file_url}?${company.certificate_file_url}`}
                    />
                  )}
                </div>

                <i
                  className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                  onClick={() => {
                    getConfirmAlert({
                      title: "Confirm delete!",
                      message: "Are you sure you want to delete this company?",
                      onClickYes: () => {
                        handleDeleteDocument(company)
                      },
                      onClickNo: () => null,
                    });
                  }}
                ></i>
              </div>

              <div
                className="font-size-12 padding-4 padding-left-8 padding-right-8 text-truncate border-bottom-radius-6"
                onClick={() => {
                  setViewedDocument({
                    ...company,
                    file_url: company.certificate_file_url
                  });
                  if (getDocumentFileformatFromUrl(company.certificate_file_url) == "pdf") {
                    setIsPdfViewerVisible(true);
                  } else if (getDocumentFileformatFromUrl(company.certificate_file_url) == "image") {
                    setIsImageViewerVisible(true);
                  }
                }}
              >
                <b>{getFileNameFromFileObj(company.certificate_file_url)}</b>
              </div>

              <div className="padding-left-8 padding-right-8 padding-bottom-8 font-size-12">
                {convertSnakeToStandardCasing(company.verification_status)}
              </div>
            </div> :
            <div>
              <Formik
                innerRef={formikRef}

                initialValues={{
                  ...company,
                  logo_file: "",
                  certificate_file: ""
                }}

                validationSchema={Yup.object({
                  organization_name: Yup.string().required("Organization name required"),
                  registration_number: Yup.string().required("Registration number"),
                  country: Yup.string().required("Select a country"),
                  city: Yup.string().required("City is required"),
                  state: Yup.string().required("State is required"),
                  certificate_file: Yup.string().required("Upload a certificate file"),
                })}

                onSubmit={(values, { setSubmitting }) => {
                  handleSubmitCompany(values)
                }}
              >
                {({
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  values,
                }) => (
                  <Form>
                    <div className="padding-left-10 padding-right-10">
                      <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                        <label className="form-label">Company Logo</label>

                        {values.logo_file ?
                          <div className="relative max-width-100">
                            <div
                              className="fixed-height-100"
                              onClick={() => {
                                setFieldValue("logo_file", "")
                              }}
                            >
                              <PreviewFileUpload file={values.logo_file} />
                            </div>

                            <i
                              className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                              onClick={() => {
                                getConfirmAlert({
                                  title: "Confirm delete!",
                                  message: "Are you sure you want to delete this file?",
                                  onClickYes: () => {
                                    setFieldValue("logo_file", "")
                                  },
                                  onClickNo: () => null,
                                });
                              }}
                            ></i>
                          </div>
                          :
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              setFieldValue("logo_file", acceptedFiles[0])
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                className="d-inline-block cursor-pointer"
                                style={{ outline: "none" }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} accept="image/*" />
                                <p className="d-inline-block max-width-100 fixed-height-80 font-size-13 font-size-sm-14 line-height-16 line-height-sm-22 padding-10 white border-radius-10 bg-charcoal opacity-75">
                                  Drag 'n' drop or click to select file
                                </p>
                              </div>
                            )}
                          </Dropzone>
                        }
                      </div>


                      <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                        <label className="form-label">Organization Name</label>
                        <Field
                          className="form-control"
                          placeholder="Enter organization name"
                          name="organization_name"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="organization_name"
                          component="div"
                        />
                      </div>

                      <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                        <label className="form-label">Registration Number</label>
                        <Field
                          className="form-control"
                          placeholder="Enter organization's registration number"
                          name="registration_number"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="registration_number"
                          component="div"
                        />
                      </div>

                      <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                        <label className="form-label">Registration Certificate</label>
                        {values.certificate_file ?
                          <div>
                            <div className="relative max-width-100 ">
                              <div
                                className="fixed-height-100"
                                onClick={() => {
                                  setViewedDocument(company.certificate_file_url);
                                  if (getDocumentFileformatFromUrl(company.certificate_file_url) == "pdf") {
                                    setIsPdfViewerVisible(true);
                                  } else if (getDocumentFileformatFromUrl(company.certificate_file_url) == "image") {
                                    setIsImageViewerVisible(true);
                                  }
                                }}
                              >
                                <PreviewFileUpload file={values.certificate_file} />
                              </div>

                              <i
                                className="fa fa-close absolute top-0 right-0 cursor-pointer font-size-10 margin-4 padding-2 rounded-circle bg-red white"
                                onClick={() => {
                                  getConfirmAlert({
                                    title: "Confirm delete!",
                                    message: "Are you sure you want to delete this company?",
                                    onClickYes: () => {
                                      setFieldValue("certificate_file", "")
                                    },
                                    onClickNo: () => null,
                                  });
                                }}
                              ></i>
                            </div>
                            <div
                              className="font-size-12 padding-4 padding-left-8 padding-right-8 text-truncate border-bottom-radius-6"
                              onClick={() => {
                                setViewedDocument(company);
                                if (getDocumentFileformatFromUrl(values.certificate_file) == "pdf") {
                                  setIsPdfViewerVisible(true);
                                } else if (getDocumentFileformatFromUrl(values.certificate_file) == "image") {
                                  setIsImageViewerVisible(true);
                                }
                              }}
                            >
                            </div>
                          </div>
                          :
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              setFieldValue("certificate_file", acceptedFiles[0])
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="d-flex align-content-left justify-content-left">
                                <div
                                  className="d-inline-block cursor-pointer"
                                  style={{ outline: "none" }}
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} accept="image/*,.pdf" />
                                  <p className="d-inline-block font-size-13 font-size-sm-14 line-height-16 line-height-sm-22 padding-6 padding-left-16 padding-right-16 white border-radius-10 bg-charcoal opacity-75">
                                    Drag 'n' drop or click to select file.
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        }

                        <ErrorMessage
                          className="error-message"
                          name="certificate_file"
                          component="div"
                        />

                      </div>

                      <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                        <label className="form-label">Country</label>
                        <Field
                          component={() => (
                            <Select
                              className="form-control padding-0"
                              classNamePrefix="react-select"
                              placeholder="Select country"
                              options={countries}
                              value={
                                countries
                                  ? countries.find(
                                    (option) => option.value === values.country
                                  )
                                  : ""
                              }
                              onChange={(option) => {
                                setFieldValue("country", option.value);
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="country"
                          component="div"
                        />
                      </div>

                      <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                        <label className="form-label">State</label>
                        <Field
                          className="form-control"
                          placeholder="Enter State or Province"
                          name="state"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="state"
                          component="div"
                        />
                      </div>

                      <div className="padding-left-0 padding-left-sm-20 padding-top-10 padding-bottom-10">
                        <label className="form-label">City</label>
                        <Field
                          className="form-control"
                          placeholder="Enter City"
                          name="city"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="city"
                          component="div"
                        />
                      </div>
                      <button
                        className="btn bg-dark-blue white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180"
                        onClick={handleSubmit}
                        type="submit"
                      >
                        <b>Submit</b>
                      </button>
                    </div>

                  </Form>
                )}
              </Formik>
            </div>
        }
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isPdfViewerVisible}
        onRequestClose={() => setIsPdfViewerVisible(false)}
        className="modal max-width-800 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div>
          <PdfViewer
            document={viewedDocument}
            handleClose={() => setIsPdfViewerVisible(false)} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isImageViewerVisible}
        onRequestClose={() => setIsImageViewerVisible(false)}
        className="modal max-width-1000 padding-0 bg-black"
        overlayClassName="modal-backdrop"
      >
        <div>
          <ImageViewer
            maxHeightImage={600}
            document={viewedDocument}
            handleClose={() => setIsImageViewerVisible(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(SubmitCompanyInfo);
