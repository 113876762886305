import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import shareIcon from "../../../images/share_icon_darkblue.svg";
import MyGuarantees from "./MyGuarantees";
import MyGuarantors from "./MyGuarantors";

const MoneyPoolGuarantee = ({ user, setIsPageLoading }) => {

  const [isMyPoolGuaranteesVisible, setIsMyPoolGuaranteesVisible] = useState(false);
  const [isMyPoolGuarantorsVisible, setIsMyPoolGuarantorsVisible] = useState(false);

  return (
    <div>
      <div>
        <div className="padding-bottom-18">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsMyPoolGuaranteesVisible(true)}
          >
            <i className="fa fa-certificate fixed-width-20 margin-right-20 margin-right-sm-24"></i>
            <span className="font-size-14 font-size-sm-17">
              Pool Guarantees I Made
            </span>
          </div>
        </div>

        <div className="padding-bottom-18">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsMyPoolGuarantorsVisible(true)}
          >
          <i className="fa fa-lock fixed-width-20 margin-right-20 margin-right-sm-24"></i>
            <span className="font-size-14 font-size-sm-17">
              My Money Pool Guarantors
            </span>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isMyPoolGuaranteesVisible}
        onRequestClose={() => setIsMyPoolGuaranteesVisible(false)}
        className="modal max-width-950 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue text-center stretch-full">
            My Money Pool Guarantees
          </h1>
          <i
            onClick={() => setIsMyPoolGuaranteesVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <MyGuarantees
            setIsPageLoading={setIsPageLoading}
            handleClose={() => setIsMyPoolGuaranteesVisible(false)} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isMyPoolGuarantorsVisible}
        onRequestClose={() => setIsMyPoolGuarantorsVisible(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue text-center stretch-full">
            My Pool Guarantors
          </h1>
          <i
            onClick={() => setIsMyPoolGuarantorsVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20"
        >
          <MyGuarantors
            setIsPageLoading={setIsPageLoading}
            handleClose={() => setIsMyPoolGuarantorsVisible(false)} />
        </div>
      </Modal>

   
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MoneyPoolGuarantee);
