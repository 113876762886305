import React, { useState } from "react";
import { Formik, Form, } from "formik";
import { Switch } from "@material-ui/core";
import { connect } from "react-redux";
import { convertSnakeToStandardCasing, getConfirmAlert } from "../../../../utilities";
import { subscribeNewsletter, confirmNewsletterUnsubscribe, editNotificationMedium } from "../../../../store/actions/auth";

const NotificationSettings = ({ user, setIsPageLoading, dispatch }) => {

  const [newsletterSubscribed, setNewsletterSubscribed] = useState(user.newsletter_subscribed)

  return (

    <div>
      <div className="inner-content">
        {/* <div className="row">
          <span>
            All notifications will be sent to the mobile number that’s
            registered with your account
          </span>
          <span>(to change this, please contact Customer Service).</span>
        </div>
        <br /> */}
        <div className="row">
          {user.notification_mediums && user.notification_mediums.length > 0 &&
            user.notification_mediums.map((item) => {
              return (
                <div key={item.id}>
                  <Formik
                    initialValues={item}
                    onSubmit={(values, { setSubmitting }) => {
                      setIsPageLoading([true, "Loading..."])
                      dispatch(editNotificationMedium(values))
                        .then(res => {
                          setIsPageLoading([false])
                        })
                    }}
                  >
                    {({
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      values
                    }) => (
                      <Form>
                        <label>{convertSnakeToStandardCasing(item.notification_type)} Notifications</label>
                        <div className="checkbox">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              checked={values.sms}
                              onChange={() => {
                                setFieldValue("sms", !values.sms);
                                handleSubmit()
                              }}
                            />
                            <label>SMS</label>
                          </label>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              checked={values.whatsapp}
                              onChange={() => {
                                setFieldValue("whatsapp", !values.whatsapp);
                                handleSubmit()
                              }}
                            />
                            <label>WhatsApp</label>
                          </label>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              disabled={values.notification_type == "SUPPORT"}
                              checked={values.email}
                              onChange={() => {
                                setFieldValue("email", !values.email);
                                handleSubmit()
                              }}
                            />
                            <label>Email</label>
                          </label>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              checked={values.push}
                              onChange={() => {
                                setFieldValue("push", !values.push);
                                handleSubmit()
                              }}
                            />
                            <label>Push</label>
                          </label>
                        </div> <br />
                      </Form>
                    )}
                  </Formik>
                </div>
              )
            })
          }
          <div>
            <label>Newsletter</label>
            &nbsp;&nbsp;
            <Switch
              color="primary"
              checked={newsletterSubscribed}
              onChange={() => {
                if (newsletterSubscribed) {
                  // Unsubscribe
                  getConfirmAlert({
                    title: "Confirm Unsubscribing!",
                    message:
                      "Are you sure you want to unsubscribe from our newsletters?",
                    onClickYes: () => {
                      setIsPageLoading([true, "Loading..."])

                      dispatch(confirmNewsletterUnsubscribe())
                        .then(res => {
                          setIsPageLoading([false])
                          setNewsletterSubscribed(!newsletterSubscribed)
                        })
                    },
                    onClickNo: () => { },
                  });
                } else {
                  // Subscribe
                  setIsPageLoading([true, "Loading..."])
                  dispatch(subscribeNewsletter())
                    .then(() => {
                      setNewsletterSubscribed(!newsletterSubscribed)
                      setIsPageLoading([false])
                    })
                }
              }} />

            {newsletterSubscribed ? "Subscribed" : "Not subscribed"}

          </div>
        </div>
      </div>

      <div className="horizontal-line" />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(NotificationSettings);
