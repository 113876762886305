import React, { useState } from "react";
import Modal from "react-modal";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import QRCode from 'qrcode.react'
import {
    enableMultiFactorAuthMethod,
    generatePhoneAuthenticationOtp,
    getAuthenticatorAppUri,
    generateNewAuthenticatorAppUriAuthenticated,
    getUser,
    disableMfa
} from "../../../../store/actions/auth"
import { getConfirmAlert } from "../../../../utilities";
import authenticatorIcon from "../../../images/authenticator_icon_darkblue.svg";
import phoneIconDarkblue from "../../../images/phone_icon_darkblue.svg";

const MultiFactorAuthMethods = ({
    user,
    setIsPageLoading,
    dispatch,
    handleClose
}) => {
    const [otp, setOtp] = useState("");
    const [isVerifyOtpVisible, setIsVerifyOtpVisible] = useState(false);
    const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);
    const [googleAuthQRCodeUri, setGoogleAuthQRCodeUri] = useState(null);

    const handleGetAuthenticatorUri = () => {
        setIsPageLoading([true, "loading..."])

        dispatch(getAuthenticatorAppUri())
            .then((res) => {
                setIsPageLoading([false])
                if (res.error) {
                    NotificationManager.error(res.error.message, "Error!")
                } else {
                    if (res.success == true) {
                        setGoogleAuthQRCodeUri(res.data.uri)

                        // Launch the qr reader
                        setIsQRCodeVisible(true)
                    }
                }
            })
    }

    return (
        <div>
            <div>
                <div>
                    {user.enabled_multi_factor_authentications.authenticator_enabled ?
                        <div className="row align-items-center stretch-full margin-top-20 margin-bottom-20">
                            <div className="col-md-8 d-flex align-items-center margin-right-12 margin-top-8 margin-bottom-8 stretch-full">
                                <img
                                    className="margin-right-10 margin-right-md-20"
                                    width="20px"
                                    src={authenticatorIcon}
                                />
                                <p className="font-size-14 font-size-sm-16 dark-blue">
                                    Authenticator App is enabled
                                </p>
                            </div>

                            <div className="col-md-4">
                                <button
                                    className="btn bg-dark-blue max-width-260 margin-top-8 margin-bottom-8 padding-10"
                                    type="button"
                                    onClick={() => {
                                        getConfirmAlert({
                                            title: "Confirm!",
                                            message: "Are you sure you want to disable your Authenticator App 2-FA?",
                                            onClickYes: () => {
                                                setIsPageLoading([true, "loading..."])
                                                dispatch(disableMfa({
                                                    "otp_type": "authenticator_app"
                                                }))
                                                    .then((res) => {
                                                        setIsPageLoading([false])
                                                        if (res.error) {
                                                            NotificationManager.error(res.error.message, "Error!")
                                                        } else {
                                                            if (res.success == true) {
                                                                NotificationManager.success(res.message, "Success!")
                                                                dispatch(getUser())
                                                            }
                                                        }
                                                    })
                                            },
                                            onClickNo: () => null
                                        })
                                    }}
                                >
                                    Disable Authenticator App 2-FA
                                </button>

                                <button
                                    className="btn bg-dark-blue max-width-260 margin-top-8 margin-bottom-8 padding-10"
                                    type="button"
                                    onClick={() => {
                                        handleGetAuthenticatorUri()
                                    }}
                                >
                                    Scan QR Code
                                </button>
                            </div>
                        </div>
                        :
                        <div className="row align-items-center stretch-full margin-top-20 margin-bottom-20">
                            <div className="col-md-8 d-flex align-items-center margin-right-12 margin-top-8 margin-bottom-8 stretch-full">
                                <img
                                    className="margin-right-10 margin-right-md-20"
                                    width="20px"
                                    src={authenticatorIcon}
                                />
                                <p className="font-size-14 font-size-sm-16 dark-blue">
                                    Authenticator App 2-FA is not enabled
                                </p>
                            </div>

                            <div className="col-md-4">
                                <button
                                    className="btn bg-dark-blue max-width-260 margin-top-8 margin-bottom-8 padding-10"
                                    type="button"
                                    onClick={() => {
                                        handleGetAuthenticatorUri()
                                    }}
                                >
                                    Set Up
                                </button>
                            </div>
                        </div>
                    }
                </div>
                {/* <div className="horizontal-line margin-0" /> */}
                {/* <div className="padding-bottom-18">
                    {
                        user.enabled_multi_factor_authentications.phone_otp_enabled ?
                            <div className="row align-items-center stretch-full margin-top-20 margin-bottom-20">
                                <div className="col-md-8 d-flex align-items-center margin-right-12 margin-top-8 margin-bottom-8 stretch-full">
                                    <img
                                        className="margin-right-10 margin-right-md-20"
                                        width="20px"
                                        src={phoneIconDarkblue}
                                    />
                                    <p className="font-size-14 font-size-sm-16 dark-blue">
                                        Phone 2-Factor Authentication is enabled with phone number ending in ...{user.enabled_multi_factor_authentications.enabled_phone_last4}
                                    </p>
                                </div>

                                <div className="col-md-4">
                                    <button
                                        className="btn bg-dark-blue max-width-260 margin-top-8 margin-bottom-8 padding-10"
                                        type="button"
                                        onClick={() => {
                                            getConfirmAlert({
                                                title: "Confirm!",
                                                message: "Are you sure you want to disable your phone 2FA?",
                                                onClickYes: () => {
                                                    setIsPageLoading([true, "loading..."])
                                                    dispatch(disableMfa({
                                                        "otp_type": "phone_otp"
                                                    }))
                                                        .then((res) => {
                                                            setIsPageLoading([false])
                                                            if (res.error) {
                                                                NotificationManager.error(res.error.message, "Error!")
                                                            } else {
                                                                if (res.success == true) {
                                                                    dispatch(getUser())
                                                                    NotificationManager.success(res.message, "Success!")
                                                                }
                                                            }
                                                        })
                                                },
                                                onClickNo: () => null
                                            })
                                        }}
                                    >
                                        Disable Phone 2-FA
                                    </button>
                                </div>
                            </div>
                            :
                            <div className="row align-items-center stretch-full margin-top-20 margin-bottom-20">
                                <div className="col-md-8 d-flex align-items-center margin-right-12 margin-top-8 margin-bottom-8 stretch-full">
                                    <img
                                        className="margin-right-10 margin-right-md-20"
                                        width="20px"
                                        src={phoneIconDarkblue}
                                    />
                                    <p className="font-size-14 font-size-sm-16 dark-blue">
                                        Mobile Phone 2-FA is not enabled
                                    </p>
                                </div>

                                <div className="col-md-4">
                                    <button
                                        className="btn bg-dark-blue max-width-260 margin-top-8 margin-bottom-8 padding-10"
                                        type="button"
                                        onClick={() => {
                                            setIsPageLoading([true, "loading..."])
                                            dispatch(generatePhoneAuthenticationOtp())
                                                .then((res) => {
                                                    setIsPageLoading([false])
                                                    if (res.error) {
                                                        NotificationManager.error(res.error.message, "Error!")
                                                    } else {
                                                        if (res.success == true) {
                                                            setIsVerifyOtpVisible(true)
                                                            NotificationManager.success(res.message, "Success!")
                                                        }
                                                    }
                                                })
                                        }}
                                    >
                                        Set Up Phone 2-FA
                                    </button>
                                </div>
                            </div>
                    }
                </div> */}
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={isVerifyOtpVisible}
                onRequestClose={() => setIsVerifyOtpVisible(false)}
                className="modal max-width-650 padding-0"
                overlayClassName="modal-backdrop"
            >
                <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                    <h1 className="font-size-20 font-size-md-22 dark-blue">
                        Verify OTP
                    </h1>
                    <i
                        onClick={() => setIsVerifyOtpVisible(false)}
                        className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
                    ></i>
                </div>

                <div className="horizontal-line margin-0" />

                <div className="padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
                    style={{ maxHeight: "80vh" }}>
                    <input
                        className="form-control margin-top-20 margin-bottom-20"
                        placeholder="Enter OTP"
                        type="text"
                        onChange={(e) => {
                            setOtp(e.target.value)
                        }} />

                    <button
                        className="btn max-width-160 margin-bottom-20 bg-dark-blue padding-top-8 padding-bottom-8 font-size-15"
                        type="button"
                        onClick={() => {
                            if (!otp) {
                                alert("Otp is required")
                            } else {
                                dispatch(enableMultiFactorAuthMethod({
                                    otp_type: "phone_otp",
                                    otp
                                }))
                                    .then(res => {
                                        setOtp(null)

                                        if (res.error) {
                                            NotificationManager.error(res.error.message, "Error!")
                                        } else {
                                            if (res.success == true) {
                                                NotificationManager.success(res.message, "Success!")
                                                setIsVerifyOtpVisible(false)
                                                dispatch(getUser())
                                            }
                                        }
                                    })
                            }
                        }} >Submit OTP</button>
                </div>
            </Modal>

            <Modal
                ariaHideApp={false}
                isOpen={isQRCodeVisible}
                onRequestClose={() => setIsQRCodeVisible(false)}
                className="modal max-width-650 padding-0"
                overlayClassName="modal-backdrop"
            >
                <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                    <h1 className="font-size-20 font-size-md-22 dark-blue">
                        Scan QR Code
                    </h1>
                    <i
                        onClick={() => setIsQRCodeVisible(false)}
                        className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
                    ></i>
                </div>

                <div className="horizontal-line margin-0" />

                <div className="padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
                    style={{ maxHeight: "80vh" }}
                >
                    <div>
                        Connect an authenticator app that generates verification codes. You can use the codes when we need to verify your identity.
                        <ol className="padding-left-16">
                            <li>Download and install an authenticator app on your mobile device.</li>
                            <li>Use the app to scan this QR code.</li>
                            <li>Enter the code generated by the app.</li>
                        </ol>
                    </div>

                    <div className="d-flex align-items-center">
                        <QRCode value={googleAuthQRCodeUri} />
                        <button
                            type="button"
                            className="btn bg-dodgerblue max-width-160 padding-4 padding-top-10 padding-bottom-10 margin-left-10 margin-left-sm-20"
                            onClick={() => getConfirmAlert({
                                title: "Warning!",
                                message: `Generating a new QR code will invalidate all authenticator tokens from 
                                the previous QR code. Failure to scan the new QR code and submit an OTP from your authenticator app might 
                                lead to you being shut out of your account. `,
                                onClickYes: () => {
                                    dispatch(generateNewAuthenticatorAppUriAuthenticated())
                                        .then(res => {
                                            if (res.error) {
                                                NotificationManager.error(res.error.message, "Error!")
                                            } else {
                                                if (res.success == true) {
                                                    NotificationManager.success(res.message, "Success!")
                                                    setGoogleAuthQRCodeUri(res.data.uri)
                                                }
                                            }
                                        })
                                },
                                onClickNo: () => null

                            })}
                        >Generate New Code</button>
                    </div>

                    <div>
                        <input
                            className="form-control margin-top-20 margin-bottom-20"
                            type="text"
                            onChange={(e) => {
                                setOtp(e.target.value)
                            }}
                            placeholder="Enter OTP"
                        />

                        <button
                            className="btn max-width-160 margin-bottom-20 bg-dark-blue padding-top-8 padding-bottom-8 font-size-15"
                            type="button"
                            onClick={() => {
                                if (!otp) {
                                    alert("Otp is required")
                                } else {
                                    dispatch(enableMultiFactorAuthMethod({
                                        otp_type: "authenticator_app",
                                        otp
                                    }))
                                        .then(res => {
                                            setOtp(null)

                                            if (res.error) {
                                                NotificationManager.error(res.error.message, "Error!")
                                            } else {
                                                if (res.success == true) {
                                                    NotificationManager.success(res.message, "Success!")
                                                    setIsQRCodeVisible(false)

                                                    dispatch(getUser())
                                                }
                                            }
                                        })
                                }
                            }} >Submit OTP</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    return {
        user: state.auth.user,
    };
};


export default connect(mapStateToProps)(MultiFactorAuthMethods);
