import React, { useEffect } from "react";
import { useSpring, animated } from 'react-spring';
import selectIcon from "../images/select_bill_icon_darkblue.svg";
import enterServiceIcon from "../images/enter_service_icon_darkblue.svg";
import payBillAndReceiveIcon from "../images/pay_bill_and_receive_icon_darkblue.svg";
import payBillsMainImage from "../images/pay_bills_main.png";
import dataBundleImage from "../images/data_bundle.svg";
import airtimeRecharge from "../images/airtime_recharge.svg";
import electricityImage from "../images/electricity.svg";
import internetImage from "../images/internet.svg";
import cableTvImage from "../images/cable_tv.svg";
import tollImage from "../images/toll.svg";
import churchTitheImage from "../images/church_tithe.svg";

const BillPaymentHome = ({
  history,
  setIsPageLoading
}) => {

  const fadeStyles = useSpring({
    from: { opacity: 0 },
    config: { duration: 2000 },
    loop: {
      opacity: 1,
    },
  })

  useEffect(() => {
    document.title = "Fundraiser Home"
  }, [])


  return (
    <animated.div className="container"
      style={fadeStyles}>
      <div className="content-container">
        <div className="padding-top-30 padding-top-md-40 padding-left-0 padding-left-lg-40 padding-right-0 padding-right-lg-40">

          <div className="row" >
            <div className="col-md-5" >
              <div className="padding-bottom-10" >
                <div className="dark-blue padding-bottom-10 font-size-20 font-size-sm-25">
                  <b>Buy Airtime, Data Bundle and Pay Bills Online Anytime, Anywhere.</b>
                </div>
                <div >
                  With Pouch Money's simple bill paying service, you can renew your pay TV, internet, electricity, buy air time, and more.
                </div>
              </div>
              <center>
                <img className="d-block max-width-250" src={payBillsMainImage} />
              </center>
            </div>
            <div className="col-md-7 row padding-top-20 padding-sm-10">
              <div
                className="d-flex flex-column align-items-center fixed-width-150 padding-bottom-10 dark-blue cursor-pointer"
                onClick={
                  () => {
                    history.push("/dashboard/pay-bills?bill=airtime")
                  }
                }
              >
                <img className="d-block max-width-70" src={airtimeRecharge} />

                Airtime
              </div>
              <div
                className="d-flex flex-column align-items-center fixed-width-150 padding-bottom-10 dark-blue cursor-pointer"
                onClick={() => {
                  history.push("/dashboard/pay-bills?bill=data_bundle")
                }} >
                <img className="d-block max-width-70" src={dataBundleImage} />
                Data Bundle
              </div>
              <div
                className="d-flex flex-column align-items-center fixed-width-150 padding-bottom-10 dark-blue cursor-pointer"
                onClick={() => {
                  history.push("/dashboard/pay-bills?bill=cables")
                }}>
                <img className="d-block max-width-70" src={cableTvImage} />
                Cable TV
              </div>
              <div
                className="d-flex flex-column align-items-center fixed-width-150 padding-bottom-10 dark-blue cursor-pointer"
                onClick={() => {
                  history.push("/dashboard/pay-bills?bill=internet")
                }}>
                <img className="d-block max-width-70" src={internetImage} />
                Internet
              </div>
              <div
                className="d-flex flex-column align-items-center fixed-width-150 padding-bottom-10 dark-blue cursor-pointer"
                onClick={() => {
                  history.push("/dashboard/pay-bills?bill=power")
                }}>
                <img className="d-block max-width-70" src={electricityImage} />
                Electricity Payment
              </div>
              <div
                className="d-flex flex-column align-items-center fixed-width-150 padding-bottom-10 dark-blue cursor-pointer"
                onClick={() => {
                  history.push("/dashboard/pay-bills?bill=toll")
                }}>
                <img className="d-block max-width-70" src={tollImage} />
                Toll
              </div>
              {/* 
          <div
            className="d-flex flex-column align-items-center fixed-width-150 padding-bottom-10 dark-blue cursor-pointer">
            <img className="d-block max-width-70" src={churchTitheImage} />
            Church Payment
          </div> */}
            </div>
          </div>

          <div className="padding-top-60 padding-bottom-50 padding-bottom-md-80">
            <div className="horizontal-line">
              <span>
                <h1 className="dark-blue text-center font-size-20 font-size-sm-24 font-size-md-30 margin-left-6 margin-left-sm-20 margin-right-6 margin-right-sm-20">
                  How It Works
                </h1>
              </span>
            </div>
          </div>


          <div className="d-flex flex-wrap justify-content-center text-center padding-bottom-20">
            <div className="max-width-320 margin-10 margin-top-40 margin-bottom-40 margin-left-sm-20 margin-right-sm-20">
              <div className="relative fixed-height-300 d-flex flex-column align-items-center padding-20 padding-sm-30 border-radius-14 bg-upkeep-stipends-light-color">
                <div className="absolute top-0 left-50-pct transform-50-50 bg-transparent padding-6 border-3 border-color-upkeep-stipends-color rounded-circle">
                  <b className="d-flex align-items-center justify-content-center fixed-height-80 fixed-width-80 font-size-30 rounded-circle bg-upkeep-stipends-color white">
                    1
                  </b>
                </div>

                <img
                  className="padding-top-50 padding-bottom-20 max-width-40"
                  src={selectIcon}
                />
                <div className="max-width-240">
                  <b className="font-size-22 font-size-sm-24 line-height-22 line-height-sm-26">
                    Select bill category
                  </b>
                </div>
              </div>
            </div>

            <div className="max-width-320 margin-10 margin-top-40 margin-bottom-40 margin-left-sm-20 margin-right-sm-20">
              <div className="relative fixed-height-300 d-flex flex-column align-items-center padding-20 padding-sm-30 border-radius-14 bg-upkeep-stipends-light-color">
                <div className="absolute top-0 left-50-pct transform-50-50 bg-transparent padding-6 border-3 border-color-upkeep-stipends-color rounded-circle">
                  <b className="d-flex align-items-center justify-content-center fixed-height-80 fixed-width-80 font-size-30 rounded-circle bg-upkeep-stipends-color white">
                    2
                  </b>
                </div>

                <img
                  className="padding-top-50 padding-bottom-20 max-width-60 padding-bottom-30"
                  src={enterServiceIcon}
                />
                <div className="max-width-240">
                  <b className="font-size-22 font-size-sm-24 line-height-22 line-height-sm-26">
                    Enter service details
                  </b>
                </div>
              </div>
            </div>

            <div className="max-width-320 margin-10 margin-top-40 margin-bottom-40 margin-left-sm-20 margin-right-sm-20">
              <div className="relative fixed-height-300 d-flex flex-column align-items-center padding-20 padding-sm-30 border-radius-14 bg-upkeep-stipends-light-color">
                <div className="absolute top-0 left-50-pct transform-50-50 bg-transparent padding-6 border-3 border-color-upkeep-stipends-color rounded-circle">
                  <b className="d-flex align-items-center justify-content-center fixed-height-80 fixed-width-80 font-size-30 rounded-circle bg-upkeep-stipends-color white">
                    3
                  </b>
                </div>
                <img
                  className="padding-top-50 padding-bottom-20 max-width-60"
                  src={payBillAndReceiveIcon}
                />
                <div className="max-width-240">
                  <b className="font-size-22 font-size-sm-24 line-height-22 line-height-sm-26">
                    Pay the bill and receive value
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default BillPaymentHome;
