import React, { useState } from "react";
import FormattedMoney from "../../utilityComponents/FormattedMoney"

const BalancesSlider = ({ balances }) => {
    const [animationPlayState, setAnimationPlayState] = useState("running")

    return (
        <div>
            <div
                className="slider"
            >
                <div
                    className={`mover-${balances.length}-currency`}
                    // className={"mover"}
                    style={{
                        animationPlayState,
                        // width: "200px"
                    }}
                    onMouseEnter={() => setAnimationPlayState("paused")}
                    onMouseLeave={() => setAnimationPlayState("running")}
                >
                    {balances && balances.map((bal, index) => {
                        return (
                            <div
                                key={index}
                                className="padding-left-10 padding-right-10">
                                <b className="normal-green font-size-20">
                                    <FormattedMoney
                                        value={bal.available_balance}
                                        currency={bal.currency}
                                        decimalScale={2}
                                    />
                                </b>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default BalancesSlider;
