import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImage } from "../../utilities";
import {
  RotateLeft,
  RotateRight,
  ZoomIn,
  ZoomOut,
  Crop,
} from "@material-ui/icons";

const ImageEditor = ({
  src,
  handleSaveImage,
  onClickRetakePhoto,
  aspectRatio
}) => {
  const [currentImage, setCurrentImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  //Any action on the Cropper will trigger this function
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImage(
        currentImage,
        croppedAreaPixels,
        rotation
      );
      // setCroppedImage(croppedImage);
      handleSaveImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const onClickCrop = async () => {
    const croppedImage = await getCroppedImage(
      currentImage,
      croppedAreaPixels,
      rotation
    );
    // setCroppedImage(croppedImage);
    setCurrentImage(croppedImage);
  };

  const onClose = useCallback(() => {
    // setCroppedImage(null);
  }, []);

  useEffect(() => {
    setCurrentImage(src);
  }, [src]);

  return (
    <div>
      <Cropper
        style={{
          containerStyle: {
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            position: "fixed",
            zIndex: "800",
            // backgroundColor: "rgba(32, 28, 48, 0.5)",
            backgroundColor: "black",
          },
          mediaStyle: {
            //width: "500px",
          },
          cropAreaStyle: {
            //backgroundColor: "black"
          },
        }}
        image={currentImage}
        crop={crop}
        rotation={rotation}
        zoom={zoom}
        aspect={aspectRatio ? aspectRatio : 1.618}
        onCropChange={setCrop}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        restrictPosition={false}
      // disableAutomaticStylesInjection={true}
      />
      <div className="take-photo-background-icon-top">
        <div className="background-icon">
          <ZoomIn
            onClick={() => setZoom((prevState) => prevState + 0.1)}
            className="icon-font-size"
          ></ZoomIn>
        </div>
        <div className="background-icon">
          <ZoomOut
            onClick={() => {
              setZoom((prevState) => prevState - 0.1);
            }}
            className="icon-font-size"
          ></ZoomOut>
        </div>
        <div className="background-icon">
          <RotateLeft
            onClick={() => setRotation((prevState) => prevState - 90)}
            className="icon-font-size"
          ></RotateLeft>
        </div>
        <div className="background-icon">
          <RotateRight
            onClick={() => setRotation((prevState) => prevState + 90)}
            className="icon-font-size"
          ></RotateRight>
        </div>
        <div className="background-icon">
          <Crop onClick={() => onClickCrop()} className="icon-font-size"></Crop>
        </div>
      </div>
      <div className="take-photo-background-icon-bottom">
        <div
          className="save-image-btn bg-black"
          onClick={onClickRetakePhoto}
          style={{ left: "30px" }}
        >
          Delete
        </div>
        <div className="save-image-btn bg-black"
          onClick={() => {
            saveCroppedImage()
          }}>
          Save
        </div>
      </div>
    </div>
  );
};

export { ImageEditor as default };
