import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import mt from "moment-timezone";
import "./date-picker-styles.css"

const DatePickerField = ({
  name,
  value,
  onChange,
  placeholderText,
  disabled,
  minDate,
  maxDate,
  dateFormat,
  showYearDropdown,
  dateFormatCalendar,
  scrollableYearDropdown,
  yearDropdownItemNumber
}) => {
  const locale = JSON.parse(localStorage.getItem("locale"));
  const timezone = locale.timezone;

  return (
    <DatePicker
      className="form-control padding-10"
      selected={(value && new Date(value)) || null}
      onChange={(val) => {
        if (val) {
          val = mt(val).tz(timezone).format();
        }
        onChange(name, val);
      }}
      placeholderText={placeholderText}
      disabled={disabled}
      showTimeSelect={false}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={dateFormat ? dateFormat : "MMMM d, yyyy h:mm aa"}
      showYearDropdown={showYearDropdown}
      dateFormatCalendar={dateFormatCalendar}
      scrollableYearDropdown={scrollableYearDropdown}
      yearDropdownItemNumber={yearDropdownItemNumber}
    />
  );
};

export default DatePickerField;
