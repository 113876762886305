export default (prevState = {
    isPageLoading: false,
    isSignout: false,
    userToken: null,
    isNabbing: false,
    isCookieConsentNeeded: false,
    user: {},
    walletBalances: []
}, action) => {
    switch (action.type) {
        case 'RESTORE_TOKEN':
            return {
                ...prevState,
                userToken: action.token,
                user: action.user
            };
        case 'SIGN_IN':
            return {
                ...prevState,
                userToken: action.token,
            };
        case 'SIGN_OUT':
            return {
                ...prevState,
                isPageLoading: false,
                userToken: null,
                user: {}
            };
        case 'STORE_USER':
            return {
                ...prevState,
                user: action.user
            };
        case 'STORE_HIJACK_STATUS':
            return {
                ...prevState,
                isNabbing: action.isNabbing
            };
        case 'SET_PAGE_LOADING':
            return {
                ...prevState,
                isPageLoading: action.isPageLoading
            };
        case 'SET_IS_COOKIE_CONSENT_NEEDED':
            return {
                ...prevState,
                isCookieConsentNeeded: action.isCookieConsentNeeded
            }
        case 'STORE_WALLET_BALANCES':
            return {
                ...prevState,
                walletBalances: action.walletBalances
            }

        default:
            return prevState;
    }
};
