import React, { useEffect, useState } from 'react';
import { Router, Route, Switch, withRouter, useLocation } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import CookieConsent from "react-cookie-consent";
// import { Offline } from "react-detect-offline";
import { GoogleOAuthProvider } from '@react-oauth/google';
import PrivateRoute from './PrivateRoute';
import AuthRoute from './AuthRoute';
import PublicRoute from './PublicRoute';
import AdminRoute from './AdminRoute';
import Header from '../components/Header';
import HijackNotification from '../components/HijackNotification';
import Home from '../components/Home';
import Footer from '../components/Footer';
import VerificationEmailSent from '../components/statusPages/VerificationEmailSent';
import VerifedEmailRedirect from '../components/statusPages/VerifedEmailRedirect';
import PasswordResetEmailSent from '../components/statusPages/PasswordResetEmailSent';
import DashboardPage from '../components/dashboard/DashboardPage';
import MoneyPoolHome from '../components/services/moneyPool/setup/MoneyPoolHome';
import BillPaymentHome from '../components/services/BillPaymentHome';
import NotFoundPage from '../components/NotFoundPage';
import Login from '../components/authForms/Login';
import ForgotPassword from '../components/authForms/ForgotPassword';
import Register from '../components/authForms/Register';
import RequestConfirmationEmail from '../components/authForms/RequestConfirmationEmail';
import ConfirmPasswordReset from '../components/authForms/ConfirmPasswordReset';
import MakePayment from '../components/MakePayment';
import Playground from '../components/Playground';
import FaqHome from '../components/faq/FaqHome'
import AboutUs from '../components/AboutUs'
import ContactUs from '../components/ContactUs'
import BlogPosts from '../components/blog/BlogPosts'
import BlogArticle from '../components/blog/BlogArticle'
import TermsAndConditions from '../components/legal/TermsAndConditions'
import PrivacyPolicy from '../components/legal/PrivacyPolicy'
import LoadingPage from "../components/utilityComponents/LoadingPage"
import setupTawk from '../tawkTo';

export const history = createBrowserHistory();

function preventModalBackgroundScrolling() {
  document.body.style.overflow = 'hidden';
  return () => document.body.style.overflow = 'unset';
}
// preventModalBackgroundScrolling()

const _ScrollToTop = (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

const AppRouter = ({
  dispatch,
  isPageLoading,
  isNabbing,
  isCookieConsentNeeded
}) => {
  const [mainPath, setMainPath] = useState(history.location.pathname.split("/")[1])

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setMainPath(location.pathname.split("/")[1]);
    });
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV == "production") {
      setupTawk()
    }
  }, [])

  return (
    <Router history={history}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}>
        <ScrollToTop>
          <div>
            <LoadingPage isPageLoading={[isPageLoading]} />

            <div className="app-router__fixed-header">
              <Header />
            </div>
            {/* 
          <div className="app-router__fixed-header text-center">
            <Offline>
              <div className="red padding-8">
                You are currently without an internet connection!
              </div>
            </Offline>
          </div> */}

            {isNabbing == true ?
              <div className="app-router__fixed-header"><HijackNotification /></div>
              : null}

            <div className="app-router__fixed-header__page">

              <div>
                <Switch>
                  <PublicRoute path="/" component={Home} exact={true} />
                  <PublicRoute path="/payment" component={MakePayment} />
                  <PublicRoute path="/playground" component={Playground} />
                  <PublicRoute path="/pay-bills" component={BillPaymentHome} />
                  <PublicRoute path="/money-pool" component={MoneyPoolHome} />
                  <PublicRoute path="/faq" component={FaqHome} />
                  <PublicRoute path="/about-us" component={AboutUs} />
                  <PublicRoute path="/contact-us" component={ContactUs} />
                  <PublicRoute path="/blog" component={BlogPosts} />
                  <PublicRoute path="/blog-post/:slug" component={BlogArticle} />
                  <PublicRoute path="/terms-and-conditions" component={TermsAndConditions} />
                  <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />

                  {/* <PrivateRoute path="/dashboard/:displayedComponent/:action/:id" component={DashboardPage} />
                <PrivateRoute path="/dashboard/:displayedComponent/:action" component={DashboardPage} /> */}
                  <PrivateRoute path="/dashboard" component={DashboardPage} />

                  <AdminRoute path="/admin/login" component={Login} />

                  <AuthRoute path="/status/verification-email-sent" component={VerificationEmailSent} />
                  <AuthRoute path="/status/password-reset-email-sent" component={PasswordResetEmailSent} />
                  <AuthRoute path="/verify-email/:key" component={VerifedEmailRedirect} />
                  <AuthRoute path="/register" component={Register} />
                  <AuthRoute path="/login" component={Login} />
                  <AuthRoute path="/forgot-password" component={ForgotPassword} />
                  <AuthRoute path="/request-confirmation-email" component={RequestConfirmationEmail} />
                  <AuthRoute path="/auth/password-reset-confirm/:uid/:token" component={ConfirmPasswordReset} />

                  <Route component={NotFoundPage} />
                </Switch>
              </div>
              {mainPath !== "dashboard" && <div><Footer /></div>}
            </div>

            {
              isCookieConsentNeeded &&
              <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="cookieConsent"
                style={{ background: "#2B373B", zIndex: 10000 }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={90}
                debug={process.env.NODE_ENV != "production"}
              >This website uses cookies to enhance the user experience.
              </CookieConsent>
            }

            <NotificationContainer />
          </div>
        </ScrollToTop>
      </GoogleOAuthProvider>
    </Router>
  )
}

const mapStateToProps = (state) => {
  return {
    isNabbing: state.auth.isNabbing,
    isPageLoading: state.auth.isPageLoading,
    isCookieConsentNeeded: state.auth.isCookieConsentNeeded,
  };
};

export default connect(mapStateToProps)(AppRouter)
