import axiosInstance from "../../helpers/axiosInstance";
import { v4 as uuidv4 } from 'uuid'

// To become an agent
export const becomeAnAgent = (data) => {
  return async () => {
    return axiosInstance.post('/agents/become-an-agent', data)
      .then(response => {
        return response.data

      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};

// Register a new user
export const registerNewCustomer = (values) => {
  const password = uuidv4()

  return async () => {
    return axiosInstance.post('/agents/create-new-customer', {
      email: values.email,
      password1: password,
      password2: password,
      first_name: values.firstName,
      last_name: values.lastName,
      phone_number: values.phoneNumber,
      country: values.country,
    })
      .then(() => {
        return { message: "Verification email sent" }

      })
      .catch(error => {
        let message

        if (error && error.response && error.response.data) {
          if (error.response.data.password1) {
            message = error.response.data.password1[0]
          } else if (error.response.data.email) {
            message = error.response.data.email[0]
          } else {
            message = error.response.data.detail
          }
        }

        return { error: message }
      })
  }
};

// Get my sales
export const getMySales = (data) => {

  return async () => {
    return axiosInstance.get('/agents/get-my-sales', {
      params: data
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return { error: error ? error.response.data : null }
    })
  }
};

// Get my accrued sales earnings
export const getMyAccruedSalesEarnings = () => {

  return async () => {
    return axiosInstance.get('/agents/get-my-accrued-sales-earning')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return { error: error ? error.response.data : null }
    })
  }
};

// Get my commission transactions
export const getMyCommissionTransactions = (data) => {

  return async () => {
    return axiosInstance.get('/agents/get-my-commission-transactions', {
      params: data
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return { error: error ? error.response.data : null }
    })
  }
};

// Payout earnings to wallet
export const payoutSalesEarningsToWallet = () => {
  return async () => {
    return axiosInstance.post(
      '/agents/pay-out-earnings-to-wallet'
    )
      .then(response => {
        return response.data
      })
      .catch(error => {
        return { error: error ? error.response.data : null }
      })
  }
};


