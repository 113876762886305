import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import Truncate from "react-truncate";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import FormattedMoney from "../../utilityComponents/FormattedMoney";
import { convertSnakeToStandardCasing } from "../../../utilities";
import poolImage from "../../images/money_pool_setup.svg";
import Pagination from "../../utilityComponents/Pagination";
import { getPublicPools } from "../../../store/actions/moneyPool";
import ProgressBar from "../../utilityComponents/ProgressBar";

const PublicMoneyPools = ({
  history,
  dispatch,
  windowWidth,
  setIsPageLoading
}) => {
  const [pools, setPools] = useState([]);
  const [initialPoolCount, setInitialPoolCount] = useState(0);
  const [totalPoolCount, setTotalPoolCount] = useState(0);
  const [query, setQuery] = useState({
    no_of_items_per_page: 5,
    page: 1,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  });
  const imageHeightRef = useRef(null);

  const [imageHeight, setimageHeight] = useState(0);
  const [coverImgHeight, setCoverImgHeight] = useState(0);

  const handleGetPublicPools = async (query) => {
    setIsPageLoading([true, "Loading..."]);
    const response = await dispatch(getPublicPools(query))

    setIsPageLoading([false]);

    if (response.error) {
      NotificationManager.error(response.error.message, "Error!")
    } else {
      if (response.success == true) {
        const data = response.data
        setPools(data.pools);
        setTotalPoolCount(data.total_count);

        setQuery(data.original_query_params)

        return data
      }
    }
  };

  const coverImgRef = useCallback(
    (node) => {
      if (node !== null) {
        setCoverImgHeight(node.getBoundingClientRect().width * 0.65);
      }
    },
    [windowWidth]
  );

  useEffect(() => {
    if (imageHeightRef.current) {
      setimageHeight(imageHeightRef.current.offsetHeight);
    }
  }, [imageHeightRef.current, windowWidth]);

  useEffect(() => {
    handleGetPublicPools(query)
      .then(data => {
        if (data) {
          setInitialPoolCount(data.total_count);
        }
      })
  }, []);

  return (
    <div className="row" >
      {pools.length > 0 &&
        <div
          className="row flex-wrap"
        >
          {pools.map((pool, index) => {
            return (
              <div
                // className="box-shadow border-radius-4 bg-white margin-bottom-30 cursor-pointer"
                className={`${windowWidth > 577 ? "max-width-350" : "max-width-320"} margin-right-10 box-shadow border-radius-4 
                bg-white margin-bottom-30 cursor-pointer`}
                key={index}
                onClick={() => {
                  history.push("/dashboard/money-pool/public-view/" + pool.slug)
                }}
              >
                <div
                  ref={coverImgRef}
                  className={`stretch-full background-image`}
                  style={{
                    backgroundImage: `url(${pool.pool_picture ? pool.pool_picture : poolImage})`,
                    height: coverImgHeight + "px",
                  }}>
                </div>

                <div
                  className="stretch-full bg-dark-blue padding-left-8 padding-right-8 padding-top-20 padding-bottom-0 padding-bottom-sm-20"
                >
                  <div className="font-size-16 white font-weight-bold padding-bottom-8 padding-left-2 padding-right-2">
                    <Truncate lines={2}>
                      {pool.title}
                    </Truncate>
                  </div>

                  <div className="d-flex padding-top-8 padding-bottom-8">
                    <div className="col-6 padding-left-2 padding-right-2">
                      <div>
                        <b className="font-size-14 white">
                          {convertSnakeToStandardCasing(pool.payment_frequency)}
                        </b>
                      </div>
                      <div className="font-size-12 line-height-12 white">
                        Interval
                      </div>
                    </div>
                    <div className="col-6 padding-left-2 padding-right-2">
                      <b className="font-size-13 white">
                        {pool.total_units}
                      </b>
                      <div className="font-size-12 line-height-12 white">
                        Total Hands
                      </div>
                    </div>
                  </div>

                  <div className="d-flex padding-top-8 padding-bottom-10">
                    <div className="col-6 padding-left-2 padding-right-2">
                      <b className="font-size-13 white">
                        {pool.monthly_interest_rate}%
                      </b>
                      <div className="font-size-12 line-height-13 white">
                        Monthly Interest
                      </div>
                    </div>
                    <div className="col-6 padding-left-2 padding-right-2">
                      {/* <div className="font-size-12 line-height-12 white">
                        Subscribed
                      </div> */}
                    </div>
                  </div>

                  <div className="d-flex padding-top-8 padding-bottom-8">
                    <div className="col-6 padding-left-2 padding-right-2">
                      <b className="font-size-13 normal-green">
                        <FormattedMoney
                          value={pool.unit_payment}
                          currency={pool.unit_payment_currency}
                        />
                      </b>
                      <div className="font-size-12 line-height-12 white">
                        Per Hand
                      </div>
                    </div>
                    <div className="col-6 padding-left-2 padding-right-2">
                      <b className="font-size-13 normal-green">
                        <FormattedMoney
                          value={pool.unit_payment * pool.total_units}
                          currency={pool.unit_payment_currency}
                        />
                      </b>
                      <div className="font-size-12 line-height-12 white">
                        Total Per Round
                      </div>
                    </div>
                  </div>

                  <div className="d-flex padding-top-8 padding-bottom-8">
                    <div className="col-6 padding-left-2 padding-right-2">
                      <b className="font-size-13 white">
                        {moment(pool.start_date).format("DD MMM YYYY")}
                      </b>
                      <div className="font-size-12 line-height-12 white">
                        Start Date
                      </div>
                    </div>
                    <div className="col-6 padding-left-2 padding-right-2">
                      <b className="font-size-13 white">
                        {moment(pool.end_date).format("DD MMM YYYY")}
                      </b>
                      <div className="font-size-12 line-height-12 white">
                        End Date
                      </div>
                    </div>
                  </div>

                  <div className="padding-top-20 padding-bottom-20">
                    <ProgressBar status={pool.picked_dates_percent} height="20px" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>}

      {
        totalPoolCount > query.no_of_items_per_page ?
          <Pagination
            {...query}
            handleGetQuery={handleGetPublicPools}
          /> :
          null
      }
    </div>
  );
};

export default connect()(PublicMoneyPools);
