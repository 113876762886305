import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import * as serviceWorker from './serviceWorker';
import AppRouter from './router/AppRouter'
import configureStore from './store/configureStore';
import './styles/index.scss'
import { RESTORE_TOKEN } from "./store/actionTypes/auth"
import { generateTransactionRef } from "./store/actions/transaction"
import { getClientLocale, signOut } from "./store/actions/auth"
import { setIsCookieConsentNeeded, getCookie, setTokenExpiryTimeout, fetchWalletBalances } from "./utilities"

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
    debug: true,
    titleCase: false,
    gaOptions: {
        userId: 123
    }
});
ReactGA.pageview(window.location.pathname + window.location.search);

export const store = configureStore();

const token = localStorage.getItem("token");
const expiry = localStorage.getItem("expiry");
let unParsedUser = localStorage.getItem("user");

if (expiry == "undefined" || expiry == "") {
    localStorage.removeItem(expiry)
}

let user
if (unParsedUser && unParsedUser != "undefined" && unParsedUser != "") {
    user = JSON.parse(unParsedUser);
}

if (unParsedUser == "undefined" || unParsedUser == "") {
    localStorage.removeItem(user)
}

if (token == "undefined" || token == "") {
    localStorage.removeItem(token)
    localStorage.removeItem(expiry)
}

store.dispatch(generateTransactionRef())

if (token && token != "undefined" && user) {
    // There is a valid token
    store.dispatch({ type: RESTORE_TOKEN, token, user, expiry })
    
    fetchWalletBalances(user)

    setTokenExpiryTimeout()

} else {
    if (token) {
        // Expired token should be signed out at the back end
        store.dispatch(signOut())
    }
}

// If no locale in the local storage, then fetch from the backend
let locale = localStorage.getItem("locale")
let cookieConsentValue = JSON.parse(getCookie("cookieConsent"))
if (!locale || locale == "undefined" || !locale.supported_currencies) {
    store.dispatch(getClientLocale())
        .then((locale) => {
            // Set isCookieConsentNeeded 
            setIsCookieConsentNeeded(store.dispatch, locale)
        })
} else {
    if (cookieConsentValue != true) {
        // Set isCookieConsentNeeded 
        setIsCookieConsentNeeded(store.dispatch, JSON.parse(locale))
    }
}

//Check if is currrently hijacking a user
let isNabbing = localStorage.getItem("isNabbing")

if (isNabbing) {
    isNabbing = JSON.parse(isNabbing)

    if (isNabbing == true) {
        store.dispatch({
            type: 'STORE_HIJACK_STATUS',
            isNabbing: true,
        })
    }
}

const jsx = (
    <Provider store={store}>
        <AppRouter />
    </Provider>
);

ReactDOM.render(jsx, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();










