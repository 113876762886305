import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Link, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import MoneyPoolSetupSlides from "../services/moneyPool/setup/MoneyPoolSetupSlides";
import MoneyPoolView from "../services/moneyPool/view/moneyPoolView/Index";
import {
  registerPoolSetupIntention,
} from "../../store/actions/moneyPool";
import poolSetupImage from "../images/money_pool_setup.svg";
import { show_no_service_notification } from "../../utilities";
import GenerateSampleMoneyPool from "./GenerateSampleMoneyPool";
import RequestToJoinMoneyPool from "./RequestToJoinMoneyPool";
import BecomeAPoolGuarantor from "./BecomeAPoolGuarantor";
import PublicMoneyPools from "../tableTemplates/pool/PublicMoneyPools";
import MyPools from "../tableTemplates/pool/MyPools";
import MoneyPoolPublicView from "../services/moneyPool/view/moneyPoolView/MoneyPoolPublicView";

const MoneyPoolHome = ({
  user,
  history,
  dispatch,
  setIsPageLoading
}) => {
  const [displayedPoolsList, setDisplayedPoolsList] = useState("")
  const [isGenerateSampleMoneyPoolVisible, setIsGenerateSampleMoneyPoolVisible] = useState(false);
  const [isRequestToJoinPoolVisible, setIsRequestToJoinPoolVisible] = useState(false);
  const [isBecomeAPoolGuarantorVisible, setIsBecomeAPoolGuarantorVisible] = useState(false);

  useEffect(() => {
    const pathname = history.location.pathname.split("/")[3]
    if (pathname) {
      setDisplayedPoolsList(pathname);
    } else {
      setDisplayedPoolsList("my-pools");
    }
  }, [history.location.pathname]);

  return (
    <div>
      <div className="d-flex">
        <div className="col-9 padding-right-0 padding-right-lg-20 stretch-full-lg">
          <center>
            <h1 className="d-block-display-sm font-size-22 dark-blue padding-bottom-10">
              Cyclic Money Pool
            </h1>
          </center>
          <div className="padding-10 padding-md-20 padding-left-md-40 padding-right-md-40 bg-lighter-blue border-radius-4">

            <div className="row align-items-center">
              <div className="d-block-hidden-sm col-sm-7">
                <h1 className="font-size-26 font-size-lg-28 dark-blue">
                  Cyclic Money Pool
                </h1>
                <h4 className="padding-top-4 font-size-16 dark-blue">
                  How cyclic money pool works
                </h4>
                <div className="padding-top-4">
                  <p className="font-size-15 padding-bottom-10">
                    <i className="fa fa-circle font-size-10 dark-blue margin-right-10"></i>
                    <span>Fill the cyclic money pool details</span>
                  </p>
                  <p className="font-size-15 padding-bottom-10">
                    <i className="fa fa-circle font-size-10 dark-blue margin-right-10"></i>
                    <span>Add members information</span>
                  </p>
                  <p className="font-size-15 padding-bottom-10">
                    <i className="fa fa-circle font-size-10 dark-blue margin-right-10"></i>
                    <span>Pick collection dates</span>
                  </p>
                  <p className="font-size-15 padding-bottom-10">
                    <i className="fa fa-circle font-size-10 dark-blue margin-right-10"></i>
                    <span>Add recurring payment setup</span>
                  </p>
                </div>
                <div
                  className=" max-width-170 margin-top-10 margin-bottom-10 margin-right-sm-20 cursor-pointer justify-content-between align-items-center border-1 border-color-red red padding-6 padding-left-sm-26 padding-right-sm-26 border-radius-8 "
                  onClick={() => setIsGenerateSampleMoneyPoolVisible(true)}
                >
                  <span className="cursor-pointer">
                    Generate Sample
                  </span>
                </div>
                <div
                  className=" max-width-190 margin-top-10 margin-bottom-10 margin-right-sm-20 cursor-pointer justify-content-between align-items-center border-1 border-color-red red padding-6 padding-left-sm-26 padding-right-sm-26 border-radius-8 "
                  onClick={() => setIsBecomeAPoolGuarantorVisible(true)}
                >
                  <span className="cursor-pointer">
                    Become a Guarantor
                  </span>
                </div>
              </div>

              <div className="col-5 d-flex justify-content-end">
                <div>
                  <img
                    className="max-width-200"
                    src={poolSetupImage}
                  />
                  <button
                    className="d-block-hidden-sm btn max-width-130 font-size-13 margin-top-10 margin-top-md-20 margin-bottom-10 padding-12 padding-sm-8 border-radius-10 bg-money-pool-color white"
                  >
                    <Link to={"/dashboard/money-pool/new"}
                      class="router-link">
                      <b>Get Started</b>
                    </Link>
                  </button>
                </div>
              </div>

              <div className="col-7 d-flex justify-content-end">
                <button
                  onClick={() => {
                    if (show_no_service_notification(user.email) == true) {
                      // Register the intention 
                      dispatch(registerPoolSetupIntention("web"))

                      NotificationManager.error("This service is not yet available. Please check back later.", "Coming Soon!")
                    } else {
                      history.push("/dashboard/money-pool/new")
                    }
                  }}
                  className="d-block-display-sm btn max-width-120 font-size-13 margin-left-6 padding-6 border-radius-10 bg-money-pool-color white"
                >
                  <Link to={"/dashboard/money-pool/new"}
                    class="router-link">
                    <b>Get Started</b>
                  </Link>
                </button>
              </div>
              <span
                onClick={() => setIsGenerateSampleMoneyPoolVisible(true)}
                className="d-block-display-sm max-width-130 margin-top-10 margin-bottom-10 margin-right-sm-20 cursor-pointer justify-content-between align-items-center border-1 border-color-red red padding-6 padding-left-sm-26 padding-right-sm-26 border-radius-8">
                Generate Sample</span>

            </div>
          </div>

          <div className="d-flex justify-content-center padding-top-16 padding-top-sm-20 padding-bottom-16 padding-bottom-sm-20">
            <div
              className={
                displayedPoolsList != "my-pools"
                  ? "btn padding-8 bg-transparent grey"
                  : "btn padding-8 border-radius-10 bg-dark-blue white darkblue"
              }
              onClick={() => {
                if (displayedPoolsList != "my-pools") {
                  history.push("/dashboard/money-pool/my-pools")
                }
              }}
            >
              <b>My Pools</b>
            </div>
            <div
              className={
                displayedPoolsList != "public-pools"
                  ? "btn padding-8 bg-transparent grey"
                  : "btn padding-8 border-radius-10 bg-dark-blue white darkblue"
              }
              onClick={() => {
                if (displayedPoolsList != "public-pools") {
                  history.push("/dashboard/money-pool/public-pools")
                }
              }}
            >
              <b>Public Pools</b>
            </div>
          </div>
        </div>
        <div
          className="col-3 d-block-hidden-lg"
        >
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isGenerateSampleMoneyPoolVisible}
        onRequestClose={() => setIsGenerateSampleMoneyPoolVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Sample Cyclic Money Pool
          </h1>
          <i
            onClick={() => setIsGenerateSampleMoneyPoolVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="overflow-scroll-y padding-10 padding-top-10 padding-bottom-10 padding-left-md-40 padding-right-md-40"
          style={{ maxHeight: "80vh" }}
        >
          <GenerateSampleMoneyPool
            setIsPageLoading={setIsPageLoading}
            history={history} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isRequestToJoinPoolVisible}
        onRequestClose={() => {
          setIsRequestToJoinPoolVisible(false)
        }}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Request To Join A Money Pool
          </h1>
          <i
            onClick={() => {
              setIsRequestToJoinPoolVisible(false)
            }}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="overflow-scroll-y padding-left-10 padding-right-10 padding-bottom-10 padding-left-md-40 padding-right-md-40"
          style={{ maxHeight: "80vh" }}
        >
          <RequestToJoinMoneyPool
            setIsPageLoading={setIsPageLoading}
            history={history}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isBecomeAPoolGuarantorVisible}
        onRequestClose={() => {
          setIsBecomeAPoolGuarantorVisible(false)
        }}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Become a Pool Guarantor
          </h1>
          <i
            onClick={() => {
              setIsBecomeAPoolGuarantorVisible(false)
            }}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="overflow-scroll-y padding-left-10 padding-right-10 padding-bottom-10 padding-left-md-40 padding-right-md-40"
          style={{ maxHeight: "80vh" }}
        >
          <BecomeAPoolGuarantor
            setIsPageLoading={setIsPageLoading}
            history={history}
            handleClose={() => setIsBecomeAPoolGuarantorVisible(false)}
          />
        </div>
      </Modal>
    </div>)
}

const MoneyPool = ({
  user,
  history,
  match,
  dispatch,
  setIsPageLoading,
  windowWidth,
  mobileBreakpoint,
  tabletBreakpoint,
  desktopBreakpoint,
}) => {
  const search = useLocation().search;
  const [pool, setPool] = useState(null);

  let { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route
          exact
          path={path}
          render={() =>
            <div>
              <MoneyPoolHome
                user={user}
                history={history}
                dispatch={dispatch}
                setIsPageLoading={setIsPageLoading}
              />
              <MyPools
                setIsPageLoading={setIsPageLoading}
                history={history}
              />
            </div>}
        />
        <Route path={`${path}/my-pools`}
          render={() =>
            <div>
              <MoneyPoolHome
                user={user}
                history={history}
                dispatch={dispatch}
                setIsPageLoading={setIsPageLoading} />
              <MyPools
                setIsPageLoading={setIsPageLoading}
                history={history}
              />
            </div>}
        />
        <Route path={`${path}/public-pools`}
          render={() =>
            <div>
              <MoneyPoolHome
                user={user}
                history={history}
                dispatch={dispatch}
                setIsPageLoading={setIsPageLoading} />
              <PublicMoneyPools
                setIsPageLoading={setIsPageLoading}
                history={history}
              />
            </div>}
        />
        <Route path={`${path}/new`}
          render={() => <div className="setup-modal">
            <div className="padding-6 padding-left-md-40 padding-right-md-40 padding-bottom-md-20">
              <MoneyPoolSetupSlides
                history={history}
                setIsPageLoading={setIsPageLoading}
                isCreatingNew={true}
              />
            </div>
          </div>}
        />
        <Route path={`${path}/edit/:slug`}
          render={() => <div className="setup-modal">
            <div className="padding-6 padding-left-md-40 padding-right-md-40 padding-bottom-md-20">
              <MoneyPoolSetupSlides
                history={history}
                setIsPageLoading={setIsPageLoading}
                isCreatingNew={false}
              />
            </div>
          </div>}
        />
        <Route path={`${path}/view/:slug`}
          render={() =>
            <div className="setup-modal">
              <div className="padding-6 padding-left-md-40 padding-right-md-40 padding-bottom-md-20">
                <MoneyPoolView
                  history={history}
                  pool={pool}
                  setPool={setPool}
                  setIsPageLoading={setIsPageLoading}
                  windowWidth={windowWidth}
                  mobileBreakpoint={mobileBreakpoint}
                  tabletBreakpoint={tabletBreakpoint}
                  desktopBreakpoint={desktopBreakpoint}
                />
              </div>
            </div>}
        />
        <Route path={`${path}/public-view/:slug`}
          render={() =>
            <MoneyPoolPublicView
              setIsPageLoading={setIsPageLoading}
              windowWidth={windowWidth}
              isForPublic={true}
            />
          }
        />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(MoneyPool);
