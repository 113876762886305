import React from "react";
import { connect } from "react-redux";
import { sendPhoneOtp } from "../../store/actions/auth";
import NationalPhoneNumber from "./NationalPhoneNumber";

const SendPhoneVerificationOtp = ({
  dispatch,
  user,
  handleClose,
  setIsVerifyPhoneNumberVisible,
  setIsPageLoading,
}) => {

  return (
    <div>
      <div className="d-flex flex-wrap align-items-center">
      <p className="margin-right-20">
        <NationalPhoneNumber internationalNumber={user.phone_number} />
      </p>
      <div className="padding-top-14 padding-bottom-14">
        <button
          className="btn bg-blue padding-6 margin-right-20"
          type="button"
          onClick={() => {
            setIsPageLoading([true, "loading..."]);
            dispatch(sendPhoneOtp())
              .then(() => {
                setIsPageLoading([false]);
                setIsVerifyPhoneNumberVisible(true)
                handleClose()
              })
          }}
        >
          Send OTP
        </button>

        <button
          className="btn bg-light-normal-blue padding-6"
          type="button"
          onClick={() => {
            setIsVerifyPhoneNumberVisible(true)
            handleClose()
          }}
        >
          Enter OTP
        </button>
      </div>
    </div>
    </div>
    
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(SendPhoneVerificationOtp);
