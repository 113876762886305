import React, { useState, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { NotificationManager } from 'react-notifications';
import { referAFriend } from "../../../../store/actions/referral";
import ReferralReward from "../../../utilityComponents/ReferralReward";

const ReferAFriend = ({ dispatch }) => {
  const [error, setError] = useState("");

  return (
    <div>
      <Formik
        initialValues={{
          referred_first_name: "",
          referred_last_name: "",
          referred_email: "",
        }}
        validationSchema={Yup.object({
          referred_first_name: Yup.string().required("Frst name is required"),
          referred_last_name: Yup.string().required("last name is required"),
          referred_email: Yup.string().required("Email is required"),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setError("");

          dispatch(referAFriend(values)).then((res) => {
            setSubmitting(false);
            if (res.error) {
              setError(res.error.message);
            } else {
              if (res.success == true) {
                NotificationManager.success("Referral email has been sent.", 'Success!')
                resetForm();
              }
            }
          });
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, isSubmitting, values }) => (
          <Form>
            <div className="padding-bottom-10">
              <ReferralReward />
            </div>
            {error ? <p className="padding-bottom-10 red">{error}</p> : null}
            <div className="row">
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-right-0 padding-right-sm-10">
                <label className="form-label">First Name</label>
                <Field
                  className="form-control padding-10"
                  placeholder="Enter first name"
                  name="referred_first_name"
                />
                <ErrorMessage
                  className="error-message"
                  name="referred_first_name"
                  component="div"
                />
              </div>
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-left-0 padding-left-sm-10">
                <div>
                  <label className="form-label">Last Name</label>
                  <Field
                    className="form-control padding-10"
                    placeholder="Enter last name"
                    name="referred_last_name"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="referred_last_name"
                    component="div"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 padding-top-10 padding-bottom-10 padding-right-0 padding-right-sm-10">
                <label className="form-label">Email</label>
                <Field
                  className="form-control padding-10"
                  placeholder="Enter email"
                  name="referred_email"
                />
                <ErrorMessage
                  className="error-message"
                  name="referred_email"
                  component="div"
                />
              </div>
            </div>

            <button
              className="btn bg-dark-blue white font-size-16 margin-top-12 margin-top-sm-20 margin-bottom-12 padding-10 max-width-180"
              onClick={handleSubmit}
            >
              Send Invitation
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    transaction_ref: state.transaction.transaction_ref,
  };
};

export default connect(mapStateToProps)(ReferAFriend);
