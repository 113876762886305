import React from "react";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3'

const MakePayment = () => {

    const config = {
        public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
        tx_ref: Date.now(),
        amount: 10000,
        currency: 'NGN',
        payment_options: 'all',
        customer: {
            email: 'user@gmail.com',
            phonenumber: '07064586146',
            name: 'joel ugwumadu',
        },
        customizations: {
            title: 'my Payment Title',
            description: 'Payment for items in cart',
            logo: process.env.REACT_APP_POUCH_MONEY_FAVICON_URL,
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    return (
        <div className="container">
            <div>
                shdhshjhdjh
            </div>
            <div>
                shdhshjhdjh
            </div>
            <button
                onClick={() => {
                    handleFlutterPayment({
                        callback: (response) => {
                            closePaymentModal() // this will close the modal programmatically
                        },
                        onClose: () => { },
                    });
                }}
            >
                Payment with React hooks
            </button>
        </div>

    );
};

export { MakePayment as default };
