import React, { useState, useContext } from "react";

const AddOrTakePhotoModal = ({ onClickTakePhoto, onClickUploadPhoto }) => {
  return (
    <div>
      <button
        className="button add-or-take-btn"
        onClick={() => onClickTakePhoto()}
      >
        Take a photo
      </button>
      <button
        className="button add-or-take-btn "
        style={{
          backgroundColor: "#2b74bc",
        }}
        onClick={() => onClickUploadPhoto()}
      >
        Upload a photo
      </button>
    </div>
  );
};

export { AddOrTakePhotoModal as default };
