import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Truncate from "react-truncate";
import { NotificationManager } from 'react-notifications';
import DisplayCollectionDates from "./fragments/DisplayCollectionDates";
import { submitPool } from "../../../../store/actions/moneyPool";
import CurrentWindowWidth from "../../../CurrentWindowWidth";
import defaultAvatar from "../../../images/defaultAvatar.png";
import { convertSnakeToStandardCasing } from "../../../../utilities";
import FormattedMoney from "../../../utilityComponents/FormattedMoney";

const MoneyPoolSummary = ({
  history,
  dispatch,
  pool,
  setPool,
}) => {
  const [isPageLoading, setIsPageLoading] = useState([false]);
  const [pictureHeight, setPictureHeight] = useState(0);
  const [coverImgHeight1, setCoverImgHeight1] = useState(0);
  const [coverImgHeight2, setCoverImgHeight2] = useState(0);

  const slideNum = 5;

  let windowWidth = CurrentWindowWidth();

  const pictureRef = useCallback(
    (node) => {
      if (node !== null) {
        setPictureHeight(node.getBoundingClientRect().height);
      }
    },
    [windowWidth, pictureHeight]
  );

  const coverImgRef1 = useCallback(
    (node) => {
      if (node !== null) {
        setCoverImgHeight1(node.getBoundingClientRect().width * 0.65);
      }
    },
    [windowWidth]
  );

  return (
    <div className="padding-left-10 padding-right-10 border-1 border-color-light-grey border-radius-8">

      <div className="padding-left-md-50 padding-right-md-50 padding-top-8 padding-bottom-8">
        <h2 className="dark-blue font-size-20 font-size-sm-21">Summary</h2>
      </div>

      <div className="horizontal-line margin-bottom-10" />

      {pool.pool_description ? (
        <div>
          <div className="padding-left-md-50 padding-right-md-50">
            <div className="padding-top-12 padding-bottom-6 padding-bottom-sm-10">
              <div className="margin-bottom-10">
                <b className="font-size-17 font-size-sm-18 dark-blue">
                  Pool Description
                </b>
              </div>

              <div className="d-flex box-shadow border-radius-14 bg-white margin-bottom-30">
                {/* <img
                  className="fixed-width-70 fixed-height-70 border-radius-4 margin-top-16 margin-bottom-16 margin-left-8 margin-left-sm-16 margin-right-6 margin-right-sm-14"
                  // height={imageHeight}
                  src={`${pool.pool_description.pool_picture ? pool.pool_description.pool_picture : defaultAvatar
                    }?${Date.now()}`}
                // src={cyclicMoneyPoolDarkBlue}
                /> */}

                <div
                  ref={coverImgRef1}
                  className="fixed-width-80 background-image border-radius-4 margin-top-16 margin-bottom-16 margin-left-8 margin-left-sm-16 margin-right-6 margin-right-sm-14"
                  style={{
                    backgroundImage: `url(${pool.pool_description.pool_picture ? pool.pool_description.pool_picture : defaultAvatar})`,
                    height: coverImgHeight1 + "px",
                  }}>
                </div>

                <div className="stretch-full padding-right-8 padding-top-20 padding-bottom-8 padding-bottom-sm-20">
                  <div className="font-size-18 dark-blue font-weight-bold padding-left-2 padding-right-2 padding-bottom-8">
                    <Truncate lines={2}>
                      {pool.pool_description.title}
                    </Truncate>
                  </div>

                  <div className="row">
                    <div className="row col-sm-6 padding-top-8 padding-bottom-8">
                      <div className="col-6 padding-left-2 padding-right-2">
                        <p className="font-size-13 line-height-13">ID</p>
                        <p className="font-size-15 dark-blue stretch-full">
                          {pool.pool_description.id}
                        </p>
                      </div>
                      <div className="col-6 padding-left-2 padding-right-2">
                        <p className="font-size-13 line-height-13 grey">
                          Frequency
                        </p>
                        <p className="font-size-15 dark-blue">
                          {convertSnakeToStandardCasing(pool.pool_description.payment_frequency)}
                        </p>
                      </div>
                    </div>
                    <div className="row col-sm-6 padding-top-8 padding-bottom-8">
                      <div className="col-6 padding-left-2 padding-right-2">
                        <p className="font-size-13 line-height-13 grey">
                          Total Units
                        </p>
                        <p className="font-size-14 font-size-sm-16 dark-blue">
                          {pool.pool_description.total_units}
                        </p>
                      </div>
                      <div className="d-flex align-items-center col-6">
                        <b className="font-size-18 line-height-14 line-height-sm-20 line-height-md-22 normal-green">
                          <FormattedMoney
                            value={pool.pool_description.unit_payment}
                            currency={pool.pool_description.unit_payment_currency}
                          />
                        </b>
                        <span className="font-size-14 font-size-sm-12 line-height-14 line-height-sm-12 dark-blue">
                          /unit
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="row col-sm-6 padding-top-8 padding-bottom-8">
                      <div className="col-6 padding-left-2 padding-right-2">
                        <p className="font-size-13 line-height-13 grey">
                          Start Date
                        </p>
                        <p className="font-size-14 font-size-sm-16 dark-blue">
                          {moment(pool.pool_description.start_date).format("DD MMM YYYY")}
                        </p>
                      </div>
                      <div className="col-6 padding-left-2 padding-right-2">
                        <p className="font-size-13 line-height-13 grey">
                          End Date
                        </p>
                        <p className="font-size-14 font-size-sm-16 dark-blue">
                          {moment(pool.pool_description.end_date).format("DD MMM YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="btn max-width-80 font-size-16 padding-6 margin-top-30 bg-red hover-bg-white white hover-red
                border-color-red border-radius-8"
                type="button"
                onClick={() => {
                  history.push(`/dashboard/money-pool/edit/${pool.pool_description.slug}?activeSlide=editPoolDescription`)
                }
                }
              >
                Edit
              </button>
            </div>

            <div className="horizontal-line margin-top-20 margin-bottom-20" />

            <div className="padding-top-10 padding-bottom-10">
              <div className="margin-bottom-10">
                <b className="font-size-17 font-size-sm-18 dark-blue">
                  Payout Dates
                </b>
              </div>
              <DisplayCollectionDates
                poolId={pool.pool_description.id}
                areYouOptedIn={pool.pool_description.are_you_opted_in}
                isDateChangeable={pool.is_date_changeable}
                collectionDates={pool.collection_dates}
                setPool={setPool}
                setIsPageLoading={setIsPageLoading}
              />
              <button
                className="btn max-width-80 font-size-16 padding-6 margin-top-30 bg-red hover-bg-white white hover-red
                border-color-red border-radius-8"
                type="button"
                onClick={() => {
                  history.push(`/dashboard/money-pool/edit/${pool.pool_description.slug}?activeSlide=poolCollectionDates`)
                }}
              >
                Edit
              </button>
            </div>
          </div>

          <div className="horizontal-line margin-top-20 margin-bottom-20" />

          <div className="d-flex justify-content-end margin-bottom-30">
            <button
              className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 bg-dark-blue
              hover-bg-white white hover-dark-blue border-color-dark-blue border-radius-12"
              onClick={() => {
                dispatch(submitPool(pool.pool_description.id)).then((res) => {
                  if (res.error) {
                    alert(`Error Message: ${res.error.message}`)
                  } else {
                    NotificationManager.success("Your Cyclic Money Pool has been saved", 'Success!')
                    history.push("/dashboard/money-pool")
                  }
                });
              }}
            >
              <b>Submit</b>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MoneyPoolSummary);
