import React, { useState, useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../store/actions/auth';
import logo from "./images/logo.png"

const HijackNotification = ({ signOutUser, user, isAuthenticated }) => {

  const [iSMobileMenu, setIsMobileMenu] = useState(false);

  return (
    <header className="header">
      <div className="content-container">
        You are currently working on behalf of {user.email} <button type="button">Relase user</button>
      </div>
    </header>
  )
}

const mapDispatchToProps = (dispatch) => ({
  signOutUser: () => dispatch(signOut())
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: !!state.auth.userToken
})

export default connect(mapStateToProps, mapDispatchToProps)(HijackNotification);
// export default HijackNotification