import React, { useState, useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { requestVerificationEmail } from '../../store/actions/auth';

const Login = ({ dispatch }) => {
    const [errorMessage, setErrorMessage] = useState("")
    const [okMessage, setOkMessage] = useState("")

    const handleRequestConfirmationEmail = async (values) => {
        setErrorMessage("")
        setOkMessage("")
        dispatch(requestVerificationEmail(values))
            .then(res => {
                if (res.error) {
                    setErrorMessage(res.error.message)
                } else {
                    if (res.success == true) {
                        setOkMessage(res.message)
                    }
                }
            })
    }

    useEffect(()=>{
        document.title = "Request Confirmation Email"
    }, [])

    return (
        <div className="container">
            <div className="content-container">
                <div className="form ">
                    <div className="form__content form__content--small">
                        <Formik
                            initialValues={{ email: '' }}
                            validationSchema={Yup.object({
                                email: Yup.string()
                                    .required('Email is required')
                                    .email('Must be an email')
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                handleRequestConfirmationEmail(values)
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <h2>Request confirmation email</h2>
                                    <div className="column">
                                        <Field className="custom-input" placeholder="Email" type="email" name="email" />
                                        <ErrorMessage className="error-message" name="email" component="div" />
                                    </div>
                                    <div className="error-message">{errorMessage}</div>
                                    <div className="ok-message">{okMessage}</div>
                                    <div>
                                        <button className="button button--secondary" type="submit" disabled={isSubmitting}>
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div className="horizontal-line" />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps)(Login)