import React, { useState, useEffect, useReducer } from 'react'
import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Playground = () => {
    const [editorState, setEditorState] = useState("")

    return (
        <div className="container">
            <h1>Welcome to playground</h1>
            <div>
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={setEditorState}
                />
            </div>
        </div>
    )
}
export { Playground as default }