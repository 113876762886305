import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import Modal from "react-modal";
import FormattedMoney from "../../../utilityComponents/FormattedMoney"
import FilterSorter from "../../../utilityComponents/FilterSorter";
import { convertSnakeToStandardCasing } from "../../../../utilities";
import Pagination from "../../../utilityComponents/Pagination";
import { getMyGuaranteedMembers } from "../../../../store/actions/moneyPool";
import ViewPoolGuarantee from "./ViewPoolGuarantee";
import AddNewGuarantee from "./AddNewGuarantee";

const MyGuarantees = ({
  user,
  dispatch,
  setIsPageLoading,
}) => {
  const [initialParams, setInitialParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  });
  const [myGuaranteesObj, setMyGuaranteesObj] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [initialTotalCount, setInitialTotalCount] = useState(0);
  const [isViewGuaranteeVisible, setIsViewGuaranteeVisible] = useState(false);
  const [specificPoolGuarantee, setSpecificPoolGuarantee] = useState({});
  const [isAddNewGuaranteeVisible, setIsAddNewGuaranteeVisible] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  let currencies = user.supported_currencies.map(item => {
    return {
      label: item,
      value: item
    }
  })

  currencies = [{ label: "All", value: "" }, ...currencies]

  const handleGetMyGuarantees = async (params) => {
    const res = await dispatch(getMyGuaranteedMembers(params))
    if (res.error) {
      NotificationManager(res.error.message, "Error!")
    }
    else {
      if (res.success == true) {
        setMyGuaranteesObj(res.data)
        setInitialParams(res.data.original_query_params)
        return res.data
      }
    }
  }

  const handleReloadGuarantees = () => {
    setRefreshKey(prev => {
      return prev + 1
    })
  }

  useEffect(() => {
    handleGetMyGuarantees(initialParams)
      .then(data => {
        if (data) {
          setInitialTotalCount(data.total_count)
        }
      })

  }, [refreshKey])

  return (
    <div>
      <div
        className="d-flex align-items-center dark-blue margin-bottom-20"
      >
        <div
          onClick={() => {
            setIsAddNewGuaranteeVisible(true)
          }} className="d-flex align-items-center justify-content-center padding-10 padding-sm-20 fixed-width-30 fixed-height-30 
        rounded-circle font-size-20 font-size-sm-28 bg-dark-blue white cursor-pointer ">
          +
        </div>
        <div
          onClick={() => {
            setIsAddNewGuaranteeVisible(true)
          }} className="margin-left-12 font-size-14 font-size-sm-18 cursor-pointer ">Add New Guarantee</div>
      </div>

      {initialTotalCount > 0 &&
        <div>
          <FilterSorter
            sorterOptions={[
              { label: 'Created Date', value: 'created_at' },
            ]}
            initialSortBy={initialParams.sort_by}
            queryOrder={initialParams.query_order}
            limit={initialParams.no_of_items_per_page}
            page={initialParams.page}
            totalCount={initialParams.total_count}
            noOfItems={myGuaranteesObj.guarantees.length}
            filterOptions={[
              {
                status: [
                  { label: "All", value: '' },
                  { label: 'Pending Redeem', value: 'PENDING_REDEEM' },
                  { label: 'Pending', value: 'PENDING' },
                  { label: 'Approved', value: 'APPROVED' },
                  { label: 'Cancelled', value: 'CANCELLED' },
                ]
              },
            ]}
            onSelectQuery={(values) => {
              setIsSearching(true)
              const newQueryObj = {
                ...initialParams,
                ...values
              }
              handleGetMyGuarantees(newQueryObj)
            }}
          />
          <div className="horizontal-line margin-top-10 margin-bottom-10" />
        </div>}

      {myGuaranteesObj &&
        myGuaranteesObj.guarantees.length > 0 ?
        <div>
          <div className="row d-flex-hidden-sm align-items-center padding-4 padding-md-0
           padding-left-md-36 padding-right-md-36 bg-light-grey">
            <div className="col-sm-5 row align-items-center">
              <div className="col-4 padding-4">ID</div>
              <div className="col-4 padding-8">Name</div>
            </div>
            <div className="col-sm-7 row align-items-center">
              <div className="col-4 padding-4"> Date</div>
              <div className="col-4 padding-4">Limit</div>
              <div className="col-4 padding-4">Status</div>
            </div>
          </div>
          {
            myGuaranteesObj.guarantees.map((guarantee, index) => {
              return (
                <div
                  key={index}
                  className="row align-items-center padding-4 
                  padding-md-16 padding-left-md-36 padding-right-md-36 border-radius-6 cursor-pointer hover-bg-lighter-grey"
                  onClick={() => {
                    setSpecificPoolGuarantee(guarantee)
                    setIsViewGuaranteeVisible(true)
                  }}
                >
                  <div className="col-sm-5 row align-items-center padding-bottom-8">
                    <div className="col-4 padding-2">
                      <div className="d-block-display-sm">
                        ID
                      </div>
                      <div>
                        <b>#{guarantee.id}</b>
                      </div>
                    </div>
                    <div className="col-8 padding-2">
                      <div className="d-block-display-sm">
                        Name
                      </div>
                      <div>
                        <b>{guarantee.guarantee_full_name}</b>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-7 row align-items-center padding-bottom-8">
                    <div className="col-4 padding-2">
                      <div className="d-block-display-sm">
                        Date
                      </div>
                      <div>
                        <b>{moment(String(guarantee.created_at)).format("Do MMM 'YY")}</b>
                      </div>
                    </div>
                    <div className="col-4 padding-2">
                      <div className="d-block-display-sm">
                        Limit
                      </div>
                      <div>
                        <b> <FormattedMoney
                          value={guarantee.guarantee_limit}
                          currency={guarantee.guarantee_limit_currency}
                        /></b>
                      </div>
                    </div>
                    <div className="col-4 padding-2">
                      <div className="d-block-display-sm">
                        Status
                      </div>
                      <div>
                        <b>
                          <b>{convertSnakeToStandardCasing(guarantee.status)}</b>
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="horizontal-line margin-top-10" />
                </div>
              )
            })
          }

          {
            (myGuaranteesObj && (myGuaranteesObj.total_count > initialParams.no_of_items_per_page)) ?
              <Pagination
                {...initialParams}
                handleGetQuery={handleGetMyGuarantees}
              /> :
              null
          }
        </div> :
        <div className="grey padding-top-10 padding-bottom-20 padding-bottom-sm-10 text-center">
          You do not have any guarantee {isSearching && "that matches this query"}
        </div>}

      <Modal
        ariaHideApp={false}
        isOpen={isViewGuaranteeVisible}
        onRequestClose={() => setIsViewGuaranteeVisible(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 
        padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Pool Guarantee Details
          </h1>
          <i
            onClick={() => setIsViewGuaranteeVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line" />
        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <ViewPoolGuarantee
            specificPoolGuarantee={specificPoolGuarantee}
            setSpecificPoolGuarantee={setSpecificPoolGuarantee}
            setIsPageLoading={setIsPageLoading}
            setRefreshKey={setRefreshKey}
            handleReloadGuarantees={handleReloadGuarantees}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isAddNewGuaranteeVisible}
        onRequestClose={() => {
          handleReloadGuarantees()
          setIsAddNewGuaranteeVisible(false)
        }}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue text-center stretch-full">
            Add New Guarantee
          </h1>
          <i
            onClick={() => {
              handleReloadGuarantees()
              setIsAddNewGuaranteeVisible(false)
            }}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line" />

        <div
          style={{ maxHeight: "80vh" }}
          className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
        >
          <AddNewGuarantee
            setIsPageLoading={setIsPageLoading}
            handleClose={() => {
              handleReloadGuarantees()
              setIsAddNewGuaranteeVisible(false)
            }} />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};


export default connect(mapStateToProps)(MyGuarantees);
