import React, { useEffect, useState, useRef } from "react";
import { NotificationManager } from "react-notifications";
import Modal from "react-modal";
import { connect } from "react-redux";
import { getAllHelpArticles } from "../../store/actions/helpArticles";

const FaqHome = ({ dispatch }) => {
  const [faq, setFaq] = useState([]);
  const [topics, setTopics] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);
  const [choosenArticleIndex, setChoosenArticleIndex] = useState(null);
  const [choosenTopicIndex, setChoosenTopicIndex] = useState(null);
  const [isMobileTableOfContentsOpen, setIsMobileTableOfContentsOpen] = useState(false);

  const searchRefs = useRef([])

  const addToSearchRefs = el => {
    if (el && !searchRefs.current.includes(el)) {
      searchRefs.current.push(el);
    }
  }

  const handleGetTopics = (faq, category) => {

    setActiveCategory(category)

    const fullCategory = faq.find((item) => item.category == category);

    let topics = [];

    if (fullCategory) {
      topics = fullCategory.topics;
    }

    setTopics(topics);

    return topics
  };

  const handleSetTopics = async (topics, topicIndex, articleIndex, keepOpen) => {
    //Update the original topics array with the openned articles
    const newTopics = topics.map((topic, originalIndex) => {
      topic.articles.map((originalArticle, originalArticleIndex) => {

        if (originalArticleIndex == articleIndex && originalIndex == topicIndex) {
          if (keepOpen) {
            originalArticle.isOpen = true
          } else {
            if (originalArticle.isOpen) {
              originalArticle.isOpen = false
            } else {
              originalArticle.isOpen = true
            }
          }
        }

        return originalArticle
      })

      return topic
    })

    setTopics(newTopics)
  }

  const handleSearchTopics = (searchString) => {
    const resultObjs = []
    const titleResults = []

    faq.forEach((item) => {
      item.topics.forEach((t, topicIndex) => {
        t.articles.forEach((a, articleIndex) => {

          const shrinkedTitle = a.title.replace(/ /g, '').toLowerCase()
          const shrinkedSearchString = searchString.replace(/ /g, '').toLowerCase()
          const regexSearchString = new RegExp(shrinkedSearchString);

          if (regexSearchString.test(shrinkedTitle)) {
            const obj = {}
            obj.category = item.category
            obj.topicIndex = topicIndex
            obj.articleIndex = articleIndex
            obj.title = a.title
            titleResults.push(a.title)
            resultObjs.push(obj)
          }
        })
      })
    })
    setSearchResults(resultObjs)
  }

  const TableOfContents = () => {
    return (
      <div>
        <h1 className="font-size-18 font-size-md-22 padding-bottom-20">
          Table of Contents
        </h1>
        {faq.map((cat, index) => {
          return (
            <p
              className="font-size-17 padding-bottom-16 cursor-pointer"
              key={index}
              onClick={() => {
                handleGetTopics(faq, cat.category)
                setIsMobileTableOfContentsOpen(false)
              }}
            >
              <b
                className={`${cat.category == activeCategory ? "orange-red" : null
                  }`}>{cat.category}</b>
            </p>
          );
        })}
      </div>
    )
  }

  useEffect(() => {
    document.title = "Frequently Asked Questions"

    dispatch(getAllHelpArticles())
      .then(res => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          if (res.success) {
            setFaq(res.data)
            handleGetTopics(res.data, "Getting started");
          }
        }
      })
  }, []);

  return (
    <div className="container">
      <div className="content-container">
        <div className="padding-top-30 padding-bottom-20 padding-bottom-md-60">
          <div className="d-flex flex-column align-items-center">
            <h1 className="font-size-20 font-size-sm-32 text-center">
              Got Questions? Look Here.
            </h1>
            <p className="padding-top-10 padding-bottom-10 text-center">
              Can't find answers? Contact us at support@pouchmoney.com.
            </p>
            <div className="max-width-600 margin-top-8 margin-bottom-20">
              <div className="d-flex margin-bottom-8 align-items-center border-radius-30 bg-lighter-grey">
                <input
                  className="input min-width-160 padding-left-20 padding-right-20 font-size-16 border-0 focus-none bg-transparent"
                  placeholder="Search help articles"
                  onKeyUp={(e) => {
                    if (e.target.value) {
                      setIsSearchResultsVisible(true)
                      handleSearchTopics(e.target.value)
                    } else {
                      setIsSearchResultsVisible(false)
                      setSearchResults([])
                    }
                  }}
                />
              </div>

              {isSearchResultsVisible &&
                <div>
                  {searchResults.length > 0 ?
                    searchResults.map((item, index) => {
                      return (
                        <div
                          className="cursor-pointer"
                          key={index}
                          onClick={async () => {
                            const topics = handleGetTopics(faq, item.category)

                            handleSetTopics(topics, item.topicIndex, item.articleIndex, true)
                            setIsSearchResultsVisible(false)

                            searchRefs.current[item.articleIndex].scrollIntoView()

                            setChoosenArticleIndex(item.articleIndex)
                            setChoosenTopicIndex(item.topicIndex)

                            setTimeout(() => {
                              setChoosenArticleIndex(null)
                              setChoosenTopicIndex(null)
                            }, 1500)
                          }}
                        >
                          {item.title}
                        </div>
                      )
                    })
                    :
                    <p>No topic matches the query</p>}
                </div>
              }
            </div>
          </div>

          <div className="d-flex">
            <div
              className="max-width-270 d-block-hidden-md"
            >
              <TableOfContents />
            </div>
            <div className="max-width-600 stretch-full-md">
              <div className="d-flex-display-md">
                <i className="fa fa-list-ul font-size-18 padding-top-10 padding-bottom-10 "
                  onClick={() => {
                    setIsMobileTableOfContentsOpen(true)
                  }}></i>
                <center className="font-size-20 font-size-md-24 dark-blue stretch-full-md" >
                  <b >{activeCategory}</b>
                </center>
              </div>

              {topics.map((topic, topicIndex) => {
                return (
                  <div key={topicIndex}>
                    <div className="padding-top-10 padding-bottom-10 font-size-18 font-size-md-22 dark-blue">
                      <b>{topic.topic}</b>
                    </div>
                    <div>
                      {topic.articles.map((art, articleIndex) => {

                        return (
                          <div
                            key={articleIndex}
                            ref={addToSearchRefs}
                          >
                            <div
                              className="align-items-center font-size-14 font-size-md-17 border-bottom-1 border-color-grey padding-bottom-18 margin-bottom-20 cursor-pointer"
                              onClick={() => {
                                handleSetTopics(topics, topicIndex, articleIndex)
                              }}
                            >
                              <div
                                className={`${articleIndex == choosenArticleIndex && topicIndex == choosenTopicIndex ? "bg-grey d-flex" : "d-flex"}`}>
                                <span>
                                  {!art.isOpen ? (
                                    <i className="fas fa-plus orange"></i>
                                  ) : (
                                    <i className="fas fa-minus orange"></i>
                                  )}
                                </span>
                                <b className="padding-left-10 padding-right-10">
                                  {art.title}
                                </b>
                              </div>
                            </div>
                            <div>
                              {art.isOpen ? (
                                <div
                                  className="font-size-14 font-size-md-16 padding-bottom-30 padding-bottom-sm-50"
                                  dangerouslySetInnerHTML={{ __html: art.body }}
                                ></div>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={isMobileTableOfContentsOpen}
          onRequestClose={() => setIsMobileTableOfContentsOpen(false)}
          className="modal modal--large fixed-height-200"
          overlayClassName="modal-backdrop"
        >
          <TableOfContents />

        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(FaqHome);
