import React, { useState, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Modal from "react-modal";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import DatePickerField from "../../../../utilityComponents/DatePickerField";
import MoneyInputField from "../../../../utilityComponents/MoneyInputField";
import AddOrTakePhotoModal from "../../../../utilityComponents/AddOrTakePhotoModal";
import ImageEditor from "../../../../utilityComponents/ImageEditor";
import PhotoCameraModal from "../../../../utilityComponents/PhotoCameraModal";
import {
  getPoolEndDate,
  createMoneyPool,
  editMoneyPoolDetails,
  uploadPoolPicture
} from "../../../../../store/actions/moneyPool";
import { base64ToBlob, convertObjectToArray, getConfirmAlert } from "../../../../../utilities";
import poolSetupImage from "../../../../images/money_pool_setup.svg";
import CurrentWindowWidth from "../../../../CurrentWindowWidth";

const PoolDescription = ({
  history,
  dispatch,
  setPool,
  initialPoolDescription,
  isCreatingNew,
  setIsPageLoading,
}) => {
  const options = [
    { value: "DAILY", label: "Daily" },
    { value: "WEEKLY", label: "Weekly" },
    { value: "BI_WEEKLY", label: "Bi-weekly" },
    { value: "MONTHLY", label: "Monthly" },
  ];
  const [changePhoto, setChangePhoto] = useState(null);
  const [isAddOrTakePhotoModalOpen, setIsAddOrTakePhotoModalOpen] = useState(false);
  const [isPhotoCameraModalVisible, setIsPhotoCameraModalVisible] = useState(false);
  const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);
  const [pictureHeight, setPictureHeight] = useState(0);
  const [poolPictureBlob, setPoolPictureBlob] = useState("")
  const [poolPictureFileType, setPoolPictureFileType] = useState("")
  const [poolPictureBase64Preview, setPoolPictureBase64Preview] = useState("")

  const poolPicture = useRef(null);

  let windowWidth = CurrentWindowWidth();

  const pictureRef = useCallback(
    (node) => {
      if (node !== null) {
        // setPictureHeight(node.getBoundingClientRect().width * 0.483);
        setPictureHeight(node.getBoundingClientRect().width * 0.6);
      }
    },
    [windowWidth]
  );

  const onClickRetakePhoto = () => {
    setIsImageEditorVisible(false);
    setIsAddOrTakePhotoModalOpen(true);
  };

  const onClickTakePhoto = () => {
    setIsAddOrTakePhotoModalOpen(false);
    setIsPhotoCameraModalVisible(true);
  };

  const onClickUploadPhoto = () => {
    setIsAddOrTakePhotoModalOpen(false);
    poolPicture.current.click();
  };

  const handleTakePhoto = (dataUri) => {
    setChangePhoto(dataUri); //For the image to be edited
    setIsImageEditorVisible(true); //Show editable picture preview
    setIsPhotoCameraModalVisible(false); //To close photo camera aperture
  };

  const handleSaveImage = (croppedImage) => {

    const convertBase64ToBlob = base64ToBlob(croppedImage);
    const fileType = `${convertBase64ToBlob.type.split("/")[0]}.${convertBase64ToBlob.type.split("/")[1]}`;

    if (isCreatingNew) {
      setPoolPictureBlob(convertBase64ToBlob)
      setPoolPictureFileType(fileType)
      setPoolPictureBase64Preview(croppedImage)
      setIsImageEditorVisible(false);
    } else {
      setIsPageLoading([true, "Uploading..."]);
      let formData = new FormData(); //formdata object
      formData.append("file", convertBase64ToBlob, fileType);
      formData.append("pool_id", initialPoolDescription.id);

      dispatch(uploadPoolPicture(formData))
        .then((response) => {
          setIsPageLoading([false]);
          if (response.errorMessage) {
          } else {
            setIsImageEditorVisible(false);
            setPool(response.data);
          }
        });
    }
  };

  const handleGetPoolEndDate = async (data) => {
    const res = await dispatch(getPoolEndDate(data))

    if (res.error) {
      NotificationManager.error(res.error.message, 'Error Message!')
    } else {
      if (res.success == true) {
        const endDate = res.data.end_date
        return endDate
      }
    }
  }

  return (
    <div>
      {initialPoolDescription ? (
        <div>
          <Formik
            initialValues={initialPoolDescription}
            validationSchema={Yup.object({
              title: Yup.string()
                .required("Title is required")
                .min(10, 'Must not be less than 10 characters')
                .max(100, 'Must not exceed 100 characters'),
              unit_payment: Yup.number()
                .typeError("Must be a number")
                .positive("Must be a positive number")
                .integer("Must be a whole number")
                .required("Unit payment is required")
                .nullable(),
              total_units: Yup.number()
                .typeError("Must be a number")
                .min(3, 'Must not be less than 3 units')
                .max(52, 'Must not exceed 52 units')
                .integer("Must be a whole number")
                .required("Total no. of units is required")
                .nullable(),
              payment_frequency: Yup.string().required(
                "Payment frequency is required"
              ),
              monthly_interest_rate: Yup.number()
                .when("is_interest_paid", {
                  is: true,
                  then: Yup.number()
                    .typeError("Must be a number")
                    .positive("Must be a positive number")
                    .required("Monthly interest rate is required")
                    .max(5, "Monthly interest rate must not be more than 5%")
                    .moreThan(0, "Monthly interest rate must be greater than 0"),
                  otherwise: Yup.number().nullable(),
                }),
              start_date: Yup.date()
                .typeError("Value must be a date")
                .required("Start date is required")
                .nullable(),
            })}

            onSubmit={(values, { setSubmitting }) => {
              setIsPageLoading([true, "Loading..."]);

              if (isCreatingNew) {
                const valuesArray = convertObjectToArray(values)

                const formData = new FormData()

                valuesArray.forEach((item) => {
                  const key = Object.keys(item)[0]
                  formData.append(key, item[key])
                })

                if (poolPictureBlob) {
                  formData.append("file", poolPictureBlob, poolPictureFileType)
                }

                dispatch(createMoneyPool(formData))
                  .then((response) => {
                    setIsPageLoading([false]);

                    if (response.error) {
                      NotificationManager.error(response.error.message, 'Error Message!')
                    } else {
                      if (response.success == true) {
                        const pool = response.data
                        setPool(pool);
                        history.push(`/dashboard/money-pool/edit/${pool.pool_description.slug}?activeSlide=poolCollectionDates`)
                      }
                    }
                  });
              } else {
                dispatch(editMoneyPoolDetails(values))
                  .then((response) => {
                    setIsPageLoading([false]);
                    if (response.error) {
                      NotificationManager.error(response.error.message, 'Error Message!')
                    } else {
                      if (response.success == true) {
                        const pool = response.data
                        setPool(pool);
                        history.push(`/dashboard/money-pool/edit/${pool.pool_description.slug}?activeSlide=poolCollectionDates`)
                      }
                    }
                  });
              }
              setSubmitting(false);
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              values,
              setFieldValue,
            }) => (
              <Form>
                <div className="padding-left-10 padding-right-10 border-1 border-color-light-grey border-radius-8">

                  <div className="padding-left-md-50 padding-right-md-50 padding-top-8 padding-bottom-8">
                    <h2 className="dark-blue font-size-19 font-size-sm-21">Money Pool Details</h2>
                  </div>

                  <div className="horizontal-line margin-bottom-10" />

                  <div className="padding-left-md-50 padding-right-md-50">
                    <div className="padding-top-10 padding-bottom-10">
                      <div className="d-flex align-items-end">
                        {isCreatingNew ?
                          <div
                            ref={pictureRef}
                            className="flex-1 max-width-200 border-radius-8 "
                            style={{ height: pictureHeight + "px" }}
                          >
                            <img
                              className="d-block border-radius-8"
                              width="100%"
                              height="100%"
                              // src={values.pool_picture ? values.pool_picture : poolSetupImage}
                              src={`${poolPictureBase64Preview
                                ? poolPictureBase64Preview
                                : poolSetupImage
                                }`}
                            />
                          </div>
                          :
                          <div
                            ref={pictureRef}
                            className="flex-1 max-width-200 border-radius-8 "
                            style={{ height: pictureHeight + "px" }}
                          >
                            <img
                              className="d-block border-radius-8"
                              width="100%"
                              height="100%"
                              // src={values.pool_picture ? values.pool_picture : poolSetupImage}

                              src={`${initialPoolDescription.pool_picture
                                ? initialPoolDescription.pool_picture
                                : poolSetupImage
                                }?${Date.now()}`}
                            />
                          </div>
                        }
                        <button
                          type="button"
                          className="btn margin-left-10 margin-left-md-20 font-size-14 font-size-sm-16 padding-10 max-width-100 bg-light-green white border-radius-10"
                          onClick={() => {
                            setIsAddOrTakePhotoModalOpen(true);
                          }}
                        >
                          Change
                        </button>

                        <input
                          hidden
                          ref={poolPicture}
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.currentTarget.files[0];
                            if (file) {
                              if (file.size > 10000000) {
                                //Maximum of 10MB
                                alert("File too large. Max. 10MB");
                              } else {
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                  setChangePhoto(reader.result);
                                  setIsImageEditorVisible(true);
                                };
                                reader.readAsDataURL(file);
                              }
                            }
                          }}
                        />

                        <div>
                          {isImageEditorVisible ? (
                            <div>
                              <ImageEditor
                                src={changePhoto}
                                handleSaveImage={handleSaveImage}
                                onClickRetakePhoto={onClickRetakePhoto}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="padding-top-10 padding-bottom-10">
                        <label className="form-label">Title *</label>
                        <Field
                          className="form-control padding-10"
                          placeholder="Enter title"
                          name="title"
                        />
                        <ErrorMessage
                          className="error-message"
                          name="title"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 padding-top-10 padding-bottom-10">
                        <div className="max-width-320 stretch-full-sm padding-right-0 padding-right-sm-10">
                          <label className="form-label">
                            Amount Per Hand *
                          </label>
                          <div>
                            <MoneyInputField
                              className="form-control padding-6"
                              value={values.unit_payment}
                              currency={values.unit_payment_currency}
                              onChange={(value) => {
                                setFieldValue("unit_payment", value)
                              }}
                              placeholder="Enter payment per hand"
                            />
                          </div>
                          <div>
                          </div>
                          <ErrorMessage
                            className="error-message"
                            name="unit_payment"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 padding-top-10 padding-bottom-10">
                        <div className="max-width-320 stretch-full-sm float-end padding-left-0 padding-left-sm-10">
                          <label className="form-label">
                            Total No. of Hands *
                          </label>
                          <Field
                            className="form-control padding-10"
                            placeholder="Enter total no. of hands"
                            type="number"
                            name="total_units"
                            onBlur={() => {
                              if (
                                values.payment_frequency &&
                                values.start_date &&
                                values.total_units
                              ) {
                                handleGetPoolEndDate({
                                  payment_frequency: values.payment_frequency,
                                  start_date: values.start_date,
                                  total_units: values.total_units
                                })
                                  .then((endDate) => {
                                    setFieldValue("end_date", endDate)
                                  })
                              }
                            }}
                          />
                          <ErrorMessage
                            className="error-message"
                            name="total_units"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 padding-top-10 padding-bottom-10">
                        <div className="max-width-320 stretch-full-sm padding-right-0 padding-right-sm-10">
                          <label className="form-label">
                            Interval *
                          </label>
                          <Field
                            component={() => (
                              <Select
                                className="form-control padding-0"
                                classNamePrefix="react-select"
                                placeholder="Select contribution interval"
                                options={options}
                                value={
                                  options
                                    ? options.find(
                                      (option) =>
                                        option.value ===
                                        values.payment_frequency
                                    )
                                    : ""
                                }
                                onChange={(option) => {
                                  setFieldValue(
                                    "payment_frequency",
                                    option.value
                                  );
                                  if (
                                    option.value &&
                                    values.start_date &&
                                    values.total_units
                                  ) {
                                    handleGetPoolEndDate({
                                      payment_frequency: option.value,
                                      start_date: values.start_date,
                                      total_units: values.total_units
                                    })
                                      .then((endDate) => {
                                        setFieldValue("end_date", endDate)
                                      })
                                  }
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            className="error-message"
                            name="payment_frequency"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 padding-top-10 padding-bottom-10">
                        <div className="max-width-320 stretch-full-sm padding-right-0 padding-right-sm-10">
                          <label className="form-label">
                            Do you want to pay interests among yourselves?
                          </label>
                          <div className="d-flex">
                            <label>
                              <input
                                className="radio-btn"
                                type="radio"
                                name="is_interest_paid"
                                checked={values.is_interest_paid == false}
                                onChange={() => {
                                  setFieldValue("is_interest_paid", false);
                                  setFieldValue("monthly_interest_rate", 0);
                                }}
                              />
                              No &nbsp;&nbsp;
                            </label>
                            <label>
                              <input
                                className="radio-btn"
                                type="radio"
                                name="is_interest_paid"
                                checked={values.is_interest_paid == true}
                                onChange={() => {
                                  setFieldValue("is_interest_paid", true);
                                }}
                              />
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      {values.is_interest_paid && <div className="col-sm-6 padding-top-10 padding-bottom-10">
                        <div className="max-width-320 stretch-full-sm float-end padding-left-0 padding-left-sm-10">
                          <label className="form-label">
                            Monthly Interest Rate
                          </label>
                          <div
                            className="d-flex">
                            <Field
                              className="form-control max-width-60"
                              type="number"
                              name="monthly_interest_rate"
                            />
                            <label
                              className="max-width-50 padding-left-10 padding-top-6">
                              %
                            </label>
                          </div>
                          <ErrorMessage
                            className="error-message"
                            name="monthly_interest_rate"
                            component="div"
                          />
                        </div>
                      </div>}
                    </div>

                    <div className="row">
                      <div className="col-sm-6 padding-top-10 padding-bottom-10">
                        <div className="max-width-320 stretch-full-sm padding-right-0 padding-right-sm-10">
                          <label className="form-label">
                            Can members of the public join this money pool?
                          </label>
                          <div className="d-flex">
                            <label>
                              <input
                                className="radio-btn"
                                type="radio"
                                name="open_to_public"
                                checked={values.open_to_public == false}
                                onChange={() => {
                                  setFieldValue("open_to_public", false);
                                }}
                              />
                              No &nbsp;&nbsp;
                            </label>
                            <label>
                              <input
                                className="radio-btn"
                                type="radio"
                                name="open_to_public"
                                checked={values.open_to_public == true}
                                onChange={() => {
                                  getConfirmAlert({
                                    title: "Confirm!",
                                    message: "Note that this cyclic money pool will be seen by the public and anyone eligible can join as members",
                                    onClickYes: () => {
                                      setFieldValue("open_to_public", true);
                                    },
                                    onClickNo: () => null
                                  })
                                }}
                              />
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 padding-top-10 padding-bottom-10">
                        <div className="max-width-320 stretch-full-sm padding-right-0 padding-right-sm-10">
                          <label className="form-label">Pool Start Date *</label>
                          <DatePickerField
                            name="start_date"
                            value={values.start_date}
                            minDate={new Date(new Date().getTime() + 4.32e+7)}
                            placeholderText="Start Date"
                            onChange={(event, selectedDate) => {
                              const currentDate = selectedDate;
                              setFieldValue("start_date", currentDate);

                              if (
                                values.payment_frequency &&
                                currentDate &&
                                values.total_units
                              ) {
                                handleGetPoolEndDate({
                                  payment_frequency: values.payment_frequency,
                                  start_date: currentDate,
                                  total_units: values.total_units
                                })
                                  .then((endDate) => {
                                    setFieldValue("end_date", endDate)
                                  })
                              }
                            }}
                          />

                          <ErrorMessage
                            className="error-message"
                            name="start_date"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 padding-top-10 padding-bottom-10">
                        <div className="max-width-320 stretch-full-sm float-end padding-left-0 padding-left-sm-10">
                          <label className="form-label">Pool End Date</label>
                          <DatePickerField
                            name="end_date"
                            value={values.end_date}
                            onChange={setFieldValue}
                            placeholderText="End Date"
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="horizontal-line margin-top-20 margin-bottom-30" />

                  <div className="d-flex justify-content-end margin-bottom-30">
                    <button
                      className="btn font-size-15 font-size-md-18 margin-right-10 max-width-160 padding-10 padding-md-16 
                      bg-dark-blue hover-bg-white white hover-dark-blue border-color-dark-blue border-radius-12"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          <Modal
            ariaHideApp={false}
            isOpen={isPhotoCameraModalVisible}
            onRequestClose={() => setIsPhotoCameraModalVisible(false)}
            className="modal black-background"
            overlayClassName="modal-backdrop"
            contentLabel="Take a picture"
          >
            <PhotoCameraModal handleTakePhoto={handleTakePhoto} />
          </Modal>

          <Modal
            ariaHideApp={false}
            isOpen={isAddOrTakePhotoModalOpen}
            onRequestClose={() => setIsAddOrTakePhotoModalOpen(false)}
            className="modal"
            overlayClassName="modal-backdrop"
          >
            <AddOrTakePhotoModal
              onClickTakePhoto={onClickTakePhoto}
              onClickUploadPhoto={onClickUploadPhoto}
            />
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default connect()(PoolDescription);
