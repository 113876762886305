import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import { getPoolTransactions } from "../../../../../store/actions/moneyPool";
import { convertSnakeToStandardCasing } from "../../../../../utilities";
import FilterSorter from "../../../../utilityComponents/FilterSorter";
import Pagination from "../../../../utilityComponents/Pagination";

const PoolTransactions = ({
  dispatch,
  pool,
}) => {
  const [visibleComponent, setVisibleComponent] = useState("summary")
  const [initialTransactionList, setInitialTransactionList] = useState([]);
  const [poolTransactionsObj, setPoolTransactionsObj] = useState({});
  const [queryParams, setQueryParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  })
  const [collectionDatesLabels, setCollectionDatesLabels] = useState([])
  const [isSearching, setIsSearching] = useState(false)

  const handleGetPoolTransactions = async (params) => {
    // Fetch the list of this pool transactions
    const res = await dispatch(getPoolTransactions({
      poolId: pool.pool_description.id,
      queryParams: params
    }))

    if (res.success == true) {
      setPoolTransactionsObj(res.data)
      setQueryParams(res.data.original_query_params)
      return res.data
    }
  }

  useEffect(() => {
    handleGetPoolTransactions(queryParams)
      .then(data => {
        if (data) {
          setInitialTransactionList(data.transactions)
        }
      })

    const collectionDates = pool.collection_dates.map((item) => {
      return {
        "label": moment(item.date).format("Do MMM YYYY"),
        "value": item.date
      }

    })
    setCollectionDatesLabels([{
      label: "All",
      value: ""
    },
    ...collectionDates])

  }, [])

  return (
    <div>
      <div className="d-flex margin-bottom-20">
        <div
          className={`btn padding-8 margin-right-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "summary" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange dark-blue"}`}
          onClick={() => setVisibleComponent("summary")}>
          Summary
        </div>
        <div
          className={`btn padding-8 margin-left-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "history" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange dark-blue"}`}
          onClick={() => {
            setVisibleComponent("history")
            handleGetPoolTransactions(queryParams)
          }}>
          History
        </div>
      </div>

      {visibleComponent == "summary" &&
        <div>
          <div className="padding-10 font-size-13 font-size-md-14 border-1 border-color-light-grey border-radius-4">
            <div className="row">
              <div className="col-4 stretch-full-sm padding-top-8 padding-bottom-8">
                Next Due Date
              </div>
              <div className="col-8 stretch-full-sm padding-top-8 padding-bottom-8">
                <b>{moment(String(pool.pool_description.next_due_date)).isSame(new Date(), "day") ? "Today" :
                  <span>{moment(String(pool.pool_description.next_due_date)).format("Do MMM YYYY")}</span>
                }</b>
              </div>
            </div>

            <div className="row">
              <div className="col-4 stretch-full-sm padding-top-8 padding-bottom-8">
                Total In Flow
              </div>
              <div className="col-8 stretch-full-sm padding-top-8 padding-bottom-8 blue">
                <b>{pool.pool_transaction_summary.total_paid_in ?
                  <FormattedMoney
                    value={pool.pool_transaction_summary.total_paid_in}
                    currency={pool.pool_transaction_summary.currency}
                  /> :
                  "NIL"}</b>
              </div>
            </div>

            <div className="row">
              <div className="col-4 stretch-full-sm padding-top-8 padding-bottom-8">
                Total Out Flow
              </div>
              <div className="col-8 stretch-full-sm padding-top-8 padding-bottom-8 red">
                <b>
                  {pool.pool_transaction_summary.total_paid_out ?
                    <FormattedMoney
                      value={Math.abs(pool.pool_transaction_summary.total_paid_out)}
                      currency={pool.pool_transaction_summary.currency}
                    /> : "NIL"}
                </b>
              </div>
            </div>

            <div className="row">
              <div className="col-4 stretch-full-sm padding-top-8 padding-bottom-8">
                Current Balance
              </div>
              <div className="col-8 stretch-full-sm padding-top-8 padding-bottom-8 green">
                <b>
                  {pool.pool_transaction_summary.balance ?
                    <FormattedMoney
                      value={pool.pool_transaction_summary.balance}
                      currency={pool.pool_transaction_summary.currency}
                    /> : "NIL"}
                </b>
              </div>
            </div>
          </div>
        </div>}

      {visibleComponent == "history" &&
        <div>
          {initialTransactionList.length > 0 &&
            <FilterSorter
              sorterOptions={[
                { label: 'Payment Date', value: 'created_at' },
                { label: 'Collection Date', value: 'collection_date' },
              ]}
              initialSortBy={queryParams.sort_by}
              queryOrder={queryParams.query_order}
              limit={queryParams.no_of_items_per_page}
              page={queryParams.page}
              totalCount={queryParams.total_count}
              noOfItems={poolTransactionsObj.transactions.length}
              filterOptions={[
                {
                  status: [
                    { label: "All", value: '' },
                    { label: 'Pending', value: 'PENDING' },
                    { label: 'Completed', value: 'COMPLETE' },
                    { label: 'Cancelled', value: 'CANCELLED' },
                    { label: 'Failed', value: 'FAILED' },
                  ]
                },
                {
                  collection_date: collectionDatesLabels
                },
              ]}
              onSelectQuery={(values) => {
                setIsSearching(true)
                const newQueryObj = {
                  ...queryParams,
                  ...values
                }
                handleGetPoolTransactions(newQueryObj)
              }}
            />}

          {poolTransactionsObj && poolTransactionsObj.transactions && poolTransactionsObj.transactions.length > 0 ?
            <div className="font-size-12 font-size-md-14">
              <div className="margin-top-10 margin-bottom-10">
                {poolTransactionsObj.transactions.map((tran, index) => {
                  return (
                    <div
                      className="row margin-top-14 margin-bottom-14 box-shadow dark-blue border-radius-6 padding-top-4 padding-bottom-4 padding-left-8 padding-right-8"
                      key={index}
                    >
                      <div className="row align-items-center padding-top-10 padding-bottom-10 padding-left-4 padding-right-4">
                        <div className="row align-items-center">
                          <div className="col-sm-4 padding-top-6 padding-bottom-6 padding-4">
                            <label className="grey font-size-13 line-height-13">ID</label>
                            <p className="font-size-14 line-height-14 text-break"> 
                              #{tran.id}
                            </p>
                          </div>
                          <div className="col-sm-4 padding-top-6 padding-bottom-6 padding-4">
                            <label className="grey font-size-13 line-height-13">Member</label>
                            <p className="font-size-14 line-height-14 text-break">
                              {tran.member_full_name}
                            </p>
                          </div>
                          <div className="col-sm-4 padding-top-6 padding-bottom-6 padding-4">
                            <label className="grey font-size-13 line-height-13"> Paid {tran.amount > 0 ? "In":"Out"} Amount</label>
                            <p className={`font-size-14 line-height-14  ${tran.amount > 0 ? "green": "red"} `}>
                              <FormattedMoney value={Math.abs(tran.amount)} currency={tran.amount_currency} />
                            </p>
                          </div>
                        </div>

                        <div className="row align-items-center">
                          <div className="col-sm-4 padding-top-6 padding-bottom-6 padding-4">
                            <label className="grey font-size-13 line-height-13">Collection Date</label>
                            <p className="font-size-14 line-height-14">
                              {moment(String(tran.collection_date)).format("Do MMM YYYY")}
                            </p>
                          </div>
                          <div className="col-sm-4 padding-top-6 padding-bottom-6 padding-4">
                            <label className="grey font-size-13 line-height-13">Status</label>
                            <p className="font-size-14 line-height-14">
                              {convertSnakeToStandardCasing(tran.status)}
                            </p>
                          </div>
                          <div className="col-sm-4 padding-top-6 padding-bottom-6 padding-4">
                            <label className="grey font-size-13 line-height-13">Payment Date</label>
                            <p className="font-size-14 line-height-14">
                              {moment(String(tran.created_at)).format("DD MMM 'YY, HH:mm a")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {poolTransactionsObj && poolTransactionsObj.total_count > queryParams.no_of_items_per_page ?
                  <Pagination
                    {...queryParams}
                    handleGetQuery={handleGetPoolTransactions}
                  /> :
                  null}
              </div>
            </div>
            :
            <center className="font-size-14 padding-top-12 grey">
              You do not have any transaction history {isSearching ? "that match this query" : ""}
            </center>}
        </div>}

    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PoolTransactions);
