import React, { useState } from "react";
import Modal from "react-modal"
import moment from "moment";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import { getPoolAmountToPay, payToPoolFromBank, payToPoolFromWallet } from "../../../../../store/actions/moneyPool";
import { getConfirmAlert, getCurrencySymbol, numberWithCommas } from "../../../../../utilities";
import PayNow from "../../../../payments/PayNow";

const PoolMemberMissedPayments = ({
  dispatch,
  user,
  transaction_ref,
  missedPayments,
  pool,
  setPool,
  handleGetMemberMissedPayments
}) => {
  const [isPaySourceOptionVisible, setIsPaySourceOptionVisible] = useState(false)
  const [missedPaymentDetails, setMissedPaymentDetails] = useState({})

  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: transaction_ref,
    payment_options: "all",
    customer: {
      email: user.email,
      phonenumber: user.phone_number,
      name: `${user.first_name} ${user.last_name}`,
    },
    customizations: {
      // title: 'my Payment Title',
      // description: 'Payment for items in cart',
      logo: process.env.REACT_APP_POUCH_MONEY_FAVICON_URL,
    },
  };

  const handlePayFromBank = (values) => {
    dispatch(
      payToPoolFromBank({
        pool_id: pool.pool_description.id,
        transaction_id: values.transaction_id,
        id_of_member_on_behalf: missedPaymentDetails.id_of_member_on_behalf,
      })
    ).then((res) => {
      if (res.error) {
        NotificationManager.error(res.error.message, 'Error message')
      } else {
        if (res.success == true) {
          handleGetMemberMissedPayments(missedPaymentDetails.member_email)
          setIsPaySourceOptionVisible(false)
          NotificationManager.success(`${res.message}`, 'Success!')
          setPool(res.data)
        }
      }
    })
  };

  const handlePayFromWallet = () => {
    getConfirmAlert({
      title: "Confirm!",
      message: `Your Pouch Wallet will be debited
       ${getCurrencySymbol(missedPaymentDetails.amount_currency)} ${numberWithCommas(missedPaymentDetails.due_amount.toFixed(2))} for ${missedPaymentDetails.name} . 
            You want to continue?`,
      onClickYes: () => {
        dispatch(
          payToPoolFromWallet({
            pool_id: pool.pool_description.id,
            id_of_member_on_behalf: missedPaymentDetails.id_of_member_on_behalf,
          })
        ).then((res) => {
          if (res.error) {
            NotificationManager.error(res.error.message, 'Error message')
          } else {
            if (res.success == true) {
              handleGetMemberMissedPayments(missedPaymentDetails.member_email)
              setPool(res.data)
              setIsPaySourceOptionVisible(false)
              NotificationManager.success(`${res.message}`, 'Success!')
            }
          }
        });
      },
      onClickNo: () => null,
    })
  }

  return (
    <div>
      {missedPayments && missedPayments.length > 0 ?
        <div className="padding-top-6 padding-bottom-6">
          {missedPayments && missedPayments.length > 0 &&
            missedPayments.map((payment, index) => {
              return (
                <div>
                  <div className="box-shadow bg-reddd border-radius-4 padding-4 margin-2 margin-top-10 margin-bottom-10">
                    <div
                      key={index}
                      className="row align-items-center">
                      <div className="col-sm-9 d-flex">
                        <div className="col-sm-6 padding-4 stretch-full">
                          <div className="font-size-13 grey">Due Date</div>
                          <span className="dark-blue">{moment(payment.due_date).format("Do MMM YYYY")}</span>
                        </div>

                        <div className="col-sm-6 padding-4 stretch-full">
                          <div className="font-size-13 grey">Due Amount</div>
                          <b className="normal-green">
                            <FormattedMoney value={payment.due_amount} currency={payment.currency} decimalScale={2} />
                          </b>
                        </div>
                      </div>

                      <div className="col-sm-3 padding-4">
                        <button
                          className="btn bg-orange max-width-100 padding-4 marign-top-4 margin-bottom-4"
                          type="button"
                          onClick={() => {
                            dispatch(getPoolAmountToPay({
                              pool_id: pool.pool_description.id,
                              id_of_member_on_behalf: payment.user_id
                            }))
                              .then(res => {
                                if (res.error) {
                                  NotificationManager.error(res.error.message, "Error!")
                                } else {
                                  if (res.success) {
                                    setMissedPaymentDetails(res.data)
                                    setIsPaySourceOptionVisible(true)
                                  }
                                }
                              })
                          }}
                          disabled={index > 0}
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
        :
        <div className="grey text-center padding-top-20 padding-bottom-20">
          This member has no missed payments
        </div>
      }

      <Modal
        ariaHideApp={false}
        isOpen={isPaySourceOptionVisible}
        onRequestClose={() => {
          setIsPaySourceOptionVisible(false);
        }}
        className="modal max-width-720 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-4 padding-left-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue text-center">
            Payment Details
          </h1>
          <i
            onClick={() => {
              setIsPaySourceOptionVisible(false);
            }}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-4">
          <div
            style={{ maxHeight: "550px" }}
            className="overflow-scroll-y padding-10 padding-top-sm-10 padding-bottom-20 padding-left-md-30 padding-right-md-30"
          >
            <div>
              <div className="row padding-top-8 padding-bottom-8">
                <span className="col-sm-3 grey">
                  Due Date
                </span>
                <span className="col-sm-9 dark-blue">
                  {moment(missedPaymentDetails.earliest_due_date).format("Do MMM YYYY")}
                </span>
              </div>

              <div className="row padding-top-8 padding-bottom-8">
                <span className="col-sm-3 grey">Principal</span>
                <div className="col-sm-9 d-flex flex-wrap align-items-center">
                  <b className="padding-right-6 normal-green">
                    <FormattedMoney
                      value={missedPaymentDetails.total_standard_due_payment}
                      currency={missedPaymentDetails.amount_currency}
                      decimalScale={2} />
                  </b>
                  <span className="dark-blue"></span>
                </div>
              </div>

              <div className="row padding-top-8 padding-bottom-8">
                <span className="col-sm-3 grey">Standard Interest</span>
                <div className="col-sm-9 d-flex flex-wrap align-items-center">
                  <b className="padding-right-6 normal-green">
                    <FormattedMoney
                      value={missedPaymentDetails.accrued_standard_interest}
                      currency={missedPaymentDetails.amount_currency}
                      decimalScale={2} />
                  </b>
                  <span className="dark-blue"></span>
                </div>
              </div>

              <div className="row padding-top-8 padding-bottom-8">
                <span className="col-sm-3 grey">Overdue Interest</span>
                <div className="col-sm-9 d-flex flex-wrap align-items-center">
                  <b className="padding-right-6 normal-green">
                    <FormattedMoney
                      value={missedPaymentDetails.accrued_overdue_interest}
                      currency={missedPaymentDetails.amount_currency}
                      decimalScale={2} />
                  </b>
                  <span className="dark-blue"></span>
                </div>
              </div>

              <div className="row padding-top-8 padding-bottom-8">
                <span className="col-sm-3 grey">Total Due Amount</span>
                <div className="col-sm-9 d-flex flex-wrap align-items-center">
                  <b className="padding-right-6 normal-green">
                    <FormattedMoney
                      value={missedPaymentDetails.due_amount}
                      currency={missedPaymentDetails.amount_currency}
                      decimalScale={2} />
                  </b>
                  <span className="dark-blue">(excl. bank charges)</span>
                </div>
              </div>
            </div>

            <PayNow
              config={{
                ...config,
                amount: missedPaymentDetails.due_amount,
                currency: pool.pool_description.unit_payment_currency,
                meta: {
                  service_id: pool.pool_description.id,
                  service_type: "pool",
                  user_id: user.id,
                  email: missedPaymentDetails.email,
                  id_of_member_on_behalf: missedPaymentDetails.id_of_member_on_behalf,
                  accrued_standard_interest: String(missedPaymentDetails.accrued_standard_interest),
                  accrued_overdue_interest: String(missedPaymentDetails.accrued_overdue_interest),
                  earliest_due_date: missedPaymentDetails.earliest_due_date
                }
              }}
              onBankPaymentSuccessful={(transactionId) => handlePayFromBank({
                transaction_id: transactionId
              })}
              handlePayFromWallet={handlePayFromWallet}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};


const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    transaction_ref: state.transaction.transaction_ref
  };
};


export default connect(mapStateToProps)(PoolMemberMissedPayments);
