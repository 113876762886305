import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { connect } from "react-redux";
import moment from "moment";
import { getPrivacyPolicy } from "../../store/actions/blog";

const PrivacyPolicy = ({ dispatch }) => {
  const [privacyPolicy, setPrivacyPolicy] = useState({})
  const [error, setError] = useState("")

  useEffect(() => {
    document.title = "Privacy Policy"
    
    dispatch(getPrivacyPolicy())
      .then(res => {
        if (res.error) {
          setError(res.error.message)
        } else {
          if (res.success) {
            setPrivacyPolicy(res.data)
          }
        }
      })
  }, [])

  return (
    <div className="container">
      <div className="padding-top-40 padding-top-md-80 padding-bottom-0 padding-bottom-md-80">
        <div className="content-container">
          {error ?
            error
            :
            <div>
              <center>
                <h1>
                  {privacyPolicy.title}
                </h1>
              </center>
              <br />
              <div>
                Last updated <b>{moment(privacyPolicy.updated_at).format("YYYY-MM-DD")}</b>
              </div>
              <br />
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(privacyPolicy.content) }} />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default connect()(PrivacyPolicy);

