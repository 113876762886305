import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import moment from "moment"
import { getMyAccruedReferralEarning, payoutEarningsToWallet, getReferralTransactions } from "../../../../store/actions/referral";
import { convertSnakeToStandardCasing, getConfirmAlert } from "../../../../utilities";
import FormattedMoney from "../../../utilityComponents/FormattedMoney";
import FilterSorter from "../../../utilityComponents/FilterSorter";
import Pagination from "../../../utilityComponents/Pagination";

const MyAccruedEarning = ({ user, dispatch, setIsPageLoading }) => {
  const [myAccruedEarnings, setMyAccruedEarnings] = useState([]);
  const [initialParams, setInitialParams] = useState({
    page: 1,
    no_of_items_per_page: 10,
    query_order: "asc",
    sort_by: "created_at",
    total_count: 0
  });
  const [referralTransactionsObj, setReferralTransactionsObj] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [visibleComponent, setVisibleComponent] = useState("summary");
  const [initialTotalTransactionCount, setInitialTotalTransactionCount] = useState(0);

  let currencies = user.supported_currencies.map(item => {
    return {
      label: item,
      value: item
    }
  })

  currencies = [{ label: "All", value: "" }, ...currencies]

  const handleGetAccruedEarnings = () => {
    dispatch(getMyAccruedReferralEarning())
      .then((res) => {
        if (res.error) {
          NotificationManager.error(res.error.message, "Error!")
        } else {
          setMyAccruedEarnings(res.data);
        }
      });
  };

  const handleGetReferralTransactions = async (params) => {
    const res = await dispatch(getReferralTransactions(params))
    if (res.error) {
      NotificationManager.error(res.error.message, "Error!")
    } else {
      if (res.success == true) {
        setReferralTransactionsObj(res.data);
        setInitialParams(res.data.original_query_params)
        return res.data
      }
    }
  }

  useEffect(() => {
    handleGetAccruedEarnings();
    handleGetReferralTransactions(initialParams)
      .then(data => {
        if (data) {
          setInitialTotalTransactionCount(data.total_count)
        }
      })
  }, []);

  return (
    <div>
      <div className="d-flex margin-bottom-10 margin-bottom-sm-20">
        <div
          className={`btn padding-8 margin-right-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "summary" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange dark-blue"}`}
          onClick={() => setVisibleComponent("summary")}>
          Summary
        </div>
        <div
          className={`btn padding-8 margin-left-10 border-bottom-2 border-radius-0 font-weight-bold
           ${visibleComponent != "transactions" ? "border-bottom-color-transparent grey" : "border-bottom-color-orange dark-blue"}`}
          onClick={() => setVisibleComponent("transactions")}>
          Transactions
        </div>
      </div>

      {visibleComponent == "summary" &&
        <div>
          <div className="d-flex">
            <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-10">
              <div className="max-width-230 padding-0 padding-sm-16 text-center">
                <p className="padding-bottom-10 font-size-14 normal-green">
                  <b>Currency</b>
                </p>
              </div>
            </div>
            <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-10">
              <div className="max-width-230 padding-0 padding-sm-16 text-center">
                <p className="padding-bottom-10 font-size-14 normal-green">
                  <b>Earnings</b>
                </p>
              </div>
            </div>
            <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-20 margin-bottom-sm-10">
              <div className="max-width-230 padding-0 padding-sm-16 text-center">
                <p className="padding-bottom-10 font-size-14 blue">
                  <b>Paid Out</b>
                </p>
              </div>
            </div>
            <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-20 margin-bottom-sm-10">
              <div className="max-width-230 padding-0 padding-sm-16 text-center">
                <p className="padding-bottom-10 font-size-14 blue">
                  <b>Balance</b>
                </p>
              </div>
            </div>
          </div>

          {myAccruedEarnings.length > 0 ?
            <div>
              {myAccruedEarnings.map((item, index) => {
                return (
                  <div key={index} className="d-flex bg-grey padding-10 margin-top-10 margin-bottom-10 border-radius-6">
                    <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-10">
                      <div className="max-width-230 padding-0 padding-sm-16 text-center">
                        <div>
                          <b className="font-size-14 line-height-14 font-size-sm-18 line-height-sm-18">
                            <b className="font-size-14 line-height-14 font-size-sm-18 line-height-sm-18">
                              {item.currency}</b>
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-10">
                      <div className="max-width-230 padding-0 padding-sm-16 text-center">
                        <div>
                          <b className="font-size-14 line-height-14 font-size-sm-18 line-height-sm-18">
                            <FormattedMoney value={item.total_earning} currency={item.currency} />
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-20 margin-bottom-sm-10">
                      <div className="max-width-230 padding-0 padding-sm-16 text-center">
                        <div>
                          <b className="font-size-14 line-height-14 font-size-sm-18 line-height-sm-18">
                            <FormattedMoney value={item.total_paid_out} currency={item.currency} />
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-20 margin-bottom-sm-10">
                      <div className="max-width-230 padding-0 padding-sm-16 text-center">
                        <div>
                          <b className="font-size-14 line-height-14 font-size-sm-18 line-height-sm-18">
                            <FormattedMoney value={item.balance_amount} currency={item.currency} />
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              <button
                className="btn bg-dark-blue max-width-220 font-size-15 padding-10 margin-top-20 margin-bottom-14"
                type="button"
                onClick={() => {
                  getConfirmAlert({
                    title: "Confirm Transfer!",
                    message: "Are you sure you want to transfer all your accrued referral earnings to your Pouch wallet?",
                    onClickYes: () => {
                      setIsPageLoading([true, "Loading..."]);

                      dispatch(
                        payoutEarningsToWallet()
                      ).then((res) => {
                        setIsPageLoading([false]);
                        if (res.error) {
                          NotificationManager.error(res.error.message, "Error!")
                        } else {
                          if (res.success = true) {
                            setMyAccruedEarnings(res.data);
                            NotificationManager.success(res.message, "Success!")
                          }
                        }
                      });
                    },
                    onClickNo: () => { },

                  })
                }}
              >
                Transfer Balance to Wallet
              </button>
            </div>
            :
            <div className="d-flex">
              <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-10">
                <div className="max-width-230 padding-0 padding-sm-16 text-center">
                  <div>
                    <b className="font-size-15 line-height-15 font-size-sm-17 line-height-sm-17">0</b>
                  </div>
                </div>
              </div>
              <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-10">
                <div className="max-width-230 padding-0 padding-sm-16 text-center">
                  <div>
                    <b className="font-size-15 line-height-15 font-size-sm-17 line-height-sm-17">0</b>
                  </div>
                </div>
              </div>
              <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-10">
                <div className="max-width-230 padding-0 padding-sm-16 text-center">
                  <div>
                    <b className="font-size-15 line-height-15 font-size-sm-17 line-height-sm-17">0</b>
                  </div>
                </div>
              </div>
              <div className="col-3 d-flex justify-content-center margin-top-10 margin-bottom-10">
                <div className="max-width-230 padding-0 padding-sm-16 text-center">
                  <div>
                    <b className="font-size-15 line-height-15 font-size-sm-17 line-height-sm-17">0</b>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>}

      {visibleComponent == "transactions" &&
        <div>
          <h3>Referral Transactions</h3>

          {initialTotalTransactionCount > 0 &&
            <FilterSorter
              sorterOptions={[
                { label: 'Created Date', value: 'created_at' },
                { label: 'Amount', value: 'amount' },
              ]}
              initialSortBy={initialParams.sort_by}
              queryOrder={initialParams.query_order}
              limit={initialParams.no_of_items_per_page}
              page={initialParams.page}
              totalCount={initialParams.total_count}
              noOfItems={referralTransactionsObj.transactions.length}
              filterOptions={[
                {
                  transaction_type: [
                    { label: "All", value: '' },
                    { label: 'Credit', value: 'credit' },
                    { label: 'Debit', value: 'debit' },
                  ]
                },
                {
                  currency: currencies
                }
              ]}
              onSelectQuery={(values) => {
                setIsSearching(true)
                const newQueryObj = {
                  ...initialParams,
                  ...values
                }
                setInitialParams(newQueryObj)
                handleGetReferralTransactions(newQueryObj)
              }}
            />}

          {referralTransactionsObj &&
            referralTransactionsObj.transactions &&
            referralTransactionsObj.transactions.length > 0 ?
            referralTransactionsObj.transactions.map((t, index) => {
              return (
                <div key={index} className="box-shadow border-radius-8 margin-top-10 margin-bottom-10 padding-4 padding-left-12 padding-right-12 font-size-13 padding-bottom-10">
                  <div className="d-flex">
                    <div className="col-5 align-items-center padding-top-6 padding-bottom-6">
                      <label className="grey font-size-13 line-height-13">ID</label>
                      <div className="font-size-14 line-height-14 text-break">
                        <b>
                          {t.id}
                        </b>
                      </div>
                    </div>

                    <div className="col-7 align-items-center padding-top-6 padding-bottom-6">
                      <label className="grey font-size-13 line-height-13">Date</label>
                      <div className="font-size-14 line-height-14 text-break">
                        <b>
                          {moment(t.created_at).format("DD MMM YYYY, HH:mm a")}
                        </b>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="col-5 align-items-center padding-top-6 padding-bottom-6">
                      <label className="grey font-size-13 line-height-13">Type</label>
                      <div className="font-size-14 line-height-14 text-break">
                        <b>
                          {convertSnakeToStandardCasing(t.transaction_type)}
                        </b>
                      </div>
                    </div>

                    <div className="col-7 align-items-center padding-top-6 padding-bottom-6">
                      <label className="grey font-size-13 line-height-13">Amount</label>
                      <div className="font-size-14 line-height-14 text-break normal-green">
                        <b>
                          <FormattedMoney value={t.amount} currency={t.amount_currency} />
                        </b>
                      </div>
                    </div>
                  </div>

                  {t.transaction_type == "credit" &&
                    <div className="row">
                      <div className="col-sm-5 align-items-center padding-top-6 padding-bottom-6">
                        <label className="grey font-size-13 line-height-13">Name of Referred</label>
                        <div className="font-size-14 line-height-14 text-break">
                          <b>
                            {t.referral ? `${t.referral.referred_first_name} ${t.referral.referred_last_name}` : "-"}
                          </b>
                        </div>
                      </div>

                      <div className="col-sm-7 align-items-center padding-top-6 padding-bottom-6">
                        <label className="grey font-size-13 line-height-13">Email of Referred</label>
                        <div className="font-size-14 line-height-14 text-break">
                          <b>
                            {t.referral ? `${t.referral.referred_email}` : "-"}
                          </b>
                        </div>
                      </div>
                    </div>
                  }

                </div>
              )
            })
            :
            <center>
              <p className="padding-top-40 padding-bottom-20 grey">You have no referral transaction {isSearching && "that matches this query"}</p>
            </center>
          }

          {
            (referralTransactionsObj && (referralTransactionsObj.total_count > initialParams.no_of_items_per_page)) ?
              <Pagination
                {...initialParams}
                handleGetQuery={handleGetReferralTransactions}
              /> :
              null
          }
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(MyAccruedEarning);
