import React, { useEffect } from "react";
import aboutUsImage from "./images/about_us_image.png";
import ServicesBlock from "./ServicesBlock"

const AboutUs = ({ history }) => {

  useEffect(()=>{
    document.title = "About Us Page"
  }, [])
  return (
    <div className="container">
      <div className="padding-top-40 padding-top-lg-0">
        <div className="row content-container align-items-center margin-bottom-40">
          <div className="col-sm-6">
            <img className="stretch-full" src={aboutUsImage} />
          </div>
          <div className="col-sm-6 stretch-full-md">
            <div className="bg-lighter-grey max-width-650 stretch-full-md padding-top-14 padding-top-md-50 padding-bottom-14 padding-bottom-md-60 padding-left-14 padding-left-md-40 padding-right-14 padding-right-md-40 border-radius-20">
              <h1 className="font-size-24 font-size-md-32 padding-bottom-8 padding-bottom-md-20 text-center dark-blue">About Us</h1>
              <p>
                Pouch Money is an online contributing platform that helps individuals, families, friends, colleagues, neighbours, etc,
                to organize contributions for a common or diverse cause. Our services include cyclic money pool, fundraiser and
                recurring upkeep contributions. We span across several countries and currencies across the globe.

              </p>
            </div>
          </div>
        </div>
        <div className="padding-top-20 padding-top-md-60 padding-bottom-30 padding-bottom-md-80 bg-lighter-grey">
          <div className="padding-bottom-0 padding-bottom-md-20">
            <h1 className="font-size-24 font-size-md-32 dark-blue text-center">
              Our Services
            </h1>
          </div>
          <div>
            <ServicesBlock
              history={history}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
