import axiosInstance from "../../helpers/axiosInstance";

// To get published posts
export const getPublishedPosts = (data) => {
    return async () => {
        return axiosInstance.get('/blog/get-published-posts', {
            params: data
        })
            .then(response => {
                return response.data
            })
            .catch(error => {
                return { error: error ? error.response.data : null }
            })
    }
};


// To get a specific post
export const getPost = (slug) => {
    return async () => {
        return axiosInstance.get('/blog/get-post/' + slug)
            .then(response => {
                return response.data
            })
            .catch(error => {
                return { error: error ? error.response.data : null }
            })
    }
};

// To get the privacy policy
export const getPrivacyPolicy = () => {
    return async () => {
        return axiosInstance.get('/blog/get-privacy-policy')
            .then(response => {
                return response.data
            })
            .catch(error => {
                return { error: error ? error.response.data : null }
            })
    }
};

// To get the terms and conditions policy
export const getTermsAndConditions = () => {
    return async () => {
        return axiosInstance.get('/blog/get-terms-and-conditions-policy')
            .then(response => {
                return response.data
            })
            .catch(error => {
                return { error: error ? error.response.data : null }
            })
    }
};
