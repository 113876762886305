import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import {
  pickCollectionDate,
  removeCollectionDate,
} from "../../../../../store/actions/moneyPool";
import { getConfirmAlert, getCurrencySymbol, numberWithCommas } from "../../../../../utilities";
import FormattedMoney from "../../../../utilityComponents/FormattedMoney";
import CurrentWindowWidth from "../../../../CurrentWindowWidth";

const DisplayCollectionDates = ({
  user,
  dispatch,
  poolId,
  isDateChangeable,
  collectionDates,
  setPool,
  setIsPageLoading,
}) => {

  let windowWidth = CurrentWindowWidth();

  const calculateNetInterest = (dateObj) => {
    return dateObj.total_interest_amount_to_receive - dateObj.total_interest_amount_to_pay
  }

  return (
    <div>
      <div className="red">
        You have picked {collectionDates.filter(date => date.user_id == user.id).length} hands
      </div>  <br />
      <div className="border-1 border-color-light-grey border-radius-4 max-width-900">

        <div className="horizontal-line d-block-display-sm" />

        <div>
          {collectionDates &&
            collectionDates.map((dateObj, index) => (
              <div key={index}>
                <div className="row bg-white align-items-center padding-0 padding-left-8 padding-left-sm-12 padding-bottom-10 padding-top-10">
                  <div className="row col-sm-9 margin-bottom-10 " >
                    <div className="d-flex padding-bottom-6">
                      <div className="col-2">
                        <b>#{index + 1}</b>
                      </div>
                      <div className="col-4">
                        {dateObj.is_picked ?
                          <div>
                            {dateObj.profile_picture ?
                              <img
                                className="fixed-width-40 fixed-height-40 rounded-circle"
                                src={`${dateObj.profile_picture}?${Date.now()}`}
                              /> :
                              <i className="fa fa-user-circle font-size-40 grey"></i>}
                          </div> : <i className="fa fa-user-circle font-size-40 grey"></i>}
                      </div>
                      <div className="col-6" >
                        <b className="font-size-md-16">{dateObj.is_picked ? (
                          <p>{dateObj.full_name}</p>
                        ) : (
                          <p>--------- ---------</p>
                        )}</b>
                        <div className="padding-right-2 font-size-14 font-size-md-16">Full Name</div>
                      </div>
                    </div>
                    <div className="d-flex padding-bottom-6">
                      <div className="col-6">
                        <b className={`font-size-16 
                        ${calculateNetInterest(dateObj) > 0 ? 'normal-green' : 'red'}`}>
                          <FormattedMoney
                            value={Math.abs(calculateNetInterest(dateObj))}
                            currency={dateObj.currency}
                            decimalScale={2}
                          />
                        </b>
                        <div className="padding-right-2 font-size-14 font-size-md-16"                        >
                          {calculateNetInterest(dateObj) > 0 ? "Net Interest To Receive" : "Net Interest To Pay"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${windowWidth > 577 ? "col-sm-3" : "d-flex"}`}>
                    <div
                      className="col-6 font-size-14 font-size-md-16 stretch-full padding-bottom-sm-8">
                      <b>{moment(dateObj.date).format("Do MMM YYYY")}</b>

                      <div className="padding-right-2 font-size-14 font-size-md-16">Receiving Date</div>
                    </div>
                    <div className={`${windowWidth > 577 ? "col-6 font-size-14 font-size-md-16" : "col-6"} fixed-height-60`} >
                      {
                        isDateChangeable == true ?
                          <div>
                            {
                              dateObj.is_picked ?
                                <span>
                                  {user.id == dateObj.user_id &&
                                    <button
                                      className="btn font-size-11 font-size-md-14 bg-transparent padding-4 padding-left-8 
                                      padding-right-8 border-color-red red border-radius-8"
                                      onClick={() => {
                                        getConfirmAlert({
                                          title: "Confirm!",
                                          message:
                                            "Are you sure you want to cancel this date?",
                                          onClickYes: () => {
                                            setIsPageLoading([true, "Loading..."]);
                                            dispatch(
                                              removeCollectionDate({
                                                picked_date_id: dateObj.picked_date_id,
                                                pool_id: poolId,
                                              })
                                            ).then((res) => {
                                              setIsPageLoading([false]);
                                              if (res.error) {
                                                NotificationManager.error(res.error.message, "Error!")
                                              } else {
                                                if (res.success == true) {
                                                  setPool(res.data);
                                                  NotificationManager.success(res.message, "Success!")
                                                }
                                              }
                                            });
                                          },
                                          onClickNo: () => { },
                                        });
                                      }}
                                    >
                                      Cancel Date
                                    </button>
                                  }
                                </span>
                                :
                                <button
                                  className="btn font-size-11 font-size-md-14 bg-normal-green padding-4 padding-left-8 padding-right-8
                           margin-right-6 white border-radius-8"
                                  onClick={() => {
                                    let risk = getCurrencySymbol(dateObj.currency) + numberWithCommas(dateObj.risk_amount)
                                    let risk_message = `Make sure you already have a guarantor to cover for you the risk of ${risk}.`

                                    getConfirmAlert({
                                      title: "Confirm!",
                                      message:
                                        `Are you sure you want to pick this date? ${dateObj.risk_amount > 0 ? risk_message : ""} `,
                                      onClickYes: () => {
                                        setIsPageLoading([true, "Loading..."]);
                                        dispatch(
                                          pickCollectionDate({
                                            pool_id: poolId,
                                            picked_date: dateObj.date,
                                          })
                                        ).then((res) => {
                                          setIsPageLoading([false]);
                                          if (res.error) {
                                            NotificationManager.error(res.error.message, "Error!")
                                          } else {
                                            if (res.success == true) {
                                              setPool(res.data);
                                              NotificationManager.success(res.message, "Success!")
                                            }
                                          }
                                        });
                                      },
                                      onClickNo: () => { },
                                    });
                                  }}
                                >
                                  Pick Date
                                </button>
                            }
                          </div>
                          :
                          null
                      }
                    </div>
                  </div>
                </div>
                {<div className="horizontal-line" />}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(DisplayCollectionDates);
