import React, { useState, useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { resetPassword } from '../../store/actions/auth'
import { NotificationManager } from 'react-notifications';

const ConfirmPasswordReset = (props) => {
    const [error, setError] = useState("")

    const uid = props.match.params.uid;
    const token = props.match.params.token;

    useEffect(() => {
        document.title = "Confirm Password Reset"
    }, [])

    return (
        <div className="container">
            <div className="content-container">
                <span>{error}</span>

                <div className="form">
                    <div className="form__content form__content--small">
                        <Formik
                            initialValues={{
                                new_password1: "",
                                new_password2: "",
                                uid,
                                token
                            }}
                            validationSchema={Yup.object({
                                new_password1: Yup.string()
                                    .required('New password is required'),
                                new_password2: Yup.string()
                                    .required('Repeat password is required')
                                    .oneOf([Yup.ref('new_password1'), null], "Passwords don't match!")
                                    .matches(),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                props.dispatch(resetPassword(values)).then(res => {
                                    setSubmitting(false);
                                    if (res && res.error) {
                                        if (res.error.token) {
                                            setError("Invalid token. Please request a new password reset email.")
                                        } else if(res.error.message){
                                            setError(res.error.message)
                                        }
                                    } else {
                                        if(res.success){
                                            NotificationManager.success(res.message, "Success!")
                                        }
                                    }
                                })
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <h2>Enter a new password</h2>
                                    <div className="column">
                                        <Field
                                            className="custom-input"
                                            type="password"
                                            name="new_password1"
                                            placeholder="New Password"
                                        />
                                        <ErrorMessage
                                            className="error-message"
                                            name="new_password1"
                                            component="div"
                                        />
                                    </div>
                                    <div className="column">
                                        <Field
                                            className="custom-input"
                                            type="password"
                                            name="new_password2"
                                            placeholder="Repeat Password"
                                        />
                                        <ErrorMessage
                                            className="error-message"
                                            name="new_password2"
                                            component="div"
                                        />
                                    </div>
                                    <div className="error-message">
                                        {error}
                                    </div>
                                    <button className="button button--secondary" type="submit" disabled={isSubmitting}>
                                        Submit
                                    </button>
                                </Form>
                            )}
                        </Formik>
                        <div className="horizontal-line" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect()(ConfirmPasswordReset)