import React from "react";
import NumberFormat from 'react-number-format';
import { getCurrencySymbol } from "../../utilities";

const FormattedMoney = ({
  value,
  currency,
  decimalScale,
}) => {
  if (!decimalScale) {
    decimalScale = 0
  }
  return (
    <div>
      <span>
        {getCurrencySymbol(currency)}
      </span>
      <NumberFormat
        style={{ whiteSpace: "nowrap" }}
        value={value ? value : 0}
        displayType={'text'}
        thousandSeparator={true}
        // prefix={getCurrencySymbol(currency)}
        decimalScale={decimalScale}
      />
    </div>
  );
};

export default FormattedMoney
