import React, { useEffect } from "react";

const NotFoundPage = () => {
  useEffect(() => {
    document.title = "Page Not Found"
  }, [])

  return (
    <div className="container">
      <div className="content-container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h2>Page not found!</h2>
      </div>
    </div>
  );
};

export { NotFoundPage as default };
