import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

const GetSalesReferralLinks = ({ user }) => {
  const [fundraiserLink, setFundraiserLink] = useState("");
  const [upkeepLink, setUpkeepLink] = useState("");
  const [moneyPoolLink, setMoneyPoolLink] = useState("");

  const [isFundraiserLinkCopiedVisible, setIsFundraiserLinkCopiedVisible] = useState(false);
  const [isUpkeepLinkCopiedVisible, setIsUpkeepLinkCopiedVisible] = useState(false);
  const [isMoneyPoolLinkCopiedVisible, setIsMoneyLinkCopiedVisible] = useState(false);

  const handleCopyFundraiserLink = () => {
    setIsFundraiserLinkCopiedVisible(true);

    setTimeout(() => {
      setIsFundraiserLinkCopiedVisible(false);
    }, 2000);
  };

  const handleCopyUpkeepLink = () => {
    setIsUpkeepLinkCopiedVisible(true);

    setTimeout(() => {
      setIsUpkeepLinkCopiedVisible(false);
    }, 2000);
  };

  const handleCopyMoneyPoolLink = () => {
    setIsMoneyLinkCopiedVisible(true);

    setTimeout(() => {
      setIsMoneyLinkCopiedVisible(false);
    }, 2000);
  };

  useEffect(() => {
    setFundraiserLink(`${process.env.REACT_APP_FRONTEND_BASE_URL}/dashboard/fundraiser/new?agentID=${user.agent_id}`);
    setUpkeepLink(`${process.env.REACT_APP_FRONTEND_BASE_URL}/dashboard/upkeep/new?agentID=${user.agent_id}`);
    setMoneyPoolLink(`${process.env.REACT_APP_FRONTEND_BASE_URL}/dashboard/money-pool/new?agentID=${user.agent_id}`);
  }, []);

  return (
    <div>
      <div>
        {isFundraiserLinkCopiedVisible ? <p className="normal-green">Copied!</p> : null}
        <h4 className=" dark-blue">
          Fundraiser
        </h4>
        <div className="d-flex">
          <CopyToClipboard
            text={fundraiserLink}
            onCopy={() => {
              handleCopyFundraiserLink();
            }}
          >
            <input
              className="form-control font-size-13 font-size-sm-16 padding-8 padding-sm-12"
              type="text"
              value={fundraiserLink}
            // disabled={true}
            />
          </CopyToClipboard>
          <div
            className="btn margin-left-10 bg-dark-blue white max-width-60 padding-8 font-size-13 font-size-sm-15 border-radius-8"
          >
            <CopyToClipboard
              text={fundraiserLink}
              onCopy={() => {
                handleCopyFundraiserLink();
              }}
            >
              <span>Copy</span>
            </CopyToClipboard>
          </div>
        </div>
      </div>

      <br />

      <div>
        {isUpkeepLinkCopiedVisible ? <p className="normal-green">Copied!</p> : null}
        <h4 className=" dark-blue">
          Upkeep
        </h4>
        <div className="d-flex">
          <CopyToClipboard
            text={upkeepLink}
            onCopy={() => {
              handleCopyUpkeepLink();
            }}
          >
            <input
              className="form-control font-size-13 font-size-sm-16 padding-8 padding-sm-12"
              type="text"
              value={upkeepLink}
            // disabled={true}
            />
          </CopyToClipboard>
          <div
            className="btn margin-left-10 bg-dark-blue white max-width-60 padding-8 font-size-13 font-size-sm-15 border-radius-8"
          >
            <CopyToClipboard
              text={upkeepLink}
              onCopy={() => {
                handleCopyUpkeepLink();
              }}
            >
              <span>Copy</span>
            </CopyToClipboard>
          </div>
        </div>
      </div>

      <br />

      <div>
        {isMoneyPoolLinkCopiedVisible ? <p className="normal-green">Copied!</p> : null}
        <h4 className=" dark-blue">
          Cyclic Money Pool
        </h4>
        <div className="d-flex">
          <CopyToClipboard
            text={moneyPoolLink}
            onCopy={() => {
              handleCopyMoneyPoolLink();
            }}
          >
            <input
              className="form-control font-size-13 font-size-sm-16 padding-8 padding-sm-12"
              type="text"
              value={moneyPoolLink}
            // disabled={true}
            />
          </CopyToClipboard>
          <div
            className="btn margin-left-10 bg-dark-blue white max-width-60 padding-8 font-size-13 font-size-sm-15 border-radius-8"
          >
            <CopyToClipboard
              text={moneyPoolLink}
              onCopy={() => {
                handleCopyMoneyPoolLink();
              }}
            >
              <span>Copy</span>
            </CopyToClipboard>
          </div>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(GetSalesReferralLinks);
