import React, { useState, useEffect } from "react";
import { useSpring, animated } from 'react-spring'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import forgotPasswordBg from "../images/forgot_password_bg.jpg";
import { requestPasswordResetEmail } from "../../store/actions/auth";

const ForgotPassword = (props) => {
  const [error, setError] = useState("");

  const fadeStyles = useSpring({
    from: { opacity: 0 },
    config: { duration: 2000 },
    loop: {
      opacity: 1,
    },
  })

  const slideStyles = useSpring({
    from: { x: -100, opacity: 0 },
    config: { duration: 1000 },
    loop: {
      x: 0, opacity: 1,
    },
  })

  
  useEffect(()=>{
    document.title = "Forgot Password"
  }, [])

  return (
    <div className="container">
      <div className="content-container">
        <div className="padding-top-30 padding-top-md-60 padding-bottom-10 padding-bottom-md-80 padding-right-0 padding-right-lg-80">
          <div className="row">
            <div className="col-6 d-block-hidden-sm">
              <animated.img className="stretch-full" style={slideStyles} src={forgotPasswordBg} />
            </div>
            <div className="col-6 d-flex justify-content-center stretch-full-sm">
              <animated.div className="max-width-500" style={fadeStyles}>
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .required("Email is required")
                      .email("Must be an email"),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    props.dispatch(requestPasswordResetEmail(values))
                      .then(res => {
                        setSubmitting(false);
                        if (res.error) {
                          setError(res.error.message)
                        } else {
                          props.history.push("/status/password-reset-email-sent");
                        }
                      })
                  }}
                >
                  {({
                    isSubmitting
                  }) => (
                    <Form className="d-flex flex-column align-items-center">
                      <div>
                        <h1 className="font-size-21 font-size-md-32 margin-bottom-10 margin-bottom-sm-20">
                          Forgot your password?
                        </h1>
                      </div>
                      <p className=" font-size-15 font-size-md-17 grey">
                        Please enter the email address associated with your
                        account
                      </p>
                      <div className="margin-top-10 margin-top-md-80 margin-bottom-20 margin-bottom-md-80 stretch-full">
                        <div>
                          <Field
                            className="input padding-top-14 padding-top-md-16 padding-bottom-14 padding-bottom-md-16 padding-left-0 padding-right-10 font-size-15 font-size-lg-17 border-color-dark-blue border-0 border-bottom-2 border-radius-0 focus-none bg-transparent"
                            placeholder="Enter email address"
                            type="email"
                            name="email"
                          />
                          <ErrorMessage
                            className="red font-size-12"
                            name="email"
                            component="div"
                          />
                        </div>
                        <div className="red font-size-12">{error}</div>
                      </div>
                      <button
                        className="btn max-width-150 font-size-15 font-size-md-17 margin-top-10 margin-top-md-20 margin-bottom-10 margin-bottom-md-20  padding-16 padding-md-20 border-radius-14 bg-dark-blue white"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Send
                      </button>
                    </Form>
                  )}
                </Formik>
              </animated.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ForgotPassword);
