import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import {
  getBankBranchList,
  getBankList,
  verifyBankAccount,
} from "../../store/actions/transaction";

const VerifyBankAccount = ({
  user,
  dispatch,
  setIsPageLoading,
  handleOnAccountVerification
}) => {
  const [transferCountries, setTransferCountries] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Generate an array for bank transfer supported countries
    const countries = user.bank_transfer_countries.map((country) => {
      return {
        label: country.country,
        value: country.country_code,
      };
    });
    setTransferCountries(countries);
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          country_code: "",
          account_number: "",
          account_bank: "",
          bank_name: "",
          bank_branch_code: "",
          bank_branch_name: "",
          is_bank_branch: false
        }}
        validationSchema={Yup.object({
          country_code: Yup.string().required("Please select a country"),
          account_bank: Yup.string().required("Please select a bank"),
          account_number: Yup.number()
            .typeError("Must be a number")
            .positive("Must be a positive number")
            .integer("Must be a whole number")
            .required("Account number is required"),
          bank_branch_code: Yup.string()
            .when("is_bank_branch", {
              is: true,
              then: Yup.string().required("Please select a bank branch"),
              otherwise: Yup.string().nullable()
            }),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setIsPageLoading([true, "Loading..."]);

          dispatch(verifyBankAccount(values))
            .then((res) => {
              setSubmitting(false);
              setIsPageLoading([false]);

              if (res.error) {
                setErrorMessage(res.error.message);
              } else {
                if (res.success == true) {
                  const bankAccount = res.data;

                  handleOnAccountVerification({
                    bank_account_name: bankAccount.account_name,
                    bank_account_number: bankAccount.account_number,
                    account_bank: values.account_bank,
                    bank_name: values.bank_name,
                    country_code: values.country_code,
                    bank_branch_code: values.bank_branch_code,
                    bank_branch_name: values.bank_branch_name
                  });
                }
              }
            });
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form>
            <div>
              {errorMessage ? (
                <p className="padding-top-10 padding-bottom-4 red">
                  {errorMessage}
                </p>
              ) : null}
              <div className="padding-top-6 padding-bottom-12">
                <label className="form-label font-size-15">Country</label>
                <Select
                  className="form-control padding-0 border-color-dark-blue"
                  classNamePrefix="react-select"
                  placeholder="Select Country of Bank"
                  options={transferCountries}
                  value={
                    transferCountries
                      ? transferCountries.find((option) => {
                        return option.value === values.country_code
                      })
                      : ""
                  }
                  onChange={(countryCode) => {
                    setIsPageLoading([true, "Loading..."]);

                    setFieldValue("country_code", countryCode.value);
                    setBranchList([])
                    setFieldValue("account_bank", "");
                    setFieldValue("bank_branch_name", "");
                    setFieldValue("bank_branch_code", "");
                    setFieldValue("is_bank_branch", false);

                    dispatch(getBankList(countryCode.value))
                      .then((res) => {
                        setIsPageLoading([false])
                        if (res.error) {
                          NotificationManager.error(res.error.message, "Error!")
                        } else {
                          if (res.success == true) {
                            const banks = res.data;

                            let list = banks.map((bank) => {
                              return {
                                label: bank.name,
                                value: bank,
                              };
                            });
                            setBankList(list);
                          }
                        }
                      })
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="country_code"
                  component="div"
                />
              </div>

              <div className="padding-top-12 padding-bottom-12">
                <label className="form-label font-size-15">Bank</label>
                <Select
                  className="form-control padding-0 border-color-dark-blue"
                  classNamePrefix="react-select"
                  placeholder="Select bank"
                  options={bankList}
                  value={
                    bankList
                      ? bankList.find(
                        (option) =>
                          option.value.code === values.account_bank
                      )
                      : ""
                  }
                  onChange={(bank) => {
                    setFieldValue("account_bank", bank.value.code);
                    setFieldValue("bank_name", bank.value.name);

                    if (bank.value.id) {
                      setIsPageLoading([true, "Loading..."]);

                      dispatch(getBankBranchList(bank.value.id))
                        .then((res) => {
                          setIsPageLoading([false]);

                          if (res.error) {
                            NotificationManager.error(res.error.message, "Error!")
                          } else {
                            if (res.success == true) {
                              const branches = res.data;

                              if (branches.length > 0) {
                                let list = branches.map((branch) => {
                                  return {
                                    label: branch.branch_name,
                                    value: branch,
                                  };
                                });
                                setFieldValue("bank_branch_name", "");
                                setFieldValue("bank_branch_code", "");
                                setFieldValue("is_bank_branch", true);
                                setBranchList(list)
                              } else {
                                setBranchList([])
                                setFieldValue("bank_branch_name", "");
                                setFieldValue("bank_branch_code", "");
                                setFieldValue("is_bank_branch", false);
                              }
                            }
                          }
                        })
                    }
                  }}
                />
                <ErrorMessage
                  className="error-message"
                  name="account_bank"
                  component="div"
                />
              </div>

              {branchList.length > 0 &&
                <div className="padding-top-12 padding-bottom-12">
                  <label className="form-label font-size-15">Branch</label>
                  <Select
                    className="form-control padding-0 border-color-dark-blue"
                    classNamePrefix="react-select"
                    placeholder="Select branch"
                    options={branchList}
                    value={
                      branchList
                        ? branchList.find(
                          (option) =>
                            option.value.branch_code === values.bank_branch_code
                        )
                        : ""
                    }
                    onChange={(branch) => {
                      setFieldValue("bank_branch_code", branch.value.branch_code);
                      setFieldValue("bank_branch_name", branch.value.branch_name);
                      setFieldValue("is_bank_branch", true);
                    }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="bank_branch_code"
                    component="div"
                  />
                </div>
              }

              <div className="padding-top-12 padding-bottom-12">
                <label className="form-label font-size-15">
                  Account Number
                </label>
                <Field
                  className="form-control padding-top-10 padding-bottom-10 border-color-dark-blue"
                  placeholder="Enter account Number"
                  type="text"
                  name="account_number"
                  value={values.account_number}
                  onChange={handleChange("account_number")}
                />
                <ErrorMessage
                  className="error-message"
                  name="account_number"
                  component="div"
                />
              </div>

              <button
                className="btn margin-top-12 margin-bottom-12 padding-10 bg-dark-blue white max-width-120 font-size-16 border-radius-8"
                type="button"
                onClick={handleSubmit}
              >
                <b>Verify</b>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(VerifyBankAccount);
