import axiosInstance from "../../helpers/axiosInstance";

// Sends message when not using websocket
export const sendChatWithAttachment = (data) => {
  return async () => {
    return axiosInstance.post(
      "/notifications/chats/submit-chat-with-attachment", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return { error: error ? error.response.data : null }
      });
  };
};