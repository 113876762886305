import React, { useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { getUser } from "../../../../store/actions/auth"
import ProfileSettings from "./ProfileSettings";
import NotificationSettings from "./NotificationSettings";
import PasswordSettings from "./PasswordSettings";
import SecurityQuestions from "./SecurityQuestions";
import passwordIcon from "../../../images/password_icon_darkblue.svg";
import bellIcon from "../../../images/bell_icon_darkblue.svg";

const Account = ({ setIsPageLoading, dispatch }) => {
  const [isProfileSettings, setIsProfileSettings] = useState(false);
  const [isPasswordSettings, setIsPasswordSettings] = useState(false);
  const [isSecurityQuestionsVisible, setIsSecurityQuestionsVisible] = useState(false);

  const [isNotificationSettings, setIsNotificationSettings] = useState(false);

  return (
    <div>
      <div>
        <div className="padding-bottom-18">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => {
              setIsPageLoading([true, "loaging..."])
              dispatch(getUser())
                .then(() => {
                  setIsPageLoading([false])
                  setIsProfileSettings(true)
                })
            }}
          >
            <i className="far fa-user font-size-20 margin-right-20 margin-right-sm-24"></i>
            <span className="font-size-14 font-size-sm-17">
              Profile Settings
            </span>
          </div>
        </div>

        <div className="padding-top-18 padding-bottom-10">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsPasswordSettings(true)}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={passwordIcon}
            />
            <span className="font-size-14 font-size-sm-17">
              Password Settings
            </span>
          </div>
        </div>

        <div className="padding-top-18 padding-bottom-10">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsSecurityQuestionsVisible(true)}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={passwordIcon}
            />
            <span className="font-size-14 font-size-sm-17">
              Security Questions
            </span>
          </div>
        </div>

        <div className="padding-top-18 padding-bottom-10">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => {
              dispatch(getUser())
                .then(() => {
                  setIsNotificationSettings(true)
                })
            }}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={bellIcon}
            />
            <span className="font-size-14 font-size-sm-17">
              Notification Settings
            </span>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isProfileSettings}
        onRequestClose={() => setIsProfileSettings(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Profile Settings
          </h1>
          <i
            onClick={() => setIsProfileSettings(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="overflow-scroll-y padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
          style={{ maxHeight: "80vh" }}>
          <ProfileSettings
            setIsPageLoading={setIsPageLoading}
            handleClose={() => setIsProfileSettings(false)} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isPasswordSettings}
        onRequestClose={() => setIsPasswordSettings(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Password Settings
          </h1>
          <i
            onClick={() => setIsPasswordSettings(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40">
          <PasswordSettings
            setIsPageLoading={setIsPageLoading}
            handleClose={() => setIsPasswordSettings(false)}
          />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isSecurityQuestionsVisible}
        onRequestClose={() => setIsSecurityQuestionsVisible(false)}
        className="modal max-width-750 padding-0"
        overlayClassName="modal-backdrop"
      >
        <SecurityQuestions
          setIsPageLoading={setIsPageLoading}
          handleClose={() => setIsSecurityQuestionsVisible(false)}
          onSaveSuccessful={() => {
            setIsSecurityQuestionsVisible(false)
          }}
        />
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isNotificationSettings}
        onRequestClose={() => setIsNotificationSettings(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Notification Settings
          </h1>
          <i
            onClick={() => setIsNotificationSettings(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>
        <div className="horizontal-line margin-0" />
        <div className="padding-10 padding-top-20 padding-bottom-20 padding-left-md-40 padding-right-md-40">
          <NotificationSettings setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>
    </div>
  );
};

export default connect()(Account);
