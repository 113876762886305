import React, { useState } from "react";
import Modal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useGoogleLogin } from '@react-oauth/google';
import { NotificationManager } from 'react-notifications';
import AppleSignin from 'react-apple-signin-auth';
import {
  saveNewPassword,
} from "../../../../store/actions/auth";
import MultiFactorAuth from "../../../authForms/MultiFactorAuth";

const PasswordSettings = ({
  setIsPageLoading,
  dispatch,
  handleClose,
}) => {
  const [error, setError] = useState("");
  const [authenticationMethod] = useState(localStorage.getItem("authenticationMethod"));
  const [isMfaVisible, setIsMfaVisible] = useState(false);
  const [enabledMfas, setEnabledMfas] = useState([]);
  const [isCurrentPwdVisible, setIsCurrentPwdVisible] = useState(false);
  const [isNewPwdVisible, setIsNewPwdVisible] = useState(false);
  const [isRepeatPwdVisible, setIsRepeatPwdVisible] = useState(false);
  const [appleAuthCredentials, setAppleAuthCredentials] = useState({});

  const handleChangePassword = async (data) => {
    const res = await dispatch(saveNewPassword(data))
    if (res.error) {
      if (res.error.loginError) {
        NotificationManager.error(res.error.loginError, "Error")
      }
      else if (res.error.mfaRequired) {
        setEnabledMfas(res.error.mfaRequired.data.enabled_mfas)
        setIsMfaVisible(true)
      } else if (res.error.invalidOtp) {
        NotificationManager.error(res.error.invalidOtp.message, "Error!")
      }
    } else {
      if (res.okMessage) {
        NotificationManager.success(res.okMessage, "Success")
        setIsMfaVisible(false)
        handleClose()
      }
    }
  }


  // Google
  const onGoogleLogin = useGoogleLogin({
    onSuccess: (response) => {
    },
    onError: (error) => {
      console.log(error)
    }
    ,
    flow: 'implicit',
  });

  return (
    <div>
      <Formik
        initialValues={{
          current_password: "",
          new_password1: "",
          new_password2: "",
          authentication_method: authenticationMethod,
          social_media_access_token: "",
          code: "",
          id_token: "",
          otp_type: "",
          otp: "",
        }}
        validationSchema={Yup.object({
          current_password: Yup.string()
            .when("authentication_method", {
              is: "password",
              then: Yup.string().required("Current password is required"),
              otherwise: Yup.string().nullable()
            }),
          new_password1: Yup.string()
            .min(8, "Password must be at least 8 characters")
            .required("Password is required"),
          new_password2: Yup.string()
            .required("Repeat password is required")
            .oneOf([Yup.ref("new_password1"), null], "Passwords don't match!")
            .matches(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (!isMfaVisible) {
            // Only when MFA is not involve
            handleChangePassword(values)
              .then(() => {
                setSubmitting(false);
              })
          }
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          values,
          errors,
          touched,
          isValid,
          ...props
        }) => (
          <Form>
            {error ? <div className="red ">{error}</div> : null}
            <div>

              {values.authentication_method == "password" &&
                <div className="padding-top-4 padding-top-sm-12 padding-bottom-12">
                  <label className="form-label">Current Password</label>
                  <div className="form-control padding-0 d-flex align-items-center">
                    <Field
                      className="form-control padding-12 focus-none"
                      placeholder="Enter old password"
                      name="current_password"
                      type={`${isCurrentPwdVisible ? "text" : "password"}`}
                    />
                    <i
                      className={`fa fa-eye${isCurrentPwdVisible ? "" : "-slash"} font-size-15 font-size-lg-17 padding-right-10`}
                      onClick={() => setIsCurrentPwdVisible(!isCurrentPwdVisible)}
                    ></i>
                  </div>
                  <ErrorMessage
                    className="error-message"
                    name="current_password"
                    component="div"
                  />
                </div>
              }

              <div className="padding-top-12 padding-bottom-12">
                <label className="form-label">New Password</label>
                <div className="form-control padding-0 d-flex align-items-center">
                  <Field
                    className="form-control padding-12 focus-none"
                    placeholder="Enter new password"
                    name="new_password1"
                    type={`${isNewPwdVisible ? "text" : "password"}`}
                  />
                  <i
                    className={`fa fa-eye${isNewPwdVisible ? "" : "-slash"} font-size-15 font-size-lg-17 padding-right-10`}
                    onClick={() => setIsNewPwdVisible(!isNewPwdVisible)}
                  ></i>
                </div>
                <ErrorMessage
                  className="error-message"
                  name="new_password1"
                  component="div"
                />
              </div>

              <div className="padding-top-12 padding-bottom-12">
                <label className="form-label">Repeat New Password</label>
                <div className="form-control padding-0 d-flex align-items-center">
                  <Field
                    className="form-control padding-12 focus-none"
                    placeholder="Enter repeat password"
                    name="new_password2"
                    type={`${isRepeatPwdVisible ? "text" : "password"}`}
                  />
                  <i
                    className={`fa fa-eye${isRepeatPwdVisible ? "" : "-slash"} font-size-15 font-size-lg-17 padding-right-10`}
                    onClick={() => setIsRepeatPwdVisible(!isRepeatPwdVisible)}
                  ></i>
                </div>
                <ErrorMessage
                  className="error-message"
                  name="new_password2"
                  component="div"
                />
              </div>

              {(authenticationMethod == "password" || authenticationMethod == "biometrics") &&
                <center>
                  <button
                    className="btn text-align-center bg-dark-blue white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180"
                    type="button"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    <b>Save Changes</b>
                  </button>
                </center>
              }

              {authenticationMethod == "google" &&
                <center>
                  <div
                    onClick={() => {
                      setTouched({
                        new_password1: true,
                        new_password2: true
                      })
                        .then(errors => {
                          if (Object.keys(errors).length === 0) {
                            onGoogleLogin()
                          }
                        })
                    }} className="d-flex white">
                    <i className="fab fa-google d-flex align-items-center justify-content-center fixed-width-50 
          fixed-height-40 font-size-22 bg-orange-red border-top-left-radius-10 border-bottom-left-radius-10"></i>
                    <div className="d-flex cursor-pointer align-items-center justify-content-center stretch-full font-size-16
           fixed-height-40 text-center bg-orange-red opacity-90 border-top-right-radius-10 border-bottom-right-radius-10">
                      Save Changes
                    </div>
                  </div>
                </center>
              }

              {
                authenticationMethod == "facebook" &&
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  autoLoad={false}
                  callback={(res) => {
                    setFieldValue("social_media_access_token", res.accessToken)
                    handleSubmit()
                  }}
                  render={(renderProps) => (
                    <center
                      onClick={() => {
                        setTouched({
                          new_password1: true,
                          new_password2: true
                        })
                          .then(errors => {
                            if (Object.keys(errors).length === 0) {
                              renderProps.onClick()
                            }
                          })
                      }}>
                      <div className="btn bg-dark-blue white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180">
                        Save Changes
                      </div>
                    </center>
                  )}
                />
              }

              {
                authenticationMethod == "apple" &&
                <div>
                  <AppleSignin
                    /** Auth options passed to AppleID.auth.init() */
                    authOptions={{
                      /** Client ID - eg: 'com.example.com' */
                      clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                      /** Requested scopes, seperated by spaces - eg: 'email name' */
                      scope: 'email name',
                      /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                      redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
                      /** State string that is returned with the apple response */
                      state: 'state',
                      /** Nonce */
                      nonce: 'nonce',
                      /** Uses popup auth instead of redirection */
                      usePopup: true,
                    }}
                    onSuccess={(res) => {
                      const data = {
                        code: res.authorization.code,
                        id_token: res.authorization.id_token,
                        client_id: process.env.REACT_APP_APPLE_CLIENT_ID,
                      }

                      setAppleAuthCredentials(data)

                      setFieldValue("client_id", process.env.REACT_APP_APPLE_CLIENT_ID)
                      setFieldValue("code", res.authorization.code)
                      setFieldValue("id_token", res.authorization.id_token)

                      handleSubmit()
                    }}

                    /** Called upon signin error */
                    onError={(error) => console.error(error)} // default = undefined

                    render={(renderProps) => (
                      <center
                        onClick={() => {
                          setTouched({
                            new_password1: true,
                            new_password2: true
                          })
                            .then(errors => {
                              if (Object.keys(errors).length === 0) {
                                renderProps.onClick()
                              }
                            })
                        }}>
                        <div className="btn bg-dark-blue white font-size-16 margin-top-20 margin-bottom-12 padding-10 max-width-180" >
                          Save Changes
                        </div>
                      </center>
                    )}

                  // render={(props) => <div
                  //   {...props}
                  //   className="d-flex white margin">
                  //   <i className="fab fa-apple d-flex align-items-center justify-content-center fixed-width-50 fixed-height-40 font-size-22 bg-black border-top-left-radius-10 border-bottom-left-radius-10"></i>
                  //   <div
                  //     onClick={() => {
                  //       setTouched({
                  //         new_password1: true,
                  //         new_password2: true
                  //       })
                  //         .then(errors => {
                  //           if (Object.keys(errors).length === 0) {
                  //             props.onClick()
                  //           }
                  //         })
                  //     }}
                  //     className="d-flex cursor-pointer align-items-center justify-content-center stretch-full font-size-16 fixed-height-40 text-center bg-black opacity-90 border-top-right-radius-10 border-bottom-right-radius-10">
                  //     Sign in with Apple
                  //   </div>
                  // </div>}
                  />
                  <span>
                    {props.socialLoginError ? (
                      <div className="margin-top-10">{props.socialLoginError}</div>
                    ) : null}
                  </span>
                </div>
              }
            </div>

            <Modal
              ariaHideApp={false}
              isOpen={isMfaVisible}
              onRequestClose={() => setIsMfaVisible(false)}
              className="modal max-width-650 padding-0"
              overlayClassName="modal-backdrop"
            >
              <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
                <h1 className="font-size-20 font-size-md-22 dark-blue">
                  Multi-Factor Authentication
                </h1>
                <i
                  onClick={() => setIsMfaVisible(false)}
                  className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
                ></i>
              </div>

              <div className="horizontal-line" />

              <div
                style={{ maxHeight: "80vh" }}
                className="overflow-scroll-y padding-10 padding-top-sm-20 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40"
              >
                <MultiFactorAuth
                  is_authenticator_app_enabled={enabledMfas.includes("authenticator_app")}
                  is_phone_two_factor_enabled={enabledMfas.includes("phone_otp")}
                  handleClose={() => setIsMfaVisible(false)}
                  isUsingSocialAuthentication={values.social_media_access_token != "" || appleAuthCredentials.id_token != ""}
                  handleSocialLogin={handleChangePassword}
                  handleLogin={handleChangePassword}
                  values={values}
                  socialMediaType={authenticationMethod}
                  authenticationMethod={authenticationMethod}
                  socialAccessToken={values.social_media_access_token}
                  appleAuthCredentials={appleAuthCredentials}
                />
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PasswordSettings);
