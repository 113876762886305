import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { NotificationManager } from "react-notifications";
import playStoreImg from "../images/play_store_img.png";
import appStoreImg from "../images/app_store_img.png";
import { subscribeNewsletter } from "../../store/actions/auth";

const BlogFooter = ({
  isAuthenticated,
  user,
  dispatch,
  history,
  setIsPageLoading
}) => {

  useEffect(() => {
  }, [])
  return (
    <div>
      <div className="bg-grad-darkblue-orange border-radius-20 margin-bottom-60 padding-top-14 padding-top-lg-30 padding-bottom-14 padding-bottom-lg-30 padding-left-10 padding-left-lg-60 padding-right-10 padding-right-lg-60">
        <Formik
          initialValues={{
            email: user.email ? user.email : ""
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .required("Email is required")
              .email("Must be an email")
          })}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(subscribeNewsletter(values))
              .then(res => {
                if (res.error) {
                  NotificationManager.error(res.error.message, "Error!")
                } else {
                  if (res.success) {
                    NotificationManager.success(res.message, "Success!")
                  }
                }
              })
          }}
        >
          {() => (
            <Form>
              <div className="row padding-10 padding-md-20">
                <b className="col-md-5 font-size-20 font-size-sm-26 font-size-lg-30 line-height-35 padding-top-10 padding-bottom-10 white">
                  Subscribe to our newsletter
                </b>
                <div className="row col-md-7 align-items-center padding-left-0 padding-left-md-10">
                  <div className="col-sm-9 padding-right-0 padding-right-sm-20">
                    <Field
                      className="form-control padding-top-14 padding-bottom-14 font-size-16"
                      name="email"
                    />
                  </div>
                  <div className="col-sm-3 padding-top-20 padding-bottom-20 padding-top-md-0 padding-bottom-md-0">
                    <button
                      className="btn bg-dark-blue font-size-16 max-width-140 border-radius-10 padding-14"
                      type="submit"
                    >Subscribe</button>
                  </div>
                  <ErrorMessage
                    className="red"
                    name="email"
                    component="div"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className="row padding-10 padding-lg-20">
          <b className="col-md-5 font-size-20 font-size-sm-26 font-size-md-30 line-height-35 padding-top-10 padding-bottom-10 white">
            Download our mobile app
          </b>
          <div className="d-flex col-md-7 align-items-center padding-left-0 padding-left-md-10">
            <div className="max-width-200 margin-right-20 margin-right-md-50">
              <a target='_blank' href={"https://apps.apple.com/app/pouchmoney/id1602625150"}>
                <img src={appStoreImg} className="max-width-200" />
              </a>
            </div>
            <div className="max-width-200">
              <a target='_blank' href={"https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.pouchmoney"}>
                <img src={playStoreImg} className="max-width-200" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {!isAuthenticated &&
        <div className="bg-dark-blue border-radius-20 margin-bottom-40 padding-top-14 padding-top-md-40 padding-bottom-14 padding-bottom-md-40 padding-left-14 padding-left-md-60 padding-right-14 padding-right-md-60">
          <center>
            <b className="col-md-5 font-size-20 font-size-sm-26 font-size-lg-28 line-height-35 white">
              Contribute money with friends and family.
            </b>
          </center>

          <Formik
            initialValues={{
              email: ""
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .required("Email is required")
                .email("Must be an email")
            })}
            onSubmit={(values, { setSubmitting }) => {
              history.push(`/register?email=${values.email}`)
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              values,
              setFieldValue,
              errors
            }) => (
              <Form>
                <div className="row padding-10 padding-md-20">
                  <div className="row align-items-center padding-left-0 padding-left-md-10 margin-top-30">
                    <div className="col-sm-9 padding-right-0 padding-right-sm-20">
                      <Field
                        className="form-control padding-top-14 padding-bottom-14 font-size-16"
                        name="email"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="col-sm-3 padding-top-20 padding-bottom-20 padding-top-md-0 padding-bottom-md-0">
                      <button className="btn max-width-150 bg-white dark-blue font-size-13 max-width-140 border-radius-10 padding-8 padding-top-14 padding-bottom-14">
                        <b>
                          SIGNUP FOR FREE
                        </b>
                      </button>
                    </div>
                  </div>
                  <div className="padding-left-0 padding-left-md-10" >
                    <ErrorMessage
                      className="red"
                      name="email"
                      component="div"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }

    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    isAuthenticated: !!state.auth.userToken,
  };
};

export default connect(mapStateToProps)(BlogFooter);