import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

const PasswordResetEmailSent = (props) => {

    useEffect(()=>{
        document.title = "Password Reset Email Sent"
    }, [])
    
    return (
        <div className="container">
            <div className="font-size-15 font-size-sm-16 text-center dark-blue padding-top-80 padding-bottom-150">
                <b>A password reset email has been sent.</b>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps)(PasswordResetEmailSent)