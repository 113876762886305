import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

const VerificationEmailSent = (props) => {
    useEffect(() => {
        document.title = "Verification Email Sent"
    }, [])

    return (
        // <div className="container">
        //     <div className="font-size-15 font-size-sm-16 text-center dark-blue padding-top-180 padding-bottom-150">
        //         <b className="border-1 border-color-black padding-70 border-radius-6">A verification email has been sent.</b>
        //     </div>
        // </div>
        <div className="container">
            <div className="font-size-15 font-size-sm-16 text-center dark-blue padding-top-80 padding-bottom-150">
                <b >A verification email has been sent.</b>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps)(VerificationEmailSent)

