import axios from "axios"
import { clearAuthCredentials, getCookie } from "../utilities";
import { RESTORE_TOKEN } from "../store/actionTypes/auth"
import { store } from "../index";

let headers = {}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    headers,

})

let csrftoken = getCookie('csrftoken')

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.xsrfCookieName = 'csrftoken'
axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken'
axiosInstance.defaults.headers.post['X-CSRFToken'] = csrftoken;
axiosInstance.defaults.withCredentials = true //For it to be able to send the csrftoken to server

axiosInstance.interceptors.request.use(
    async config => {
        let token = localStorage.getItem("token");
        let unParsedUser = localStorage.getItem("user");

        let user
        if (unParsedUser && unParsedUser != "undefined" && unParsedUser != "") {
            user = JSON.parse(unParsedUser);
        }

        if (unParsedUser == "undefined" || unParsedUser == "") {
            localStorage.removeItem(user)
        }

        if (token == "undefined" || token == "") {
            localStorage.removeItem(token)
        }



        if (token && token != "undefined" && user) {
            // There is a valid token
            store.dispatch({ type: RESTORE_TOKEN, token, user })

            config.headers.Authorization = `Token ${token}`
        }


        // if (token) {
        // config.headers.Authorization = `Token ${token}`
        //     store.dispatch({ type: 'RESTORE_TOKEN', token, user });
        // }

        return config
    },
    error => {
        return Promise.reject(error);
    })


axiosInstance.interceptors.response.use(response => {
    // Edit response config

    return response;
}, async error => {
    if (error.response && error.response.data && (
        error.response.data.detail == "Invalid token." ||
        error.response.data.detail == "Authentication credentials were not provided.")
    ) {
        await clearAuthCredentials()

        return Promise.reject(null)
    } else {
        return Promise.reject(error);
    }
});



export default axiosInstance
