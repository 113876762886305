import React, { useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import GetReferralLink from "./GetReferralLink";
import ReferAFriend from "./ReferAFriend";
import MyReferrals from "./MyReferrals";
import MyAccruedEarning from "./MyAccruedEarning";
import shareIcon from "../../../images/share_icon_darkblue.svg";
import handIcon from "../../../images/hand_icon_darkblue.svg";
import referIcon from "../../../images/refer_icon_darkblue.svg";
import managerIcon from "../../../images/manager_icon_darkblue.svg";

const ReferAndEarn = ({ setIsPageLoading }) => {
  const [isGetReferralLinkVisible, setIsGetReferralLinkVisible] = useState(
    false
  );
  const [isReferAFriendVisible, setIsReferAFriendVisible] = useState(false);
  const [isMyReferralsVisible, setIsMyReferralsVisible] = useState(false);
  const [isMyAccruedEarningVisible, setIsMyAccruedEarningVisible] = useState(
    false
  );
  return (
    <div>
      <div>
        <div className="padding-bottom-18">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsGetReferralLinkVisible(true)}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={shareIcon}
            />
            <span className="font-size-14 font-size-sm-17">
              Get a Referral Link
            </span>
          </div>
        </div>

        <div className="padding-top-18 padding-bottom-10">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsReferAFriendVisible(true)}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={referIcon}
            />
            <span className="font-size-14 font-size-sm-17">Refer a Friend</span>
          </div>
        </div>

        <div className="padding-top-18 padding-bottom-10">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsMyReferralsVisible(true)}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={managerIcon}
            />
            <span className="font-size-14 font-size-sm-17">My Referrals</span>
          </div>
        </div>

        <div className="padding-top-18 padding-bottom-10">
          <div
            className="d-flex cursor-pointer align-items-center bg-pale-navy stretch-full dark-blue padding-14 padding-left-sm-36 padding-right-sm-36 border-radius-8"
            onClick={() => setIsMyAccruedEarningVisible(true)}
          >
            <img
              className="fixed-width-20 margin-right-20 margin-right-sm-24"
              src={handIcon}
            />
            <span className="font-size-14 font-size-sm-17">
              My Referral Earnings
            </span>
          </div>
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isGetReferralLinkVisible}
        onRequestClose={() => setIsGetReferralLinkVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Referral Link
          </h1>
          <i
            onClick={() => setIsGetReferralLinkVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="overflow-scroll-y padding-10 padding-top-sm-30 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40">
          <GetReferralLink setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isReferAFriendVisible}
        onRequestClose={() => setIsReferAFriendVisible(false)}
        className="modal max-width-650 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            Refer a Friend
          </h1>
          <i
            onClick={() => setIsReferAFriendVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="overflow-scroll-y padding-10 padding-top-sm-30 padding-bottom-sm-30 padding-left-md-40 padding-right-md-40">
          <ReferAFriend setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isMyReferralsVisible}
        onRequestClose={() => setIsMyReferralsVisible(false)}
        className="modal max-width-1000 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-20 font-size-md-22 dark-blue">
            My Referrals
          </h1>
          <i
            onClick={() => setIsMyReferralsVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="overflow-scroll-y padding-10 padding-top-sm-20 padding-bottom-sm-40 padding-left-md-40 padding-right-md-40"
          style={{ minHeight: "60vh", maxHeight: "80vh" }}>
          <MyReferrals setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={isMyAccruedEarningVisible}
        onRequestClose={() => setIsMyAccruedEarningVisible(false)}
        className="modal max-width-950 padding-0"
        overlayClassName="modal-backdrop"
      >
        <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
          <h1 className="font-size-18 font-size-md-20 dark-blue">
            My Accrued Referral Earnings
          </h1>
          <i
            onClick={() => setIsMyAccruedEarningVisible(false)}
            className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
          ></i>
        </div>

        <div className="horizontal-line margin-0" />

        <div className="overflow-scroll-y padding-10 padding-top-sm-20 padding-bottom-20 padding-left-md-40 padding-right-md-40"
          style={{ height: "80vh" }}
        >
          <MyAccruedEarning setIsPageLoading={setIsPageLoading} />
        </div>
      </Modal>
    </div>
  );
};

export default connect()(ReferAndEarn);
