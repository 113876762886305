import React, { useState, useEffect } from "react";
import { useSpring, animated } from 'react-spring';
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import poolImage from "../../../images/money_pool_setup.svg";
import contractIcon from "../../../images/contract_icon_darkblue.svg";
import informationIcon from "../../../images/information_icon_darkblue.svg";
import calendarIcon from "../../../images/calendar_icon_darkblue.svg";
import cashlessPaymentIcon from "../../../images/cashless-payment_icon_darkblue.svg";
import playButtonIcon from "../../../images/play_button_icon_orange.svg";
import CurrentWindowWidth from "../../../CurrentWindowWidth";
import GenerateSampleMoneyPool from "../../../dashboard/GenerateSampleMoneyPool";
import LoadingPage from "../../../utilityComponents/LoadingPage";
import PublicMoneyPools from "../../../tableTemplates/pool/PublicMoneyPools";

const MoneyPoolHome = ({ history }) => {
  const fadeStyles = useSpring({
    from: { opacity: 0 },
    config: { duration: 2000 },
    loop: {
      opacity: 1,
    },
  })

  const slideStyles = useSpring({
    from: { x: -100, opacity: 0 },
    config: { duration: 1000 },
    loop: {
      x: 0, opacity: 1,
    },
  })

  const search = useLocation().search;
  const visibleModal = new URLSearchParams(search).get("visibleModal")

  const [isPageLoading, setIsPageLoading] = useState([false]);
  const [isExplainerVideoOpen, setIsExplainerVideoOpen] = useState(false)
  const [isGenerateSampleMoneyPoolVisible, setIsGenerateSampleMoneyPoolVisible] = useState(false);

  let windowWidth = CurrentWindowWidth();

  useEffect(() => {
    document.title = "Cyclic Money Pool Home"

    if (visibleModal == "samplePool") {
      setIsGenerateSampleMoneyPoolVisible(true)
    }


  }, [])

  return (
    <animated.div className="container"
      style={fadeStyles}>
      <LoadingPage isPageLoading={isPageLoading} />

      <div className="content-container">
        <div className="padding-top-30 padding-top-md-80 padding-left-0 padding-left-lg-40 padding-right-0 padding-right-lg-40"
        >
          <div className="row">
            <animated.div className="col-sm-4"
              style={slideStyles}>
              <h1 className="font-size-30 font-size-lg-40 margin-bottom-4 orange text-center">
                Cyclic Money Pool
              </h1>
              <img width="100%" src={poolImage} />
            </animated.div >
            <div className="col-sm-8 stretch-full-sm padding-left-0 padding-left-sm-30 padding-left-md-60">
              <div className="padding-top-30 padding-top-sm-10">
                <p className="font-size-17 font-size-lg-20 line-height-20 line-height-lg-28">
                  They call it "Ajo" or "Osusu". Set up a rotational
                  "contribute-and-carry" fund among the people you trust
                </p>
              </div>
              <div className="d-flex justify-content-between max-width-540 padding-top-30 padding-top-sm-100">
                <button
                  className="btn max-width-230 border-radius-14 font-size-13 font-size-sm-15 font-size-md-17 margin-right-14 padding-6 padding-top-sm-18 padding-bottom-sm-18 bg-money-pool-color white"
                  onClick={() => {
                    history.push("/dashboard/money-pool/new");
                  }}
                >
                  <b>Get started</b>
                </button>
                <button
                  className="btn d-flex align-items-center justify-content-evenly font-size-13 font-size-sm-15 font-size-md-17 max-width-230 border-radius-14 margin-left-14 padding-6 padding-top-sm-18 padding-bottom-sm-18 border-2 border-color-orange bg-white orange"
                  onClick={() => setIsExplainerVideoOpen(true)}
                >
                  <img
                    className="padding-left-6 padding-left-sm-0 padding-right-6 padding-right-sm-0 max-width-30"
                    src={playButtonIcon}
                  />
                  <b>Video Explainer</b>
                </button>
              </div>
              <div className="margin-top-20 margin-bottom-0  " >
                <b
                  onClick={() => setIsGenerateSampleMoneyPoolVisible(true)}
                  className="btn stretch-full-sm max-width-230 border-radius-14 font-size-13 font-size-sm-15 font-size-md-17 margin-right-14 padding-10 padding-top-sm-18 padding-bottom-sm-18 border-color-dark-blue dark-blue">
                  Generate Sample</b>
              </div>
              {/* <div className="margin-top-20 margin-bottom-0  " >
                <b
                  onClick={() => setIsGenerateSampleMoneyPoolVisible(true)}
                  className="btn stretch-full-sm max-width-230 border-radius-14 font-size-13 font-size-sm-15 font-size-md-17 margin-right-14 padding-10 padding-top-sm-18 padding-bottom-sm-18 border-color-dark-blue dark-blue">
                  Request to Join</b>
              </div> */}
            </div>
          </div>
        </div>

        <div className="padding-top-60 padding-bottom-50 padding-bottom-md-80">
          <div className="horizontal-line">
            <span>
              <h1 className="dark-blue text-center font-size-20 font-size-sm-24 font-size-md-30 margin-left-6 margin-left-sm-20 margin-right-6 margin-right-sm-20">
                How&nbsp;Cyclic&nbsp;Money Pool Works
              </h1>
            </span>
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-center text-center padding-bottom-20">
          <div className="max-width-320 margin-10 margin-top-40 margin-bottom-40 margin-left-sm-20 margin-right-sm-20">
            <div className="relative fixed-height-300 d-flex flex-column align-items-center padding-20 padding-sm-30 border-radius-14 bg-money-pool-light-color">
              <div className="absolute top-0 left-50-pct transform-50-50 bg-transparent padding-6 border-3 border-color-money-pool-color rounded-circle">
                <b className="d-flex align-items-center justify-content-center fixed-height-80 fixed-width-80 font-size-30 rounded-circle bg-money-pool-color white">
                  1
                </b>
              </div>

              <img
                className="padding-top-50 padding-bottom-20 max-width-60"
                src={contractIcon}
              />
              <div className="max-width-260">
                <b className="font-size-22 font-size-sm-24 line-height-22 line-height-sm-26">
                  Fill in the Money Pool details
                </b>
              </div>
            </div>
          </div>

          <div className="max-width-320 margin-10 margin-top-40 margin-bottom-40 margin-left-sm-20 margin-right-sm-20">
            <div className="relative fixed-height-300 d-flex flex-column align-items-center padding-20 padding-sm-30 border-radius-14 bg-money-pool-light-color">
              <div className="absolute top-0 left-50-pct transform-50-50 bg-transparent padding-6 border-3 border-color-money-pool-color rounded-circle">
                <b className="d-flex align-items-center justify-content-center fixed-height-80 fixed-width-80 font-size-30 rounded-circle bg-money-pool-color white">
                  2
                </b>
              </div>

              <img
                className="padding-top-50 padding-bottom-20 max-width-60"
                src={informationIcon}
              />
              <div className="max-width-260">
                <b className="font-size-22 font-size-sm-24 line-height-22 line-height-sm-26">
                  Invite members to opt into the pool
                </b>
              </div>
            </div>
          </div>

          <div className="max-width-320 margin-10 margin-top-40 margin-bottom-40 margin-left-sm-20 margin-right-sm-20">
            <div className="relative fixed-height-300 d-flex flex-column align-items-center padding-20 padding-sm-30 border-radius-14 bg-money-pool-light-color">
              <div className="absolute top-0 left-50-pct transform-50-50 bg-transparent padding-6 border-3 border-color-money-pool-color rounded-circle">
                <b className="d-flex align-items-center justify-content-center fixed-height-80 fixed-width-80 font-size-30 rounded-circle bg-money-pool-color white">
                  3
                </b>
              </div>

              <img
                className="padding-top-50 padding-bottom-20 max-width-60"
                src={calendarIcon}
              />
              <div className="max-width-260">
                <b className="font-size-22 font-size-sm-24 line-height-22 line-height-sm-26">
                  Pick or swap your favorite receiving date
                </b>
              </div>
            </div>
          </div>

          <div className="max-width-320 margin-10 margin-top-40 margin-bottom-40 margin-left-sm-20 margin-right-sm-20">
            <div className="relative fixed-height-300 d-flex flex-column align-items-center padding-20 padding-sm-30 border-radius-14 bg-money-pool-light-color">
              <div className="absolute top-0 left-50-pct transform-50-50 bg-transparent padding-6 border-3 border-color-money-pool-color rounded-circle">
                <b className="d-flex align-items-center justify-content-center fixed-height-80 fixed-width-80 font-size-30 rounded-circle bg-money-pool-color white">
                  4
                </b>
              </div>

              <img
                className="padding-top-50 padding-bottom-20 max-width-60"
                src={cashlessPaymentIcon}
              />
              <div className="max-width-260">
                <b className="font-size-22 font-size-sm-24 line-height-22 line-height-sm-26">
                  Set up payment and a receiving bank account
                </b>
              </div>
            </div>
          </div>
        </div>

        <div className="padding-top-60 padding-bottom-50 padding-bottom-md-80">
          <div className="horizontal-line">
            <span>
              <h1 className="dark-blue text-center font-size-20 font-size-sm-24 font-size-md-30 margin-left-6 margin-left-sm-20 margin-right-6 margin-right-sm-20">
                Public Cyclic Money Pools
              </h1>
            </span>
          </div>
        </div>

        <div className="
        row justify-content-center padding-bottom-20
        ">

          <PublicMoneyPools
            setIsPageLoading={setIsPageLoading}
            history={history} />
        </div>

        <Modal
          ariaHideApp={false}
          isOpen={isExplainerVideoOpen}
          onRequestClose={() => setIsExplainerVideoOpen(false)}
          className="modal"
          overlayClassName="modal-backdrop"
        >
          <iframe
            width={0.9 * windowWidth}
            height={0.45 * windowWidth}
            src="https://www.youtube.com/embed/JtfSyLLBEPM?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          ></iframe>
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={isGenerateSampleMoneyPoolVisible}
          onRequestClose={() => {
            setIsGenerateSampleMoneyPoolVisible(false)
            history.push("/money-pool")
          }}
          className="modal max-width-650 padding-0"
          overlayClassName="modal-backdrop"
        >
          <div className="d-flex padding-10 padding-top-12 padding-top-sm-20 padding-bottom-12 padding-bottom-sm-20 padding-left-md-40 padding-right-md-40 justify-content-between align-items-center">
            <h1 className="font-size-20 font-size-md-22 dark-blue">
              Sample Cyclic Money Pool
            </h1>
            <i
              onClick={() => {
                setIsGenerateSampleMoneyPoolVisible(false)
                history.push("/money-pool")
              }}
              className="fa fa-close font-size-22 dark-blue cursor-pointer hover-red"
            ></i>
          </div>
          <div className="horizontal-line margin-0" />
          <div className="overflow-scroll-y padding-left-10 padding-right-10 padding-bottom-10 padding-left-md-40 padding-right-md-40"
            style={{ maxHeight: "80vh" }}
          >
            <GenerateSampleMoneyPool
              setIsPageLoading={setIsPageLoading}
              history={history}
            />
          </div>
        </Modal>

      </div>
    </animated.div>
  );
};

export default MoneyPoolHome;
