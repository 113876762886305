import React, { useState } from "react";
// import { css } from "@emotion/core";
// import ScaleLoader from "react-spinners/ClipLoader";

const Spinner = (props) => {
 
    return (
        <div className={`d-flex align-items-center justify-content-center padding-left-10 padding-right-10 text-center ${props.backgroundColor && 'bg-back-drop-color'}`}
            style={{ height: "100%" }}>
            <div>

                <i className="fa fa-spinner orange" style={{ fontSize: props.fontSizeIcon ? props.fontSizeIcon : "20px" }}></i>

                {props.label &&
                    <div className={`padding-2 text-center dark-blue ${props.backgroundColor && 'white'}`}>
                        {props.label}
                    </div>
                }
            </div>
        </div>
    );
}

export default Spinner;