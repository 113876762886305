import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCurrencySymbol, numberWithCommas } from "../../utilities";

const ReferralReward = ({
  user
}) => {
  const [referralReward, setReferralReward] = useState(null);

  useEffect(() => {
    if (user && user.currency_data) {
      const referralReward = user.currency_data.find((item) => item.currency == user.preferred_currency)
      setReferralReward(referralReward)
    }
  }, []);

  return (
    <div>
      {referralReward &&
        <span>Earn {getCurrencySymbol(referralReward.currency)}{numberWithCommas(referralReward.referral_amount)} for every friend you refer when they make a minimum total transaction of {getCurrencySymbol(referralReward.currency)}{numberWithCommas(referralReward.minimum_amount)} </span>
      }
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ReferralReward);
