import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

const NoOfUnseenMessages = ({
  user,
  service_type,
  service_id
}) => {
  const [noOfUnseenMessages, setNoOfUnseenMessages] = useState(0)
  const [chatLastSeen, setChatLastSeen] = useState(null)

  const chatRef = useRef(null);

  const initializeChats = () => {

    chatRef.current = new WebSocket(
      `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/chat/${service_type}/${service_id}/connect`)

    chatRef.current.onopen = e => {

      chatRef.current.send(JSON.stringify({
        type: "chat_last_seen",
        user_id: JSON.parse(process.env.REACT_APP_LOCAL_TO_REMOTE_SERVER) ? user.id : null
      }))
    }

    chatRef.current.onmessage = e => {
      const data = JSON.parse(e.data)

      if (data.type == "chat_last_seen") {
        setNoOfUnseenMessages(data.last_seen_obj.no_of_messages_not_seen)
      } else if (data.type == "chat_message") {
        if (data.chat) {
          if (data.chat.sender.id != user.id) {
            setNoOfUnseenMessages(prev => {
              return prev + 1
            })
          }
        }
      } else if (data.type == "message_deleted") {
        if (data.chat) {
          if (data.chat.sender.id != user.id) {
            if (noOfUnseenMessages > 0) {
              setNoOfUnseenMessages(prev => {
                return prev - 1
              })
            }
          }
        }
      }
    }

    chatRef.current.onerror = e => {
      console.log('error', e)
    }
  }

  useEffect(() => {
    initializeChats()
  }, [])

  return (
    <div>
      {(noOfUnseenMessages > 0) &&
        <div>
          {noOfUnseenMessages > 0 && noOfUnseenMessages < 100 ?
            <span className="font-size-12 dark-blue bg-red white border-radius-10 width-50 height-50 padding-2 padding-left-4 padding-right-4 margin-left-8">{noOfUnseenMessages}</span>
            :
            <span className="font-size-12 dark-blue bg-red white border-radius-10 width-50 height-50 padding-2 padding-left-4 padding-right-4 margin-left-8">99+</span>
          }
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(NoOfUnseenMessages);

